import crypt from '@skavinvarnan/cryptlib';
import configuration from '../config';
import {dump} from './helpers';

export const ENCRYPTION_KEY = configuration.ENCRYPTION_KEY;
export const ENCRYPTION_ENABLED = configuration.ENCRYPTION_ENABLED;

/**
 * Determine whether the encryption featured enabled or not.
 *
 * @returns {boolean}
 */
export const isEncryptionEnabled = () => {
  return ENCRYPTION_ENABLED === 'true';
};

/**
 * Encrypt the given data.
 *
 * @param data
 * @returns {*}
 */
export const encrypt = data =>
  crypt.encryptPlainTextWithRandomIV(data, ENCRYPTION_KEY);

/**
 * Decrypt the given data.
 *
 * @param text
 * @returns {*}
 */
export const decrypt = text => {
  if (typeof text === 'string') {
    return crypt.decryptCipherTextWithRandomIV(text, ENCRYPTION_KEY);
  }

  return text;
};

/**
 * Encrypt the request.
 *
 * @param config
 * @returns {*}
 */
export const encryptRequest = config => {
  const {data, method, url, params: cfgParams} = config || {};
  const originalConfig = {params: cfgParams, data};

  const isFormData = data instanceof FormData;
  if (data && !isFormData) {
    const encryptedData = encrypt(JSON.stringify(data));
    config.data = {
      data: encryptedData,
    };
  }

  if (cfgParams) {
    const encryptedParams = {};
    for (let key in cfgParams) {
      if (cfgParams.hasOwnProperty(key)) {
        const value = cfgParams[key];
        if (value !== undefined && value !== null) {
          encryptedParams[key] = encrypt(value);
        }
      }
    }
    config.params = encryptedParams;
  }

  dump(`==AXIOS.REQ== ${method.toUpperCase()} ${url}`, {
    method: method.toUpperCase(),
    baseURL: config.baseURL,
    date: new Date(),
    original: originalConfig,
    encrypted: {params: config.params, data: config.data},
  });

  return config;
};

/**
 * Decrypt the response.
 *
 * @param response
 * @returns {*}
 */
export const decryptResponse = response => {
  const {config} = response;
  const {url, method} = config || {};
  const responseData = response.data?.data;
  let decryptedResponseData;

  if (responseData) {
    try {
      const decrypted = decrypt(responseData);
      decryptedResponseData =
        typeof decrypted === 'string' ? JSON.parse(decrypted) : decrypted;
      response.data.data = decryptedResponseData;
    } catch (err) {
      dump('Failed to decrypt response.', {err});
    }
  }

  dump(`==AXIOS.RES== ${method.toUpperCase()} ${url}`, {
    response,
    method: method.toUpperCase(),
    baseURL: config.baseURL,
    date: new Date(),
    params: config.params,
    decryptedResponseData,
    responseData,
  });

  return response;
};

/**
 * Decrypt the response.
 *
 * @param response
 * @returns {*}
 */
export const decryptResponseError = error => {
  const {response, config} = error;
  const {url, method} = config || {};
  const responseData = response.data?.data;
  let decryptedResponseData;

  if (responseData) {
    try {
      const decrypted = decrypt(responseData);
      decryptedResponseData =
        typeof decrypted === 'string' ? JSON.parse(decrypted) : decrypted;
      response.data.data = decryptedResponseData;
    } catch (err) {
      dump('Failed to decrypt error response.', {err});
    }
  }

  dump(`==AXIOS.RES.ERROR== ${method.toUpperCase()} ${url}`, {
    response,
    method: method.toUpperCase(),
    baseURL: config.baseURL,
    date: new Date(),
    params: config.params,
    decryptedResponseData,
    responseData,
  });

  return response;
};

/**
 * Encrypt the form body.
 *
 * @param data
 * @returns {*}
 */
export const encryptFormBody = data => {
  return encrypt(JSON.stringify(data));
};
