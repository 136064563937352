import {
  CREATE_MENU_REQUEST,
  FIND_MENU_REQUEST,
  GET_BEVERAGE_LIST_REQUEST,
  GET_LIST_MENU_REQUEST,
  ORDER_MENU_REQUEST,
  REMOVE_MENU_REQUEST,
  UPDATE_MENU_REQUEST,
} from './actionsList';

/**
 * Create New Beverage.
 *
 * @param data
 * @returns {{type: string, payload: {data: *}}}
 */
export const createMenuRequest = data => {
  return {type: CREATE_MENU_REQUEST, payload: {data}};
};

/**
 * Get Menu List.
 *
 * @param params
 * @returns {{type: string, payload: {page: *, limit: *, sortBy: *, search: *, descending: *}}}
 */
export const getMenuListRequest = params => {
  params = params || {};
  const {page, limit, sortBy, search, descending} = params;
  return {
    type: GET_LIST_MENU_REQUEST,
    payload: {page, limit, sortBy, search, descending},
  };
};

/**
 * Remove menu.
 *
 * @param id
 * @returns {{type: string, payload: {id: *}}}
 */
export const removeMenuRequest = (id, pagination) => {
  return {type: REMOVE_MENU_REQUEST, payload: {id, pagination}};
};

/**
 * Find menu by ID.
 *
 * @param id
 * @returns {{type: *, payload: {id: *}}}
 */
export const findMenuRequest = id => {
  return {type: FIND_MENU_REQUEST, payload: {id}};
};

/**
 * Update menu.
 *
 * @param id
 * @param data
 * @returns {{type: string, payload: {data: *}}}
 */
export const updateMenuRequest = (id, data) => {
  return {type: UPDATE_MENU_REQUEST, payload: {id, data}};
};

/**
 * Get Beverage List.
 *
 * @param params
 * @returns {{type: string, payload: {page: *, limit: *, sortBy: *, search: *, descending: *}}}
 */
export const getBeverageListRequest = params => {
  params = params || {};
  const {page, limit, sortBy, search, descending} = params;
  return {
    type: GET_BEVERAGE_LIST_REQUEST,
    payload: {page, limit, sortBy, search, descending},
  };
};

/**
 * Order menu.
 *
 * @param data
 * @returns {{type: string, payload: {data: *}}}
 */
export const orderMenuRequest = data => {
  return {type: ORDER_MENU_REQUEST, payload: {data}};
};
