export class Category {
  _id: number = null;

  _name: string = null;

  _icon: string = null;

  _image: string = null;

  constructor(
    id: number,
    name: string,
    icon: string = null,
    image: string = null
  ) {
    this._id = id;
    this._name = name;
    this._icon = icon;
    this._image = image;
  }

  get id(): number {
    return this._id;
  }

  set id(value: number) {
    this._id = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get icon(): string {
    return this._icon;
  }

  set icon(value: string) {
    this._icon = value;
  }

  get image(): string {
    return this._image;
  }

  set image(value: string) {
    this._image = value;
  }
}
