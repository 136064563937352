import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {openModal, toggleModal} from '../../actions/modal';
import connect from 'react-redux/es/connect/connect';
import {
  ModalHeader,
  Button,
  ModalBody,
  Label,
  FormGroup,
  UncontrolledAlert,
} from 'reactstrap';
import {Field, reduxForm} from 'redux-form';
import {PasswordField} from '../Forms/Fields';
import {changePasswordAction} from '../../actions/user';
import MaterialSpinner from '../UIKit/MaterialSpinner';

class ModalChangePassword extends Component {
  state = {
    showOriPassword: false,
    showNewPassword: false,
    showConfirmPassword: false,
  };

  toggle = () => {
    this.props.toggleModal('changePassword');
  };

  onSubmit = values => {
    this.props.changePassword(values);
  };

  onShowPassword = type => () => {
    this.setState({
      ...this.state,
      [`${type}`]: !this.state[type],
    });
  };

  onForgotClick = () => {
    this.props.toggleModal('forgotPassword');
  };

  render() {
    const {handleSubmit, user, headerConfig} = this.props;
    const {changingPassword, changePasswordState} = user || {};
    const {error} = changePasswordState || {};

    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <ModalHeader toggle={this.toggle} {...(headerConfig || {})}>
          Change Password
        </ModalHeader>
        <ModalBody>
          {error && (
            <UncontrolledAlert color="danger">{error}</UncontrolledAlert>
          )}
          <p className="text-muted">
            Password are case-sensitive and must be at least 8 characters.
          </p>
          <FormGroup>
            <Label for="currentPassword">Current Password</Label>
            <Field
              id="currentPassword"
              name="old_password"
              showTogglePasswordVisibility={true}
              component={PasswordField}
            />
          </FormGroup>
          <FormGroup>
            <Label for="newPassword">New Password</Label>
            <Field
              id="newPassword"
              name="new_password"
              showTogglePasswordVisibility={true}
              component={PasswordField}
            />
          </FormGroup>
          <FormGroup>
            <Label for="confirmPassword">Confirm Password</Label>
            <Field
              id="confirmPassword"
              name="confirm_password"
              showTogglePasswordVisibility={true}
              component={PasswordField}
            />
          </FormGroup>

          <div className={'text-right mb-2'}>
            <Button
              disabled={changingPassword}
              block
              style={{
                background: 'transparent',
                color: '#E0301E',
                display: 'inline-block',
                border: 0,
                width: 'auto',
              }}
              onClick={this.onForgotClick}>
              Forgot Password?
            </Button>
          </div>

          <Button color="primary" disabled={changingPassword} block>
            <MaterialSpinner indeterminate={changingPassword} small button />
            {changingPassword ? 'Submitting...' : 'Change Password'}
          </Button>
        </ModalBody>
      </form>
    );
  }
}

function mapStateToProps(state) {
  const {global, user} = state;

  return {
    global,
    user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    openModal: bindActionCreators(openModal, dispatch),
    toggleModal: bindActionCreators(toggleModal, dispatch),
    changePassword: bindActionCreators(changePasswordAction, dispatch),
  };
}

const connected = connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalChangePassword);

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

const validate = values => {
  const errors = {};
  if (!values.old_password) {
    errors.old_password = 'Current password is required';
  }
  if (!values.new_password) {
    errors.new_password = 'New password is required';
  }
  if (values.new_password && values.new_password.length < 8) {
    errors.new_password = 'Password must be at least 8 characters';
  }
  if (!values.confirm_password) {
    errors.confirm_password = 'Confirm password is required';
  }
  if (values.new_password !== values.confirm_password) {
    errors.confirm_password = "The new passwords you entered didn't match";
  }

  // have combination of three of upper case, lower case, numbers or symbols.
  const safePass = values.new_password ? escapeRegExp(values.new_password) : '';
  const hasDigit = /\d/.test(safePass);
  const hasSymbol = /[\W_]/.test(safePass);
  const hasLowercase = /[a-z]/.test(safePass);
  const hasUppercase = /[A-Z]/.test(safePass);

  const validatePolicy = [hasDigit, hasSymbol, hasLowercase, hasUppercase];
  if (validatePolicy.filter(e => e === true).length < 3) {
    errors.new_password =
      'Password must be at least 8 characters with the combination three of the following: upper case, lower case, numbers or symbols';
  }

  return errors;
};

export default reduxForm({
  form: 'FormModalChangePassword',
  validate,
})(connected);
