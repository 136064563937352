import React, {Component, Fragment} from 'react';
import {openModal} from '../../../../actions/modal';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {
  Alert,
  AppContainer,
  CheckBox,
  CheckMark,
  Container,
} from '../../../../styles';
import {ContentHeader, MainContent} from '../../../../styles';
import {ContentSpacer} from '../../Me/MyReservationList';
import Navigation from '../../../../components/Navigation';
import {Field, FieldArray, getFormValues, reduxForm, reset} from 'redux-form';
import {FormLabel} from '../../../../components/Profile/edit';
import {Card, CardBody, CardFooter} from '../../../../styles/card';
import styled from 'styled-components';
import {InputField, SelectField} from '../../../../components/Forms/Fields';
import moment from 'moment/moment';
import {
  bookGroupReservationClusterAdminRequest,
  bookReservationForClusterAdminRequest,
  bookReservationRequest,
  findRoomForClusterAdminRequest,
  selectMeetingRoom,
  setMeetingRoomParams,
} from '../../../../actions/reservation';
import {Redirect} from 'react-router-dom';
import CurrentBooking from '../../../../utils/CurrentBooking';
import {
  getCurrentUser,
  getReservationType,
  handleKeyDownNumberOfParticipant,
  isNeedClientInfo,
  reservationTypeText,
  roundedTime,
} from '../../../../utils/helpers';
import DatePickerField from '../../../../components/Datepicker';
import {searchUsersLiteAction} from '../../../../actions/user';
import swal from 'sweetalert2';
import TypeaheadField from '../../../../components/Forms/MultiTypeaheadField';
import {Button, FormGroup, Col, Row} from 'reactstrap';
import MeetingRoomField from '../../../../components/Forms/MeetingRoomField';
import {validate} from '../validation';
import {PageHeading, SubHeading} from '../../../../styles/typography';
import TimePicker from '../../../../components/UIKit/TimePicker';
import config from '../../../../config';
import MaterialSpinner from '../../../../components/UIKit/MaterialSpinner';
import PageNotFound from '../../../errors/PageNotFound';
import ParticipantField from '../../../../components/Forms/ParticipantField';
import {setGlobalError} from '../../../../actions/global';

const FORM_NAME = 'create-single-reservation-cluster-admin';
export const DATEPICKER_FORMAT = 'YYYY-MM-DD';

class CreateSingleReservationClusterAdmin extends Component {
  state = {
    cancel: false,
  };

  book = values => {
    const {selectedMeetingRoom} = this.props;
    const approval = values.approvalUsers ? values.approvalUsers : [];
    // set creator_id & host_name
    const {host_name, conference_type} = values;
    if (host_name) {
      values.creator_id = host_name.id;
      values.host_name = host_name.name;
    }
    if (values.need_conference) {
      values.video_conference = conference_type;
    }

    delete values.conference_type;
    delete values.need_conference;

    const approvalUsers = approval.map(item => item.user.id);

    values.meetingRoom = selectedMeetingRoom;
    values.approval = approvalUsers;
    values.book_platform = 'web';
    values.booking_type = 'pre-reserve';
    values.reservation_type = 'single';
    values.check_in = values.meeting_date;
    values.check_in_release = values.meeting_date;
    delete values.meeting_date;
    const data = {...values};
    const participant = data.participant || [];
    data.participant = participant.map(item => item.user);

    data.poi_id = selectedMeetingRoom
      ? selectedMeetingRoom.id
      : data.meetingRoom.id;
    // remove object meeting room from data object
    delete data.meetingRoom;

    this.props.bookReservationForClusterAdmin(data);
  };

  onSubmit = values => {
    swal({
      title: 'Confirm Reservation',
      text: 'Are you sure want to submit this reservation?',
      type: 'warning',
      showCancelButton: true,
    }).then(result => {
      if (result.value) {
        this.book(values);
      }
    });
  };

  resetBookingData = () => {
    const {selectMeetingRoom, setMeetingRoomParams} = this.props;
    CurrentBooking.remove();
    selectMeetingRoom(null);
    setMeetingRoomParams({});
  };

  handleCancel = () => {
    this.props.selectMeetingRoom(null);
    this.setState({
      cancel: true,
    });
  };

  reservationPath() {
    const paths = this.props.match.url.split('/');
    const rule = paths[3];
    const type = paths[4];
    return {rule, type};
  }

  componentWillReceiveProps(props) {
    if (
      props.reservation.bookingSuccess !==
        this.props.reservation.bookingSuccess &&
      props.reservation.bookingSuccess
    ) {
      this.resetBookingData();
      this.resetForm();
    }
  }

  componentWillUnmount() {
    this.props.selectMeetingRoom(null);
    this.props.setError(null);
    this.resetForm();
    this.resetBookingData();
  }

  componentDidMount() {
    this.setDefaultValues();
  }

  setDefaultValues = () => {
    const now = moment();
    const start_time = roundedTime(now.format(TIME_FORMAT)).format(TIME_FORMAT);
    const end_time = roundedTime(now.add(1, 'hour').format(TIME_FORMAT)).format(
      TIME_FORMAT
    );
    const currentDate = now.format('YYYY-MM-DD');
    const type = getReservationType();

    this.props.change('meeting_date', currentDate);
    this.props.change('check_in', currentDate);
    this.props.change('start_time', start_time);
    this.props.change('end_time', end_time);
    this.props.change('meeting_type', 'internal');
    this.props.change('reservation_type', type);

    const {user} = this.props;
    this.props.change('host_name', {
      id: user ? user.id : 0,
      name: user ? user.name : '',
    });

    this.props.selectMeetingRoom(null);
    this.props.setError(null);
  };

  handleNumberOfParticipantChange = event => {
    const total = +event.target.value;
    if (total < 0) {
      this.props.change('number_of_participant', 0);
    }
  };

  removeParticipant = (fields, index) => {
    fields.remove(index);
  };

  addParticipant = fields => {
    fields.push({
      user: '',
    });
  };

  getMandatoryFields = (values, isReqClientInfo) => {
    const mandatory = {};
    const {meeting_type: meetingType} = values;
    const reservationType = getReservationType();
    const isExternalMeeting = meetingType === 'external';
    const isSingleBooking = reservationType === 'single';
    const isProjectBooking = reservationType === 'project';
    const isGroupBooking = reservationType === 'group';

    mandatory.number_of_participant = true;
    if (isExternalMeeting) {
      mandatory.participant = true;
      if (isReqClientInfo) {
        mandatory.client_name = true;
      }
    }
    if (!isSingleBooking || isReqClientInfo) {
      // mandatory.client_code = true;
      // mandatory.job_code = true;
      // mandatory.job_description = true;
      mandatory.engagement_partner = true;
    }
    return {mandatory, isSingleBooking, isProjectBooking, isGroupBooking};
  };

  setApprovers = selectedMeetingRoom => {
    const approvers = [];
    const numberOfApprover = this.getNumberOfApprovers(selectedMeetingRoom);
    for (let i = 1; i <= numberOfApprover; i++) {
      approvers.push({value: ''});
    }
    this.setState({approvers});
  };

  handleSearchApprover = value => {
    if (value && value.length > 2) {
      const me = getCurrentUser().id;
      const creatorId = this.props.formValues.creator_id;
      const approval = this.state.approvalUsers.map(item => item.id);
      const except = [me, creatorId, ...approval].join(',');
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.props.searchUsers({
          keyword: value,
          perPage: 10,
          page: 1,
          except,
        });
      }, 200);
    }
  };

  getNumberOfApprovers = meetingRoom => {
    let {selectedMeetingRoom} = this.props;
    selectedMeetingRoom = meetingRoom || selectedMeetingRoom;
    if (selectedMeetingRoom && selectedMeetingRoom.rules) {
      return selectedMeetingRoom.rules.number_approver;
    }
    return 0;
  };

  clearError = e => {
    const {setError} = this.props;
    setError(null);
    e.preventDefault();
  };

  getReservationTypeText = type => {
    return reservationTypeText(type);
  };

  handleAsyncTypeaheadUsers = value => {
    this.search(value);
  };

  search = (value, params = {}) => {
    if (value && value.length > 2) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.props.searchUsers({
          keyword: value,
          page: 1,
          perPage: 10,
          ...params,
        });
      }, 300);
    }
  };

  resetForm = () => {
    this.props.dispatch(reset(FORM_NAME));
  };

  toggleCheckboxConference = () => {
    const {formValues} = this.props;
    const {need_conference} = formValues;
    this.props.change('need_conference', !need_conference);
  };

  getWorkspaceUsers = () => {
    const {formValues} = this.props;
    const workspaces = formValues ? formValues.workspaces || [] : [];

    return workspaces.filter(item => !!item.user).map(item => item.user);
  };

  render() {
    const {onSubmit, handleCancel} = this;
    const {
      selectedMeetingRoom,
      handleSubmit,
      formValues,
      users,
      reservation,
      global,
      searching,
    } = this.props;

    const conferenceTypes = [config.CONFERENCE_TYPES.at(0)]; // only allow video conference type
    const {bookingDone, submitting} = reservation;
    const {cancel} = this.state;
    const isReqClientInfo = isNeedClientInfo(selectedMeetingRoom);
    const {error: errorMessageFromAPI} = global;

    if (cancel || bookingDone) {
      return <Redirect push to="/reservations" />;
    }

    const type = getReservationType();
    const {rule} = this.reservationPath();
    const isClusterAdmin = rule === 'cluster-admin';
    if (isClusterAdmin && !config.allowedReservationTypes.includes(type)) {
      return <PageNotFound />;
    }
    const reservationTypeText = this.getReservationTypeText(type);
    const isClusterAdminGroup = isClusterAdmin && type === 'group';

    const values = formValues || {};
    const {mandatory} = this.getMandatoryFields(values, isReqClientInfo);

    const RequiredSign = <MandatoryText>*)</MandatoryText>;
    const RequiredText = field => (mandatory[field] ? RequiredSign : null);
    const filteredUsers = users.map(item => ({
      id: item.id,
      name: item.name,
    }));

    const needConference = values.need_conference;

    if (config.DEBUG) {
      console.log('PROPS', this.getWorkspaceUsers(), this.props);
    }

    return (
      <AppContainer>
        <Navigation />
        <CustomContentHeader>
          <Container>
            <Row>
              <Col xs={12}>
                <PageHeading>Room Reservation</PageHeading>
                <SubHeading>Reserve Room in the office.</SubHeading>
              </Col>
            </Row>
          </Container>
        </CustomContentHeader>
        <MainContent>
          <Container>
            <ContentSpacer />
            <Card>
              <form onSubmit={handleSubmit(onSubmit)}>
                <FormBody>
                  <FormGroup>
                    <FormLabel>Host Name {RequiredSign}</FormLabel>
                    <Field
                      component={TypeaheadField}
                      name="host_name"
                      onSearch={this.handleAsyncTypeaheadUsers}
                      isLoading={searching}
                      options={filteredUsers}
                      labelKey="name"
                      useCache={false}
                      delay={0}
                      id="hostName"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormLabel>Reservation Type</FormLabel>
                    <div>
                      {reservationTypeText}
                      <Field
                        name="reservation_type"
                        component="input"
                        type="hidden"
                      />
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <FormLabel>Meeting Name {RequiredSign}</FormLabel>
                    <Field name="meeting_description" component={InputField} />
                  </FormGroup>
                  <Row>
                    <Col xs={12} md={3}>
                      <FormGroup>
                        <FormLabel>Meeting Date {RequiredSign}</FormLabel>
                        <Field
                          name="meeting_date"
                          component={DatePickerField}
                          minDate={moment()}
                          maxDate={moment().add(1, 'year')}
                          dateFormat={DATEPICKER_FORMAT}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={6} md={3}>
                      <FormGroup>
                        <FormLabel>Start Time</FormLabel>
                        <Field
                          name="start_time"
                          component={TimePicker}
                          onChange={this.refetchWorkspace}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={6} md={3}>
                      <FormGroup>
                        <FormLabel>End Time</FormLabel>
                        <Field
                          name="end_time"
                          component={TimePicker}
                          onChange={this.refetchWorkspace}
                          right
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <FormGroup>
                    <FormLabel>Meeting type</FormLabel>
                    <RadioGroup>
                      <RadioBox>
                        <StyledFormLabel normal>
                          <Field
                            name="meeting_type"
                            value="internal"
                            component={InputField}
                            type="radio"
                          />
                          Internal meeting
                        </StyledFormLabel>
                      </RadioBox>
                      <RadioBox>
                        <StyledFormLabel normal>
                          <Field
                            name="meeting_type"
                            value="external"
                            component={InputField}
                            type="radio"
                          />
                          External meeting
                        </StyledFormLabel>
                      </RadioBox>
                    </RadioGroup>
                  </FormGroup>

                  {!isClusterAdminGroup && (
                    <Fragment>
                      <Row>
                        <Col xs={12} md={6}>
                          <FormGroup>
                            <FormLabel>
                              Total Participant{' '}
                              {RequiredText('number_of_participant')}
                            </FormLabel>
                            <Field
                              type="number"
                              name="number_of_participant"
                              component={InputField}
                              placeholder="How many Attendance ?"
                              min={0}
                              onKeyUp={this.handleNumberOfParticipantChange}
                              onKeyDown={handleKeyDownNumberOfParticipant}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <FieldArray
                        name="participant"
                        component={ParticipantField}
                        onRemove={this.removeParticipant}
                        onAdd={this.addParticipant}
                      />

                      <Row>
                        <Col xs={12} sm={6}>
                          <FormGroup>
                            <FormLabel>Remarks</FormLabel>
                            <Field
                              name="remarks_reservation"
                              component={InputField}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <CustomFormGroup>
                        <Row>
                          <Col xs={'auto'}>
                            <FormLabel onClick={this.toggleCheckboxConference}>
                              Need Conference Facility?
                            </FormLabel>
                          </Col>
                          <Col xs={'auto'}>
                            <CheckBox initialPosition>
                              <Field
                                name="need_conference"
                                value="1"
                                component="input"
                                type="checkbox"
                              />
                              <CheckMark />
                            </CheckBox>
                          </Col>
                        </Row>
                      </CustomFormGroup>

                      <Row className={needConference ? 'd-none' : 'd-none'}>
                        <Col xs={12} sm={6}>
                          <FormGroup>
                            <Field
                              component={SelectField}
                              name="conference_type"
                              items={conferenceTypes}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Field
                        name="poi_id"
                        formData={formValues}
                        isClusterAdmin={isClusterAdmin}
                        reservationType={type}
                        component={MeetingRoomField}
                        noMargin={true}
                      />
                    </Fragment>
                  )}

                  {errorMessageFromAPI && (
                    <Row className="mt-3">
                      <Col>
                        <Alert color="danger">
                          {errorMessageFromAPI}
                          <a
                            href="#"
                            onClick={this.clearError}
                            className="close"
                            aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                          </a>
                        </Alert>
                      </Col>
                    </Row>
                  )}

                  <Field
                    name="is_req_client_info"
                    component="input"
                    type="hidden"
                  />
                  <FormSpacer />
                </FormBody>
                <CardAction>
                  <Button type="reset" onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button type="submit" color="primary" disabled={submitting}>
                    <MaterialSpinner indeterminate={submitting} small button />
                    {submitting ? 'Reserving...' : 'Reserve'}
                  </Button>
                </CardAction>
              </form>
            </Card>
          </Container>
        </MainContent>
      </AppContainer>
    );
  }
}

const CardAction = styled(CardFooter)`
  display: flex;
  justify-content: space-between;
`;

const FormBody = styled(CardBody)`
  padding: 1em;
`;

const FormSpacer = styled.div`
  height: 100px;
`;
const RadioGroup = styled.div``;

const RadioBox = styled.div`
  display: inline-flex;
  margin-right: 10px;
  align-items: center;
  justify-content: flex-start;
`;

const CustomContentHeader = styled(ContentHeader)`
  background: #fafafa;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.05);
`;

const MandatoryText = styled.span`
  color: ${props => props.theme.colors.primary};
  font-weight: normal;
`;

const StyledFormLabel = styled(FormLabel)`
  margin-bottom: 0;
`;

const CustomFormGroup = styled(FormGroup)`
  margin-bottom: 0 !important;
`;

const TIME_FORMAT = config.shortTimeFormat;

function mapStateToProps(state) {
  const formValues = getFormValues(FORM_NAME)(state);
  const now = moment();
  const start_time = roundedTime(now.format(TIME_FORMAT)).format(TIME_FORMAT);
  const end_time = roundedTime(now.add(1, 'hour').format(TIME_FORMAT)).format(
    TIME_FORMAT
  );
  let {selectedMeetingRoom, meetingRooms} = state.reservation;
  const user = state.auth.userData;
  const currentDate = now.format('YYYY-MM-DD');
  const currentBooking = CurrentBooking.fetch();
  let numberOfApprover = 0;

  if (currentBooking && currentBooking.meetingRoom) {
    selectedMeetingRoom = currentBooking.meetingRoom;

    if (selectedMeetingRoom && selectedMeetingRoom.rules) {
      numberOfApprover = selectedMeetingRoom.rules.number_approver;
    }
  }

  return {
    reservation: state.reservation,
    selectedMeetingRoom,
    initialValues: {
      need_conference: false,
      conference_type: 1,
      host_name: {
        id: user ? user.id : 0,
        name: user ? user.name : '',
      },
      meeting_type: 'internal',
      start_time,
      end_time,
      workspaces: [{user: '', poi: ''}],
      check_in: currentDate,
      meeting_date: currentDate,
      creator_id: user ? user.id : '',
      number_of_approver: numberOfApprover,
      // engagement_partner: user ? user.name : '',
      reservation_type: 'single',
      remarks_reservation: '',
      ...currentBooking,
    },
    formValues,
    users: state.user.users,
    userSearchResult: state.user.searchResult,
    searching: state.user.searching,
    meetingRooms,
    global: state.global,
    state,
    user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    openModal: bindActionCreators(openModal, dispatch),
    setMeetingRoomParams: bindActionCreators(setMeetingRoomParams, dispatch),
    selectMeetingRoom: bindActionCreators(selectMeetingRoom, dispatch),
    searchUsers: bindActionCreators(searchUsersLiteAction, dispatch),
    findRoomForClusterAdmin: bindActionCreators(
      findRoomForClusterAdminRequest,
      dispatch
    ),
    bookReservation: bindActionCreators(bookReservationRequest, dispatch),
    bookReservationForClusterAdmin: bindActionCreators(
      bookReservationForClusterAdminRequest,
      dispatch
    ),
    bookGroupReservationClusterAdmin: bindActionCreators(
      bookGroupReservationClusterAdminRequest,
      dispatch
    ),
    setError: bindActionCreators(setGlobalError, dispatch),
  };
}

const form = reduxForm({
  form: FORM_NAME,
  validate,
  enableReinitialize: true,
})(CreateSingleReservationClusterAdmin);

export default connect(mapStateToProps, mapDispatchToProps)(form);
