import {
  AUTH_IN_REQUEST,
  AUTH_OUT_REQUEST,
  FORGOT_PASSWORD_ACTION,
  FORGOT_PASSWORD_MODAL_ACTION,
  OAUTH_CALLBACK,
  SET_AUTH_CREDENTIALS,
  SET_REMEMBER_ME,
  VALIDATE_AUTH,
  VERIFIED_OTP_REQ,
} from './actionsList';

export const oauthCallback = token => {
  return {
    type: OAUTH_CALLBACK,
    payload: token,
  };
};

export const authInRequest = (credentials, history) => {
  return {
    type: AUTH_IN_REQUEST,
    payload: {credentials, history},
  };
};

export const authOutRequest = emptyPayload => {
  return {
    type: AUTH_OUT_REQUEST,
    payload: emptyPayload,
  };
};

export const forgotPasswordAction = email => {
  return {
    type: FORGOT_PASSWORD_ACTION,
    payload: {email},
  };
};
export const forgotPasswordModalAction = email => {
  return {
    type: FORGOT_PASSWORD_MODAL_ACTION,
    payload: {email},
  };
};

export const validateAuthRequest = token => {
  return {
    type: VALIDATE_AUTH,
    payload: {token},
  };
};

export const setAuthCredentialsAction = data => {
  return {
    type: SET_AUTH_CREDENTIALS,
    payload: {data},
  };
};

export const setRememberMe = payload => {
  return {
    type: SET_REMEMBER_ME,
    payload,
  };
};

export const verifyOTP = payload => {
  return {
    type: VERIFIED_OTP_REQ,
    payload,
  };
};
