import {call, put, takeEvery} from 'redux-saga/effects';
import {
  LOADED,
  LOADING,
  PROFILE_EDIT_REQUEST,
  PROFILE_REQUEST,
  SET_PROFILE,
  SET_PROFILE_TO_EDIT,
  UPDATE_ADDITIONAL_EDITOR,
  UPDATE_PROFILE_REQUEST,
  UPDATING,
  UPDATED,
  GET_MY_PROFILE_REQUEST,
  AUTH_IN_SUCCESS,
  GET_PROFILE_FOR_EDIT_ACTION,
  GET_PROFILE_FOR_EDIT_FAILED,
  GET_PROFILE_FOR_EDIT_SUCCESS,
  GET_PROFILE_FOR_EDIT_REQUEST,
  EDIT_PROFILE_SUCCESS,
} from '../actions/actionsList';
import {setUserData} from './auth';
import {closeModal} from '../actions/modal';
import {setOON} from '../actions/oon';
import {profileRequest} from '../actions/profile';
import User from '../api/User';
import {setAccessToken} from '../utils/api';
import {loadState} from '../utils/localStorage';
import config from '../config';
import {getCurrentUser} from '../utils/helpers';
import {removeAccessToken, removeUserData} from '../utils/TokenStorage';
import {detailUserRequest} from '../actions/user';
import {setGlobalError} from '../actions/global';

/**
 * Get My Profile.
 *
 * @param action
 * @returns {IterableIterator<*>}
 */
function* getMyProfileRequestHandler(action) {
  // const {payload} = action;

  try {
    let responseData = yield call(User.me);
    const {code, data: profile, message} = responseData || {};

    if (code === 200) {
      yield put({type: SET_PROFILE, payload: {profile}});
      yield put({
        type: SET_PROFILE_TO_EDIT,
        payload: {
          profileData: profile.profile,
        },
      });
      const {last_oon: oon} = profile;
      yield put(setOON(oon));
      const state = loadState();
      const token = state.auth.token;
      yield put({type: AUTH_IN_SUCCESS, payload: {token, profile}});
      setAccessToken(token);
    } else {
      yield put(setGlobalError(message));
    }
  } catch (error) {
    yield put(setGlobalError(config.INTERNAL_SERVER_ERROR));
  }
}

function* profileRequestHandler(action) {
  const {payload} = action;
  const {userId} = payload || {};

  try {
    yield put({type: LOADING});

    let responseData = yield call(User.find, userId);

    const {code, data: profile, message} = responseData || {};

    if (code === 200) {
      yield put({type: LOADED});
      yield put({type: SET_PROFILE, payload: {profile}});
      const {last_oon: oon} = profile;
      yield put(setOON(oon));
    } else {
      yield put(setGlobalError(message));
      yield put({type: LOADED});
    }
  } catch (error) {
    yield put(setGlobalError(config.INTERNAL_SERVER_ERROR));
    yield put({type: LOADED});
  }
}

function* updateProfileRequestHandler(action) {
  const {payload} = action;

  try {
    yield put({type: UPDATING});
    const {data: formData} = payload || {};
    const responseData = yield call(User.update, formData);
    const {code, data, message} = responseData || {};

    if (code === 200) {
      yield put({type: UPDATED});
      yield put(closeModal('editProfile'));
      const {payload: userData} = data || {};
      yield put({type: EDIT_PROFILE_SUCCESS, payload: {userData}});
    } else {
      yield put({type: UPDATED});
      yield put(setGlobalError(message));
    }
  } catch (error) {
    yield put({type: UPDATED});
    yield put(setGlobalError(config.INTERNAL_SERVER_ERROR));
  }
}

function* updateAdditionalEditorRequestHandler(action) {
  const {payload} = action;

  try {
    yield put({type: UPDATING});
    const {data: formData, id} = payload || {};
    const responseData = yield call(User.updateAdditionalEditor, id, formData);
    const {code, data, message} = responseData || {};

    if (code === 200) {
      yield put({type: UPDATED, payload: {data}});
      yield put(closeModal('editProfile'));
      yield put(profileRequest(id));
    } else {
      yield put({type: UPDATED});
      yield put(setGlobalError(message));
    }
  } catch (error) {
    yield put({type: UPDATED});
    yield put(setGlobalError(config.INTERNAL_SERVER_ERROR));
  }
}

function* setProfileToEditHandler(action) {
  const {payload} = action;
  const {profileData, isEditedByEditor} = payload || {};
  yield put({
    type: SET_PROFILE_TO_EDIT,
    payload: {profileData, isEditedByEditor},
  });
}

function* getProfileForEditActionHandler(action) {
  const {payload} = action;

  try {
    const {id} = payload || {};
    yield put({type: GET_PROFILE_FOR_EDIT_REQUEST, payload: {id}});
    const responseData = yield call(User.find, id);
    const {code, data, message} = responseData || {};
    if (code === 200) {
      yield put({type: GET_PROFILE_FOR_EDIT_SUCCESS, payload: {data}});
    } else {
      yield put({type: GET_PROFILE_FOR_EDIT_FAILED});
      yield put(setGlobalError(message));
    }
  } catch (error) {
    yield put({type: GET_PROFILE_FOR_EDIT_FAILED});
    yield put(setGlobalError(config.INTERNAL_SERVER_ERROR));
  }
}

export default function*() {
  yield takeEvery(PROFILE_REQUEST, profileRequestHandler);
  yield takeEvery(GET_MY_PROFILE_REQUEST, getMyProfileRequestHandler);
  yield takeEvery(UPDATE_PROFILE_REQUEST, updateProfileRequestHandler);
  yield takeEvery(PROFILE_EDIT_REQUEST, setProfileToEditHandler);
  yield takeEvery(
    UPDATE_ADDITIONAL_EDITOR,
    updateAdditionalEditorRequestHandler
  );
  yield takeEvery(GET_PROFILE_FOR_EDIT_ACTION, getProfileForEditActionHandler);
}
