import {
  CLEAR_POI_BY_FLOOR,
  GET_FLOOR_ACTION,
  GET_FLOORS_FOR_GANTT_CHART,
  GET_POI_BY_FLOOR_ACTION,
} from './actionsList';

export const getFloorRequest = params => {
  params = params || {};
  const {page, limit, perPage, sortBy, key, descending} = params;
  return {
    type: GET_FLOOR_ACTION,
    payload: {page, limit, perPage, sortBy, key, descending},
  };
};

export const getPoiByFloorRequest = ({
  id,
  page,
  key,
  perPage,
  loadMore = false,
  callback,
}) => {
  return {
    type: GET_POI_BY_FLOOR_ACTION,
    payload: {id, page, key, perPage, loadMore, callback},
  };
};

export const clearPoiByFloor = () => {
  return {
    type: CLEAR_POI_BY_FLOOR,
    payload: {},
  };
};

export const getFloorsForGanttChart = () => {
  return {
    type: GET_FLOORS_FOR_GANTT_CHART,
    payload: {},
  };
};
