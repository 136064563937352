import React, {Component} from 'react';
import {connect} from 'react-redux';
import ApplicationLayout from '../../components/Layouts/ApplicationLayout';
import {Col, media, Row} from '../../styles/grid';
import {PageHeading, SubHeading} from '../../styles/typography';
import {
  Container,
  IconMapMarkerGrey,
  IconStartingPoin,
  ListHeader,
  ListText,
  SearchBox,
  SearchButton,
  SearchIcon,
  ContentHeader,
  MainContent,
  CenteredContent,
} from '../../styles';
import {PageHeader} from '../reservations/LiasonOfficer/ReservationLiasonOfficerModule';
import styled, {css} from 'styled-components';
import {
  Card,
  Input,
  InputGroup,
  InputGroupAddon,
  ListGroup,
  ListGroupItem,
  Button,
  UncontrolledTooltip,
  Alert,
} from 'reactstrap';
import {bindActionCreators} from 'redux';
import {getFloorRequest, getPoiByFloorRequest} from '../../actions/floor';
import {clearPoiListAction, findRoomPOIRequest} from '../../actions/poi';
import {reduxForm} from 'redux-form';
import {AsyncTypeahead, Menu, MenuItem} from 'react-bootstrap-typeahead';
import RoomCard from '../../components/Reservations/RoomCard';
import {
  dump,
  getFloorFromLocation,
  parseLastLocation,
  toast,
} from '../../utils/helpers';
import {openModal} from '../../actions/modal';
import ModalHeadMap from '../../components/Maps/ModalHeadMap';
import {getSettingsAction} from '../../actions/setting';
import {setSelectedFloorAction} from '../../actions/map';
import LoadingComponent from '../../components/UIKit/LoadingComponent';
import config from '../../config';
import {range} from 'ramda';
import MaterialSpinner from '../../components/UIKit/MaterialSpinner';
import hosts from '../../utils/hosts';
import MaterialIcon from '../../components/UIKit/MaterialIcon';
import qs from 'query-string';
import ModalMapInfo from '../../components/Maps/ModalMapInfo';
import CardSelectedMarkerInfo from '../../components/Maps/CardSelectedMarkerInfo';

class Maps extends Component {
  timer = null;

  POIListRef = null;

  searchPOIRef = null;

  state = {
    poi: null,
    openAutoComplete: '',
    startingPoint: null,
    destination: null,
    searchPOI: '',
    search: '',
    poiPerPage: 10,
    doneSearchingPOI: false,
    routeFloors: [],
    routeFloor: '',
    defaultStartingPointSelected: [],
    lift: {},
    zone: null,
    selectedMarker: null,
    startingPoints: [],
    destinations: [],
    selectedDestinations: [],
    selectedStartingPoints: [],
    path: null,
  };

  handleSearchMeetingRoom = key => {
    key = key.trim();
    if (key && key.length > 2) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.props.findRoomPOI({key});
      }, 500);
    }
  };

  getFloorFromPOI = poi => {
    if (!poi || !poi.zone) return null;

    const {zone} = poi || {};
    const floor = zone.floor;
    return floor ? floor.level : null;
  };

  handleTypeaheadSearchChange = selected => {
    const poi = selected[0];
    if (poi) {
      const floor = this.getFloorFromPOI(poi);
      if (floor) {
        this.props.setFloor(floor);
      }
      this.selectPOI(null, poi);
    }
  };

  search = key => {
    key = key.trim();
    if (key) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.props.findRoomPOI({key});
      }, 500);
    }
  };

  componentDidMount() {
    this.fetchSettings();
    this.props.getFloorList({
      sortBy: 'level',
      descending: false,
    });
    const viewer = this.viewerRef;
    if (viewer) {
      viewer.addEventListener('selected-marker-changed', function(e) {
        console.log('MARKER LOG', e.detail);
      });
      viewer.addEventListener('selected-route-floors-changed', e => {
        // const routeFloors = e.detail.value.map(item => +item);
        // console.log('FLOOR ROUTE LOG', routeFloors);
        // this.setState({
        //   routeFloors,
        // });
        // this.setRouteFloors();
      });
    }
    const {userData, location} = this.props;
    const {last_location} = userData || {};
    if (last_location && last_location.lift) {
      const {lift} = last_location;
      const selectedStartingPoints = [lift];
      this.setState({
        startingPoint: last_location.lift,
        selectedStartingPoints,
        lift,
      });
    }
    const query = qs.parse(location.search);
    if (query.zone) {
      const {zone} = query;
      this.setState({zone});
    }

    // handle scroll div
    document
      .getElementById('scrolledListGroup')
      .addEventListener('scroll', this.handlePOIListScroll);

    // handle message
    window.addEventListener('message', this.handleMessage, false);
  }

  setStartingPointFromLift = () => {
    const {userData} = this.props;
    const {last_location} = userData || {};
    if (last_location && last_location.lift) {
      const {lift} = last_location;
      const selectedStartingPoints = [lift];
      this.setState({
        startingPoint: last_location.lift,
        selectedStartingPoints,
        lift,
      });
    }
  };

  handleMessage = event => {
    const data = event.data;
    if (data && data.id) {
      this.setState({selectedMarker: data});
    }
  };

  getPOIListResult = () => {
    const {poiList: poiListResult} = this.props.poi || {};
    const {defaultStartingPointSelected, startingPoint} = this.state;

    return poiListResult.length > 0 || startingPoint
      ? poiListResult
      : defaultStartingPointSelected;
  };

  handlePOIListScroll = event => {
    const element = event.target;
    const {scrollHeight, scrollTop, clientHeight} = element;
    const isScrolledToBottom = scrollHeight - scrollTop === clientHeight;
    if (isScrolledToBottom) {
      this.loadMorePOI();
    }
  };

  getLastDetectedFloor = () => {
    const {last_location} = this.props.userData || {};
    return last_location ? getFloorFromLocation(last_location) : null;
  };

  componentWillUnmount() {
    this.setState({
      poi: null,
      openAutoComplete: '',
      startingPoint: null,
      destination: null,
      searchPOI: '',
      search: '',
      poiPerPage: 10,
      doneSearchingPOI: false,
    });
    this.props.setFloor('');
    this.props.clearPoiList();
    document
      .getElementById('scrolledListGroup')
      .removeEventListener('scroll', this.handlePOIListScroll);
  }

  setDefaultFloor = (settings = null) => {
    settings = settings || this.props.settings;
    const lastDetectedFloor = this.getLastDetectedFloor();
    const defaultFloorSetting = this.getDefaultFloor(settings);
    const {location} = this.props;
    const query = qs.parse(location.search);
    if (query.floor) {
      this.props.setFloor(query.floor);
    } else {
      this.props.setFloor(
        lastDetectedFloor ? lastDetectedFloor : defaultFloorSetting
      );
    }
    this.setState({
      routeFloor: lastDetectedFloor,
    });
  };

  fetchSettings = () => {
    this.props.getSettings();
  };

  getDefaultFloor = (settings = null, defaultValue) => {
    settings = settings || this.props.settings;
    const setting = settings.find(item => item.key === 'DEFAULT_FLOOR');
    return setting ? setting.value : defaultValue;
  };

  handleFloorChanged = event => {
    const floor = event.target.value;
    this.props.setFloor(floor);
    this.setState({
      poi: null,
      searchPOI: '',
      routeFloor: floor,
      zone: null,
      selectedMarker: null,
    });
  };

  componentWillUpdate(props, state) {
    if (
      props.map.floor !== this.props.map.floor ||
      state.searchPOI !== this.state.searchPOI
    ) {
      this.fetchPoiByFloor({
        id: props.map.floor,
        key: state.searchPOI,
        callback: () => {
          this.setState({
            doneSearchingPOI: true,
          });
          setTimeout(() => {
            if (this.searchPOIRef) {
              this.searchPOIRef.focus();
            }
          }, 500);
        },
      });
    }
  }

  fetchPoiByFloor = ({id, page = 1, key, callback}) => {
    key = key.trim();
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.props.getPoiByFloorList({
        id,
        page,
        key,
        perPage: this.state.poiPerPage,
        callback,
      });
    }, 500);
  };

  loadMorePOI = () => {
    const {pagination} = this.props.floor || {};
    const {floor, searchPOI, poiPerPage} = this.state;
    let page = pagination.currentPage;
    page++;
    this.props.getPoiByFloorList({
      perPage: poiPerPage,
      loadMore: true,
      key: searchPOI,
      id: floor,
      page,
    });
  };

  selectPOI = (event, poi) => {
    const {poi: currentPOI} = this.state;
    let state = {};
    if (currentPOI === poi) {
      state = {poi: null, zone: null};
    } else {
      state = {poi, zone: null};
    }
    this.setState({...state, selectedMarker: poi});
    if (event) {
      event.preventDefault();
    }
  };

  handleStartingPointChange = selected => {
    this.setState({selectedStartingPoints: selected});

    let poi = selected[0];
    let floor = this.getFloorFromPOI(poi);

    if (poi) {
      if (!poi.id_poi_anyplace) {
        toast({
          title:
            "The starting point you selected currently don't support map route",
          type: 'error',
        });
      } else {
        this.setState(
          {
            startingPoint: poi,
            poi,
          },
          () => {
            if (floor) {
              this.props.setFloor(floor);
              this.setRouteFloors();
            }
          }
        );
      }
    }
  };

  handleDestinationChange = selected => {
    this.setState({
      selectedDestinations: [],
    });

    const poi = selected[0];

    if (poi) {
      if (!poi.id_poi_anyplace) {
        toast({
          title:
            "The destination you selected currently don't support map route",
          type: 'error',
        });
      } else {
        this.setState(
          {
            destination: poi,
            selectedDestinations: selected,
            poi,
          },
          () => {
            const floor = this.getFloorFromPOI(poi);
            if (floor) {
              this.props.setFloor(floor);
              this.setState({routeFloor: floor});
              this.setRouteFloors();
            }
          }
        );
      }
    }
  };

  setRouteFloors = () => {
    const {startingPoint, destination} = this.state;
    if (startingPoint && destination) {
      let routeFloors;
      const startPointFloor = +startingPoint.zone.floor.level;
      const destinationFloor = +destination.zone.floor.level;
      if (startPointFloor !== destinationFloor) {
        routeFloors = [startPointFloor, destinationFloor].sort(function(a, b) {
          return b - a;
        });
      } else {
        routeFloors = [startPointFloor];
      }
      this.setState({routeFloors, routeFloor: startPointFloor});
      this.props.setFloor(startPointFloor);
    }
  };

  handleRouteFloorClick = (event, routeFloor) => {
    event.preventDefault();
    this.setState({
      routeFloor,
    });
    this.props.setFloor(routeFloor);
  };

  handleSearchPOIChange = event => {
    this.setState({
      searchPOI: event.target.value,
      searchingPOI: true,
      doneSearchingPOI: false,
    });
  };

  clearRoute = () => {
    this.setState({
      startingPoint: null,
      destination: null,
      poi: null,
      routeFloor: '',
      routeFloors: [],
      defaultStartingPointSelected: [],
      destinations: [],
      startingPoints: [],
      selectedDestinations: [],
      selectedStartingPoints: [],
    });
    if (this.startingPointInput) {
      this.startingPointInput.getInstance().clear();
    }
    if (this.destinationInput) {
      this.destinationInput.getInstance().clear();
    }
    this.setDefaultFloor();
  };

  onSearchSubmitted = values => {
    const keyword = values.keyword;
    if (keyword && keyword.trim()) {
      this.search(keyword);
    }
  };

  renderMenu = (results, menuProps) => {
    return (
      <Menu {...menuProps}>
        {results.map((result, index) => (
          <StyledMenuItem option={result} key={index} position={index}>
            <StyledRoomCard item={result} />
          </StyledMenuItem>
        ))}
      </Menu>
    );
  };

  getMapParams = (floor, IDAnyplacePOI, startingPoint, destination) => {
    const {routeFloor} = this.state;

    let params = {
      id: 'viewer',
      width: '100%',
      height: '100%',
      'default-lat': '-6.214059393887857',
      'default-lon': '106.82127307546375',
      floor: floor || undefined,
      poi: IDAnyplacePOI,
      'show-all-poi': '1',
      // 'my-location': '',
    };

    if (startingPoint && destination) {
      params = {
        ...params,
        'route-from-poi': startingPoint.id_poi_anyplace,
        'route-to-poi': destination.id_poi_anyplace,
        'route-floor': routeFloor,
        poi: '',
      };
    }
    return params;
  };

  handleHeatMapClicked = event => {
    event.preventDefault();
    this.props.openModal('modalHeatMap');
  };

  handleMapInfoClicked = event => {
    event.preventDefault();
    this.props.openModal('modalMapInfo');
  };

  componentWillReceiveProps(props) {
    if (props.settings !== this.props.settings) {
      this.setDefaultFloor(props.settings);
    }
  }

  getPath = () => {
    const {floor} = this.props.map || {};
    const {startingPoint, poi, destination, routeFloor, zone} = this.state;

    if (startingPoint && destination) {
      const routeFloorPath = routeFloor ? `/${routeFloor}` : '';
      return `route/${startingPoint.id_poi_anyplace}/${destination.id_poi_anyplace}${routeFloorPath}`;
    }

    if (poi) {
      return `poi/${poi.id_poi_anyplace}`;
    }

    if (floor && zone) {
      return `floor/${floor}/${zone}`;
    }

    return `floor/${floor}/allpoi`;
  };

  getStartingPointExtraParams = () => {
    const {poiList: poiListResult} = this.props.poi || {};
    const {defaultStartingPointSelected, startingPoint} = this.state;
    const params = {};

    if (poiListResult.length < 1 || !startingPoint) {
      params.defaultSelected = defaultStartingPointSelected;
      params.selected = defaultStartingPointSelected;
      params.options = Array.from(
        new Set([...poiListResult, ...defaultStartingPointSelected])
      );
    }

    return params;
  };

  onMarkerClosed = () => {
    this.setState({selectedMarker: null});
  };

  directionToMarker = poi => {
    if (poi) {
      if (!poi.id_poi_anyplace) {
        toast({
          title:
            "The destination you selected currently don't support map route",
          type: 'error',
        });
      } else {
        const {destinations, startingPoint} = this.state;
        if (!startingPoint) {
          this.setStartingPointFromLift();
        }
        const destinationList = [...destinations, poi];
        this.setState(
          {
            destination: poi,
            selectedDestinations: [poi],
            destinationList,
            poi,
          },
          () => {
            const floor = this.getFloorFromPOI(poi);
            if (floor) {
              this.props.setFloor(floor);
              this.setState({routeFloor: floor});
              this.setRouteFloors();
            }
          }
        );
      }
    }
  };

  onHeatMapClosed = floor => {
    this.setState({
      poi: null,
      searchPOI: '',
      routeFloor: floor,
      zone: null,
      selectedMarker: null,
    });
  };

  handleShowMyLocation = e => {
    const {last_location} = this.props.userData || {};
    dump({last_location});
    const {zone} = last_location || {};
    if (!zone) {
      toast({
        title: 'System cannot find your location',
        type: 'warning',
      });
      return;
    }
    const {floor} = zone || {};
    const floorLevel = floor.level;
    this.setState({
      zone: String(zone.id),
      routeFloor: floorLevel,
      poi: null,
      selectedMarker: null,
    });
    this.props.setFloor(floorLevel);
    e.preventDefault();
  };

  render() {
    const {floor} = this.props.map || {};
    const {
      poi,
      startingPoint,
      destination,
      searchPOI,
      doneSearchingPOI,
      routeFloors,
      selectedMarker,
      destinations,
      startingPoints,
      selectedDestinations,
      selectedStartingPoints,
    } = this.state;
    const {floors, poiList, pagination, loadingPOI, loadingMorePOI} =
      this.props.floor || {};
    const poiListResult = this.getPOIListResult();
    const {handleSubmit, token} = this.props;
    const startingPointExtraParams = this.getStartingPointExtraParams();
    const scrolledListGroupHeight = floor ? 430 : 488;
    const hasMorePOI = pagination.currentPage < pagination.lastPage;
    const noPOIFound = !loadingPOI && poiList.length < 1 && doneSearchingPOI;
    const path = this.getPath();
    const src = `${hosts.map}/?token=${token}#/${path}`;
    const {loadingHeatMap} = this.props.poi || {};
    const destinationList = [...destinations, ...poiListResult];
    const startingPointList = [...startingPoints, ...poiListResult];

    dump({
      path,
      src,
      poiListResult,
      startingPointExtraParams,
      destinationList,
      startingPointList,
      ...this.state,
    });

    return (
      <ApplicationLayout withoutFooter>
        <ModalHeadMap onSelected={this.onHeatMapClosed} />
        <ModalMapInfo />
        <ContentHeader>
          <Container>
            <Row justifyContent="space-between">
              <Col xs={12} sm={5}>
                <PageHeading>Map Directory</PageHeading>
                <SubHeading>Search location in the office.</SubHeading>
              </Col>
              <Col xs={12} sm={6}>
                <SearchFormCustom>
                  <StyledSearchBox
                    onSubmit={handleSubmit(this.onSearchSubmitted)}>
                    <InputGroup>
                      <StyledAsyncTypeahead
                        id="searchPOI"
                        name="keyword"
                        onSearch={this.handleSearchMeetingRoom}
                        onChange={this.handleTypeaheadSearchChange}
                        filterBy={['name']}
                        isLoading={!!poiListResult.length}
                        options={poiListResult}
                        labelKey="name"
                        placeholder="Find Room or Workspace"
                        renderMenu={this.renderMenu}
                      />
                      <InputGroupAddon addonType="append">
                        <StyledSearchButton>
                          <MaterialIcon icon="search" />
                        </StyledSearchButton>
                      </InputGroupAddon>
                    </InputGroup>
                  </StyledSearchBox>
                </SearchFormCustom>
              </Col>
            </Row>
          </Container>
        </ContentHeader>
        <MainContent>
          <Container>
            <Row>
              <Col>
                <MapDirections>
                  <FullRow>
                    <Col xs={12} sm={5} lg={4}>
                      <StyledInputGroup>
                        <InputGroupAddon addonType="prepend">
                          <IconStartingPoin />
                        </InputGroupAddon>
                        <StartingPointTypeahead
                          id="startingPoint"
                          name="startingPoint"
                          onSearch={this.handleSearchMeetingRoom}
                          onChange={this.handleStartingPointChange}
                          filterBy={['name']}
                          isLoading={!!poiListResult.length}
                          options={startingPointList}
                          labelKey="name"
                          placeholder="Choose Starting Point"
                          renderMenu={this.renderMenu}
                          ref={input => (this.startingPointInput = input)}
                          selected={selectedStartingPoints}
                        />
                      </StyledInputGroup>
                    </Col>
                    <ColTo xs={12} sm={1}>
                      <strong>To</strong>
                    </ColTo>
                    <Col xs={12} sm={4} lg={4}>
                      <StyledInputGroup>
                        <InputGroupAddon addonType="prepend">
                          <IconMapMarkerGrey />
                        </InputGroupAddon>
                        <DestinationTypeahead
                          id="destination"
                          name="destination"
                          onSearch={this.handleSearchMeetingRoom}
                          onChange={this.handleDestinationChange}
                          filterBy={['name']}
                          isLoading={!!poiListResult.length}
                          options={destinationList}
                          labelKey="name"
                          placeholder="Choose Destination"
                          renderMenu={this.renderMenu}
                          ref={input => (this.destinationInput = input)}
                          selected={selectedDestinations}
                        />
                      </StyledInputGroup>
                    </Col>
                    <Col>
                      {startingPoint || destination ? (
                        <ClearButton onClick={this.clearRoute}>
                          Clear
                        </ClearButton>
                      ) : (
                        ''
                      )}
                    </Col>
                  </FullRow>
                </MapDirections>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={4}>
                <PageTitle>Meeting Rooms</PageTitle>
                <FloorCard>
                  <ListGroup flush>
                    <ListGroupItem>
                      <select
                        className="form-control"
                        value={floor}
                        onChange={this.handleFloorChanged}>
                        {floors.map(item => (
                          <option key={item.id} value={item.level}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </ListGroupItem>
                    {floor && (
                      <ListGroupItem>
                        <Input
                          value={searchPOI}
                          placeholder="Search..."
                          ref={input => (this.searchPOIRef = input)}
                          onChange={this.handleSearchPOIChange}
                          disabled={loadingPOI}
                        />
                      </ListGroupItem>
                    )}
                  </ListGroup>
                  <ScrolledListGroup
                    id="scrolledListGroup"
                    height={scrolledListGroupHeight}
                    flush>
                    {loadingPOI && (
                      <CenteredContent>
                        <LoadingComponent />
                      </CenteredContent>
                    )}
                    {!loadingPOI &&
                      poiList.map(item => (
                        <StyledListGroupItem
                          active={!!poi && poi.id === item.id}
                          tag="a"
                          href="#"
                          key={item.id}
                          onClick={event => this.selectPOI(event, item)}>
                          <MutedListText>
                            <ListHeader>{item.name}</ListHeader>
                            {item.zone.floor.name} - {item.zone.name}
                          </MutedListText>
                        </StyledListGroupItem>
                      ))}
                    {noPOIFound && searchPOI && (
                      <CenteredContent>{`"${searchPOI}" not found`}</CenteredContent>
                    )}
                    {hasMorePOI && !loadingPOI && (
                      <StyledListGroupItem center={1}>
                        {loadingMorePOI && (
                          <MaterialSpinner indeterminate={loadingMorePOI} />
                        )}
                        {/*<Button*/}
                        {/*color="primary"*/}
                        {/*onClick={this.loadMorePOI}*/}
                        {/*disabled={loadingMorePOI}>*/}
                        {/*{loadingMorePOI ? 'Loading...' : 'Load More'}*/}
                        {/*</Button>*/}
                      </StyledListGroupItem>
                    )}
                  </ScrolledListGroup>
                </FloorCard>
              </Col>
              <ColMaps xs={12} sm={8}>
                <MapInfoWrapper>
                  {/*<CircleButton*/}
                  {/*  className="mr-1"*/}
                  {/*  color="secondary"*/}
                  {/*  id="Legends"*/}
                  {/*  onClick={this.handleMapInfoClicked}*/}
                  {/*  outline>*/}
                  {/*  <MaterialIcon icon="info" />*/}
                  {/*</CircleButton>*/}
                  {/*<CircleButton*/}
                  {/*  id="HeatMapButton"*/}
                  {/*  color="secondary"*/}
                  {/*  disabled={loadingHeatMap}*/}
                  {/*  onClick={this.handleHeatMapClicked}*/}
                  {/*  outline>*/}
                  {/*  <MaterialIcon icon="layer-group" />*/}
                  {/*</CircleButton>*/}
                  {/*<UncontrolledTooltip placement="top" target="HeatMapButton">*/}
                  {/*  Heat Map*/}
                  {/*</UncontrolledTooltip>*/}
                  {/*<UncontrolledTooltip placement="top" target="Legends">*/}
                  {/*  Floor Capacity Legend*/}
                  {/*</UncontrolledTooltip>*/}
                </MapInfoWrapper>
                <MapsViewer key={src} src={src} />
                <MapsBottomNavigation>
                  <CircleButtonSpecial
                    id="currentLocationButton"
                    color="secondary"
                    disabled={loadingHeatMap}
                    onClick={this.handleShowMyLocation}
                    outline>
                    <MaterialIcon icon="my_location" material />
                  </CircleButtonSpecial>
                  <UncontrolledTooltip
                    placement="top"
                    target="currentLocationButton">
                    Show Your Location
                  </UncontrolledTooltip>
                </MapsBottomNavigation>
                {selectedMarker ? (
                  <CardSelectedMarkerInfo
                    poi={selectedMarker}
                    onClose={this.onMarkerClosed}
                    onDirections={this.directionToMarker}
                  />
                ) : (
                  ''
                )}

                <RouteFloors>
                  <ListGroup>
                    {routeFloors.map(routeFloor => (
                      <ListGroupItem
                        key={routeFloor}
                        tag="a"
                        href="#"
                        active={+floor === +routeFloor}
                        onClick={event =>
                          this.handleRouteFloorClick(event, routeFloor)
                        }
                        action>
                        {routeFloor}
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                </RouteFloors>
              </ColMaps>
            </Row>
          </Container>
        </MainContent>
      </ApplicationLayout>
    );
  }
}

const CircleButton = styled(Button)`
  border-radius: 50% !important;
  padding: 6px 0 !important;
  width: 35px !important;
  height: 35px !important;
  background-color: #ffffff !important;
  color: var(--secondary) !important;
`;

const CircleButtonSpecial = styled(Button)`
  border-radius: 50% !important;
  padding: 8px 0 !important;
  width: 40px !important;
  height: 40px !important;
  background-color: #ffffff !important;
  color: var(--secondary) !important;
`;

const MapInfoWrapper = styled.div`
  position: absolute;
  z-index: 99;
  top: 25px;
  right: 15px;
`;

const ScrolledListGroup = styled(ListGroup)`
  height: ${props => props.height}px;
  overflow-x: hidden;
  overflow-y: auto;
  ${media.xs`
    height: auto;
  `};
`;

const StyledListGroupItem = styled(ListGroupItem)`
  ${props =>
    props.center &&
    css`
      text-align: center;
    `} ${props =>
    props.active &&
    css`
      h4 {
        color: #fff !important;
      }
      div {
        color: #fefefe !important;
      }
    `};
`;

const FloorCard = styled(Card)`
  margin-bottom: 1em;
`;

const PageTitle = styled(PageHeader)`
  color: #757575;
  font-size: 24px;
  margin-top: 10px;
`;

const MutedListText = styled(ListText)`
  color: #a1a1a1;
`;

const FullRow = styled(Row)`
  width: 100%;
`;

const SearchFormCustom = styled.div`
  font-size: 20px;
  margin-top: 8px;

  ${media.xs`
    margin-top: 10px;
  `};
`;

export const MapDirections = styled.div`
  background: #a32020;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  color: #ffffff;
  padding: 10px;
  display: flex;
  align-items: center;
  margin-top: 1em;
`;

const ColMaps = styled(Col)`
  padding-top: 1em;
  height: 600px;
  position: relative;
`;

const StyledInputGroup = styled(InputGroup)`
  border-bottom: 1px solid #dadada;
`;

const ClearButton = styled(Button).attrs({
  outline: true,
  color: 'secondary',
})`
  background: transparent !important;
  border-color: #ffffff !important;
  color: #ffffff !important;
  &:hover,
  &:focus {
    background: var(--secondary);
    border-color: var(--secondary);
    color: var(--secondary);
  }

  ${media.xs`
     margin-top: 10px;
  `};
`;

const StyledMenuItem = styled(MenuItem)`
  .dropdown-item {
    padding: 0;
  }
  .dropdown-item.active div {
    color: #ffffff !important;
  }
`;

const StyledSearchButton = styled(SearchButton)`
  border-bottom: 0;
`;

const StyledSearchBox = styled(SearchBox)`
  border-bottom: 1px solid #dfdfdf;
  display: block;
`;

const StyledAsyncTypeahead = styled(AsyncTypeahead)`
  width: 92%;

  ${media.xs`
    width: 90%;
  `} input {
    border: none;
    padding-left: 0;
    &:focus {
      box-shadow: none;
    }
  }
`;

const FlatInputStyles = css`
  width: 95%;

  input {
    border: none;
    background: transparent;
    color: #f8f8f8 !important;

    &::placeholder {
      color: #e9e9e9 !important;
    }

    &::-webkit-input-placeholder {
      color: #e9e9e9 !important;
    }

    &:focus {
      box-shadow: none;
      border: none;
      background: transparent;
    }
  }
`;

const StartingPointTypeahead = styled(AsyncTypeahead)`
  ${FlatInputStyles};
`;

const DestinationTypeahead = styled(AsyncTypeahead)`
  ${FlatInputStyles};
`;

const StyledRoomCard = styled(RoomCard)`
  border-radius: 0;
`;

const ColTo = styled(Col)`
  justify-content: center;
  align-items: center;
  display: flex;
  ${media.xs`
    justify-content: flex-start;
    margin-top: 10px;
  `};
`;

const RouteFloors = styled.div`
  position: absolute;
  bottom: 1em;
  right: 1em;
  z-index: 999;
`;

const MapsViewer = styled.iframe`
  border: 0;
  width: 100%;
  height: 100%;
`;

const MapsBottomNavigation = styled.div`
  position: absolute;
  right: 1em;
  bottom: 0.5em;
`;

function mapStateToProps(state) {
  const {floor, poi, setting, map, auth} = state;
  const {settings} = setting;
  const {userData, token} = auth;

  return {
    floor,
    poi,
    settings,
    map,
    userData,
    token,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setFloor: bindActionCreators(setSelectedFloorAction, dispatch),
    getFloorList: bindActionCreators(getFloorRequest, dispatch),
    getPoiByFloorList: bindActionCreators(getPoiByFloorRequest, dispatch),
    findRoomPOI: bindActionCreators(findRoomPOIRequest, dispatch),
    openModal: bindActionCreators(openModal, dispatch),
    getSettings: bindActionCreators(getSettingsAction, dispatch),
    clearPoiList: bindActionCreators(clearPoiListAction, dispatch),
  };
}

const connected = connect(mapStateToProps, mapDispatchToProps)(Maps);

const formed = reduxForm({
  form: 'SearchMap',
})(connected);

export default formed;
