import React, {Component} from 'react';
import {connect} from 'react-redux';
import ApplicationLayout from '../../components/Layouts/ApplicationLayout';
import {
  ContentHeader,
  MainContent,
  CenteredContent,
  Container,
  SearchIcon,
} from '../../styles';
import {getNewsAction, searchNewsAction} from '../../actions/news';
import {bindActionCreators} from 'redux';
import qs from 'query-string';
import {
  Input,
  InputGroupAddon,
  InputGroup,
  Button,
  Row,
  Col,
  Alert,
} from 'reactstrap';
import LoadingComponent from '../../components/UIKit/LoadingComponent';
import styled, {createGlobalStyle} from 'styled-components';
import LatestNewsPlaceholder from './LatestNewsPlaceholder';
import {dump, scrolledToBottom} from '../../utils/helpers';
import PostGrid from '../../components/News/PostGrid';

class SearchNews extends Component {
  timer = null;

  state = {
    search: '',
  };

  componentDidMount() {
    const keyword = this.getKeyword();
    if (keyword) {
      this.setState({search: keyword}, () => {
        this.fetch({keyword});
      });
    }
    // window.addEventListener('scroll', this.handleOnScroll);
  }

  componentWillUnmount() {
    // window.removeEventListener('scroll', this.handleOnScroll);
  }

  handleOnScroll = () => {
    if (scrolledToBottom()) {
      this.loadMore();
    }
  };

  loadMore = () => {
    const {global, news} = this.props;
    const {pagination} = global;
    const {loadingMore} = news;
    if (this.hasMorePage() && !loadingMore) {
      let page = pagination.current_page;
      page++;
      this.fetch({page, loadMore: true, append: true}, false);
    }
  };

  hasMorePage = () => {
    const {global} = this.props;
    const {pagination} = global;
    return pagination.current_page < pagination.last_page;
  };

  fetch = (params = {}, timer = false) => {
    const keyword = this.state.search || this.getKeyword();
    dump('Fetching: ' + keyword);
    this.search(
      {
        keyword,
        limit: 6,
        orderBy: 'id',
        descending: true,
        ...params,
      },
      timer
    );
  };

  handleSearchChange = event => {
    const key = event.key;
    const isEnter = key === 'Enter';
    const keyword = event.target.value;
    if (isEnter && keyword) {
      this.props.history.push(`/news/search?keyword=${keyword}`);
    }
  };

  componentWillReceiveProps(props) {
    if (props.location !== this.props.location) {
      const currentKeyword = this.getKeyword(this.props.location);
      const nextKeyword = this.getKeyword(props.location);
      this.setState({search: nextKeyword});
      if (nextKeyword !== currentKeyword) {
        this.fetch({keyword: nextKeyword});
      }
    }
  }

  getKeyword = location => {
    location = location || this.props.location;
    const query = qs.parse(location.search);
    return query.keyword;
  };

  search = (params = {}, timer = true) => {
    dump('Searching: ' + params.keyword);
    if (timer) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.props.searchNews(params);
      }, 500);
    } else {
      this.props.searchNews(params);
    }
  };

  handleButtonSearchClicked = () => {
    const {search} = this.state;
    if (!search) {
      document.getElementById('searchInput').focus();
      return;
    }

    this.fetch({keyword: search});
  };

  handleSearchInputChange = event => {
    const search = event.target.value;
    dump({search});
    this.setState({
      search,
    });
  };

  render() {
    const {search} = this.state;
    const {
      searchResult: posts,
      searching: loading,
      loadingMore,
    } = this.props.news;
    const {current_page} = this.props.global.pagination;
    const keyword = this.getKeyword();
    const hasPosts = posts.length > 0 && !loading && !!keyword;
    const noResult = posts.length < 1 && !!keyword;
    const hasMorePage = this.hasMorePage();
    const noMoreResults = !hasMorePage && current_page > 1;

    return (
      <ApplicationLayout withoutFooter>
        <SearchHeader>
          <Container>
            <Row className="align-items-center">
              <Col xs={12} sm={6}>
                <PageHeader>Search {keyword ? 'Result' : 'News'}</PageHeader>
              </Col>
              <Col xs={12} sm={6}>
                <StyledInputGroup>
                  <Input
                    id="searchInput"
                    disabled={loading}
                    autoFocus={true}
                    placeholder="Search..."
                    value={search}
                    onKeyDown={this.handleSearchChange}
                    onChange={this.handleSearchInputChange}
                  />
                  <InputGroupAddon addonType="append">
                    <Button
                      onClick={this.handleButtonSearchClicked}
                      disabled={loading}
                      color="primary">
                      {loading ? (
                        <LoadingComponent small button noMargin />
                      ) : (
                        <SearchIcon />
                      )}
                    </Button>
                  </InputGroupAddon>
                </StyledInputGroup>
              </Col>
            </Row>
          </Container>
        </SearchHeader>
        <MainContent>
          <Container>
            {loading ? (
              <LatestNewsPlaceholder />
            ) : hasPosts ? (
              <PostGrid posts={posts} />
            ) : (
              <CenteredContent>No result</CenteredContent>
            )}
            {loadingMore ? (
              <LoadingComponent />
            ) : (
              <div className="text-center mb-3 mt-2">
                {!noMoreResults && hasMorePage && (
                  <Button
                    color="primary"
                    onClick={this.loadMore}
                    disabled={loadingMore}>
                    {loadingMore ? 'Loading...' : 'Load More'}
                  </Button>
                )}
              </div>
            )}
            {noMoreResults && (
              <Alert className="text-center" color="default">
                No more results to show
              </Alert>
            )}
          </Container>
        </MainContent>
        <CreateGlobalStyle />
      </ApplicationLayout>
    );
  }
}

const CreateGlobalStyle = createGlobalStyle`
  body {
    background: #f8f8f8!important;
  }
 `;

const PageHeader = styled.h1`
  font-size: 24px;
  font-weight: bold;
  margin: 0;
  padding: 0;
`;

const SearchHeader = styled(ContentHeader)`
  background-color: #ffffff;
  padding: 16px 0;
`;

const StyledInputGroup = styled(InputGroup)`
  margin-top: 10px;
`;

function mapStateToProps(state) {
  const {news, global} = state;
  return {
    news,
    global,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getNews: bindActionCreators(getNewsAction, dispatch),
    searchNews: bindActionCreators(searchNewsAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchNews);
