import {
  AUTH_OUT_SUCCESS,
  RESCAN_ACTION,
  SCAN_QRCODE_FAILED,
  SCAN_QRCODE_SUCCESS,
  SCANNING_QRCODE,
} from '../actions/actionsList';

let initialState = {
  result: null,
  scanning: false,
  showScanner: true,
};

export default function(state = initialState, action) {
  const {type, payload} = action;

  switch (type) {
    case AUTH_OUT_SUCCESS: {
      return {...initialState};
    }

    case SCANNING_QRCODE:
      return {...state, scanning: true};

    case SCAN_QRCODE_SUCCESS:
      const {result} = payload;
      return {...state, scanning: false, result, showScanner: false};

    case SCAN_QRCODE_FAILED:
      return {...state, scanning: false, result: null, showScanner: true};

    case RESCAN_ACTION: {
      return {...state, scanning: false, result: null, showScanner: true};
    }

    default:
      return state;
  }
}
