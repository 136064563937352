import {BeverageApi} from '../utils/api';

export default {
  /**
   * Create new beverage.
   *
   * @param values
   * @returns {Promise<*>}
   */
  async create(values) {
    try {
      const {data} = (await BeverageApi.post(`/v1/admin/menu`, values)) || {};
      return data;
    } catch (e) {
      return e && (e.response || {}).data;
    }
  },

  /**
   * Get List Menu.
   *
   * @param search
   * @param limit
   * @param page
   * @param sortBy
   * @param descending
   * @returns {Promise<*>}
   */
  async getList({search, limit, page, sortBy, descending}) {
    try {
      const {data} =
        (await BeverageApi.get(`/v1/admin/menu`, {
          params: {
            search,
            limit: limit || 10,
            page: page || 1,
            sortBy: sortBy || 'id',
            descending: descending || false,
          },
        })) || {};
      return data;
    } catch (e) {
      return e && (e.response || {}).data;
    }
  },

  /**
   * Remove Menu by ID.
   *
   * @param id
   * @returns {AxiosPromise}
   */
  remove(id) {
    return BeverageApi.delete(`/v1/admin/menu`, {params: {id}})
      .then(response => (response || {}).data)
      .catch(err => err && (err.response || {}).data);
  },

  /**
   * Find menu by ID.
   *
   * @param id
   * @returns {Promise<AxiosResponse<any>>}
   */
  find(id) {
    return BeverageApi.get(`/v1/admin/menu/${id}`)
      .then(response => (response || {}).data)
      .catch(err => err && (err.response || {}).data);
  },

  /**
   * Update menu.
   *
   * @param id
   * @param values
   * @returns {Promise<*>}
   */
  async update(id, values) {
    try {
      const {data} =
        (await BeverageApi.put(`/v1/admin/menu`, values, {
          params: {id},
        })) || {};
      return data;
    } catch (e) {
      return e && (e.response || {}).data;
    }
  },

  /**
   * Get List Menu.
   *
   * @param search
   * @param limit
   * @param page
   * @param sortBy
   * @param descending
   * @returns {Promise<*>}
   */
  async getBeverageList({search, limit, page, sortBy, descending}) {
    try {
      const {data} =
        (await BeverageApi.get(`/v1/menu`, {
          params: {
            search,
            limit: limit || 10,
            page: page || 1,
            sortBy: sortBy || 'id',
            descending: descending || false,
          },
        })) || {};
      return data;
    } catch (e) {
      return e && (e.response || {}).data;
    }
  },

  /**
   * Order Beverages.
   *
   * @param values
   * @returns {Promise<*>}
   */
  async order(values) {
    try {
      const {data} = (await BeverageApi.post(`/v1/order`, values)) || {};
      return data;
    } catch (e) {
      return e && (e.response || {}).data;
    }
  },
};
