import React, {Component} from 'react';
import HeatMapItem from './HeatMapItem';
import {ListGroup} from 'reactstrap';

class HeatMapList extends Component {
  render() {
    const {heatMap, onSelected} = this.props;

    return (
      <ListGroup>
        {heatMap.map(item => (
          <HeatMapItem onSelected={onSelected} item={item} key={item.id} />
        ))}
      </ListGroup>
    );
  }
}

export default HeatMapList;
