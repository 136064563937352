import React, {Component, Fragment} from 'react';
import styled from 'styled-components';
import 'react-datepicker/dist/react-datepicker.css';
import {Field, reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {releaseOONRequest, submitOonRequest} from '../../../actions/oon';
import MessageField from './MessageField';
import moment from 'moment';
import {datepickerDate} from '../../../utils/helpers';
import {
  ModalBody,
  ModalHeader,
  Button,
  ModalFooter,
  Row,
  Col,
} from 'reactstrap';
import {toggleModal} from '../../../actions/modal';
import config from '../../../config';
import DatePicker from 'react-datepicker';
import {InputStyle, TextError} from '../../../styles';
import MaterialSpinner from '../../UIKit/MaterialSpinner';

const SHOW_OPTION_TO_BLASTMAIL_OON = false;

class Index extends Component {
  onSubmit = values => {
    const startDate = moment(values.startDate).format('YYYY-MM-DD');
    const endDate = moment(values.endDate).format('YYYY-MM-DD');
    const data = {
      message: values.message,
      start_date: `${startDate} 00:00:00`,
      end_date: `${endDate} 23:59:00`,
      send_external_mail: values.sendNotification === 'send' ? 1 : 0,
    };
    const {submitOonRequest} = this.props;
    submitOonRequest(data);
  };

  toggleModal = () => {
    this.props.toggleModal('modalOON');
  };

  releaseOON = () => {
    const {releaseOON, OONData, willOON} = this.props;
    const id = OONData ? OONData.id : willOON.id;
    releaseOON(id);
  };

  render() {
    const {handleSubmit, loading, oon} = this.props;
    const {willOON} = this.props;
    const {onSubmit} = this;
    const {releasing} = oon;

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader toggle={this.toggleModal}>
          Set Out of Office Notification
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Field
                name="startDate"
                component={ReduxFormDateRange}
                placeholderText="Start Date"
                dateFormat={config.datepickerFormat}
              />
            </Col>
            <Col>
              <Field
                name="endDate"
                component={ReduxFormDateRange}
                placeholderText="End Date"
                dateFormat={config.datepickerFormat}
                minDate={moment()}
                maxDate={moment().add(1, 'year')}
              />
            </Col>
          </Row>
          <Field name="message" component={MessageField} />
          {SHOW_OPTION_TO_BLASTMAIL_OON && (
            <>
              <FormGroup margin={0}>
                <RadioField
                  id="send-oon"
                  component="input"
                  type="radio"
                  name="sendNotification"
                  value="send"
                  required
                />
                <RadioLabel htmlFor="send-oon">
                  Send Out of Office Notification to external mail
                </RadioLabel>
              </FormGroup>
              <FormGroup margin={0}>
                <RadioField
                  id="dont-send-oon"
                  component="input"
                  type="radio"
                  name="sendNotification"
                  value="dont-send"
                  required
                />
                <RadioLabel htmlFor="dont-send-oon">
                  Don't send Out of Office Notification to external mail
                </RadioLabel>
              </FormGroup>
            </>
          )}
        </ModalBody>
        <FormAction>
          {willOON && (
            <Button
              type="button"
              disabled={releasing}
              color="secondary"
              onClick={this.releaseOON}>
              <MaterialSpinner indeterminate={releasing} small button />
              {releasing ? 'Releasing...' : 'Cancel'}
            </Button>
          )}
          <SubmitButton
            block={!willOON}
            transform="uppercase"
            color="primary"
            disabled={loading}
            type="submit">
            <MaterialSpinner indeterminate={loading} small button />
            {loading ? 'Submitting' : 'Submit'}
          </SubmitButton>
        </FormAction>
      </form>
    );
  }
}

const ReduxFormDateRange = props => {
  const {
    meta: {touched, error},
    input,
    ...rest
  } = props;
  let selected = input.value || null;
  selected = selected ? moment(selected, config.dateFormat) : selected;

  return (
    <Fragment>
      <FlatDatePicker selected={selected} onChange={input.onChange} {...rest} />
      {touched && error && <TextError>{error}</TextError>}
    </Fragment>
  );
};

const SubmitButton = styled(Button)``;

export const FormGroup = styled.div`
  margin-bottom: ${props =>
    props.margin !== undefined ? `${props.margin}px` : '1em'};
  &:last-child:not(:first-child) {
    margin-top: ${props =>
      props.margin !== undefined ? `${props.margin}px` : '1em'};
    margin-bottom: 0;
    button[type='submit'] {
      margin-bottom: 0;
    }
  }
`;

const FormAction = styled(ModalFooter)`
  justify-content: center !important;
`;

export const FormText = styled.div`
  color: #808080;
  font-size: 16px;
`;

const RadioLabel = styled.label`
  font-size: 16px;
  position: relative;
  top: -2px;
`;
const RadioField = styled(Field)`
  margin-right: 10px;
`;

const FlatDatePicker = styled(DatePicker)`
  ${props => InputStyle};
`;

function getWillOONData(willOON) {
  return willOON
    ? {
        startDate: datepickerDate(willOON.start_date),
        endDate: datepickerDate(willOON.end_date),
        message: willOON.message,
      }
    : {startDate: moment().format(config.dateFormat)};
}

function mapStateToProps(state) {
  const {profile, oon} = state;
  const {loading} = state.global;
  const {profileData} = profile;
  const {will_oon: willOON, oon: OONData} = profileData;
  const willOONData = getWillOONData(willOON);

  return {
    oon,
    OONData,
    loading,
    profileData,
    willOON,
    initialValues: {
      sendNotification: 'send',
      ...willOONData,
    },
  };
}

function mapDispatchToProps(dispatch) {
  return {
    submitOonRequest: bindActionCreators(submitOonRequest, dispatch),
    toggleModal: bindActionCreators(toggleModal, dispatch),
    releaseOON: bindActionCreators(releaseOONRequest, dispatch),
  };
}

const validate = values => {
  const errors = {};
  if (!values.startDate) {
    errors.startDate = 'Start date is required';
  }
  if (!values.endDate) {
    errors.endDate = 'End date is required';
  }
  if (values.startDate && values.endDate) {
    const startDate = moment(values.startDate, config.dateFormat);
    const endDate = moment(values.endDate, config.dateFormat);
    if (endDate < startDate) {
      errors.endDate = 'End date must be later than start date';
    }
  }
  if (!values.message) {
    errors.message = 'Message is required';
  } else if (values.message.length > 255) {
    errors.message = 'OON Message should be no longer than 255 characters ';
  }
  return errors;
};

const form = reduxForm({
  form: 'FormSubmitOON',
  validate,
})(Index);

export default connect(mapStateToProps, mapDispatchToProps)(form);
