import {AUTH_OUT_SUCCESS, SET_FLOOR} from '../actions/actionsList';

let initialState = {
  floor: '',
};

export default function(state = initialState, action) {
  const {type, payload} = action;

  switch (type) {
    case AUTH_OUT_SUCCESS: {
      return {...initialState};
    }

    case SET_FLOOR:
      const {floor: floorValue} = payload;
      const floor = floorValue ? floorValue : '';
      return {...state, floor};

    default:
      return state;
  }
}
