import React, {Component} from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {authOutRequest} from '../../actions/auth';
import LoadingComponent from '../UIKit/LoadingComponent';
import Avatar from '../Avatar';
import {openModal} from '../../actions/modal';
import {releaseOONRequest} from '../../actions/oon';
import {withRouter} from 'react-router';
import {isMyProfile} from '../../utils/helpers';
import WillOON from './WillOON';
import {Button} from 'reactstrap';
import PropTypes from 'prop-types';

class ProfileCard extends Component {
  onOutOfOffice = () => {
    const {openModal} = this.props;
    openModal('modalOON');
  };

  releaseOON = () => {
    this.props.openModal('modalReleaseOON');
  };

  render() {
    const {profileData, oon: oonState, noAction} = this.props;

    if (!profileData) return null;

    const {profile: user} = profileData;
    const {onOutOfOffice, releaseOON} = this;
    if (!user) return <LoadingComponent />;
    const {hiris, will_oon: willOON} = profileData;
    const {releasing, oon} = oonState;
    const isMe = isMyProfile(user);
    const canSubmitOON = !noAction && (oon ? !oon.active : true) && !willOON;
    const canReleaseOON = !noAction && !!oon && oon.active && !willOON;
    // const isNotStaff = isNonStaff(user || {});

    return (
      <ProfileCardWrapper>
        <ProfileAvatar>
          <ProfileAvatarImage>
            <Avatar image={user.photo} />
            <ProfileCardAvatarMask image={user.avatar} />
          </ProfileAvatarImage>
        </ProfileAvatar>
        <ProfileCardInfo>
          <ProfileCardInfoUsername>{user.name}</ProfileCardInfoUsername>
          {hiris && hiris.jobTitle && (
            <ProfileCardInfoNickname>{hiris.jobTitle}</ProfileCardInfoNickname>
          )}
          {hiris && hiris.bunName && (
            <ProfileCardInfoDivision>{hiris.bunName}</ProfileCardInfoDivision>
          )}
        </ProfileCardInfo>
        {isMe && canSubmitOON && (
          <LogoutButton color="primary" onClick={onOutOfOffice}>
            Set Out of Office Notification
          </LogoutButton>
        )}
        {isMe && willOON && <WillOON oon={willOON} />}
        {isMe && canReleaseOON && (
          <ReleaseOONWrapper>
            <Button
              color="primary"
              disabled={releasing}
              onClick={releaseOON}
              outline>
              {releasing ? 'Releasing...' : "I've returned to office"}
            </Button>
          </ReleaseOONWrapper>
        )}
      </ProfileCardWrapper>
    );
  }
}

const ReleaseOONWrapper = styled.div`
  margin-top: 1em;
`;

const ProfileCardWrapper = styled.div`
  width: 268px;
  margin: 20px auto;
  text-align: center;
`;
const ProfileAvatar = styled.div`
  display: flex;
  justify-content: center;
`;
export const ProfileAvatarImage = styled.div`
  width: 100px;
  max-width: 100%;
  position: relative;
`;

export const ProfileCardAvatarMask = styled(Avatar).attrs({
  mask: 1,
})``;
const ProfileCardInfo = styled.div``;
const ProfileCardInfoUsername = styled.h3`
  font-size: 22px;
  font-weight: bold;
  color: #505050;
  margin-top: 15px;
  margin-bottom: 5px;
`;
const ProfileCardInfoNickname = styled.div`
  color: rgba(80, 80, 80, 0.87);
  font-size: 14px;
  margin-bottom: 5px;
  text-transform: capitalize;
`;
const ProfileCardInfoDivision = styled.div`
  color: rgba(117, 117, 117, 0.87);
  font-size: 14px;
`;
const LogoutButton = styled(Button)`
  background: #e0301e;
  border-radius: 5px;
  color: #fff;
  width: 100%;
  margin-top: 15px;
  border-color: #e0301e;

  &:hover {
    background-color: #a32020 !important;
  }
`;

function mapStateToProps(state) {
  const {oon, profile} = state;
  const {loading} = state.global;
  return {
    oon,
    profile,
    loading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    authOutRequest: bindActionCreators(authOutRequest, dispatch),
    openModal: bindActionCreators(openModal, dispatch),
    releaseOON: bindActionCreators(releaseOONRequest, dispatch),
  };
}

ProfileCard.propTypes = {
  noAction: PropTypes.bool,
};

ProfileCard.defaultProps = {
  noAction: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProfileCard));
