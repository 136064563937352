import {dateReservationFormat} from '../../utils/helpers';
import React from 'react';

class ReservationDate extends React.Component {
  render() {
    const {reservation} = this.props;

    return dateReservationFormat(reservation.date_reservation);
  }
}

export default ReservationDate;
