import {
  MediaBody,
  MediaHeading,
  MediaList,
  NotifMessage,
  NotifMessageLink,
} from '../../../styles';
import React, {useEffect, useState} from 'react';
import MediaPOIInfo from '../MediaPOIInfo';
import MediaObject from '../MediaObject';
import {parseBodyMessage, reservationURL} from '../../../utils/helpers';

const MediaInfoReservation = props => {
  const {notification} = props;

  const [bodyMessage, setBodyMessage] = useState({});
  const [message, setMessage] = useState();

  useEffect(() => {
    const {message: msg, body} = parseBodyMessage(notification);

    setBodyMessage(body || {});
    setMessage(msg);
  }, [notification]);

  const id = bodyMessage.reservation_id || bodyMessage.id_reservation;
  const to = reservationURL(id);

  return (
    <MediaList>
      <MediaObject notification={notification} />
      <MediaBody>
        <MediaHeading heading>You Info Reservation</MediaHeading>
        <NotifMessage>{message}</NotifMessage>
        <NotifMessageLink to={to} target="_blank">
          {bodyMessage.meeting_description}
        </NotifMessageLink>
        <div>
          <MediaPOIInfo reservation={bodyMessage} />
        </div>
      </MediaBody>
    </MediaList>
  );
};

export default MediaInfoReservation;
