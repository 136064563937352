import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import ApplicationLayout from '../../components/Layouts/ApplicationLayout';
import {
  MainContent,
  ContentHeader,
  Container,
  CategoryHeader,
  CategoryTitle,
  MoreButton,
  CategoryPosts,
  NewsCardColumns,
  FlexCol,
} from '../../styles';
import CarouselNews from '../../components/Carousel/CarouselNews';
import {getDashboardNews} from '../../actions/news';
import {bindActionCreators} from 'redux';
import styled, {createGlobalStyle} from 'styled-components';
import {Button, CardColumns, Col, Input, InputGroup, Row} from 'reactstrap';
import {chunkArray, getNewsCategory, getNewsType} from '../../utils/helpers';
import config from '../../config';
import LatestNewsPlaceholder from './LatestNewsPlaceholder';
import {media} from '../../styles/grid';
import PostCard from '../../components/News/PostCard';
import NoNewsFound from './NoNewsFound';
import NewsNav from '../../components/News/NewsNav';
import PostGrid from '../../components/News/PostGrid';

class News extends Component {
  timer = null;

  fetch = (params = {}) => {
    this.props.getNews({
      limit: 6,
      orderBy: 'publish_date',
      descending: true,
      ...params,
    });
  };

  componentDidMount() {
    const type = this.getNewsType();
    this.fetch({type});
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const category = nextProps.match.params.category;
    if (category !== this.props.match.params.category) {
      const type = this.getNewsType(nextProps);
      this.fetch({type});
    }
  }

  getNewsType = props => {
    return getNewsType(props || this.props);
  };

  render() {
    const category = getNewsCategory();
    const {
      latestNews,
      pagination,
      highlight,
      loadingDashboardNews,
    } = this.props.news;
    const categoryTitle = category === 'announcement' ? category : 'News';
    const hasNews = latestNews.length > 0;

    if (config.DEBUG) {
      console.log({
        latestNews,
        pagination,
        highlight,
        loadingDashboardNews,
        category,
      });
    }

    return (
      <ApplicationLayout withoutFooter>
        <NewsNav onSearch={this.handleSearch} />
        <MainContent>
          <Container>
            <CarouselWrapper>
              <CarouselNews items={highlight} loading={loadingDashboardNews} />
            </CarouselWrapper>

            <CategoryHeader>
              <CategoryTitle>Latest {categoryTitle}</CategoryTitle>
              <MoreButton to={`/news/${category}/others`}>
                See Others
              </MoreButton>
            </CategoryHeader>

            {loadingDashboardNews ? (
              <LatestNewsPlaceholder />
            ) : hasNews ? (
              <PostGrid posts={latestNews} />
            ) : (
              <NoNewsFound />
            )}
          </Container>
        </MainContent>
        <CreateGlobalStyle />
      </ApplicationLayout>
    );
  }
}

const CarouselWrapper = styled.div`
  margin-top: 1em;
`;

const CreateGlobalStyle = createGlobalStyle`
  body {
    background: #F8f8f8!important;
  }
`;

const StyledCardColumns = styled(CardColumns)`
  column-count: 2 !important;
  ${media.xs`
    column-count: 1!important;
  `};
`;

function mapStateToProps(state) {
  const {news} = state;
  return {
    news,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getNews: bindActionCreators(getDashboardNews, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(News);
