import ApplicationLayout from '../../components/Layouts/ApplicationLayout';
import React, {Component} from 'react';
import {MainContent, Container, NotFoundWrapper} from '../../styles';
import {createGlobalStyle} from 'styled-components';
import PropTypes from 'prop-types';

class PageNotFound extends Component {
  render() {
    const {withoutTitle, title, message} = this.props;

    return (
      <ApplicationLayout withoutFooter>
        <MainContent>
          <Container>
            <NotFoundWrapper>
              {!withoutTitle && <h1>{title}</h1>}
              <p>{message}</p>
            </NotFoundWrapper>
          </Container>
        </MainContent>
        <CreateGlobalStyle />
      </ApplicationLayout>
    );
  }
}

PageNotFound.defaultProps = {
  title: 'Error: 404',
  message: 'Page is not available',
  withoutTitle: false,
};

PageNotFound.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  withoutTitle: PropTypes.bool,
};

const CreateGlobalStyle = createGlobalStyle`
  body {
    background: #f8f8f8!important;
  }
`;

export default PageNotFound;
