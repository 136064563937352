import {
  GET_DASHBOARD_EXTERNAL_NEWS_ACTION,
  GET_DASHBOARD_NEWS_ACTION,
  GET_DEPARTMENT_LIST_ACTION,
  GET_EXTERNAL_NEWS_ACTION,
  GET_EXTERNAL_NEWS_BY_CATEGORY_ACTION,
  GET_EXTERNAL_NEWS_CATEGORIES_ACTION,
  GET_EXTERNAL_NEWS_DETAIL_ACTION,
  GET_HOME_ANNOUNCEMENT,
  GET_HOME_NEWS_ACTION,
  GET_NEWS_BY_DEPARTMENT_ACTION,
  GET_NEWS_DETAIL_ACTION,
  SEARCH_NEWS_ACTION,
} from './actionsList';

export const getHomeNews = () => {
  return {type: GET_HOME_NEWS_ACTION, payload: {}};
};

export const getNewsDetailAction = id => {
  return {type: GET_NEWS_DETAIL_ACTION, payload: {id}};
};

export const getNewsAction = ({
  key,
  department_id,
  orderBy = 'viewers',
  descending = true,
  page = 1,
  limit = 10,
  type,
}) => {
  return {
    type: GET_NEWS_BY_DEPARTMENT_ACTION,
    payload: {department_id, key, orderBy, descending, page, limit, type},
  };
};

export const getDashboardNews = ({
  type = 0,
  page = 1,
  limit = 3,
  descending = true,
  orderBy = 'createdAt',
}) => {
  return {
    type: GET_DASHBOARD_NEWS_ACTION,
    payload: {type, page, limit, descending, orderBy},
  };
};

export const getDepartmentList = () => {
  return {
    type: GET_DEPARTMENT_LIST_ACTION,
    payload: {},
  };
};

export const getExternalNewsByCategoryAction = ({
  department_id,
  page = 1,
  limit = 10,
}) => {
  return {
    type: GET_EXTERNAL_NEWS_BY_CATEGORY_ACTION,
    payload: {page, limit, department_id},
  };
};

export const getExternalNewsAction = ({page = 1, limit = 10}) => {
  return {
    type: GET_EXTERNAL_NEWS_ACTION,
    payload: {page, limit},
  };
};

export const getExternalNewsCategories = () => {
  return {
    type: GET_EXTERNAL_NEWS_CATEGORIES_ACTION,
    payload: {},
  };
};

export const getExternalNewsDetail = id => {
  return {
    type: GET_EXTERNAL_NEWS_DETAIL_ACTION,
    payload: {id},
  };
};

export const searchNewsAction = ({
  keyword = '',
  orderBy = 'viewers',
  descending = true,
  page = 1,
  limit = 5,
  append = false,
  loadMore = false,
}) => {
  return {
    type: SEARCH_NEWS_ACTION,
    payload: {keyword, orderBy, descending, page, limit, loadMore, append},
  };
};

export const getHomepageAnnouncement = () => {
  return {
    type: GET_HOME_ANNOUNCEMENT,
  };
};
