import React, {Component} from 'react';
import {connect} from 'react-redux';
import ApplicationLayout from '../../components/Layouts/ApplicationLayout';
import {
  MainContent,
  Container,
  CategoryHeader,
  CategoryTitle,
} from '../../styles';
import {
  getExternalNewsAction,
  getExternalNewsByCategoryAction,
  getExternalNewsCategories,
} from '../../actions/news';
import {bindActionCreators} from 'redux';
import {createGlobalStyle} from 'styled-components';
import {
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';
import {chunkArray, getNewsCategory} from '../../utils/helpers';
import config from '../../config';
import LatestNewsPlaceholder from './LatestNewsPlaceholder';
import PostCard from '../../components/News/PostCard';
import {Link} from 'react-router-dom';
import NoNewsFound from './NoNewsFound';
import Pagination from '../../components/Pagination/Pagination';
import {Category} from '../../models/Category';
import NewsNav from '../../components/News/NewsNav';
import PostGrid from '../../components/News/PostGrid';

class ExternalSeeOthers extends Component {
  state = {
    page: 1,
    limit: 10,
  };

  componentDidMount() {
    this.fetchCategories();
    const {department_id} = this.props.match.params;
    this.fetch({
      department_id,
    });
  }

  fetchCategories = () => {
    this.props.getCategories();
  };

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.match.params !== this.props.match.params) {
      const {department_id} = nextProps.match.params;
      this.fetch({
        department_id,
      });
    }
  }

  componentWillUpdate(nextProps, nextState, nextContext) {
    if (nextState.page !== this.state.page) {
      this.fetch({page: nextState.page});
    }
  }

  handlePagination = page => {
    this.setState({page});
  };

  fetch = (params = {}) => {
    const {limit} = this.state;
    const {department_id} = params;

    if (department_id) {
      this.props.getExternalNews({
        orderBy: 'publish_date',
        descending: true,
        limit,
        ...params,
      });
    } else {
      this.props.getExternalDashboardNews({
        key: '',
        orderBy: 'publish_date',
        descending: true,
        ...params,
        limit,
      });
    }
  };

  getDepartmentText = () => {
    const {department_id} = this.props.match.params;
    const {departments} = this.props.externalNews;

    if (department_id) {
      const department = departments.find(
        item => item.category_id === department_id
      );
      console.log({department, department_id});
      return department ? department.category_name || 'Loading...' : 'Filtered';
    }

    return 'All Departments';
  };

  getPageTitle = () => {
    const category = getNewsCategory();
    if (category !== config.NEWS_TYPES.ANNOUNCEMENT.name) {
      return `${category} News`;
    }
    return category;
  };

  getCategories = () => {
    const {departments} = this.props.externalNews;
    return departments
      .map(
        item =>
          new Category(
            item.category_id,
            item.category_name,
            item.category_icon,
            item.category_img
          )
      )
      .sort((x, y) => {
        let textA = x.name.toUpperCase();
        let textB = y.name.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
  };

  render() {
    const {page: currentPage, limit: itemsPerPage} = this.state;
    const departmentId = this.props.match.params.department_id;
    const category = getNewsCategory();
    const {
      pagination,
      departments,
      posts,
      loadingDepartment,
      loading,
      latestNews,
    } = this.props.externalNews;
    const categories = this.getCategories();
    const departmentText = this.getDepartmentText();
    const pageTitle = this.getPageTitle();
    const newsItems = departmentId ? posts || [] : latestNews;
    const lastPage = pagination
      ? Math.ceil(pagination.total / itemsPerPage)
      : 0;
    const showPagination =
      pagination && Object.keys(pagination).length > 0 && lastPage > 1;
    const hasNews = newsItems.length > 0;

    if (config.DEBUG) {
      console.log({
        showPagination,
        pagination,
        currentPage,
        posts,
        departments,
        departmentId,
        categories,
        newsItems,
        lastPage,
      });
    }

    return (
      <ApplicationLayout withoutFooter>
        <NewsNav />
        <MainContent>
          <Container>
            <CategoryHeader>
              <CategoryTitle>{pageTitle}</CategoryTitle>
              <UncontrolledDropdown>
                <DropdownToggle
                  disabled={loading || loadingDepartment}
                  color="primary"
                  caret>
                  <span dangerouslySetInnerHTML={{__html: departmentText}} />
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem
                    active={!departmentId}
                    tag={Link}
                    to={`/news/${category}/others`}>
                    All Departments
                  </DropdownItem>
                  {categories.map(department => (
                    <DropdownItem
                      active={department.id === departmentId}
                      tag={Link}
                      to={`/news/${category}/others/${department.id}`}
                      key={department.id}
                      dangerouslySetInnerHTML={{__html: department.name}}
                    />
                  ))}
                </DropdownMenu>
              </UncontrolledDropdown>
            </CategoryHeader>

            {loading ? (
              <LatestNewsPlaceholder />
            ) : hasNews ? (
              <PostGrid posts={newsItems} external />
            ) : (
              <NoNewsFound />
            )}

            {showPagination && (
              <Pagination
                currentPage={currentPage}
                onChange={this.handlePagination}
                totalPage={lastPage}
              />
            )}
          </Container>
        </MainContent>
        <CreateGlobalStyle />
      </ApplicationLayout>
    );
  }
}

const CreateGlobalStyle = createGlobalStyle`
  body {
    background: #F8f8f8!important;
  }
`;

function mapStateToProps(state) {
  const {externalNews} = state;
  return {
    externalNews,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getCategories: bindActionCreators(getExternalNewsCategories, dispatch),
    getExternalNews: bindActionCreators(
      getExternalNewsByCategoryAction,
      dispatch
    ),
    getExternalDashboardNews: bindActionCreators(
      getExternalNewsAction,
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ExternalSeeOthers);
