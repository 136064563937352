import React, {Component} from 'react';
import {CardBody, Button} from 'reactstrap';
import {oonDate} from '../../utils/helpers';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {openModal} from '../../actions/modal';
import {OONCard, OONDate, OONText} from '../../styles';

class WillOON extends Component {
  handleChangeDate = () => {
    this.props.openModal('modalOON');
  };

  render() {
    const {oon} = this.props;
    const startDate = oonDate(oon.start_date);
    const endDate = oonDate(oon.end_date);

    return (
      <OONCard>
        <CardBody>
          <OONText>You are out of office from</OONText>
          <OONDate>
            {startDate} - {endDate}
          </OONDate>
          <Button color="primary" onClick={this.handleChangeDate} block>
            Change Date
          </Button>
        </CardBody>
      </OONCard>
    );
  }
}
function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    openModal: bindActionCreators(openModal, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WillOON);
