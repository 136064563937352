import {
  SCAN_QRCODE_FAILED,
  SCAN_QRCODE_REQUEST,
  SCAN_QRCODE_SUCCESS,
  SCANNING_QRCODE,
} from '../actions/actionsList';
import Reservation from '../api/Reservation';
import {takeEvery, put, call} from 'redux-saga/effects';
import {getCurrentUser, toast} from '../utils/helpers';
import firebase from '../utils/firebase';
import config from '../config';
import {setGlobalError} from '../actions/global';

function* scanQRCodeHandler(action) {
  const {payload} = action;

  try {
    yield put({type: SCANNING_QRCODE});
    const {id} = payload || {};
    const responseData = yield call(Reservation.getDetail, id);
    const {data: reservation, code, message} = responseData || {};
    if (code === 200) {
      yield put({type: SCAN_QRCODE_SUCCESS, payload: {result: reservation}});
      const user = getCurrentUser() || {};
      const userId = user.id;
      const db = firebase.database();
      // save data reservation to firebase realtime database
      db.ref('users/' + userId).set(reservation);
      toast({
        title: 'Your reservation has been found!',
        type: 'success',
      });
    } else {
      yield put({type: SCAN_QRCODE_FAILED});
      yield put(setGlobalError(message));
    }
  } catch (e) {
    yield put({type: SCAN_QRCODE_FAILED});
    yield put(setGlobalError(config.INTERNAL_SERVER_ERROR));
  }
}

/**
 * Reservation Saga.
 *
 * @returns {IterableIterator<*|ForkEffect>}
 */
export default function*() {
  yield takeEvery(SCAN_QRCODE_REQUEST, scanQRCodeHandler);
}
