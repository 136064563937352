import {PoiApi, ReservationApi} from '../utils/api';
import moment from 'moment';

export default {
  /**
   * Get API WFO Reservation List.
   *
   * @param page
   * @param perPage
   * @param sortBy
   * @param keyword
   * @param descending
   * @param withPaginate
   * @returns {Promise<*>}
   */
  async getWFOList({
    page = 1,
    perPage = 10,
    sortBy = 'reserve_date',
    descending = false,
    key,
    id_user,
    is_web = 1, // this is flag to let server now that it need to return customized list for web display
  }) {
    try {
      const res =
        (await ReservationApi.get(`/v1/reservation/wfo`, {
          params: {
            page,
            perPage,
            sortBy,
            descending,
            key,
            id_user,
            is_web,
          },
        })) || {};

      return res.data;
    } catch (e) {
      const {message} = (e && e.response && e.response.data) || e || {};
      return {error: message};
    }
  },

  /**
   * Get API Reservation List.
   *
   * @param page
   * @param perPage
   * @param sortBy
   * @param keyword
   * @param descending
   * @param withPaginate
   * @returns {Promise<*>}
   */
  async getList({
    page = 1,
    perPage = 12,
    sortBy = 'date_reservation',
    keyword,
    descending = false,
    withPaginate,
  }) {
    try {
      const {data} =
        (await ReservationApi.get(`/v1/reservation/history`, {
          params: {
            page,
            perPage,
            sortBy,
            key: keyword,
            descending,
            withPaginate: withPaginate || 1,
          },
        })) || {};
      return data;
    } catch (e) {
      const {message} = (e && e.response && e.response.data) || e || {};
      return {error: message};
    }
  },

  /**
   * API Request for Get Reservation Detail by ID.
   *
   * @param id
   * @returns {Promise<*>}
   */
  async getDetail(id) {
    try {
      const {data} =
        (await ReservationApi.get(`/v1/reservation/detail/${id}`)) || {};
      return data;
    } catch (e) {
      const {message} = (e && e.response && e.response.data) || e || {};
      return {error: message};
    }
  },

  /**
   * Find meeting room (active POI) API.
   *
   * @param start_time
   * @param end_time
   * @param check_in
   * @param check_in_release
   * @param booking_type
   * @param total_attendance
   * @param book_platform
   * @param key
   * @param reservation_type
   * @param video_conference
   * @param meeting_type
   * @returns {Promise<*>}
   */
  async findRoom({
    start_time,
    end_time,
    check_in,
    check_in_release,
    booking_type,
    total_attendance,
    book_platform,
    key,
    reservation_type,
    video_conference,
    meeting_type,
  }) {
    try {
      const {data} =
        (await PoiApi.post(`/v1/reservation/find_room`, {
          start_time,
          end_time,
          check_in,
          check_in_release,
          total_attendance,
          booking_type: booking_type || 'pre-reserve',
          book_platform: book_platform || 'web',
          key,
          reservation_type,
          video_conference,
          meeting_type,
        })) || {};
      return data;
    } catch (e) {
      const {message} = (e && e.response && e.response.data) || e || {};
      return {error: message};
    }
  },

  async findRoomWithQRCode({
    start_time,
    end_time,
    check_in,
    check_in_release,
    total_attendance,
    book_platform,
    key,
    qrcode,
  }) {
    const now = moment();
    try {
      const {data} =
        (await PoiApi.post(`/v1/reservation/find_room/qrcode/${qrcode}`, {
          start_time: start_time || now().format('HH:mm:ss'),
          end_time:
            end_time ||
            now()
              .add(3, 'hour')
              .format('HH:mm:ss'),
          check_in: check_in || now.format('YYYY-MM-DD'),
          check_in_release: check_in_release || now.format('YYYY-MM-DD'),
          total_attendance: total_attendance || 0,
          book_platform: book_platform || 'web',
          key: key || '',
        })) || {};
      return data;
    } catch (e) {
      const {message} = (e && e.response && e.response.data) || e || {};
      return {error: message};
    }
  },

  /**
   * Create reservation.
   *
   * @param values
   * @returns {Promise<*>}
   */
  async create(values) {
    try {
      const {data} =
        (await ReservationApi.post(`/v1/reservation`, values)) || {};
      return data;
    } catch (e) {
      const {message} = (e && e.response && e.response.data) || e || {};
      return {error: message};
    }
  },

  /**
   * Create WFO reservation.
   *
   * @param values
   * @returns {Promise<*>}
   */
  async reserveWFO(values) {
    try {
      const res =
        (await ReservationApi.post(`/v1/reservation/wfo`, values)) || {};

      return res.data;
    } catch (e) {
      const {message, data} = (e && e.response && e.response.data) || e || {};
      return {error: message, data};
    }
  },

  /**
   * Cancel reservation.
   *
   * @param id
   * @param remarks
   * @returns {Promise<*>}
   */
  async cancel({id, remarks}) {
    try {
      const {data} =
        (await ReservationApi.delete(`/v1/reservation/cancel/${id}`, {
          data: {remarks},
        })) || {};
      return data;
    } catch (e) {
      const {message} = (e && e.response && e.response.data) || e || {};
      return {error: message};
    }
  },

  /**
   * Cancel WFO reservation.
   *
   * @param id
   * @param remarks
   * @returns {Promise<*>}
   */
  async cancelWFOReserve({id}) {
    try {
      const res = await ReservationApi.put(`/v1/reservation/wfo/cancel/${id}`);

      return (res || {}).data;
    } catch (e) {
      const {message} = (e && e.response && e.response.data) || e || {};
      return {error: message};
    }
  },

  /**
   * Approve reservation by cluster admin.
   *
   * @param id
   * @returns {Promise<*>}
   */
  approveByClusterAdmin(id) {
    return ReservationApi.get(`/v1/approve/cluster/admin/${id}`)
      .then(res => (res || {}).data)
      .catch(err => err && (err.response || {}).data);
  },

  /**
   * Approve reservation by cluster's sic.
   *
   * @param id
   * @returns {Promise<*>}
   */
  approveByClusterSIC(id) {
    return ReservationApi.get(`/v1/approve/cluster/sic/${id}`)
      .then(res => (res || {}).data)
      .catch(err => err && (err.response || {}).data);
  },

  /**
   * Approve reservation.
   *
   * @param id
   * @returns {Promise<*>}
   */
  approve(id) {
    return ReservationApi.get(`/v1/approve/${id}`)
      .then(res => (res || {}).data)
      .catch(err => err && (err.response || {}).data);
  },

  /**
   * Reject reservation.
   *
   * @param id
   * @param remarks
   * @returns {Promise<*>}
   */
  reject(id, remarks) {
    return ReservationApi.delete(`/v1/approve/${id}`, {
      data: {remarks},
    })
      .then(res => (res || {}).data)
      .catch(err => err && (err.response || {}).data);
  },

  /**
   * Reject reservation.
   *
   * @param id
   * @param remarks
   * @returns {Promise<*>}
   */
  rejectByClusterAdmin(id, remarks) {
    return ReservationApi.delete(`/v1/approve/cluster/admin/${id}`, {
      data: {remarks},
    })
      .then(res => (res || {}).data)
      .catch(err => err && (err.response || {}).data);
  },

  /**
   * Reject reservation by cluster's SIC
   *
   * @param id
   * @param remarks
   * @returns {Promise<*>}
   */
  rejectByClusterSIC(id, remarks) {
    return ReservationApi.delete(`/v1/approve/cluster/sic/${id}`, {
      data: {remarks},
    })
      .then(res => (res || {}).data)
      .catch(err => err && (err.response || {}).data);
  },

  /**
   * Release reservation.
   *
   * @param id
   * @returns {Promise<*>}
   */
  async release(id) {
    try {
      const {data} =
        (await ReservationApi.get(`/v1/reservation/release/${id}`)) || {};
      return data;
    } catch (e) {
      const {message} = (e && e.response && e.response.data) || e || {};
      return {error: message};
    }
  },

  /**
   * Get List Reservation Cluster Admin.
   *
   * @param page
   * @param limit
   * @param perPage
   * @param sortBy
   * @param keyword
   * @param descending
   * @param clusterId
   * @param status
   * @returns {Promise<*>}
   */
  async getListForClusterAdmin({
    page,
    limit,
    perPage,
    sortBy,
    keyword,
    descending,
    clusterId,
    status,
  }) {
    try {
      const {data} =
        (await ReservationApi.get(`/v2/reservation/cluster/admin`, {
          params: {
            page: page || 1,
            limit: limit || 10,
            perPage: perPage || 10,
            sortBy: sortBy || 'status',
            descending: descending || false,
            key: keyword,
            cluster_id: clusterId,
            status,
          },
        })) || {};
      return data;
    } catch (e) {
      const {message} = (e && e.response && e.response.data) || e || {};
      return {error: message};
    }
  },

  /**
   * Get List Reservation Cluster SIC.
   *
   * @param page
   * @param limit
   * @param perPage
   * @param sortBy
   * @param keyword
   * @param descending
   * @param clusterId
   * @param status
   * @returns {Promise<*>}
   */
  async getListForClusterSIC({
    page,
    limit,
    perPage,
    sortBy,
    keyword,
    descending,
    clusterId,
    status,
  }) {
    try {
      const {data} =
        (await ReservationApi.get(`/v2/reservation/cluster/sic`, {
          params: {
            page: page || 1,
            limit: limit || 10,
            perPage: perPage || 10,
            sortBy: sortBy || 'status',
            descending: descending || false,
            key: keyword,
            cluster_id: clusterId,
            status,
          },
        })) || {};
      return data;
    } catch (e) {
      const {message} = (e && e.response && e.response.data) || e || {};
      return {error: message};
    }
  },

  /**
   * Create reservation by cluster admin.
   *
   * @param values
   * @returns {Promise<*>}
   */
  createForClusterAdmin(values) {
    return ReservationApi.post(`/v1/reservation/admin/cluster`, values)
      .then(res => (res || {}).data)
      .catch(err => err && (err.response || {}).data);
  },

  /**
   * Create group reservation by cluster admin.
   *
   * @param values
   * @returns {Promise<*>}
   */
  createGroupReservationClusterAdmin(values) {
    return ReservationApi.post(`/v1/reservation/admin/cluster/group`, {
      data: values,
    })
      .then(res => (res || {}).data)
      .catch(err => err && (err.response || {}).data);
  },

  /**
   * Find meeting room for cluster admin
   *
   * @param start_time
   * @param end_time
   * @param check_in
   * @param check_in_release
   * @param booking_type
   * @param total_attendance
   * @param book_platform
   * @param key
   * @param is_workspace
   * @param reservation_type
   * @param video_conference
   * @returns {Promise<*>}
   */
  async findRoomForClusterAdmin({
    start_time,
    end_time,
    check_in,
    check_in_release,
    booking_type,
    total_attendance,
    book_platform,
    key,
    is_workspace,
    reservation_type,
    video_conference,
    meeting_type,
  }) {
    const type = reservation_type === 'group' ? 'single' : reservation_type;
    try {
      const {data} =
        (await PoiApi.post(`/v1/reservation/find_room/cluster/admin`, {
          start_time,
          end_time,
          check_in,
          check_in_release,
          total_attendance,
          booking_type: booking_type || 'pre-reserve',
          book_platform: book_platform || 'web',
          key,
          is_workspace,
          reservation_type: type,
          video_conference,
          meeting_type,
        })) || {};
      return data;
    } catch (e) {
      const {message} = (e && e.response && e.response.data) || e || {};
      return {error: message};
    }
  },

  /**
   * Get list of reservation for admin.
   *
   * Used for receptionist.
   *
   * @param page
   * @param limit
   * @param perPage
   * @param sortBy
   * @param key
   * @param descending
   * @returns {Promise<AxiosResponse<any>>}
   */
  getListForReceptionist({page, limit, perPage, sortBy, key, descending}) {
    return ReservationApi.get('/v1/receptionist/reservation', {
      params: {
        page: page || 1,
        limit: limit || 10,
        perPage: perPage || 10,
        sortBy: sortBy || 'date_reservation',
        descending: !!descending,
        key,
      },
    })
      .then(res => (res || {}).data)
      .catch(err => err && (err.response || {}).data);
  },

  /**
   * Get list reservation for liason officer.
   *
   * @param page
   * @param limit
   * @param perPage
   * @param sortBy
   * @param key
   * @param descending
   * @returns {Promise<AxiosResponse<any>>}
   */
  getListForLiasonOfficer({page, limit, perPage, sortBy, key, descending}) {
    return ReservationApi.get('/v1/receptionist/reservation/guest', {
      params: {
        page: page || 1,
        limit: limit || 10,
        perPage: perPage || 10,
        sortBy: sortBy || 'updated_at',
        descending: !!descending,
        key,
      },
    })
      .then(res => (res || {}).data)
      .catch(err => err && (err.response || {}).data);
  },

  /**
   * Get Reservation List for LO by date.
   *
   * @param page
   * @param limit
   * @param perPage
   * @param sortBy
   * @param key
   * @param descending
   * @param fromDate
   * @param toDate
   * @param status
   * @param show_all
   * @param meeting_type
   * @returns {Promise<AxiosResponse<any>>}
   */
  getListByDate({
    page,
    limit,
    perPage,
    sortBy,
    key,
    descending,
    fromDate,
    toDate,
    status,
    show_all,
    meeting_type,
  }) {
    return ReservationApi.get('v1/reservation/bydate', {
      params: {
        page: page || 1,
        limit: limit || 10,
        perPage: perPage || 10,
        sortBy: sortBy || 'date_reservation',
        descending: !!descending,
        show_all: show_all || 0,
        meeting_type,
        fromDate,
        toDate,
        status,
        key,
      },
    })
      .then(res => (res || {}).data)
      .catch(err => err && (err.response || {}).data);
  },

  /**
   * API Get Reservation List Approval Request.
   *
   * @param page
   * @param limit
   * @param perPage
   * @param sortBy
   * @param key
   * @param descending
   * @param is_pending
   * @returns {Promise<AxiosResponse<any>>}
   */
  getListApprovalRequest({
    page,
    limit,
    perPage,
    sortBy,
    key,
    descending,
    is_pending,
  }) {
    return ReservationApi.get('v1/reservation/cluster/myApproval', {
      params: {
        page: page || 1,
        limit: limit || 10,
        perPage: perPage || 10,
        sortBy: sortBy || 'date_reservation',
        descending: !!descending,
        is_pending,
        key,
      },
    })
      .then(res => (res || {}).data)
      .catch(err => err && (err.response || {}).data);
  },

  /**
   * Get Detail POI with QR Code.
   *
   * @param id
   * @returns {AxiosPromise<any>}
   */
  getDetailPoiWithQRCode(id) {
    return PoiApi.get(`v1/poi/qrcode/${id}`, {
      params: {
        cluster_object: 1,
        book_platform: 'web',
      },
    })
      .then(res => (res || {}).data)
      .catch(err => err && (err.response || {}).data);
  },

  /**
   * Update reservation.
   *
   * @param id
   * @param data
   * @returns {Promise<AxiosResponse<any>>}
   */
  update(id, data) {
    return ReservationApi.put(`/v1/admin/reservation/${id}`, data)
      .then(res => (res || {}).data)
      .catch(err => err && (err.response || {}).data);
  },

  /**
   * Get My Cluster Admin.
   *
   * @returns {AxiosPromise<any>}
   */
  getMyClusterAdmin() {
    return PoiApi.get(`/v1/cluster/admin/myCluster`)
      .then(res => (res || {}).data)
      .catch(err => err && (err.response || {}).data);
  },

  /**
   * Get My Cluster Admin.
   *
   * @returns {AxiosPromise<any>}
   */
  getMyClusterSIC() {
    return PoiApi.get(`/v1/cluster/sic/myCluster`)
      .then(res => (res || {}).data)
      .catch(err => err && (err.response || {}).data);
  },

  /**
   * Reschedule.
   *
   * @param data
   * @returns {Promise<AxiosResponse<any> | never>}
   */
  reschedule(data) {
    return ReservationApi.post(`/v1/reservation/reschedule`, data)
      .then(res => (res || {}).data)
      .catch(err => err && (err.response || {}).data);
  },

  /**
   * Add reservation guest.
   *
   * @param data
   * @returns {Promise<AxiosResponse<any> | never>}
   */
  addGuest(data) {
    return ReservationApi.post(`/v1/receptionist/reservation/guest`, data)
      .then(res => (res || {}).data)
      .catch(err => err && (err.response || {}).data);
  },

  /**
   * Get reservation by POI ID.
   *
   * @param id
   * @returns {Promise<AxiosResponse<any> | never>}
   */
  getReservationByPOI(id) {
    return PoiApi.get(`/v1/poi/reservation/now/${id}`)
      .then(response => (response || {}).data)
      .catch(err => err && (err.response || {}).data);
  },

  /**
   * Get User Statistic
   * @returns {Promise<T | T>}
   */
  async getUserMonitoringStat() {
    try {
      const res = (await ReservationApi.get('/v1/reservation/wfo/home')) || {};

      return res.data;
    } catch (e) {
      const {message} = (e && e.response && e.response.data) || e || {};
      return {error: message};
    }
  },
};
