import React, {Component} from 'react';
import {Container, TableCard, TableCardHeader} from '../../../styles/index';
import {
  ContentHeader,
  MainContent,
  ButtonAddonSearch,
  EmptyAlert,
  EmptyRow,
  InputGroupSearch,
  SearchIcon,
} from '../../../styles';
import styled, {createGlobalStyle} from 'styled-components';
import {Col, Row, media} from '../../../styles/grid';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {openModal} from '../../../actions/modal';
import LoadingComponent from '../../../components/UIKit/LoadingComponent';
import {getReservationListRequest} from '../../../actions/reservation';
import ApplicationLayout from '../../../components/Layouts/ApplicationLayout';
import {CardBody, CardTitle} from '../../../styles/card';
import ReservationRow from './ReservationRow';
import TablePagination from '../../../components/Pagination/TablePagination';
import {
  UncontrolledTooltip,
  InputGroup,
  InputGroupAddon,
  Table,
} from 'reactstrap';
import {PageHeading, SubHeading} from '../../../styles/typography';
import SortableLink from '../../../components/DataTable/SortableLink';
import MaterialIcon from '../../../components/UIKit/MaterialIcon';

class Reservations extends Component {
  timer = null;

  state = {
    sortBy: 'date_reservation',
    descending: false,
    perPage: 10,
    page: 1,
    search: '',
  };

  componentDidMount() {
    this.fetch();
  }

  sortIcon = column => {
    const {sortBy, descending} = this.state;
    if (sortBy === column) {
      const icon = descending ? 'sort-down' : 'sort-up';
      const title = descending ? 'Descending' : 'Ascending';
      return <MaterialIcon icon={icon} title={title} />;
    }
    return null;
  };

  sortClass = column => {
    return this.state.sortBy === column;
  };

  fetch = params => {
    params = params || {};
    const defaultParams = {
      ...this.state,
    };
    this.props.getReservationList({
      ...params,
      ...defaultParams,
      withPaginate: 1,
    });
  };

  handlePagination = pagination => {
    const {currentPage: page, rowsPerPage: perPage} = pagination;
    this.setState({perPage, page});
  };

  handleSearch = event => {
    const search = event.target.value;
    this.setState({search});
  };

  handleSort = (event, column) => {
    let {descending} = this.state;
    descending = !descending;
    this.setState({
      sortBy: column,
      descending,
    });
  };

  componentDidUpdate(prevProps, prevState, ss) {
    const isSearch = prevState.search !== this.state.search;
    if (
      prevState.sortBy !== this.state.sortBy ||
      prevState.descending !== this.state.descending ||
      isSearch ||
      prevState.perPage !== this.state.perPage ||
      prevState.page !== this.state.page
    ) {
      const {state} = this;
      const params = {
        page: state.page,
        perPage: state.perPage,
        sortBy: state.sortBy,
        keyword: state.search,
        descending: state.descending,
      };

      if (isSearch) {
        const {keyword} = params;
        if (keyword) {
          if (keyword.length > 2) {
            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
              this.fetch(params);
            }, 500);
          }
        } else {
          this.fetch(params);
        }
      } else {
        this.fetch(params);
      }
    }
  }

  getRowsPerPageItems() {
    return [5, 10, 15, 20, 25, 50, 100];
  }

  render() {
    const {reservation, pagination} = this.props;
    const {reservations, loading} = reservation || {};
    const isEmpty = !reservations || reservations.length < 1;
    const {perPage} = this.state;

    return (
      <ApplicationLayout withoutFooter>
        <CreateGlobalStyle />
        <ContentHeader>
          <Container>
            <Row>
              <Col>
                <PageHeading>My Reservation</PageHeading>
                <SubHeading>List all of your reservation</SubHeading>
              </Col>
            </Row>
          </Container>
        </ContentHeader>
        <MainContent>
          <Container>
            <TableCard>
              <TableCardHeader>
                <Row>
                  <Col flex alignItems="center" xs={12} sm={3}>
                    <CardTitle noMargin>My Reservation</CardTitle>
                  </Col>
                  <Col flex alignItems="center" xs={12} sm={4}>
                    <InputGroup>
                      <InputGroupAddon addonType="append">
                        <ButtonAddonSearch
                          disabled={loading}
                          onClick={this.handleSearch}
                          color="primary">
                          <SearchIcon />
                        </ButtonAddonSearch>
                      </InputGroupAddon>
                      <InputGroupSearch
                        id="searchField"
                        onKeyUp={this.handleSearch}
                        title="Search by meeting name, host name, room or booker name"
                        placeholder="Search by meeting name, host name, room or booker name"
                        ref={input => (this.searchInput = input)}
                        disabled={loading}
                      />
                    </InputGroup>
                    <UncontrolledTooltip placement="top" target="searchField">
                      Search by meeting name, host name, room or booker name
                    </UncontrolledTooltip>
                  </Col>
                </Row>
              </TableCardHeader>
              <Table responsive>
                <thead>
                  <tr>
                    <th>
                      <SortableLink
                        column="poi_id"
                        title="Room"
                        parent={this}
                      />
                    </th>
                    <th>
                      <SortableLink
                        column="date_reservation"
                        title="Date"
                        parent={this}
                      />
                    </th>
                    <th>
                      <SortableLink
                        column="start_time"
                        title="Start Time"
                        parent={this}
                      />
                    </th>
                    <th>
                      <SortableLink
                        column="end_time"
                        title="End Time"
                        parent={this}
                      />
                    </th>
                    <th>
                      <SortableLink
                        column="creator_id"
                        title="Booker Name"
                        parent={this}
                      />
                    </th>
                    <th>
                      <SortableLink
                        column="host_name"
                        title="Host Name"
                        parent={this}
                      />
                    </th>
                    <th>
                      <SortableLink
                        column="meeting_description"
                        title="Meeting Name"
                        parent={this}
                      />
                    </th>
                    <th>
                      <SortableLink
                        column="status"
                        title="Status"
                        parent={this}
                      />
                    </th>
                    <th className="center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {loading && (
                    <tr>
                      <td colSpan={9}>
                        <LoadingComponent />
                      </td>
                    </tr>
                  )}
                  {isEmpty && !loading && (
                    <EmptyRow>
                      <td colSpan={9} className="text-center">
                        <EmptyAlert>No data available</EmptyAlert>
                      </td>
                    </EmptyRow>
                  )}
                  {!loading &&
                    (reservations || []).map((item, index) => {
                      return (
                        <ReservationRow
                          item={item}
                          key={index}
                          type="me"
                          canRelease
                          canCancel
                        />
                      );
                    })}
                </tbody>
              </Table>
              <CardBody>
                <TablePagination
                  onChange={this.handlePagination}
                  totalItems={pagination.total}
                  rowsPerPage={perPage}
                  rowsPerPageItems={this.getRowsPerPageItems()}
                  disabled={loading}
                />
              </CardBody>
            </TableCard>
          </Container>
        </MainContent>
      </ApplicationLayout>
    );
  }
}

const CreateGlobalStyle = createGlobalStyle`
  body {
    background-color: #f8f8f8!important;
  }
`;

const respCustomCol = {
  xs: media.xs`
    margin-top: 10px;
    justify-content: flex-start;
  `,
};
export const CustomCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  ${respCustomCol.xs};
`;

export const ContentSpacer = styled.div`
  clear: both;
  margin-bottom: 20px;
`;

function mapStateToProps(state) {
  const {reservation} = state;
  const {loading, pagination} = state.global;
  return {
    reservation,
    loading,
    pagination,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    openModal: bindActionCreators(openModal, dispatch),
    getReservationList: bindActionCreators(getReservationListRequest, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Reservations);
