import {CLOSE_MODAL, OPEN_MODAL, TOGGLE_MODAL} from './actionsList';

export const openModal = (name, config) => {
  return {type: OPEN_MODAL, payload: {name, config}};
};

export const closeModal = (name, config) => {
  return {type: CLOSE_MODAL, payload: {name, config}};
};

export const toggleModal = (name, config) => {
  return {type: TOGGLE_MODAL, payload: {name, config}};
};
