import React, {Component} from 'react';
import {connect} from 'react-redux';

class BackgroundProcess extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  shouldComponentUpdate() {
    return false;
  }

  componentWillMount() {
    setInterval(() => {}, 1000);
  }
  render() {
    return <div />;
  }
}

function mapDispatchToProps(dispatch) {
  return {};
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(BackgroundProcess);
