export const RESET_REDUX_STORE = 'RESET_REDUX_STORE';

export const AUTH_IN_REQUEST = 'AUTH_REQUEST';
export const AUTH_IN_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_IN_FAIL = 'AUTH_FAIL';
export const AUTH_OUT_REQUEST = 'AUTH_OUT_REQUEST';
export const AUTH_OUT_SUCCESS = 'AUTH_OUT';
export const AUTH_VALIDATE = 'AUTH_VALIDATE';

export const AUTHENTICATING = 'AUTHENTICATING';
export const AUTHENTICATED = 'AUTHENTICATED';

export const LOADING = 'LOADING';
export const LOADED = 'LOADED';

export const SEARCH_USERS_REQUEST = 'SEARCH_USERS_REQUEST';
export const DETAIL_USER_REQUEST = 'DETAIL_USER_REQUEST';
export const CLEAR_USER_REQUEST = 'CLEAR_USER_REQUEST';
export const LOAD_RECENT_SEARCH = 'LOAD_RECENT_SEARCH';
export const CLEAR_RECENT_SEARCH = 'CLEAR_RECENT_SEARCH';
export const EMPTY_USER_DATA_REQUEST = 'EMPTY_USER_DATA_REQUEST';

export const SET_USER = 'SET_USER';
export const SET_USERS = 'SET_USERS';
export const SET_PAGINATION = 'SET_PAGINATION';
export const SET_ERROR = 'SET_ERROR';
export const SET_LAST_SEARCH = 'SET_LAST_SEARCH';
export const SET_USER_SEARCH_RESULT = 'SET_USER_SEARCH_RESULT';

export const GET_USER_DETAIL_ACTION = 'GET_USER_DETAIL_ACTION';
export const GET_USER_DETAIL_REQUEST = 'GET_USER_DETAIL_REQUEST';
export const GET_USER_DETAIL_SUCCEED = 'GET_USER_DETAIL_SUCCEED';
export const GET_USER_DETAIL_FAILED = 'GET_USER_DETAIL_FAILED';

export const SEARCH_USERS_ACTION = 'SEARCH_USERS_ACTION';
export const SEARCH_USERS_SUCCEED = 'SEARCH_USERS_SUCCEED';
export const SEARCH_USERS_FAILED = 'SEARCH_USERS_FAILED';

export const PROFILE_REQUEST = 'PROFILE_REQUEST';
export const SET_PROFILE = 'SET_PROFILE';
export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST';
export const SET_PROFILE_TO_EDIT = 'SET_PROFILE_TO_EDIT';
export const PROFILE_EDIT_REQUEST = 'PROFILE_EDIT_REQUEST';
export const UPDATE_ADDITIONAL_EDITOR = 'UPDATE_ADDITIONAL_EDITOR';

export const SUBMIT_OON_REQUEST = 'SUBMIT_OON_REQUEST';
export const SUBMIT_OON_SUCCESS = 'SUBMIT_OON_SUCCESS';
export const SUBMIT_OON_FAILED = 'SUBMIT_OON_FAILED';
export const RELEASE_OON_REQUEST = 'RELEASE_OON_REQUEST';

export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const TOGGLE_MODAL = 'TOGGLE_MODAL';
export const UPDATE_MODAL = 'UPDATE_MODAL';

export const UPDATING = 'UPDATING';
export const UPDATED = 'UPDATED';

export const SET_USER_DATA = 'SET_USER_DATA';

export const SET_MEETING_ROOM = 'SET_MEETING_ROOM';
export const SET_RESERVATIONS = 'SET_RESERVATIONS';
export const GET_WFO_RESERVATION_LIST = 'GET_WFO_RESERVATION_LIST_REQUEST';
export const SET_WFO_RESERVATION_LIST = 'SET_WFO_RESERVATION_LIST';
export const CANCEL_WFO_RESERVATION_REQUEST = 'CANCEL_WFO_RESERVATION_REQUEST';
export const GET_RESERVATION_LIST_REQUEST = 'GET_RESERVATION_LIST_REQUEST';
export const GET_RESERVATION_DETAIL_ACTION = 'GET_RESERVATION_DETAIL_ACTION';
export const SET_RESERVATION = 'SET_RESERVATION';
export const SET_MEETING_ROOMS = 'SET_MEETING_ROOMS';
export const FIND_MEETING_ROOM_REQUEST = 'FIND_MEETING_ROOM_REQUEST';
export const SET_MEETING_ROOM_PARAMS = 'SET_MEETING_ROOM_PARAMS';
export const BOOK_RESERVATION_REQUEST = 'BOOK_RESERVATION_REQUEST';
export const BOOK_RESERVATION_WFO_REQUEST = 'BOOK_RESERVATION_WFO_REQUEST';
export const SUBMITTING = 'SUBMITTING';
export const SUBMITTED = 'SUBMITTED';
export const BOOKING_SUCCESS = 'BOOKING_SUCCESS';
export const BOOKING_DONE = 'BOOKING_DONE';
export const BOOKING_RESET = 'BOOKING_RESET';
export const CANCEL_RESERVATION_REQUEST = 'CANCEL_RESERVATION_REQUEST';
export const APPROVE_RESERVATION_REQUEST = 'APPROVE_RESERVATION_REQUEST';
export const RELEASE_RESERVATION_REQUEST = 'RELEASE_RESERVATION_REQUEST';
export const REJECT_RESERVATION_REQUEST = 'REJECT_RESERVATION_REQUEST';
export const APPROVING = 'APPROVING';
export const APPROVED = 'APPROVED';
export const CANCELING = 'CANCELING';
export const CANCELED = 'CANCELED';
export const WFO_CANCELED = 'WFO_CANCELED';
export const WFO_CREATED = 'WFO_CREATED';
export const WFO_FORM_RESET = 'WFO_FORM_RESET';
export const RELEASING = 'RELEASING';
export const RELEASED = 'RELEASED';
export const REJECTING = 'REJECTING';
export const REJECTED = 'REJECTED';
export const APPROVE_RESERVATION_BY_CLUSTER_ADMIN_REQUEST =
  'APPROVE_RESERVATION_BY_CLUSTER_ADMIN_REQUEST';
export const REJECT_RESERVATION_BY_CLUSTER_ADMIN_REQUEST =
  'REJECT_RESERVATION_BY_CLUSTER_ADMIN_REQUEST';

export const APPROVE_RESERVATION_BY_CLUSTER_SIC_REQUEST =
  'APPROVE_RESERVATION_BY_CLUSTER_SIC_REQUEST';
export const REJECT_RESERVATION_BY_CLUSTER_SIC_REQUEST =
  'REJECT_RESERVATION_BY_CLUSTER_SIC_REQUEST';

export const GET_LIST_GROUP_MENU_REQUEST = 'GET_LIST_GROUP_MENU_REQUEST';
export const SET_GROUP_MENU = 'SET_GROUP_MENU';
export const CREATE_MENU_REQUEST = 'CREATE_MENU_REQUEST';
export const MENU_CREATED = 'MENU_CREATED';
export const MENU_CREATED_DONE = 'MENU_CREATED_DONE';
export const CREATING_MENU = 'CREATING_MENU';
export const GET_LIST_MENU_REQUEST = 'GET_LIST_MENU_REQUEST';
export const SET_MENUS = 'SET_MENUS';
export const REMOVE_MENU_REQUEST = 'REMOVE_MENU_REQUEST';
export const MENU_REMOVED = 'MENU_REMOVED';
export const REMOVING_MENU = 'REMOVING_MENU';
export const FIND_MENU_REQUEST = 'FIND_MENU_REQUEST';
export const SET_MENU = 'SET_MENU';
export const MENU_UPDATED = 'MENU_UPDATED';
export const UPDATING_MENU = 'UPDATING_MENU';
export const UPDATE_MENU_REQUEST = 'UPDATE_MENU_REQUEST';

export const BOOK_RESERVATION_CLUSTER_ADMIN_REQUEST =
  'BOOK_RESERVATION_CLUSTER_ADMIN_REQUEST';
export const FIND_MEETING_ROOM_CLUSTER_ADMIN_REQUEST =
  'FIND_MEETING_ROOM_CLUSTER_ADMIN_REQUEST';
export const BOOK_GROUP_RESERVATION_CLUSTER_ADMIN_REQUEST =
  'BOOK_GROUP_RESERVATION_CLUSTER_ADMIN_REQUEST';
export const FIND_ROOM_WITH_QRCODE_REQUEST = 'FIND_ROOM_WITH_QRCODE_REQUEST';
export const GET_RESERVATION_LIST_FOR_RECEPTIONIST_REQUEST =
  'GET_RESERVATION_LIST_FOR_RECEPTIONIST_REQUEST';

export const GET_ORDER_LIST_REQUEST = 'GET_ORDER_LIST_REQUEST';
export const SET_ORDERS = 'SET_ORDERS';
export const SET_ORDER = 'SET_ORDER';
export const SET_ORDER_LIST = 'SET_ORDER_LIST';
export const ORDER_MENU_FETCHED = 'ORDER_MENU_FETCHED';
export const FETCHING_ORDER_MENU = 'FETCHING_ORDER_MENU';

export const GET_RESERVATION_LIST_FOR_LIASON_OFFICER_REQUEST =
  'GET_RESERVATION_LIST_FOR_LIASON_OFFICER_REQUEST';
export const GET_RESERVATION_LIST_BY_DATE_REQUEST =
  'GET_RESERVATION_LIST_BY_DATE_REQUEST';

export const GET_BEVERAGE_LIST_REQUEST = 'GET_BEVERAGE_LIST_REQUEST';
export const ORDER_MENU_REQUEST = 'ORDER_MENU_REQUEST';
export const ORDER_SUBMITTED = 'ORDER_SUBMITTED';
export const SUBMITTING_ORDER = 'SUBMITTING_ORDER';
export const ORDER_MENU_FAILED = 'ORDER_MENU_FAILED';

export const GET_ORDER_MENU_REQUEST = 'GET_ORDER_MENU_REQUEST';
export const UPDATE_ORDER_REQUEST = 'UPDATE_ORDER_REQUEST';
export const UPDATING_ORDER = 'UPDATING_ORDER';
export const ORDER_UPDATED = 'ORDER_UPDATED';
export const SUBMIT_ORDER_DONE = 'SUBMIT_ORDER_DONE';

export const SCAN_QRCODE_REQUEST = 'SCAN_QRCODE_REQUEST';
export const SCANNING_QRCODE = 'SCANNING_QRCODE';
export const SCAN_QRCODE_SUCCESS = 'SCAN_QRCODE_SUCCESS';
export const SCAN_QRCODE_FAILED = 'SCAN_QRCODE_FAILED';

export const REMOVE_ADDITIONAL_EDITOR_REQUEST =
  'REMOVE_ADDITIONAL_EDITOR_REQUEST';
export const ADD_ADDITIONAL_EDITOR_REQUEST = 'ADD_ADDITIONAL_EDITOR_REQUEST';
export const REMOVING_ADDITIONAL_EDITOR = 'REMOVING_ADDITIONAL_EDITOR';
export const ADDITIONAL_EDITOR_REMOVED = 'ADDITIONAL_EDITOR_REMOVED';
export const REMOVE_ADDITIONAL_EDITOR_FAILED =
  'REMOVE_ADDITIONAL_EDITOR_FAILED';

export const ADDING_ADDITIONAL_EDITOR = 'ADDING_ADDITIONAL_EDITOR';
export const ADD_ADDITIONAL_EDITOR_SUCCESS = 'ADD_ADDITIONAL_EDITOR_SUCCESS';
export const ADD_ADDITIONAL_EDITOR_FAILED = 'ADD_ADDITIONAL_EDITOR_FAILED';
export const ADD_ADDITIONAL_EDITOR_DONE = 'ADD_ADDITIONAL_EDITOR_DONE';

export const UPDATE_RESERVATION_REQUEST = 'UPDATE_RESERVATION_REQUEST';
export const UPDATE_RESERVATION_FAILED = 'UPDATE_RESERVATION_FAILED';
export const UPDATE_RESERVATION_SUCCESS = 'UPDATE_RESERVATION_SUCCESS';
export const UPDATING_RESERVATION = 'UPDATING_RESERVATION';
export const UPDATE_RESERVATION_DONE = 'UPDATE_RESERVATION_DONE';

export const GET_RESERVATION_LIST_APPROVAL_REQUEST =
  'GET_RESERVATION_LIST_APPROVAL_REQUEST';

export const GET_SETTING_REQUEST = 'GET_SETTING_REQUEST';
export const UPSERT_SETTING_REQUEST = 'UPSERT_SETTING_REQUEST';
export const SET_SETTINGS = 'SET_SETTINGS';
export const UPSERT_SETTING_LOADING = 'UPSERT_SETTING_LOADING';
export const UPSERT_SETTING_SUCCESS = 'UPSERT_SETTING_SUCCESS';
export const UPSERT_SETTING_FAILED = 'UPSERT_SETTING_FAILED';

export const GET_MY_CLUSTER_ADMIN_ACTION = 'GET_MY_CLUSTER_ADMIN_ACTION';
export const GET_MY_CLUSTER_ADMIN_REQUEST = 'GET_MY_CLUSTER_ADMIN_REQUEST';
export const GET_MY_CLUSTER_ADMIN_SUCCESS = 'GET_MY_CLUSTER_ADMIN_SUCCESS';
export const GET_MY_CLUSTER_ADMIN_FAILED = 'GET_MY_CLUSTER_ADMIN_FAILED';

export const GET_MY_CLUSTER_SIC_ACTION = 'GET_MY_CLUSTER_SIC_ACTION';
export const GET_MY_CLUSTER_SIC_REQUEST = 'GET_MY_CLUSTER_SIC_REQUEST';
export const GET_MY_CLUSTER_SIC_SUCCESS = 'GET_MY_CLUSTER_SIC_SUCCESS';
export const GET_MY_CLUSTER_SIC_FAILED = 'GET_MY_CLUSTER_SIC_FAILED';

export const GET_NOTIFICATION_NEED_ACTION = 'GET_NOTIFICATION_NEED_ACTION';
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const GET_NOTIFICATION_NEED_ACTION_REQUEST =
  'GET_NOTIFICATION_NEED_ACTION_REQUEST';
export const GET_NOTIFICATION_NEED_ACTION_SUCCESS =
  'GET_NOTIFICATION_NEED_ACTION_SUCCESS';
export const GET_NOTIFICATION_NEED_ACTION_FAILED =
  'GET_NOTIFICATION_NEED_ACTION_FAILED';
export const APPEND_NOTIFICATIONS = 'APPEND_NOTIFICATIONS';
export const GET_NOTIFICATION_NO_NEED_ACTION =
  'GET_NOTIFICATION_NO_NEED_ACTION';

export const GET_NOTIFICATION_NO_NEED_ACTION_REQUEST =
  'GET_NOTIFICATION_NO_NEED_ACTION_REQUEST';
export const GET_NOTIFICATION_NO_NEED_ACTION_SUCCESS =
  'GET_NOTIFICATION_NO_NEED_ACTION_SUCCESS';
export const GET_NOTIFICATION_NO_NEED_ACTION_FAILED =
  'GET_NOTIFICATION_NO_NEED_ACTION_FAILED';

export const GET_MY_PROFILE_REQUEST = 'GET_MY_PROFILE_REQUEST';

export const GET_FLOOR_ACTION = 'GET_FLOOR_ACTION';
export const SET_FLOORS = 'SET_FLOORS';

export const GET_POI_BY_FLOOR_ACTION = 'GET_POI_BY_FLOOR_ACTION';
export const GET_POI_BY_FLOOR_REQUEST = 'GET_POI_BY_FLOOR_REQUEST';
export const GET_POI_BY_FLOOR_SUCCESS = 'GET_POI_BY_FLOOR_SUCCESS';
export const GET_POI_BY_FLOOR_FAILED = 'GET_POI_BY_FLOOR_FAILED';

export const CLEAR_POI_BY_FLOOR = 'CLEAR_POI_BY_FLOOR';

export const SET_POI = 'SET_POI';
export const SET_POI_LIST = 'SET_POI_LIST';
export const FIND_ROOM_POI_ACTION = 'FIND_ROOM_POI_ACTION';
export const CLEAR_POI_LIST = 'CLEAR_POI_LIST';

export const CANCEL_ORDER_ACTION = 'CANCEL_ORDER_ACTION';
export const ORDER_CANCELED = 'ORDER_CANCELED';
export const CANCELING_ORDER = 'CANCELING_ORDER';

export const EDIT_PROFILE_ACTION = 'EDIT_PROFILE_ACTION';
export const EDIT_PROFILE_SUCCESS = 'EDIT_PROFILE_SUCCESS';

export const GET_PROFILE_FOR_EDIT_ACTION = 'GET_PROFILE_FOR_EDIT_ACTION';
export const GET_PROFILE_FOR_EDIT_REQUEST = 'GET_PROFILE_FOR_EDIT_REQUEST';
export const GET_PROFILE_FOR_EDIT_FAILED = 'GET_PROFILE_FOR_EDIT_FAILED';
export const GET_PROFILE_FOR_EDIT_SUCCESS = 'GET_PROFILE_FOR_EDIT_SUCCESS';

export const GET_HOME_NEWS_ACTION = 'GET_HOME_NEWS_ACTION';
export const SET_HIGHLIGHT_NEWS = 'SET_HIGHLIGHT_NEWS';
export const SET_DEPARTMENTS_NEWS = 'SET_DEPARTMENTS_NEWS';
export const SET_DEPARTMENT = 'SET_DEPARTMENT';

export const FORGOT_PASSWORD_ACTION = 'FORGOT_PASSWORD_ACTION';
export const FORGOT_PASSWORD_MODAL_ACTION = 'FORGOT_PASSWORD_MODAL_ACTION';
export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_FAILED = 'FORGOT_PASSWORD_FAILED';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_DONE = 'FORGOT_PASSWORD_DONE';

export const GET_NEWS_DETAIL_ACTION = 'GET_NEWS_DETAIL_ACTION';
export const GET_NEWS_DETAIL_REQUEST = 'GET_NEWS_DETAIL_REQUEST';
export const GET_NEWS_DETAIL_SUCCESS = 'GET_NEWS_DETAIL_SUCCESS';
export const GET_NEWS_DETAIL_FAILED = 'GET_NEWS_DETAIL_FAILED';

export const GET_NEWS_BY_DEPARTMENT_ACTION = 'GET_NEWS_BY_DEPARTMENT_ACTION';
export const GET_NEWS_BY_DEPARTMENT_REQUEST = 'GET_NEWS_BY_DEPARTMENT_REQUEST';
export const GET_NEWS_BY_DEPARTMENT_SUCCESS = 'GET_NEWS_BY_DEPARTMENT_SUCCESS';
export const GET_NEWS_BY_DEPARTMENT_FAILED = 'GET_NEWS_BY_DEPARTMENT_FAILED';

export const RELEASE_OON_LOADING = 'RELEASE_OON_LOADING';
export const RELEASE_OON_SUCCESS = 'RELEASE_OON_SUCCESS';
export const RELEASE_OON_FAILED = 'RELEASE_OON_FAILED';

export const GET_HEAT_MAP_ACTION = 'GET_HEAT_MAP_ACTION';
export const GET_HEAT_MAP_REQUEST = 'GET_HEAT_MAP_REQUEST';
export const GET_HEAT_MAP_SUCCESS = 'GET_HEAT_MAP_SUCCESS';
export const GET_HEAT_MAP_FAILED = 'GET_HEAT_MAP_FAILED';

export const STORE_DEVICE_TOKEN_ACTION = 'STORE_DEVICE_TOKEN_ACTION';
export const STORE_DEVICE_TOKEN_REQUEST = 'STORE_DEVICE_TOKEN_REQUEST';
export const STORE_DEVICE_TOKEN_SUCCESS = 'STORE_DEVICE_TOKEN_SUCCESS';
export const STORE_DEVICE_TOKEN_FAILED = 'STORE_DEVICE_TOKEN_FAILED';

export const GET_EULA_NOW_ACTION = 'GET_EULA_NOW_ACTION';
export const GET_EULA_NOW_REQUEST = 'GET_EULA_NOW_REQUEST';
export const GET_EULA_NOW_SUCCESS = 'GET_EULA_NOW_SUCCESS';
export const GET_EULA_NOW_FAILED = 'GET_EULA_NOW_FAILED';

export const CHANGE_PASSWORD_ACTION = 'CHANGE_PASSWORD_ACTION';
export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED';

export const RESCHEDULE_RESERVATION_ACTION = 'RESCHEDULE_RESERVATION_ACTION';
export const RESCHEDULE_RESERVATION_REQUEST = 'RESCHEDULE_RESERVATION_REQUEST';
export const RESCHEDULE_RESERVATION_FAILED = 'RESCHEDULE_RESERVATION_FAILED';
export const RESCHEDULE_RESERVATION_DONE = 'RESCHEDULE_RESERVATION_DONE';
export const RESCHEDULE_RESERVATION_SUCCESS = 'RESCHEDULE_RESERVATION_SUCCESS';

export const REMOVE_DEVICE_TOKEN_ACTION = 'REMOVE_DEVICE_TOKEN_ACTION';
export const REMOVE_DEVICE_TOKEN_REQUEST = 'REMOVE_DEVICE_TOKEN_REQUEST';
export const REMOVE_DEVICE_TOKEN_SUCCESS = 'REMOVE_DEVICE_TOKEN_SUCCESS';
export const REMOVE_DEVICE_TOKEN_FAILED = 'REMOVE_DEVICE_TOKEN_FAILED';

export const GET_ROOMS_WITH_RESERVATION = 'GET_ROOMS_WITH_RESERVATION';
export const GET_ROOMS_WITH_RESERVATION_REQUEST =
  'GET_ROOMS_WITH_RESERVATION_REQUEST';
export const GET_ROOMS_WITH_RESERVATION_SUCCESS =
  'GET_ROOMS_WITH_RESERVATION_SUCCESS';
export const GET_ROOMS_WITH_RESERVATION_FAILED =
  'GET_ROOMS_WITH_RESERVATION_FAILED';

export const RESCAN_ACTION = 'RESCAN_ACTION';
export const SET_FLOOR = 'SET_FLOOR';

export const ADD_CONTACT_PERSON = 'ADD_CONTACT_PERSON';
export const ADD_CONTACT_PERSON_REQUEST = 'ADD_CONTACT_PERSON_REQUEST';
export const ADD_CONTACT_PERSON_SUCCEED = 'ADD_CONTACT_PERSON_SUCCEED';
export const ADD_CONTACT_PERSON_FAILED = 'ADD_CONTACT_PERSON_FAILED';

export const REMOVE_CONTACT_PERSON = 'REMOVE_CONTACT_PERSON';
export const REMOVE_CONTACT_PERSON_REQUEST = 'REMOVE_CONTACT_PERSON_REQUEST';
export const REMOVE_CONTACT_PERSON_SUCCEED = 'REMOVE_CONTACT_PERSON_SUCCEED';
export const REMOVE_CONTACT_PERSON_FAILED = 'REMOVE_CONTACT_PERSON_FAILED';

export const GET_FLOOR_REQUEST = 'GET_FLOOR_REQUEST';
export const GET_FLOOR_SUCCESS = 'GET_FLOOR_SUCCESS';
export const GET_FLOOR_FAILED = 'GET_FLOOR_FAILED';

export const FIND_ROOM_REQUEST = 'FIND_ROOM_REQUEST';
export const FIND_ROOM_SUCCEED = 'FIND_ROOM_SUCCEED';
export const FIND_ROOM_FAILED = 'FIND_ROOM_FAILED';

export const FIND_ROOM_CLUSTER_ADMIN_REQUEST =
  'FIND_ROOM_CLUSTER_ADMIN_REQUEST';
export const FIND_ROOM_CLUSTER_ADMIN_SUCCEED =
  'FIND_ROOM_CLUSTER_ADMIN_SUCCEED';
export const FIND_ROOM_CLUSTER_ADMIN_FAILED = 'FIND_ROOM_CLUSTER_ADMIN_FAILED';

export const GET_FLOORS_FOR_GANTT_CHART = 'GET_FLOORS_FOR_GANTT_CHART';
export const GET_FLOORS_FOR_GANTT_CHART_REQUEST =
  'GET_FLOORS_FOR_GANTT_CHART_REQUEST';
export const GET_FLOORS_FOR_GANTT_CHART_SUCCESS =
  'GET_FLOORS_FOR_GANTT_CHART_SUCCESS';
export const GET_FLOORS_FOR_GANTT_CHART_FAILED =
  'GET_FLOORS_FOR_GANTT_CHART_FAILED';

export const VALIDATE_AUTH = 'VALIDATE_AUTH';
export const VALIDATE_AUTH_REQUEST = 'VALIDATE_AUTH_REQUEST';
export const VALIDATE_AUTH_SUCCESS = 'VALIDATE_AUTH_SUCCESS';
export const VALIDATE_AUTH_FAILED = 'VALIDATE_AUTH_FAILED';

export const OAUTH_CALLBACK = 'OAUTH_CALLBACK';
export const OAUTH_SUCCESS = 'OAUTH_SUCCESS';

export const VERIFIED_OTP_REQ = 'VERIFIED_OTP_REQ';
export const VERIFIED_OTP = 'VERIFIED_OTP';

export const SEARCH_USERS_LITE_ACTION = 'SEARCH_USERS_LITE_ACTION';
export const SEARCH_USERS_LITE_REQUEST = 'SEARCH_USERS_LITE_REQUEST';
export const SEARCH_USERS_LITE_SUCCEED = 'SEARCH_USERS_LITE_SUCCEED';
export const SEARCH_USERS_LITE_FAILED = 'SEARCH_USERS_LITE_FAILED';

export const GET_RESERVATION_DETAIL_REQUEST = 'GET_RESERVATION_DETAIL_REQUEST';
export const GET_RESERVATION_DETAIL_SUCCEED = 'GET_RESERVATION_DETAIL_SUCCEED';
export const GET_RESERVATION_DETAIL_FAILED = 'GET_RESERVATION_DETAIL_FAILED';

export const CLEAR_USER_SEARCH_RESULT = 'CLEAR_USER_SEARCH_RESULT';

export const GET_RESERVATION_APPROVAL_REQUESTS_REQUEST =
  'GET_RESERVATION_APPROVAL_REQUESTS_REQUEST';
export const GET_RESERVATION_APPROVAL_REQUESTS_SUCCEED =
  'GET_RESERVATION_APPROVAL_REQUESTS_SUCCEED';
export const GET_RESERVATION_APPROVAL_REQUESTS_FAILED =
  'GET_RESERVATION_APPROVAL_REQUESTS_FAILED';

export const GET_MY_RESERVATIONS_REQUEST = 'GET_MY_RESERVATIONS_REQUEST';
export const GET_MY_RESERVATIONS_SUCCEED = 'GET_MY_RESERVATIONS_SUCCEED';
export const GET_MY_RESERVATIONS_FAILED = 'GET_MY_RESERVATIONS_FAILED';

export const GET_RESERVATION_CLUSTER_ADMIN_ACTION =
  'GET_RESERVATION_CLUSTER_ADMIN_ACTION';
export const GET_RESERVATION_CLUSTER_ADMIN_REQUEST =
  'GET_RESERVATION_CLUSTER_ADMIN_REQUEST';
export const GET_RESERVATION_CLUSTER_ADMIN_SUCCEED =
  'GET_RESERVATION_CLUSTER_ADMIN_SUCCEED';
export const GET_RESERVATION_CLUSTER_ADMIN_FAILED =
  'GET_RESERVATION_CLUSTER_ADMIN_FAILED';

export const GET_RESERVATION_CLUSTER_SIC_ACTION =
  'GET_RESERVATION_CLUSTER_SIC_ACTION';
export const GET_RESERVATION_CLUSTER_SIC_REQUEST =
  'GET_RESERVATION_CLUSTER_SIC_REQUEST';
export const GET_RESERVATION_CLUSTER_SIC_SUCCEED =
  'GET_RESERVATION_CLUSTER_SIC_SUCCEED';
export const GET_RESERVATION_CLUSTER_SIC_FAILED =
  'GET_RESERVATION_CLUSTER_SIC_FAILED';

export const GET_RESERVATION_RECEPTIONIST_REQUEST =
  'GET_RESERVATION_RECEPTIONIST_REQUEST';
export const GET_RESERVATION_RECEPTIONIST_SUCCEED =
  'GET_RESERVATION_RECEPTIONIST_SUCCEED';
export const GET_RESERVATION_RECEPTIONIST_FAILED =
  'GET_RESERVATION_RECEPTIONIST_FAILED';

export const GET_RESERVATION_LIASON_OFFICER_REQUEST =
  'GET_RESERVATION_LIASON_OFFICER_REQUEST';
export const GET_RESERVATION_LIASON_OFFICER_SUCCEED =
  'GET_RESERVATION_LIASON_OFFICER_SUCCEED';
export const GET_RESERVATION_LIASON_OFFICER_FAILED =
  'GET_RESERVATION_RECEPTIONIST_FAILED';

export const MENU_UPDATE_DONE = 'MENU_UPDATE_DONE';

export const ADD_GUEST_ACTION = 'ADD_GUEST_ACTION';
export const ADD_GUEST_REQUEST = 'ADD_GUEST_REQUEST';
export const ADD_GUEST_SUCCEED = 'ADD_GUEST_SUCCEED';
export const ADD_GUEST_FAILED = 'ADD_GUEST_FAILED';

export const SET_GUEST_RESERVATION_ID = 'SET_GUEST_RESERVATION_ID';
export const CLEAR_GUEST_RESERVATION_ID = 'CLEAR_GUEST_RESERVATION_ID';

export const GET_MENU_LIST_ACTION = 'GET_MENU_LIST_ACTION';
export const GET_MENU_LIST_REQUEST = 'GET_MENU_LIST_REQUEST';
export const GET_MENU_LIST_SUCCEED = 'GET_MENU_LIST_SUCCEED';
export const GET_MENU_LIST_FAILED = 'GET_MENU_LIST_FAILED';

export const GET_HOME_NEWS_REQUEST = 'GET_HOME_NEWS_REQUEST';
export const GET_HOME_NEWS_SUCCEED = 'GET_HOME_NEWS_SUCCEED';
export const GET_HOME_NEWS_FAILED = 'GET_HOME_NEWS_FAILED';

export const GET_DASHBOARD_NEWS_ACTION = 'GET_DASHBOARD_NEWS_ACTION';
export const GET_DASHBOARD_NEWS_REQUEST = 'GET_DASHBOARD_NEWS_REQUEST';
export const GET_DASHBOARD_NEWS_SUCCEED = 'GET_DASHBOARD_NEWS_SUCCEED';
export const GET_DASHBOARD_NEWS_FAILED = 'GET_DASHBOARD_NEWS_FAILED';

export const GET_DEPARTMENT_LIST_ACTION = 'GET_DEPARTMENT_LIST_ACTION';
export const GET_DEPARTMENT_LIST_REQUEST = 'GET_DEPARTMENT_LIST_REQUEST';
export const GET_DEPARTMENT_LIST_SUCCEED = 'GET_DEPARTMENT_LIST_SUCCEED';
export const GET_DEPARTMENT_LIST_FAILED = 'GET_DEPARTMENT_LIST_FAILED';

export const GET_EXTERNAL_NEWS_ACTION = 'GET_EXTERNAL_NEWS_ACTION';
export const GET_EXTERNAL_NEWS_REQUEST = 'GET_EXTERNAL_NEWS_REQUEST';
export const GET_EXTERNAL_NEWS_SUCCEED = 'GET_EXTERNAL_NEWS_SUCCEED';
export const GET_EXTERNAL_NEWS_FAILED = 'GET_EXTERNAL_NEWS_FAILED';

export const GET_EXTERNAL_NEWS_BY_CATEGORY_ACTION =
  'GET_EXTERNAL_NEWS_BY_CATEGORY_ACTION';
export const GET_EXTERNAL_NEWS_BY_CATEGORY_REQUEST =
  'GET_EXTERNAL_NEWS_BY_CATEGORY_REQUEST';
export const GET_EXTERNAL_NEWS_BY_CATEGORY_SUCCEED =
  'GET_EXTERNAL_NEWS_BY_CATEGORY_SUCCEED';
export const GET_EXTERNAL_NEWS_BY_CATEGORY_FAILED =
  'GET_EXTERNAL_NEWS_BY_CATEGORY_FAILED';

export const GET_EXTERNAL_NEWS_CATEGORIES_ACTION =
  'GET_EXTERNAL_NEWS_CATEGORIES_ACTION';
export const GET_EXTERNAL_NEWS_CATEGORIES_REQUEST =
  'GET_EXTERNAL_NEWS_CATEGORIES_REQUEST';
export const GET_EXTERNAL_NEWS_CATEGORIES_SUCCEED =
  'GET_EXTERNAL_NEWS_CATEGORIES_SUCCEED';
export const GET_EXTERNAL_NEWS_CATEGORIES_FAILED =
  'GET_EXTERNAL_NEWS_CATEGORIES_FAILED';

export const GET_EXTERNAL_NEWS_DETAIL_ACTION =
  'GET_EXTERNAL_NEWS_DETAIL_ACTION';
export const GET_EXTERNAL_NEWS_DETAIL_REQUEST =
  'GET_EXTERNAL_NEWS_DETAIL_REQUEST';
export const GET_EXTERNAL_NEWS_DETAIL_SUCCEED =
  'GET_EXTERNAL_NEWS_DETAIL_SUCCEED';
export const GET_EXTERNAL_NEWS_DETAIL_FAILED =
  'GET_EXTERNAL_NEWS_DETAIL_FAILED';

export const SEARCH_NEWS_ACTION = 'SEARCH_NEWS_ACTION';
export const SEARCH_NEWS_REQUEST = 'SEARCH_NEWS_REQUEST';
export const SEARCH_NEWS_SUCCEED = 'SEARCH_NEWS_SUCCEED';
export const SEARCH_NEWS_FAILED = 'SEARCH_NEWS_FAILED';

export const GET_RESERVATION_BY_POI_ACTION = 'GET_RESERVATION_BY_POI_ACTION';
export const GET_RESERVATION_BY_POI_REQUEST = 'GET_RESERVATION_BY_POI_REQUEST';
export const GET_RESERVATION_BY_POI_SUCCEED = 'GET_RESERVATION_BY_POI_SUCCEED';
export const GET_RESERVATION_BY_POI_FAILED = 'GET_RESERVATION_BY_POI_FAILED';

export const SET_AUTH_CREDENTIALS = 'SET_AUTH_CREDENTIALS';
export const SET_REMEMBER_ME = 'SET_REMEMBER_ME';

export const GET_STAT_USER_MONITORING = 'GET_STAT_USER_MONITORING';
export const SET_STAT_OFFICE_CAPACITY = 'SET_STAT_OFFICE_CAPACITY';
export const SET_STAT_RESERVE_QUOTA = 'SET_STAT_RESERVE_QUOTA';

export const GET_HOME_ANNOUNCEMENT = 'GET_HOME_ANNOUNCEMENT';
export const SET_HOME_ANNOUNCEMENT = 'SET_HOME_ANNOUNCEMENT';
