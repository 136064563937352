import React, {Component} from 'react';
import {Alert} from 'reactstrap';

class NoNewsFound extends Component {
  render() {
    return <Alert color="warning">No Result Found</Alert>;
  }
}

export default NoNewsFound;
