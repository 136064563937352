import React, {Component} from 'react';
import {NewsTabs, NewsTabItem, NewsTabItemLink} from '../../styles';
import {getNewsCategory} from '../../utils/helpers';
import config from '../../config';

class NewsTabsNav extends Component {
  render() {
    const category = getNewsCategory();
    const newsTypes = Object.keys(config.NEWS_TYPES).map(value => ({
      ...config.NEWS_TYPES[value],
    }));

    return (
      <NewsTabs>
        {newsTypes.map(type => (
          <NewsTabItem
            key={`type-${type.name}`}
            active={category === type.name}>
            <NewsTabItemLink to={`/news/${type.name}`}>
              {type.name}
            </NewsTabItemLink>
          </NewsTabItem>
        ))}
      </NewsTabs>
    );
  }
}

export default NewsTabsNav;
