import React, {Component} from 'react';
import MaterialSpinner from './MaterialSpinner';
import styled from 'styled-components';

class PageLoader extends Component {
  render() {
    const {text} = this.props;

    return (
      <PageLoaderWrapper>
        {text && <LoaderText>{text}</LoaderText>}
        <MaterialSpinner indeterminate />
      </PageLoaderWrapper>
    );
  }
}

const PageLoaderWrapper = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LoaderText = styled.div`
  margin-bottom: 1em;
  font-size: 1.5em;
`;

export default PageLoader;
