import React, {useState} from 'react';
import styled, {css} from 'styled-components';
import hosts from '../../utils/hosts';
import {Modal, ModalBody, ModalHeader} from 'reactstrap';

const Avatar = props => {
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  let {image, fallbackImage, source, className, ...imgProps} = props;
  fallbackImage = fallbackImage || '/assets/images/person-circle-big.png';
  let url = `${hosts.upload}/images/${image}`;
  if (!image) {
    url = fallbackImage;
  }
  if (!!source) {
    url = image;
  }

  const title = imgProps.mask ? 'Avatar Image' : 'Formal Photo';

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <ModalBody className="text-center">
          <img src={url} alt={title} className="img-fluid" />
        </ModalBody>
        {/*<ModalFooter>*/}
        {/*  <Button color="secondary" onClick={toggle}>*/}
        {/*    Close*/}
        {/*  </Button>*/}
        {/*</ModalFooter>*/}
      </Modal>
      <AvatarImage onClick={toggle} url={url} {...imgProps} />
    </>
  );
};

const AvatarImage = styled.div`
  width: ${props => props.size || 100}px;
  height: ${props => props.size || 100}px;
  border-radius: ${props => (props.circle ? '100%' : '5px')};
  background-image: url('${props => props.url}');
  background-size: cover;
  background-position: center;
  background-color: #dedede;
  cursor: pointer;
  border-width: 2px;
  border-style: solid;
  border-color: #FFFFFF;
  transition: border-color .5s;
  
  &:hover {
    border-color: ${props => props.theme.colors.primary};
  }
  
  ${props =>
    props.mask &&
    css`
      position: absolute;
      bottom: -10px;
      right: -27px;
      width: 64px !important;
      max-width: 100%;
      height: 64px !important;
      border-radius: 100%;
    `}
`;

export default Avatar;
