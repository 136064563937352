import {
  AUTH_IN_FAIL,
  AUTH_IN_SUCCESS,
  AUTH_OUT_SUCCESS,
  SET_USER_DATA,
  AUTHENTICATING,
  AUTHENTICATED,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILED,
  FORGOT_PASSWORD_DONE,
  VALIDATE_AUTH_REQUEST,
  VALIDATE_AUTH_FAILED,
  VALIDATE_AUTH_SUCCESS,
  EDIT_PROFILE_SUCCESS,
  SET_AUTH_CREDENTIALS,
  OAUTH_SUCCESS,
  VERIFIED_OTP,
  VERIFIED_OTP_REQ,
  SET_REMEMBER_ME,
} from '../actions/actionsList';
import config from '../config';
import {getAccessToken, getUserData} from '../utils/TokenStorage';

const token = getAccessToken();
const userData = getUserData();

let initialState = {
  userIsLoggedIn: !!token,
  token,
  userData,
  error: null,
  authenticating: false,
  loadingForgotPassword: false,
  forgotPasswordSuccess: false,
  message: null,
  validating: false,
  validated: false,
  credentials: null,
  rememberMe: false,
  otpVerification: {
    loading: false,
    isVerified: false,
  },
};

export default function(state = initialState, action) {
  const {type, payload} = action;

  switch (type) {
    case OAUTH_SUCCESS:
      const {token: accessToken} = payload;

      return {
        ...state,
        userIsLoggedIn: true,
        token: accessToken,
        userData: null,
        error: null,
      };

    case AUTH_IN_SUCCESS:
      const {token, userData} = payload;

      return {
        ...state,
        userIsLoggedIn: true,
        token: token,
        userData: userData,
        error: null,
      };

    case AUTH_IN_FAIL:
      let errorMessage;

      if (payload) {
        payload.message
          ? (errorMessage = payload.message)
          : (errorMessage = config.INTERNAL_SERVER_ERROR);
      }

      return {
        ...state,
        userIsLoggedIn: false,
        otpVerification: {loading: false, isVerified: false},
        token: null,
        error: errorMessage,
      };

    case AUTH_OUT_SUCCESS:
      return {
        ...state,
        userIsLoggedIn: false,
        otpVerification: {loading: false, isVerified: false},
        token: null,
        error: null,
      };

    case SET_USER_DATA: {
      const {userData} = payload;
      return {...state, userData};
    }

    case AUTHENTICATING:
      return {...state, authenticating: true, error: null};

    case AUTHENTICATED:
      return {...state, authenticating: false};

    case FORGOT_PASSWORD_REQUEST: {
      return {...state, loadingForgotPassword: true};
    }

    case FORGOT_PASSWORD_SUCCESS: {
      const {message} = payload;
      return {
        ...state,
        loadingForgotPassword: false,
        forgotPasswordSuccess: true,
        message,
      };
    }

    case FORGOT_PASSWORD_FAILED: {
      const {message} = payload;
      return {
        ...state,
        loadingForgotPassword: false,
        forgotPasswordSuccess: false,
        message,
      };
    }

    case FORGOT_PASSWORD_DONE: {
      return {
        ...state,
        loadingForgotPassword: false,
        forgotPasswordSuccess: false,
        message: null,
      };
    }

    case VALIDATE_AUTH_REQUEST: {
      return {
        ...state,
        validating: true,
        validated: false,
        error: null,
      };
    }

    case VALIDATE_AUTH_SUCCESS: {
      const {userData} = payload;

      return {
        ...state,
        validating: false,
        validated: true,
        userIsLoggedIn: true,
        userData,
        error: null,
      };
    }

    case VALIDATE_AUTH_FAILED: {
      const {error} = payload;

      return {
        ...state,
        validating: false,
        validated: false,
        userIsLoggedIn: false,
        error,
      };
    }

    case VERIFIED_OTP_REQ: {
      return {
        ...state,
        otpVerification: {...state.otpVerification, loading: true},
      };
    }

    case VERIFIED_OTP: {
      const {result, error} = payload;

      return {
        ...state,
        otpVerification: {loading: false, isVerified: result},
        error,
      };
    }

    case EDIT_PROFILE_SUCCESS: {
      const {userData: newUserData} = payload;
      const {userData: currentUserData} = state;
      const updatedUserData = {...currentUserData, ...newUserData};
      updatedUserData.profile = newUserData;
      return {...state, userData: updatedUserData};
    }

    case SET_REMEMBER_ME: {
      return {
        ...state,
        rememberMe: !!payload,
      };
    }

    case SET_AUTH_CREDENTIALS: {
      const {data} = payload;
      return {...state, credentials: data};
    }

    default:
      return state;
  }
}
