import {
  AUTH_OUT_SUCCESS,
  RELEASE_OON_FAILED,
  RELEASE_OON_LOADING,
  RELEASE_OON_SUCCESS,
  SUBMIT_OON_SUCCESS,
} from '../actions/actionsList';

let initialState = {
  oon: null,
  releasing: false,
  released: false,
};

export default function(state = initialState, action) {
  const {type, payload} = action;

  switch (type) {
    case AUTH_OUT_SUCCESS: {
      return {...initialState};
    }

    case SUBMIT_OON_SUCCESS:
      const {data} = payload;
      return {...state, oon: data};

    case RELEASE_OON_LOADING: {
      return {...state, releasing: true, released: false};
    }

    case RELEASE_OON_SUCCESS: {
      return {...state, releasing: false, released: true};
    }

    case RELEASE_OON_FAILED: {
      return {...state, releasing: false, released: false};
    }

    default:
      return state;
  }
}
