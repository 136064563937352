import {SET_ERROR, SET_PAGINATION} from './actionsList';

export const clearPagination = () => {
  return {
    type: SET_PAGINATION,
    payload: {pagination: {}},
  };
};

export const setGlobalError = message => {
  return {type: SET_ERROR, payload: {message}};
};
