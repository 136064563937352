import {
  SET_USERS,
  SET_USER,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILED,
  SET_USER_SEARCH_RESULT,
  SEARCH_USERS_LITE_REQUEST,
  SEARCH_USERS_LITE_SUCCEED,
  SEARCH_USERS_LITE_FAILED,
  CLEAR_USER_SEARCH_RESULT,
  SEARCH_USERS_REQUEST,
  SEARCH_USERS_SUCCEED,
  SEARCH_USERS_FAILED,
  GET_USER_DETAIL_REQUEST,
  GET_USER_DETAIL_SUCCEED,
  GET_USER_DETAIL_FAILED,
  SET_STAT_RESERVE_QUOTA,
  SET_STAT_OFFICE_CAPACITY,
  AUTH_OUT_SUCCESS,
} from '../actions/actionsList';

let initialState = {
  loading: false,
  users: [],
  user: null,
  changingPassword: false,
  passwordChanged: false,
  changePasswordState: {
    error: undefined,
  },
  searchResult: [],
  searching: false,
  loadingUserDetail: false,
  statOfficeCapacity: {
    totalPeople: null,
    officeCapacity: null,
  },
  statReserveQuota: {
    totalReserve: null,
    reserveQuota: null,
  },
};

export default function(state = initialState, action) {
  const {type, payload} = action;

  switch (type) {
    case AUTH_OUT_SUCCESS: {
      return {...initialState};
    }

    case GET_USER_DETAIL_REQUEST: {
      return {
        ...state,
        loadingUserDetail: true,
      };
    }

    case GET_USER_DETAIL_SUCCEED: {
      return {
        ...state,
        loadingUserDetail: false,
      };
    }

    case GET_USER_DETAIL_FAILED: {
      return {
        ...state,
        loadingUserDetail: false,
      };
    }

    case SEARCH_USERS_REQUEST: {
      return {
        ...state,
        searching: true,
        users: [],
        searchResult: [],
      };
    }

    case SEARCH_USERS_SUCCEED: {
      const {users} = payload;

      return {
        ...state,
        searching: false,
        users,
        searchResult: users,
      };
    }

    case SEARCH_USERS_FAILED: {
      return {
        ...state,
        searching: false,
        users: [],
        searchResult: [],
      };
    }

    case SEARCH_USERS_LITE_REQUEST: {
      return {
        ...state,
        searching: true,
        users: [],
        searchResult: [],
      };
    }

    case SEARCH_USERS_LITE_SUCCEED: {
      const {users} = payload;

      return {
        ...state,
        searching: false,
        users,
        searchResult: users,
      };
    }

    case SEARCH_USERS_LITE_FAILED: {
      return {
        ...state,
        searching: false,
        users: [],
        searchResult: [],
      };
    }

    case SET_USERS:
      const {users} = payload;
      return {...state, users};

    case SET_USER_SEARCH_RESULT: {
      const {users} = payload;
      return {...state, searchResult: users};
    }

    case SET_USER:
      const {user} = payload;
      return {...state, user};

    case CHANGE_PASSWORD_REQUEST: {
      return {
        ...state,
        changingPassword: true,
        passwordChanged: false,
        changePasswordState: {
          loading: true,
          error: undefined,
        },
      };
    }

    case CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        changingPassword: false,
        passwordChanged: true,
        changePasswordState: {
          loading: false,
          error: undefined,
        },
      };
    }

    case CHANGE_PASSWORD_FAILED: {
      return {
        ...state,
        changingPassword: false,
        passwordChanged: false,
        changePasswordState: {
          loading: false,
          error: payload?.message,
        },
      };
    }

    case CLEAR_USER_SEARCH_RESULT: {
      return {
        ...state,
        searchResult: [],
      };
    }

    case SET_STAT_OFFICE_CAPACITY: {
      return {
        ...state,
        statOfficeCapacity: payload,
      };
    }

    case SET_STAT_RESERVE_QUOTA: {
      return {
        ...state,
        statReserveQuota: payload,
      };
    }

    default:
      return state;
  }
}
