import {BeverageApi} from '../utils/api';

export default {
  /**
   * Get API Group Menu List.
   *
   * @param page
   * @param perPage
   * @param sortBy
   * @param descending
   * @returns {Promise<*>}
   */
  async getList({search, limit, page, sortBy, descending}) {
    page = page || 1;
    limit = limit || 12;
    sortBy = sortBy || 'id';
    descending = descending || false;

    try {
      const {data} =
        (await BeverageApi.get(`/v1/admin/groupmenu`, {
          params: {page, limit, sortBy, search, descending},
        })) || {};
      return data;
    } catch (e) {
      return e && e.response;
    }
  },
};
