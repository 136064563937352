import React, {Fragment} from 'react';
import {CenteredContent} from '../../styles';
import LoadingComponent from './LoadingComponent';

export const LoadingOrEmpty = ({
  data,
  loading,
  message = 'No data available',
}) => (
  <Fragment>
    {data.length < 1 && (
      <CenteredContent>
        {loading ? <LoadingComponent /> : message}
      </CenteredContent>
    )}
  </Fragment>
);

export default LoadingOrEmpty;
