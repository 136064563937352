import React, {Component, Fragment} from 'react';
import {
  approveByClusterAdminReservationRequest,
  approveReservationRequest,
  cancelReservationRequest,
  rejectByClusterAdminReservationRequest,
  rejectReservationRequest,
  releaseReservationRequest,
  setGuestReservationId,
} from '../../../actions/reservation';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {ButtonDropdown, DropdownItem, DropdownMenu} from 'reactstrap';
import {Link} from 'react-router-dom';
import {StyledDropdownToggle} from '../../../styles';
import swal from 'sweetalert2';
import {
  isReservationMissed,
  canCancelReservation,
  canReleaseReservation,
  canRescheduleReservation,
  canApprove,
  canEditReservation,
  dump,
} from '../../../utils/helpers';
import config from '../../../config';
import theme from '../../../styles/theme';
import {closeModal, openModal, toggleModal} from '../../../actions/modal';
import ModalAddGuest from '../../../components/Modal/ModalAddGuest';

const RESERVATION_STATUS = config.reservationStatus;

class ActionButtons extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false,
    };
  }

  handleCancel = item => {
    const {canceling} = this.props.reservation;
    const key = this.props.listKey || 'reservations';

    swal({
      title: 'Cancel Reservation',
      text: 'Are you sure want to cancel this reservation?',
      input: 'text',
      inputAttributes: {
        placeholder: 'Type a reason to cancel...',
      },
      showCancelButton: true,
      showLoaderOnConfirm: true,
      type: 'warning',
      cancelButtonText: 'No',
      confirmButtonColor: theme.colors.danger,
      confirmButtonText: 'Yes',
      preConfirm: remarks => {
        this.props.cancelReservation(
          {id: item.id, remarks, key},
          this.props.onCancel
        );
      },
      allowOutsideClick: () => !canceling,
    });
  };

  handleAddGuest = reservation => {
    this.props.setGuestReservationId(reservation.id);
    this.props.openModal('modalAddGuest');
  };

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  getDetailURL = item => {
    const type = this.props.type;

    switch (type) {
      case 'liason-officer':
        return `/reservations/${item.reservation_id}/${type}`;

      case 'approval-request':
      case 'cluster-admin':
      case 'receptionist':
        return `/reservations/${item.id}/${type}`;

      default:
        return `/reservations/${item.id}`;
    }
  };

  canApprove = item => {
    const missed = isReservationMissed(item);

    return this.props.canApprove && !missed && canApprove(item);
  };

  canReject = item => {
    const missed = isReservationMissed(item);

    return (
      !missed &&
      this.props.canReject &&
      [
        RESERVATION_STATUS.PENDING,
        RESERVATION_STATUS.WAITING_CLUSTER_ADMIN,
      ].includes(item.status)
    );
  };

  canRelease = item => {
    return this.props.canRelease && canReleaseReservation(item);
  };

  canCancel = item => {
    return (
      this.props.canCancel &&
      canCancelReservation(item, this.props.forClusterAdmin)
    );
  };

  canEdit = reservation => {
    const {canEdit, type} = this.props;
    const isFromClusterAdmin = type === 'cluster-admin';

    return canEdit && isFromClusterAdmin && canEditReservation(reservation);
  };

  canReschedule = reservation => {
    return canRescheduleReservation(reservation);
  };

  isFromClusterAdmin = () => {
    return this.props.type === 'cluster-admin';
  };

  render() {
    let {item, onGuestAdded} = this.props;

    if (!item) return null;

    const canCancel = this.canCancel(item);
    const isFromClusterAdmin = this.isFromClusterAdmin();
    const canEdit = this.canEdit(item);
    const canReschedule = this.canReschedule(item);
    const detailURL = this.getDetailURL(item);
    const canEditOrReschedule = isFromClusterAdmin ? canEdit : canReschedule;

    dump({canReschedule, canEdit, canEditOrReschedule, canCancel});

    return (
      <Fragment>
        <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
          <StyledDropdownToggle size="sm" caret />
          <DropdownMenu right>
            <Link className="dropdown-item" target="_blank" to={detailURL}>
              Details
            </Link>
            <DropdownItem onClick={() => this.handleAddGuest(item)}>
              Add Guest
            </DropdownItem>
          </DropdownMenu>
        </ButtonDropdown>
        <ModalAddGuest onSuccess={onGuestAdded} />
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const {reservation} = state;
  return {
    reservation,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setGuestReservationId: bindActionCreators(setGuestReservationId, dispatch),
    openModal: bindActionCreators(openModal, dispatch),
    closeModal: bindActionCreators(closeModal, dispatch),
    toggleModal: bindActionCreators(toggleModal, dispatch),
    cancelReservation: bindActionCreators(cancelReservationRequest, dispatch),
    approveReservation: bindActionCreators(approveReservationRequest, dispatch),
    releaseReservation: bindActionCreators(releaseReservationRequest, dispatch),
    rejectReservation: bindActionCreators(rejectReservationRequest, dispatch),
    approveByClusterAdmin: bindActionCreators(
      approveByClusterAdminReservationRequest,
      dispatch
    ),
    rejectByClusterAdmin: bindActionCreators(
      rejectByClusterAdminReservationRequest,
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ActionButtons);
