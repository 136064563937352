import axios from 'axios';

export default {
  /**
   * Submit OON.
   *
   * @param data
   * @returns {Promise<AxiosResponse<any>>}
   */
  submit(data) {
    return axios
      .post('/v1/oon/create', data)
      .then(response => (response || {}).data)
      .catch(err => err && (err.response || {}).data);
  },

  /**
   * Release OON.
   *
   * @param userId
   * @returns {Promise<AxiosResponse<any>>}
   */
  release(userId) {
    return axios
      .get(`/v1/users/me/oon/${userId}/release`)
      .then(response => (response || {}).data)
      .catch(err => err && (err.response || {}).data);
  },
};
