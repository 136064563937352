export const EXTERNAL_NEWS_DETAIL_STORAGE = 'EXTERNAL_NEWS_DETAIL';

export default {
  get() {
    const localState = localStorage.getItem('state');
    const state = localState ? JSON.parse(localState) : {};
    const {externalNews} = state || {};
    const {latestNews} = externalNews || {};
    return latestNews || [];
  },
  exists() {
    return this.get().length > 0;
  },
  save(data) {
    // return localStorage.setItem(
    //   EXTERNAL_NEWS_DETAIL_STORAGE,
    //   JSON.stringify(data)
    // );
  },
  remove() {
    return localStorage.removeItem(EXTERNAL_NEWS_DETAIL_STORAGE);
  },
  has(id) {
    return this.exists() && this.find(id);
  },
  find(id) {
    return this.get().find(item => item && item.pub_id === id);
  },
};
