import styled, {css} from 'styled-components';
import {Row} from './grid';

export const Card = styled.div`
  border: 1px solid ${props => props.theme.card.borderColor};
  background-color: ${props => {
    const {color} = props;
    return color ? props.theme.colors[color] : `#fff`;
  }};
  width: 100%;
  border-radius: 10px;
  margin-bottom: 20px;
  box-sizing: border-box;
  ${props =>
    props.withShadow &&
    css`
      box-shadow: 0px 0px 1px 0px #d9d9d9;
    `};
  ${props =>
    props.linkable &&
    css`
      cursor: pointer;
    `};

  ${props =>
    props.header &&
    css`
      border-radius: 5px;
      margin-top: 10px;
    `};
`;

export const CardHeader = styled.div`
  border-bottom: 1px solid ${props => props.theme.card.borderColor};
  padding: 1em;
  background-color: transparent;
  box-sizing: border-box;
`;

export const CardBody = styled.div`
  padding: 1em;
  box-sizing: border-box;
`;

export const CardFooter = styled.div`
  border-top: 1px solid ${props => props.theme.card.borderColor};
  padding: ${props => (props.noPadding ? '0' : '10px')};
  background-color: transparent;
  box-sizing: border-box;
  button {
    margin-bottom: 0;
  }
`;

export const CardAction = styled(Row)`
  border-top: 1px solid #efefef;
  padding: 10px;
  text-align: center;
  margin: 0;
`;

export const SmallCardFooter = styled(CardFooter)`
  color: #949494;
  padding: 1em;
  font-size: 12px;

  div {
    margin-bottom: 5px;
  }
`;

export const CardTitle = styled.h3`
  font-size: 18px;
  color: #ffffff;
  font-weight: normal;
  margin: ${props => (props.noMargin ? 0 : '1em')};
  margin-left: 10px;
`;
