import {
  APPROVED,
  APPROVING,
  BOOKING_DONE,
  BOOKING_RESET,
  BOOKING_SUCCESS,
  CANCELED,
  CANCELING,
  GET_MY_CLUSTER_ADMIN_FAILED,
  GET_MY_CLUSTER_ADMIN_REQUEST,
  GET_MY_CLUSTER_ADMIN_SUCCESS,
  GET_MY_CLUSTER_SIC_FAILED,
  GET_MY_CLUSTER_SIC_REQUEST,
  GET_MY_CLUSTER_SIC_SUCCESS,
  GET_RESERVATION_CLUSTER_SIC_FAILED,
  GET_RESERVATION_CLUSTER_SIC_REQUEST,
  GET_RESERVATION_CLUSTER_SIC_SUCCEED,
  REJECTED,
  REJECTING,
  RELEASED,
  RELEASING,
  RESCHEDULE_RESERVATION_DONE,
  RESCHEDULE_RESERVATION_FAILED,
  RESCHEDULE_RESERVATION_REQUEST,
  RESCHEDULE_RESERVATION_SUCCESS,
  SCAN_QRCODE_FAILED,
  SCAN_QRCODE_SUCCESS,
  SCANNING_QRCODE,
  SET_MEETING_ROOM,
  SET_MEETING_ROOM_PARAMS,
  SET_MEETING_ROOMS,
  SET_RESERVATION,
  SET_RESERVATIONS,
  SUBMITTED,
  SUBMITTING,
  UPDATE_RESERVATION_DONE,
  UPDATE_RESERVATION_FAILED,
  UPDATE_RESERVATION_SUCCESS,
  UPDATING_RESERVATION,
  FIND_ROOM_REQUEST,
  FIND_ROOM_SUCCEED,
  FIND_ROOM_FAILED,
  FIND_ROOM_CLUSTER_ADMIN_REQUEST,
  FIND_ROOM_CLUSTER_ADMIN_SUCCEED,
  FIND_ROOM_CLUSTER_ADMIN_FAILED,
  GET_RESERVATION_DETAIL_REQUEST,
  GET_RESERVATION_DETAIL_SUCCEED,
  GET_RESERVATION_DETAIL_FAILED,
  GET_RESERVATION_APPROVAL_REQUESTS_REQUEST,
  GET_RESERVATION_APPROVAL_REQUESTS_SUCCEED,
  GET_RESERVATION_APPROVAL_REQUESTS_FAILED,
  GET_MY_RESERVATIONS_REQUEST,
  GET_MY_RESERVATIONS_SUCCEED,
  GET_MY_RESERVATIONS_FAILED,
  GET_RESERVATION_CLUSTER_ADMIN_REQUEST,
  GET_RESERVATION_CLUSTER_ADMIN_SUCCEED,
  GET_RESERVATION_CLUSTER_ADMIN_FAILED,
  GET_RESERVATION_RECEPTIONIST_REQUEST,
  GET_RESERVATION_RECEPTIONIST_SUCCEED,
  GET_RESERVATION_RECEPTIONIST_FAILED,
  GET_RESERVATION_LIASON_OFFICER_REQUEST,
  GET_RESERVATION_LIASON_OFFICER_SUCCEED,
  GET_RESERVATION_LIASON_OFFICER_FAILED,
  ADD_GUEST_REQUEST,
  ADD_GUEST_SUCCEED,
  ADD_GUEST_FAILED,
  SET_GUEST_RESERVATION_ID,
  CLEAR_GUEST_RESERVATION_ID,
  GET_RESERVATION_BY_POI_REQUEST,
  GET_RESERVATION_BY_POI_SUCCEED,
  GET_RESERVATION_BY_POI_FAILED,
  GET_WFO_RESERVATION_LIST,
  SET_WFO_RESERVATION_LIST,
  CANCEL_WFO_RESERVATION_REQUEST,
  WFO_CANCELED,
  WFO_CREATED,
  WFO_FORM_RESET,
  AUTH_OUT_SUCCESS,
} from '../actions/actionsList';

import update from 'immutability-helper';
import config from '../config';
import {getCurrentUser} from '../utils/helpers';
import moment from 'moment';

const initialState = {
  selectedMeetingRoom: null,
  // my reservations
  reservations: [],
  // reservation detail
  reservation: null,
  meetingRooms: [],
  params: {},
  reservationData: {},
  submitting: false,
  bookingSuccess: false,
  bookingDone: false,
  approving: null,
  canceling: null,
  releasing: null,
  rejecting: null,
  scanningQRCode: false,
  QRCodeResult: null,
  updating: false,
  updated: false,
  fetchingMyClusterAdmin: false,
  myClusterAdmin: [],
  rescheduling: false,
  rescheduled: false,
  findingRoom: false,
  loadingReservationDetail: false,
  // loading reservations data
  loading: false,
  // reservations: approval requests data
  approvalRequests: [],
  // reservation cluster admin
  clusterAdmins: [],
  // reservation receptionists
  receptionists: [],
  // reservation liasonOfficers
  liasonOfficers: [],
  // add guest
  addingGuest: false,
  // guest reservation ID,
  guestReservationId: null,
  // reservation by POI
  reservationByPOI: null,
  loadingReservationByPOI: false,
  poiMarker: null,
  findingRoomClusterAdmin: false,
  wfoReservation: {
    error: null,
    data: [],
    loading: false,
    cancelling: [],
    formError: null,
  },
  clusterSIC: {
    clustersLoading: false,
    clusters: undefined,
    reservationLoading: false,
    reservations: [],
  },
};

let storeState = {...initialState};

function reduceApproved(state, payload) {
  let {id, data, key} = payload;
  key = key || 'reservations';
  const {reservation} = state;
  const reservations =
    key === 'clusterSICs' ? state.clusterSIC.reservations : state[key];

  if (reservation) {
    let {approver, rule} = reservation;
    const user = getCurrentUser();
    const approverIdx = approver.findIndex(item => item.users_id === user.id);
    if (approverIdx > -1) {
      const approvalItem = approver[approverIdx];
      const updatedBy = approvalItem.users;
      const updatedAt = moment().format('YYYY-MM-DD HH:mm:ss');
      approver = update(approver, {
        [approverIdx]: {
          is_approve: {$set: 1},
          users_updated_by: {$set: updatedBy},
          updated_at: {$set: updatedAt},
        },
      });
      const statusData = {};
      const approved = approver.filter(item => +item.is_approve === 1);
      const isApprovedByAllApprovers = approver.length === approved.length;
      if (isApprovedByAllApprovers) {
        if (rule.is_need_admin_approval) {
          statusData.status = {
            $set: config.reservationStatus.WAITING_CLUSTER_ADMIN,
          };
        } else {
          statusData.status = {$set: config.reservationStatus.APPROVED};
        }
      }
      state = update(state, {
        reservation: {
          approver: {$set: approver},
          ...statusData,
        },
        approving: {$set: null},
      });
    }
  }

  if (data && data.approved_ids) {
    data.approved_ids.forEach(approveId => {
      const approvedIdx = reservations.findIndex(item => item.id === approveId);
      if (approveId && approvedIdx > -1) {
        if (key === 'clusterSICs') {
          state = update(state, {
            clusterSIC: {
              reservations: {
                [approvedIdx]: {
                  status: {$set: config.reservationStatus.APPROVED},
                },
              },
            },
            approving: {$set: null},
          });
        } else {
          state = update(state, {
            [key]: {
              [approvedIdx]: {
                status: {$set: config.reservationStatus.APPROVED},
              },
            },
            approving: {$set: null},
          });
        }
      }
    });
    return state;
  }

  const reservationIdx = reservations.findIndex(item => item.id === id);
  if (reservationIdx > -1) {
    if (key === 'clusterSICs') {
      return update(state, {
        clusterSIC: {
          reservations: {
            [reservationIdx]: {
              status: {$set: config.reservationStatus.APPROVED},
            },
          },
        },
        approving: {$set: null},
      });
    } else {
      return update(state, {
        [key]: {
          [reservationIdx]: {
            status: {$set: config.reservationStatus.APPROVED},
          },
        },
        approving: {$set: null},
      });
    }
  }

  return {...state, approving: null};
}

function reduceCancelled(state, payload) {
  const {data, key: payloadKey, id: payloadId} = payload || {};
  const key = payloadKey || 'reservations';
  const reservations =
    key === 'clusterSICs' ? state.clusterSIC.reservations : state[key];

  if (!payloadId) {
    return {...state, canceling: null};
  }

  const {reservation} = state;

  if (reservation) {
    state = update(state, {
      reservation: {
        status: {$set: config.reservationStatus.CANCELLED},
      },
      canceling: {$set: null},
    });
  }

  if (data && data.cancelled_ids) {
    data.cancelled_ids.forEach(id => {
      const cancelledIdx = reservations.findIndex(item => +item.id === +id);
      if (id && cancelledIdx > -1) {
        state = update(state, {
          [key]: {
            [cancelledIdx]: {
              status: {$set: config.reservationStatus.CANCELLED},
            },
          },
          canceling: {$set: null},
        });
      }
    });
    return state;
  }

  const index = reservations.findIndex(item => item.id === payloadId);
  if (index > -1) {
    return update(state, {
      [key]: {
        [index]: {
          status: {$set: config.reservationStatus.CANCELLED},
        },
      },
      canceling: {$set: null},
    });
  }
}

function reduceRejected(state, payload) {
  const {id, data} = payload || {};
  const {reservation} = state;
  const key = payload.key || 'reservations';
  const reservations =
    key === 'clusterSICs' ? state.clusterSIC.reservations : state[key];

  if (reservation) {
    state = update(state, {
      reservation: {
        status: {$set: config.reservationStatus.REJECTED},
      },
      rejecting: {$set: null},
    });
  }

  if (data && data.rejected_ids) {
    data.rejected_ids.forEach(rejectId => {
      const rejectedReservationIdx = reservations.findIndex(
        item => item.id === rejectId
      );
      if (rejectId && rejectedReservationIdx > -1) {
        if (key === 'clusterSICs') {
          state = update(state, {
            clusterSIC: {
              reservations: {
                [rejectedReservationIdx]: {
                  status: {$set: config.reservationStatus.REJECTED},
                },
              },
            },
            rejecting: {$set: null},
          });
        } else {
          state = update(state, {
            [key]: {
              [rejectedReservationIdx]: {
                status: {$set: config.reservationStatus.REJECTED},
              },
            },
            rejecting: {$set: null},
          });
        }
      }
    });

    return state;
  }

  const reservationIdx = reservations.findIndex(item => item.id === id);
  if (id && reservationIdx > -1) {
    if (key === 'clusterSICs') {
      return update(state, {
        clusterSIC: {
          reservations: {
            [reservationIdx]: {
              status: {$set: config.reservationStatus.REJECTED},
            },
          },
        },
        rejecting: {$set: null},
      });
    } else {
      return update(state, {
        [key]: {
          [reservationIdx]: {
            status: {$set: config.reservationStatus.REJECTED},
          },
        },
        rejecting: {$set: null},
      });
    }
  }

  return {...state, rejecting: null};
}

const wfoReservationActionReducer = (state, action) => {
  const {type, payload} = action;

  switch (type) {
    case GET_WFO_RESERVATION_LIST: {
      return {
        ...state,
        wfoReservation: {
          ...state.wfoReservation,
          loading: true,
          error: null,
        },
      };
    }

    case SET_WFO_RESERVATION_LIST: {
      const {data, total, error} = payload;

      return {
        ...state,
        wfoReservation: {
          ...state.wfoReservation,
          error,
          data: data || [],
          total,
          loading: false,
        },
      };
    }

    case CANCEL_WFO_RESERVATION_REQUEST: {
      const {id} = payload;

      const nuCancelList = [...state.wfoReservation.cancelling, id];

      return {
        ...state,
        wfoReservation: {
          ...state.wfoReservation,
          cancelling: Array.from(new Set(nuCancelList).values()),
        },
      };
    }

    case WFO_CANCELED: {
      const {id, error, data} = payload;

      const nuData = data
        ? (state.wfoReservation.data || []).map(e =>
            e.id === data.id ? data : e
          )
        : state.wfoReservation.data;

      return {
        ...state,
        wfoReservation: {
          ...state.wfoReservation,
          error,
          data: nuData,
          cancelling: state.wfoReservation.cancelling.filter(e => e !== id),
        },
      };
    }

    case WFO_CREATED: {
      const {error, data} = payload || {};

      return {
        ...state,
        wfoReservation: {
          ...state.wfoReservation,
          error,
          formError: error ? data : null,
        },
      };
    }

    case WFO_FORM_RESET: {
      return {
        wfoReservation: {
          ...state.wfoReservation,
          error: null,
          formError: null,
        },
      };
    }

    default:
      return state;
  }
};

const findRoomActionReducer = (state, action) => {
  const {type} = action;

  switch (type) {
    case FIND_ROOM_REQUEST: {
      return {...state, findingRoom: true};
    }

    case FIND_ROOM_SUCCEED: {
      return {...state, findingRoom: false};
    }

    case FIND_ROOM_FAILED: {
      return {...state, findingRoom: false};
    }

    case FIND_ROOM_CLUSTER_ADMIN_REQUEST: {
      return {...state, findingRoomClusterAdmin: true};
    }

    case FIND_ROOM_CLUSTER_ADMIN_SUCCEED: {
      return {...state, findingRoomClusterAdmin: false};
    }

    case FIND_ROOM_CLUSTER_ADMIN_FAILED: {
      return {...state, findingRoomClusterAdmin: false};
    }

    default:
      return state;
  }
};

const statusActionReducer = (state, action) => {
  const {type, payload} = action;

  switch (type) {
    case APPROVING: {
      const {id} = payload;
      return {...state, approving: id};
    }

    case APPROVED: {
      return reduceApproved(state, payload);
    }

    case CANCELING:
      return {...state, canceling: payload.id};

    case CANCELED: {
      return reduceCancelled(state, payload);
    }

    case RELEASING:
      return {...state, releasing: payload.id};

    case RELEASED: {
      const {id} = payload;
      const key = payload.key || 'reservations';
      const {reservation} = state;
      const reservations = state[key];

      const index = reservations.findIndex(item => item.id === id);

      if (reservation) {
        state = update(state, {
          reservation: {
            status: {$set: config.reservationStatus.RELEASED},
          },
          releasing: {$set: null},
        });
      }

      if (id && index > -1) {
        return update(state, {
          [key]: {
            [index]: {
              status: {$set: config.reservationStatus.RELEASED},
            },
          },
          releasing: {$set: null},
        });
      } else {
        return {...state, releasing: null};
      }
    }

    case REJECTING:
      return {...state, rejecting: payload.id};

    case REJECTED: {
      return reduceRejected(state, payload);
    }

    default: {
      return state;
    }
  }
};

const guestActionReducer = (state, action) => {
  const {type, payload} = action;

  switch (type) {
    case ADD_GUEST_REQUEST: {
      return {
        ...state,
        addingGuest: true,
      };
    }

    case ADD_GUEST_SUCCEED:
    case ADD_GUEST_FAILED: {
      return {
        ...state,
        addingGuest: false,
      };
    }

    case SET_GUEST_RESERVATION_ID: {
      const {id} = payload;

      return {
        ...state,
        guestReservationId: id,
      };
    }

    case CLEAR_GUEST_RESERVATION_ID: {
      return {
        ...state,
        guestReservationId: null,
      };
    }

    default:
      return state;
  }
};

const liasonActionReducer = (state, action) => {
  const {type, payload} = action;

  switch (type) {
    case GET_RESERVATION_LIASON_OFFICER_REQUEST: {
      return {
        ...state,
        loading: true,
        liasonOfficers: [],
      };
    }

    case GET_RESERVATION_LIASON_OFFICER_SUCCEED: {
      const {reservations: liasonOfficers} = payload;

      return {
        ...state,
        loading: false,
        liasonOfficers,
      };
    }

    case GET_RESERVATION_LIASON_OFFICER_FAILED: {
      return {
        ...state,
        loading: false,
        liasonOfficers: [],
      };
    }

    default:
      return state;
  }
};

const clusterAdminActionReducer = (state, action) => {
  const {type, payload} = action;

  switch (type) {
    case GET_MY_CLUSTER_ADMIN_REQUEST:
      return {...state, fetchingMyClusterAdmin: true};

    case GET_MY_CLUSTER_ADMIN_SUCCESS:
      const {data: myClusterAdmin} = payload;
      return {...state, fetchingMyClusterAdmin: false, myClusterAdmin};

    case GET_MY_CLUSTER_ADMIN_FAILED:
      return {...state, fetchingMyClusterAdmin: false};

    case GET_RESERVATION_CLUSTER_ADMIN_REQUEST: {
      return {
        ...state,
        loading: true,
        clusterAdmins: [],
      };
    }

    case GET_RESERVATION_CLUSTER_ADMIN_SUCCEED: {
      const {reservations: clusterAdmins} = payload;

      return {
        ...state,
        loading: false,
        clusterAdmins,
      };
    }

    case GET_RESERVATION_CLUSTER_ADMIN_FAILED: {
      return {
        ...state,
        loading: false,
        clusterAdmins: [],
      };
    }

    default:
      return state;
  }
};

const clusterSICActionReducer = (state, action) => {
  const {type, payload} = action || {};

  switch (type) {
    case GET_MY_CLUSTER_SIC_REQUEST:
      return {
        ...state,
        clusterSIC: {
          ...state.clusterSIC,
          clustersLoading: true,
        },
      };

    case GET_MY_CLUSTER_SIC_SUCCESS:
      const {data} = payload || {};
      return {
        ...state,
        clusterSIC: {
          ...state.clusterSIC,
          clustersLoading: false,
          clusters: data,
        },
      };

    case GET_MY_CLUSTER_SIC_FAILED:
      return {
        ...state,
        clusterSIC: {
          ...state.clusterSIC,
          clustersLoading: false,
        },
      };

    case GET_RESERVATION_CLUSTER_SIC_REQUEST: {
      return {
        ...state,
        clusterSIC: {
          ...state.clusterSIC,
          reservationLoading: true,
          reservations: [],
        },
      };
    }

    case GET_RESERVATION_CLUSTER_SIC_SUCCEED: {
      const {reservations} = payload;

      return {
        ...state,
        clusterSIC: {
          ...state.clusterSIC,
          reservationLoading: false,
          reservations,
        },
      };
    }

    case GET_RESERVATION_CLUSTER_SIC_FAILED: {
      return {
        ...state,
        clusterSIC: {
          ...state.clusterSIC,
          reservationLoading: false,
          reservations: [],
        },
      };
    }

    default:
      return state;
  }
};

const rescheduleActionReducer = (state, action) => {
  const {type} = action;

  switch (type) {
    case RESCHEDULE_RESERVATION_REQUEST:
      return {...state, rescheduled: false, rescheduling: true};

    case RESCHEDULE_RESERVATION_SUCCESS: {
      return {...state, rescheduled: true, rescheduling: false};
    }

    case RESCHEDULE_RESERVATION_FAILED:
      return {...state, rescheduled: false, rescheduling: false};

    case RESCHEDULE_RESERVATION_DONE:
      return {...state, rescheduled: false, rescheduling: false};

    default:
      return state;
  }
};

const qrcodeActionReducer = (state, action) => {
  const {type, payload} = action;

  switch (type) {
    case SCANNING_QRCODE:
      return {...state, scanningQRCode: true};

    case SCAN_QRCODE_SUCCESS:
      const {data: QRCodeResult} = payload;
      return {...state, scanningQRCode: false, QRCodeResult};

    case SCAN_QRCODE_FAILED:
      return {...state, scanningQRCode: false, QRCodeResult: null};
    default:
      return state;
  }
};

export default function(state = storeState, action = {}) {
  const {type, payload} = action;

  const wfoActions = [
    GET_WFO_RESERVATION_LIST,
    SET_WFO_RESERVATION_LIST,
    CANCEL_WFO_RESERVATION_REQUEST,
    WFO_CANCELED,
    WFO_CREATED,
    WFO_FORM_RESET,
  ];
  if (wfoActions.indexOf(type) > -1) {
    return wfoReservationActionReducer(state, action);
  }

  const findRoomActions = [
    FIND_ROOM_REQUEST,
    FIND_ROOM_SUCCEED,
    FIND_ROOM_FAILED,
    FIND_ROOM_CLUSTER_ADMIN_REQUEST,
    FIND_ROOM_CLUSTER_ADMIN_SUCCEED,
    FIND_ROOM_CLUSTER_ADMIN_FAILED,
  ];
  if (findRoomActions.indexOf(type) > -1) {
    return findRoomActionReducer(state, action);
  }

  const statusActions = [
    APPROVING,
    APPROVED,
    CANCELING,
    CANCELED,
    RELEASING,
    RELEASED,
    REJECTING,
    REJECTED,
  ];
  if (statusActions.indexOf(type) > -1) {
    return statusActionReducer(state, action);
  }

  const guestActions = [
    ADD_GUEST_REQUEST,
    ADD_GUEST_SUCCEED,
    ADD_GUEST_FAILED,
    SET_GUEST_RESERVATION_ID,
    CLEAR_GUEST_RESERVATION_ID,
  ];
  if (guestActions.indexOf(type) > -1) {
    return guestActionReducer(state, action);
  }

  const liasonActions = [
    GET_RESERVATION_LIASON_OFFICER_REQUEST,
    GET_RESERVATION_LIASON_OFFICER_SUCCEED,
    GET_RESERVATION_LIASON_OFFICER_FAILED,
  ];
  if (liasonActions.indexOf(type) > -1) {
    return liasonActionReducer(state, action);
  }

  const rescheduleActions = [
    RESCHEDULE_RESERVATION_REQUEST,
    RESCHEDULE_RESERVATION_SUCCESS,
    RESCHEDULE_RESERVATION_FAILED,
    RESCHEDULE_RESERVATION_DONE,
  ];
  if (rescheduleActions.indexOf(type) > -1) {
    return rescheduleActionReducer(state, action);
  }

  const clusterAdminActions = [
    GET_MY_CLUSTER_ADMIN_REQUEST,
    GET_MY_CLUSTER_ADMIN_SUCCESS,
    GET_MY_CLUSTER_ADMIN_FAILED,
    GET_RESERVATION_CLUSTER_ADMIN_REQUEST,
    GET_RESERVATION_CLUSTER_ADMIN_SUCCEED,
    GET_RESERVATION_CLUSTER_ADMIN_FAILED,
  ];
  if (clusterAdminActions.indexOf(type) > -1) {
    return clusterAdminActionReducer(state, action);
  }

  const clusterSICActions = [
    GET_MY_CLUSTER_SIC_REQUEST,
    GET_MY_CLUSTER_SIC_SUCCESS,
    GET_MY_CLUSTER_SIC_FAILED,
    GET_RESERVATION_CLUSTER_SIC_REQUEST,
    GET_RESERVATION_CLUSTER_SIC_SUCCEED,
    GET_RESERVATION_CLUSTER_SIC_FAILED,
  ];
  if (clusterSICActions.indexOf(type) > -1) {
    return clusterSICActionReducer(state, action);
  }

  const qrcodeActions = [
    SCANNING_QRCODE,
    SCAN_QRCODE_SUCCESS,
    SCAN_QRCODE_FAILED,
  ];
  if (qrcodeActions.indexOf(type) > -1) {
    return qrcodeActionReducer(state, action);
  }

  switch (type) {
    case AUTH_OUT_SUCCESS: {
      return {...initialState};
    }

    case GET_RESERVATION_DETAIL_REQUEST: {
      return {
        ...state,
        loadingReservationDetail: true,
        reservation: null,
      };
    }

    case GET_RESERVATION_DETAIL_SUCCEED: {
      return {
        ...state,
        loadingReservationDetail: false,
      };
    }

    case GET_RESERVATION_DETAIL_FAILED: {
      return {
        ...state,
        loadingReservationDetail: false,
        reservation: null,
      };
    }

    case SET_RESERVATIONS:
      const {reservations} = payload;
      return {...state, reservations};

    case SET_RESERVATION:
      const {reservation} = payload;
      return {...state, reservation};

    case SET_MEETING_ROOMS:
      const {rooms} = payload;
      return {...state, meetingRooms: rooms};

    case SET_MEETING_ROOM_PARAMS:
      const {params} = payload;
      return {...state, params};

    case SUBMITTING:
      return {...state, submitting: true};

    case SUBMITTED:
      return {...state, submitting: false};

    case BOOKING_SUCCESS:
      return {...state, bookingSuccess: true};

    case BOOKING_DONE:
      return {...state, bookingSuccess: false, bookingDone: true};

    case BOOKING_RESET:
      return {...state, bookingSuccess: false, bookingDone: false};

    case SET_MEETING_ROOM:
      const {room: selectedMeetingRoom} = payload;
      return {...state, selectedMeetingRoom};

    case UPDATING_RESERVATION:
      return {...state, updating: true};

    case UPDATE_RESERVATION_SUCCESS:
      return {...state, updating: false, updated: true};

    case UPDATE_RESERVATION_FAILED:
      return {...state, updating: false, updated: false};

    case UPDATE_RESERVATION_DONE:
      return {...state, update: false, updated: false};

    case GET_RESERVATION_APPROVAL_REQUESTS_REQUEST: {
      return {
        ...state,
        loading: true,
        approvalRequests: [],
      };
    }

    case GET_RESERVATION_APPROVAL_REQUESTS_SUCCEED: {
      const {data: approvalRequests} = payload;

      return {
        ...state,
        loading: false,
        approvalRequests,
      };
    }

    case GET_RESERVATION_APPROVAL_REQUESTS_FAILED: {
      return {
        ...state,
        loading: false,
        approvalRequests: [],
      };
    }

    case GET_MY_RESERVATIONS_REQUEST: {
      return {
        ...state,
        loading: true,
        reservations: [],
      };
    }

    case GET_MY_RESERVATIONS_SUCCEED: {
      return {
        ...state,
        loading: false,
        reservations: payload.reservations,
      };
    }

    case GET_MY_RESERVATIONS_FAILED: {
      return {
        ...state,
        loading: false,
        reservations: [],
      };
    }

    case GET_RESERVATION_RECEPTIONIST_REQUEST: {
      return {
        ...state,
        loading: true,
        receptionists: [],
      };
    }

    case GET_RESERVATION_RECEPTIONIST_SUCCEED: {
      const {reservations: receptionists} = payload;

      return {
        ...state,
        loading: false,
        receptionists,
      };
    }

    case GET_RESERVATION_RECEPTIONIST_FAILED: {
      return {
        ...state,
        loading: false,
        receptionists: [],
      };
    }

    case GET_RESERVATION_BY_POI_REQUEST: {
      return {
        ...state,
        loadingReservationByPOI: true,
        reservationByPOI: null,
        poiMarker: null,
      };
    }

    case GET_RESERVATION_BY_POI_SUCCEED: {
      return {
        ...state,
        loadingReservationByPOI: false,
        reservationByPOI: payload.reservation,
        poiMarker: payload.poi,
      };
    }

    case GET_RESERVATION_BY_POI_FAILED: {
      return {
        ...state,
        loadingReservationByPOI: false,
        reservationByPOI: null,
        poiMarker: null,
      };
    }

    default:
      return state;
  }
}
