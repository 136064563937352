import styled from 'styled-components';

const Body = styled.div`
  font-family: ${props => props.theme.global.fontFamily};
  font-size: 1em;
  color: ${props => props.theme.global.fontColor};
  a,
  a:hover {
    text-decoration: none;
  }

  ol,
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
`;

export default Body;
