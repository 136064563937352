import React, {Component} from 'react';
import {Alert} from 'reactstrap';

export class AccessDeniedAlert extends Component {
  render() {
    return (
      <Alert color="danger">
        <strong>Access Denied!</strong>
        <p>Sorry, you do not have permission to access this page.</p>
      </Alert>
    );
  }
}
