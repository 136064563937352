import React, {Component} from 'react';
import {Container, ContentHeader} from '../../styles';
import {Button, Col, Input, InputGroup, InputGroupAddon, Row} from 'reactstrap';
import {PageHeading} from '../../styles/typography';
import MaterialIcon from '../UIKit/MaterialIcon';
import NewsTabsNav from './NewsTabsNav';
import styled from 'styled-components';
import {media} from '../../styles/grid';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';

class NewsNav extends Component {
  handleSearch = event => {
    const key = event.key;
    const isEnter = key === 'Enter';
    const keyword = event.target.value;
    if (isEnter && keyword) {
      this.props.history.push(`/news/search?keyword=${keyword}`);
    }
  };

  render() {
    const {title} = this.props;

    return (
      <NewsContentHeader>
        <Container>
          <Row className="align-items-center justify-content-between">
            <Col xs={12} sm={6} lg={2}>
              <PageHeading>{title}</PageHeading>
            </Col>
            <Col xs={12} sm={6} lg={4}>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <SearchButton color="default">
                    <MaterialIcon icon="search" />
                  </SearchButton>
                </InputGroupAddon>
                <SearchInput
                  onKeyDown={this.handleSearch}
                  placeholder="Search news..."
                />
              </InputGroup>
            </Col>
            <Col xs={12} sm={12} lg={4}>
              <NewsTabsNav />
            </Col>
          </Row>
        </Container>
      </NewsContentHeader>
    );
  }
}

const NewsContentHeader = styled(ContentHeader)`
  padding: 0 !important;

  ${media.sm`
    padding: 0!important;
  `} ${media.xs`
    padding: 0!important;
  `} ${media.md`
    padding: 0!important;
  `};
`;

const SearchButton = styled(Button)`
  &:not(:disabled) {
    background: none !important;
    border-bottom: 1px solid #ededed !important;
    border-radius: 0 !important;
    color: #828282 !important;
    padding-right: 0 !important;
  }
`;

const SearchInput = styled(Input)`
  border-top: none !important;
  border-right: none !important;
  border-radius: 0 !important;
  border-left: none !important;
  border-bottom: 1px solid #ededed !important;
  &:focus {
    box-shadow: none !important;
  }
  ::placeholder {
    color: #bfbfbf !important;
    transition: color 0.5s;
  }
  &:focus {
    ::placeholder {
      color: #828282 !important;
    }
  }
`;

NewsNav.defaultProps = {
  title: 'News',
};

NewsNav.propTypes = {
  title: PropTypes.string,
};

export default withRouter(NewsNav);
