import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Alert} from 'reactstrap';
import {getHomepageAnnouncement} from '../../../actions/news';

function HomepageAnnouncement(props) {
  // store
  const dispatch = useDispatch();
  const {loading, data} = useSelector(
    store => store.news?.homeAnnouncement || {}
  );

  // effects
  useEffect(() => {
    dispatch(getHomepageAnnouncement());
  }, []);

  // render
  if (!data || loading) {
    return null;
  }

  return (
    <Alert
      color={'warning'}
      className={
        'homepage-announcement text-white bg-pwc-yellow my-4 d-flex align-items-start'
      }
      style={{borderRadius: '0.5rem'}}
      {...props}
      data-qa={'home-announce'}>
      <img
        className={'d-inline-block mr-1 py-1'}
        src={'/assets/images/info.svg'}
        style={{width: '1rem'}}
      />
      <div
        className={'flex-grow-1 font-weight-bold align-middle d-inline-block'}>
        <div data-qa={'title'}>{data?.title}</div>
        <div
          data-qa={'desc'}
          className={'small announcement-content'}
          dangerouslySetInnerHTML={{__html: data?.detail_news}}></div>
      </div>
    </Alert>
  );
}

HomepageAnnouncement.propTypes = {};

export default HomepageAnnouncement;
