import {
  AUTH_OUT_SUCCESS,
  CANCELING_ORDER,
  FETCHING_ORDER_MENU,
  ORDER_CANCELED,
  ORDER_MENU_FETCHED,
  ORDER_UPDATED,
  SET_ORDER,
  SET_ORDER_LIST,
  SET_ORDERS,
  UPDATING_ORDER,
} from '../actions/actionsList';

import update from 'immutability-helper';

let initialState = {
  orders: [],
  order: null,
  orderList: [],
  fetchingOrderMenu: false,
  updatingOrder: null,
  cancelingOrder: null,
};

export default function(state = initialState, action) {
  const {type, payload} = action;

  switch (type) {
    case AUTH_OUT_SUCCESS: {
      return {...initialState};
    }

    case SET_ORDERS:
      const {orders} = payload;
      return {...state, orders};

    case SET_ORDER:
      const {order} = payload;
      return {...state, order};

    case SET_ORDER_LIST:
      const {data} = payload;
      return {...state, orderList: data};

    case FETCHING_ORDER_MENU:
      return {...state, fetchingOrderMenu: true};

    case ORDER_MENU_FETCHED:
      const orderList = payload ? payload.data : [];
      return {...state, fetchingOrderMenu: false, orderList};

    case UPDATING_ORDER:
      return {...state, updatingOrder: payload.orderId};

    case ORDER_UPDATED:
      return {...state, updatingOrder: null};

    case CANCELING_ORDER:
      return {...state, cancelingOrder: payload.id};

    case ORDER_CANCELED: {
      const canceling = {
        cancelingOrder: {$set: null},
      };
      if (payload && payload.id) {
        const {id} = payload;
        const cancelled = {
          status: {$set: 'cancelled'},
        };
        // update state from orders
        const index = state.orders.findIndex(item => item.id === id);
        if (index > -1) {
          return update(state, {
            orders: {
              ...canceling,
              [index]: cancelled,
            },
          });
        }
        // update state from orderList
        const indexOrderList = state.orderList.findIndex(
          item => item.id === id
        );
        if (indexOrderList > -1) {
          return update(state, {
            orderList: {
              ...canceling,
              [indexOrderList]: cancelled,
            },
          });
        }
      }
      return {...state, ...canceling};
    }

    default:
      return state;
  }
}
