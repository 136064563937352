import React from 'react';
import {
  getFormattedReservationDate,
  getFormattedTime,
} from '../../utils/helpers';
import {TextMuted} from '../../styles';
import MaterialIcon from '../../components/UIKit/MaterialIcon';
import styled from 'styled-components';
import {Card, CardBody} from 'reactstrap';

const MediaPOIInfo = props => {
  const {reservation} = props;
  const {zone, floor, cluster, room_name, start_time, end_time} =
    reservation || {};

  const reserveDate = getFormattedReservationDate(reservation);
  const startTime = getFormattedTime(start_time);
  const endTime = getFormattedTime(end_time);

  return (
    <Card>
      <SmallCardBody>
        <DateInfo>
          {reserveDate} &middot; {startTime} - {endTime}
        </DateInfo>
        <TextMuted>
          <IconWrapper>
            <MaterialIcon icon="map-marker-alt" />
          </IconWrapper>
          <div>
            {room_name} &middot; {zone} &middot; {floor} &middot; {cluster}
          </div>
        </TextMuted>
      </SmallCardBody>
    </Card>
  );
};

const IconWrapper = styled.span`
  margin-right: 5px;
`;

const DateInfo = styled(TextMuted)`
  border-bottom: 1px solid #aeaeae;
  margin-bottom: 5px;
  padding-bottom: 5px;
`;

const SmallCardBody = styled(CardBody)`
  padding: 10px !important;
`;

export default MediaPOIInfo;
