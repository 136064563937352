import {combineReducers} from 'redux';
import {reducer as formReducer} from 'redux-form';
import {RESET_REDUX_STORE} from '../actions/actionsList';
import auth from './authReducer';
import user from './userReducer';
import profile from './profileReducer';
import oon from './oonReducer';
import modal from './modalReducer';
import reservation from './reservationReducer';
import global from './globalReducer';
import groupMenu from './groupMenuReducer';
import menu from './menuReducer';
import order from './orderReducer';
import setting from './settingReducer';
import qrcode from './QRCodeReducer';
import notification from './notificationReducer';
import floor from './floorReducer';
import poi from './poiReducer';
import news from './newsReducer';
import eula from './eulaReducer';
import map from './mapReducer';
import externalNews from './externalNewsReducer';

const appReducer = combineReducers({
  auth,
  user,
  profile,
  oon,
  modal,
  reservation,
  global,
  groupMenu,
  menu,
  order,
  setting,
  qrcode,
  notification,
  floor,
  poi,
  news,
  eula,
  map,
  externalNews,
  form: formReducer,
});

const rootReducer = (state, action) => {
  const {type} = action;

  if (type === RESET_REDUX_STORE) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
