import {
  AUTH_OUT_SUCCESS,
  GET_EXTERNAL_NEWS_BY_CATEGORY_FAILED,
  GET_EXTERNAL_NEWS_BY_CATEGORY_REQUEST,
  GET_EXTERNAL_NEWS_BY_CATEGORY_SUCCEED,
  GET_EXTERNAL_NEWS_CATEGORIES_FAILED,
  GET_EXTERNAL_NEWS_CATEGORIES_REQUEST,
  GET_EXTERNAL_NEWS_CATEGORIES_SUCCEED,
  GET_EXTERNAL_NEWS_DETAIL_FAILED,
  GET_EXTERNAL_NEWS_DETAIL_REQUEST,
  GET_EXTERNAL_NEWS_DETAIL_SUCCEED,
  GET_EXTERNAL_NEWS_FAILED,
  GET_EXTERNAL_NEWS_REQUEST,
  GET_EXTERNAL_NEWS_SUCCEED,
} from '../actions/actionsList';

let initialState = {
  highlight: [],
  departments: [],
  post: null,
  loading: false,
  posts: [],
  department: null,
  latestNews: [],
  pagination: {
    last_page: 1,
    limit: 3,
    perPage: 3,
    total: 0,
  },
  loadingDepartment: false,
};

export default function(state = initialState, action) {
  const {type, payload} = action;

  switch (type) {
    case AUTH_OUT_SUCCESS: {
      return {...initialState};
    }

    case GET_EXTERNAL_NEWS_REQUEST: {
      return {...state, loading: true, latestNews: []};
    }

    case GET_EXTERNAL_NEWS_SUCCEED: {
      const {news, pagination} = payload;
      return {
        ...state,
        loading: false,
        latestNews: news,
        pagination,
      };
    }

    case GET_EXTERNAL_NEWS_FAILED: {
      return {...state, loading: false, latestNews: []};
    }

    case GET_EXTERNAL_NEWS_BY_CATEGORY_REQUEST: {
      return {...state, loading: true, posts: []};
    }

    case GET_EXTERNAL_NEWS_BY_CATEGORY_SUCCEED: {
      const {news, pagination} = payload;
      return {...state, loading: false, posts: news, pagination};
    }

    case GET_EXTERNAL_NEWS_BY_CATEGORY_FAILED: {
      return {...state, loading: false, posts: []};
    }

    case GET_EXTERNAL_NEWS_CATEGORIES_REQUEST: {
      return {...state, loading: true, departments: []};
    }

    case GET_EXTERNAL_NEWS_CATEGORIES_SUCCEED: {
      const {categories: departments} = payload;
      return {...state, loading: false, departments};
    }

    case GET_EXTERNAL_NEWS_CATEGORIES_FAILED: {
      return {...state, loading: false, departments: []};
    }

    case GET_EXTERNAL_NEWS_DETAIL_REQUEST: {
      return {...state, loading: true, post: null};
    }

    case GET_EXTERNAL_NEWS_DETAIL_SUCCEED: {
      const {post} = payload;
      return {...state, loading: false, post};
    }

    case GET_EXTERNAL_NEWS_DETAIL_FAILED: {
      return {...state, loading: false, post: null};
    }

    default:
      return state;
  }
}
