import {
  MENU_CREATED,
  CREATING_MENU,
  MENU_REMOVED,
  MENU_UPDATED,
  REMOVING_MENU,
  SET_MENU,
  SET_MENUS,
  UPDATING_MENU,
  SUBMITTING_ORDER,
  ORDER_SUBMITTED,
  ORDER_MENU_FAILED,
  SUBMIT_ORDER_DONE,
  MENU_UPDATE_DONE,
  MENU_CREATED_DONE,
  GET_MENU_LIST_REQUEST,
  GET_MENU_LIST_SUCCEED,
  GET_MENU_LIST_FAILED,
  AUTH_OUT_SUCCESS,
} from '../actions/actionsList';

let initialState = {
  isCreated: false,
  isCreating: false,
  menus: [],
  isRemoving: null,
  isRemoved: false,
  menu: null,
  isUpdating: null,
  isUpdated: false,
  submittingOrder: false,
  orderSubmitted: false,
  isLoading: false,
};

export default function(state = initialState, action) {
  const {type, payload} = action;

  switch (type) {
    case AUTH_OUT_SUCCESS: {
      return {...initialState};
    }

    case MENU_CREATED:
      return {...state, isCreated: true, isCreating: false};

    case MENU_CREATED_DONE:
      return {...state, isCreated: false, isCreating: false};

    case CREATING_MENU:
      return {...state, isCreating: true};

    case SET_MENUS:
      const {menus} = payload;
      return {...state, menus};

    case REMOVING_MENU:
      return {...state, isRemoving: payload.id};

    case MENU_REMOVED:
      return {...state, isRemoving: null, isRemoved: true};

    case SET_MENU:
      const {menu} = payload;
      return {...state, menu};

    case UPDATING_MENU:
      return {...state, isUpdating: payload.id};

    case MENU_UPDATED:
      return {...state, isUpdating: null, isUpdated: true};

    case MENU_UPDATE_DONE:
      return {...state, isUpdated: false};

    case SUBMITTING_ORDER:
      return {...state, submittingOrder: true};

    case ORDER_SUBMITTED:
      return {...state, submittingOrder: false, orderSubmitted: true};

    case ORDER_MENU_FAILED:
      return {...state, submittingOrder: false};

    case SUBMIT_ORDER_DONE:
      return {...state, submittingOrder: false, orderSubmitted: false};

    case GET_MENU_LIST_REQUEST: {
      return {...state, isLoading: true};
    }

    case GET_MENU_LIST_SUCCEED: {
      const {menus} = payload;
      return {...state, isLoading: false, menus};
    }

    case GET_MENU_LIST_FAILED: {
      return {...state, isLoading: false};
    }

    default:
      return state;
  }
}
