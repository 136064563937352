import {
  LOADED,
  LOADING,
  SET_ERROR,
  SET_PAGINATION,
} from '../actions/actionsList';

let initialState = {
  pagination: {
    current_page: 1,
    last_page: 1,
    per_page: 10,
    total: 0,
  },
  error: null,
  loading: false,
};

export default function(state = initialState, action) {
  const {type, payload} = action;

  switch (type) {
    case SET_PAGINATION:
      const {pagination} = payload;
      return {...state, pagination};

    case SET_ERROR:
      const {message} = payload;
      return {...state, error: message};

    case LOADING:
      return {...state, loading: true};

    case LOADED:
      return {...state, loading: false};

    default:
      return state;
  }
}
