import {
  CANCEL_ORDER_ACTION,
  GET_ORDER_LIST_REQUEST,
  GET_ORDER_MENU_REQUEST,
  SUBMIT_ORDER_DONE,
  UPDATE_ORDER_REQUEST,
} from './actionsList';

/**
 * Action for getting order list (pantry).
 *
 * @param params
 * @returns {{type: string, payload: {page: *, limit: *, sortBy: *, descending: *, status: *}}}
 */
export const getOrderListRequest = params => {
  params = params || {};
  const {page, limit, sortBy, descending, status} = params;
  return {
    type: GET_ORDER_LIST_REQUEST,
    payload: {page, limit, sortBy, descending, status},
  };
};

/**
 * Action for Get Order Menu by Reservation ID.
 *
 * @param reservationId
 * @returns {{type: *, payload: {reservationId: *}}}
 */
export const getOrderMenuRequest = reservationId => {
  return {
    type: GET_ORDER_MENU_REQUEST,
    payload: {reservationId},
  };
};

/**
 * Update Order Request.
 *
 * @param orderId
 * @param data
 * @returns {{type: string, payload: {orderId: *, data: *}}}
 */
export const updateOrderRequest = (orderId, data) => {
  return {
    type: UPDATE_ORDER_REQUEST,
    payload: {orderId, data},
  };
};

/**
 * Order Submission Done.
 *
 * @returns {{type: string, payload: {}}}
 */
export const submitOrderDone = () => {
  return {type: SUBMIT_ORDER_DONE, payload: {}};
};

/**
 * Cancel order action.
 *
 * @param id
 * @param onCancel
 * @returns {{type: string, payload: {id: *}}}
 */
export const cancelOrderAction = (id, onCancel) => {
  return {type: CANCEL_ORDER_ACTION, payload: {id, onCancel}};
};
