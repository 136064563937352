/**
 * Action for getting all settings.
 *
 * @returns {{type: *, payload: {}}}
 */
import {GET_SETTING_REQUEST, UPSERT_SETTING_REQUEST} from './actionsList';

export const getSettingsAction = () => {
  return {
    type: GET_SETTING_REQUEST,
    payload: {},
  };
};

/**
 * Update or insert (upsert) setting.
 *
 * @param data
 * @returns {{type: string, payload: {data: *}}}
 */
export const upsertSettingRequest = data => {
  return {
    type: UPSERT_SETTING_REQUEST,
    payload: {data},
  };
};
