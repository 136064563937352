import {FeatureFlag} from '../config/constants';

const theme = {
  breakMobile: '767px',
  breakTablet: '768px',
  breakDesktop: '1024px',
  breakHeightIpadPro: '1025px',
  global: {
    fontFamily: FeatureFlag.USE_HELVETICA
      ? `HelveticaNeue`
      : 'Arial, sans-serif',
    fontColor: '#595959',
    fontSize: '1rem',
  },
  wrapper: {
    width: '100%',
  },
  content: {
    width: '90%',
    maxWidth: '1200px',
    margin: 'auto',
  },
  main: {
    marginTop: '3.45em',
  },
  header: {
    height: '4.6875em',
  },
  footer: {
    height: '3.6em',
  },
  card: {
    borderColor: '#E8E8E8',
  },
  colors: {
    default: '#dedede',
    primary: '#E0301E',
    secondary: '#6c757d',
    warning: '#EE9C34',
    success: '#37BE63',
    blue: '#448AFF',
    yellow: '#ee9c34',
    danger: '#dc3545',
  },
  button: {
    borderColors: {
      default: '#dedede',
      primary: '#E0301E',
      secondary: '#6c757d',
      warning: '#EE9C34',
      success: '#37BE63',
      yellow: '#EDD73A',
      white: '#dedede',
      blue: '#448AFF',
    },
    backgroundColors: {
      default: '#EFEFEF',
      primary: '#E0301E',
      secondary: '#6c757d',
      warning: '#EE9C34',
      success: '#37BE63',
      yellow: '#EDD73A',
      white: '#FFFFFF',
      blue: '#448AFF',
    },
    outlineDisabledBorderColors: {
      default: '#dedede',
      primary: '#E0301E',
      secondary: '#6c757d',
      warning: '#EE9C34',
      success: '#37BE63',
      yellow: '#EDD73A',
      white: '#dedede',
      blue: '#448AFF',
    },
    outlineDisabledBackgroundColors: {
      default: '#EFEFEF',
      primary: '#E0301E',
      secondary: '#6c757d',
      warning: 'transparent',
      success: '#37BE63',
      yellow: '#EDD73A',
      white: '#FFFFFF',
      blue: '#448AFF',
    },
    disabledBorderColors: {
      default: '#dedede',
      primary: '#e0301e9e',
      secondary: '#6c757db8',
      warning: '#ee9c34ab',
      success: '#37be6391',
      yellow: '#edd73ab5',
      white: '#dedede',
      blue: '#448affb5',
    },
    disabledBackgroundColors: {
      default: '#EFEFEF',
      primary: '#e0301e9e',
      secondary: '#6c757db8',
      warning: '#ee9c34ab',
      success: '#37be6391',
      yellow: '#edd73ab5',
      white: '#FFFFFF',
      blue: '#448affb5',
    },
    disabledColors: {
      default: '#EFEFEF',
      primary: '#ffffff',
      secondary: '#ffffff',
      warning: '#ffffff',
      success: '#ffffff',
      yellow: '#ffffff',
      white: '#FFFFFF',
      blue: '#ffffff',
    },
    outlineDisabledColors: {
      default: '#EFEFEF',
      primary: '#E0301E',
      secondary: '#6c757d',
      warning: '#f8bb86',
      success: '#37BE63',
      yellow: '#EDD73A',
      white: '#FFFFFF',
      blue: '#448AFF',
    },
    colors: {
      default: '#999999',
      primary: '#FFFFFF',
      secondary: '#FFFFFF',
      warning: '#FFFFFF',
      success: '#FFFFFF',
      yellow: '#FFFFFF',
      color: '#6c757d',
      blue: '#FFFFFF',
    },
  },
};

export default theme;
