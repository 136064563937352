import {fork, all} from 'redux-saga/effects';
import auth from './auth';
import user from './user';
import profile from './profile';
import oon from './oon';
import reservation from './reservation';
import groupMenu from './groupMenu';
import beverage from './menu';
import order from './order';
import setting from './setting';
import qrcode from './qrcode';
import notification from './notification';
import floor from './floor';
import poi from './poi';
import news from './news';
import eula from './eula';

export default function* rootSaga() {
  yield all([
    fork(auth),
    fork(user),
    fork(profile),
    fork(oon),
    fork(reservation),
    fork(groupMenu),
    fork(beverage),
    fork(order),
    fork(setting),
    fork(qrcode),
    fork(notification),
    fork(floor),
    fork(poi),
    fork(news),
    fork(eula),
  ]);
}
