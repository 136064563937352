import {
  AUTH_OUT_SUCCESS,
  CLEAR_POI_BY_FLOOR,
  CLEAR_POI_LIST,
  GET_HEAT_MAP_FAILED,
  GET_HEAT_MAP_REQUEST,
  GET_HEAT_MAP_SUCCESS,
  GET_ROOMS_WITH_RESERVATION_FAILED,
  GET_ROOMS_WITH_RESERVATION_REQUEST,
  GET_ROOMS_WITH_RESERVATION_SUCCESS,
  SET_POI_LIST,
} from '../actions/actionsList';

let initialState = {
  poiList: [],
  heatMap: [],
  loadingHeatMap: false,
  loadingGanttChart: false,
  roomsWithReservations: [],
};

export default function(state = initialState, action) {
  const {type, payload} = action;

  switch (type) {
    case AUTH_OUT_SUCCESS: {
      return {...initialState};
    }

    case CLEAR_POI_LIST: {
      return {...state, poiList: []};
    }

    case SET_POI_LIST: {
      const {data} = payload;
      return {...state, poiList: data};
    }

    case GET_HEAT_MAP_REQUEST: {
      return {
        ...state,
        loadingHeatMap: true,
      };
    }
    case GET_HEAT_MAP_SUCCESS: {
      const {data} = payload;

      return {
        ...state,
        heatMap: data,
        loadingHeatMap: false,
      };
    }
    case GET_HEAT_MAP_FAILED: {
      return {
        ...state,
        loadingHeatMap: false,
      };
    }

    case GET_ROOMS_WITH_RESERVATION_REQUEST: {
      return {
        ...state,
        loadingGanttChart: true,
      };
    }

    case GET_ROOMS_WITH_RESERVATION_SUCCESS: {
      const {data} = payload;

      return {
        ...state,
        roomsWithReservations: data,
        loadingGanttChart: false,
      };
    }

    case GET_ROOMS_WITH_RESERVATION_FAILED: {
      return {
        ...state,
        roomsWithReservations: [],
        loadingGanttChart: false,
      };
    }

    case CLEAR_POI_BY_FLOOR: {
      return {
        ...state,
        roomsWithReservations: [],
        loadingGanttChart: false,
      };
    }

    default:
      return state;
  }
}
