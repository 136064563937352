import React, {Component} from 'react';
import {connect} from 'react-redux';
import ApplicationLayout from '../../components/Layouts/ApplicationLayout';
import {
  MainContent,
  Container,
  CategoryHeader,
  CategoryTitle,
  MoreButton,
  CategoryPosts,
} from '../../styles';
import CarouselNews from '../../components/Carousel/CarouselNews';
import {getDashboardNews, getExternalNewsAction} from '../../actions/news';
import {bindActionCreators} from 'redux';
import styled, {createGlobalStyle} from 'styled-components';
import {CardColumns, Col, Row} from 'reactstrap';
import {chunkArray, getNewsCategory} from '../../utils/helpers';
import config from '../../config';
import LatestNewsPlaceholder from './LatestNewsPlaceholder';
import {media} from '../../styles/grid';
import PostCard from '../../components/News/PostCard';
import NoNewsFound from './NoNewsFound';
import NewsNav from '../../components/News/NewsNav';
import PostGrid from '../../components/News/PostGrid';

class ExternalNews extends Component {
  timer = null;

  fetch = (params = {}) => {
    this.props.getExternalNews({limit: 6, ...params});
  };

  componentDidMount() {
    this.fetch();
  }

  handleSearch = event => {
    const keyword = event.target.value;
    if (keyword) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.props.history.push(`/news/search?keyword=${keyword}`);
      }, 500);
    }
  };

  render() {
    const category = getNewsCategory();
    const {
      latestNews,
      pagination,
      highlight,
      loading,
    } = this.props.externalNews;
    const hasNews = latestNews.length > 0;

    if (config.DEBUG) {
      console.log({
        latestNews,
        pagination,
        highlight,
        loading,
        category,
      });
    }

    return (
      <ApplicationLayout withoutFooter>
        <NewsNav />
        <MainContent>
          <Container>
            <CarouselWrapper>
              <CarouselNews items={highlight} loading={loading} />
            </CarouselWrapper>

            <CategoryHeader>
              <CategoryTitle>Latest External News</CategoryTitle>
              <MoreButton to={`/news/${category}/others`}>
                See Others
              </MoreButton>
            </CategoryHeader>

            {loading ? (
              <LatestNewsPlaceholder />
            ) : hasNews ? (
              <PostGrid posts={latestNews} external />
            ) : (
              <NoNewsFound />
            )}
          </Container>
        </MainContent>
        <CreateGlobalStyle />
      </ApplicationLayout>
    );
  }
}

const CarouselWrapper = styled.div`
  margin-top: 1em;
`;

const CreateGlobalStyle = createGlobalStyle`
  body {
    background: #F8f8f8!important;
  }
`;

const StyledCardColumns = styled(CardColumns)`
  column-count: 2 !important;
  ${media.xs`
    column-count: 1!important;
  `};
`;

function mapStateToProps(state) {
  const {news, externalNews} = state;
  return {
    news,
    externalNews,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getNews: bindActionCreators(getDashboardNews, dispatch),
    getExternalNews: bindActionCreators(getExternalNewsAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ExternalNews);
