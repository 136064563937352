import React, {Component} from 'react';
import PropsType from 'prop-types';

class MaterialSpinner extends Component {
  sizes = {
    small: 16,
    normal: 32,
    large: 64,
  };

  getSize = () => {
    const {small, large} = this.props;
    let {size} = this.props;

    if (small) {
      size = this.sizes['small'];
    } else if (large) {
      size = this.sizes['large'];
    } else {
      size = this.sizes.normal;
    }

    return size;
  };

  render() {
    const size = this.getSize();
    const {width, indeterminate, button} = this.props;

    if (!indeterminate) {
      return null;
    }

    return (
      <svg
        className={`material-spinner ${button && 'button-spinner'}`}
        width={`${size}px`}
        height={`${size}px`}
        viewBox="0 0 66 66"
        xmlns="http://www.w3.org/2000/svg">
        <circle
          className={`material-path ${button && 'button-spinner'}`}
          fill="none"
          strokeWidth={width}
          strokeLinecap="round"
          cx="33"
          cy="33"
          r="30"
        />
      </svg>
    );
  }
}

MaterialSpinner.defaultProps = {
  size: 32,
  small: false,
  large: false,
  width: 6,
  indeterminate: false,
  button: false,
};

MaterialSpinner.propTypes = {
  size: PropsType.number,
  small: PropsType.bool,
  large: PropsType.bool,
  width: PropsType.number,
  indeterminate: PropsType.bool,
  button: PropsType.bool,
};

export default MaterialSpinner;
