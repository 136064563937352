import {
  DETAIL_USER_REQUEST,
  CLEAR_USER_REQUEST,
  LOAD_RECENT_SEARCH,
  CLEAR_RECENT_SEARCH,
  EMPTY_USER_DATA_REQUEST,
  CHANGE_PASSWORD_ACTION,
  REMOVE_CONTACT_PERSON,
  ADD_CONTACT_PERSON,
  SEARCH_USERS_LITE_ACTION,
  SEARCH_USERS_ACTION,
  GET_USER_DETAIL_ACTION,
  CLEAR_USER_SEARCH_RESULT,
  GET_STAT_USER_MONITORING,
} from './actionsList';

export const searchUsersRequest = ({
  keyword,
  page,
  perPage,
  except,
  sortBy,
  isTypeahead,
}) => {
  return {
    type: SEARCH_USERS_ACTION,
    payload: {keyword, page, perPage, except, sortBy, isTypeahead},
  };
};

export const detailUserRequest = id => {
  return {type: GET_USER_DETAIL_ACTION, payload: {id}};
};

export const clearUserSearchResultAction = emptyPayload => {
  return {type: CLEAR_USER_SEARCH_RESULT, emptyPayload};
};

export const clearUserRequest = emptyPayload => {
  return {type: CLEAR_USER_REQUEST, emptyPayload};
};

export const emptyUserDataRequest = emptyPayload => {
  return {type: EMPTY_USER_DATA_REQUEST, emptyPayload};
};

export const clearRecentSearchRequest = emptyPayload => {
  return {type: CLEAR_RECENT_SEARCH, emptyPayload};
};

export const loadRecentSearchRequest = emptyPayload => {
  return {type: LOAD_RECENT_SEARCH, emptyPayload};
};

export const changePasswordAction = data => {
  return {type: CHANGE_PASSWORD_ACTION, payload: {data}};
};

export const addContactPerson = (currentUserId, contactPersonUser) => {
  return {
    type: ADD_CONTACT_PERSON,
    payload: {currentUserId, contactPersonUser},
  };
};

export const removeContactPerson = (contactPersonId, userId) => {
  return {
    type: REMOVE_CONTACT_PERSON,
    payload: {contactPersonId, userId},
  };
};

export const searchUsersLiteAction = ({
  sortBy = 'name',
  descending = 0,
  limit = 10,
  page = 1,
  key,
  attributes,
  keyword,
  except,
}) => {
  return {
    type: SEARCH_USERS_LITE_ACTION,
    payload: {
      sortBy,
      descending,
      limit,
      page,
      key: key || keyword,
      attributes,
      except,
    },
  };
};

export const getUserMonitoringStat = () => {
  return {
    type: GET_STAT_USER_MONITORING,
  };
};
