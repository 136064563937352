import React, {Component, Fragment} from 'react';
import {
  approveByClusterAdminReservationRequest,
  approveByClusterSICReservationRequest,
  approveReservationRequest,
  cancelReservationRequest,
  rejectByClusterAdminReservationRequest,
  rejectByClusterSICReservationRequest,
  rejectReservationRequest,
  releaseReservationRequest,
  setGuestReservationId,
} from '../../actions/reservation';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {ButtonDropdown, DropdownItem, DropdownMenu} from 'reactstrap';
import {Link} from 'react-router-dom';
import {FeatureFlag} from '../../config/constants';
import {StyledDropdownToggle} from '../../styles';
import swal from 'sweetalert2';
import {
  isReservationMissed,
  canCancelReservation,
  canReleaseReservation,
  canRescheduleReservation,
  canApprove,
  canEditReservation,
  dump,
} from '../../utils/helpers';
import config from '../../config';
import theme from '../../styles/theme';
import {closeModal, openModal, toggleModal} from '../../actions/modal';
import ModalAddGuest from '../../components/Modal/ModalAddGuest';

const RESERVATION_STATUS = config.reservationStatus;

class ActionButtons extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false,
    };
  }

  handleCancel = item => {
    const {canceling} = this.props.reservation;
    const key = this.props.listKey || 'reservations';

    swal({
      title: 'Cancel Reservation',
      text: 'Are you sure want to cancel this reservation?',
      input: 'text',
      inputAttributes: {
        placeholder: 'Type a reason to cancel...',
      },
      showCancelButton: true,
      showLoaderOnConfirm: true,
      type: 'warning',
      cancelButtonText: 'No',
      confirmButtonColor: theme.colors.danger,
      confirmButtonText: 'Yes',
      preConfirm: remarks => {
        dump('Canceling reservation...', item);
        this.props.cancelReservation(
          {id: item.id, remarks, key},
          this.props.onCancel
        );
      },
      allowOutsideClick: () => !canceling,
    });
  };

  handleApprove = item => {
    const key = this.props.listKey || 'reservations';

    swal({
      title: 'Approve Reservation',
      text: 'Are you sure want to approve this reservation?',
      type: 'warning',
      showCancelButton: true,
    }).then(result => {
      if (result.value) {
        const {
          forClusterAdmin,
          approveByClusterAdmin,
          approveByClusterSIC,
          approveReservation,
          onApprove,
        } = this.props;
        if (forClusterAdmin) {
          approveByClusterAdmin(item.id);
        } else if (this.isFromClusterSIC()) {
          approveByClusterSIC(item.id);
        } else {
          approveReservation(item.id, onApprove, key);
        }
      }
    });
  };

  handleReject = item => {
    // swal({
    //   title: 'Reject Reservation',
    //   text: 'Are you sure want to reject this reservation?',
    //   type: 'warning',
    //   showCancelButton: true,
    // }).then(result => {
    //   if (result.value) {
    //     const {
    //       forClusterAdmin,
    //       rejectReservation,
    //       rejectByClusterAdmin,
    //       onReject,
    //     } = this.props;
    //     if (forClusterAdmin) {
    //       rejectByClusterAdmin(item.id, onReject);
    //     } else {
    //       rejectReservation(item.id, onReject);
    //     }
    //   }
    // });

    const {rejecting} = this.props.reservation;
    const key = this.props.listKey || 'reservations';

    swal({
      title: 'Reject Reservation',
      text: 'Are you sure want to reject this reservation?',
      input: 'text',
      inputAttributes: {
        placeholder: 'Type a reason to reject...',
      },
      showCancelButton: true,
      showLoaderOnConfirm: true,
      type: 'warning',
      cancelButtonText: 'No',
      confirmButtonColor: theme.colors.danger,
      confirmButtonText: 'Yes',
      preConfirm: remarks => {
        // this.props.cancelReservation(
        //   {id: item.id, remarks},
        //   this.props.onCancel
        // );
        const {
          forClusterAdmin,
          rejectReservation,
          rejectByClusterAdmin,
          rejectByClusterSIC,
          onReject,
        } = this.props;
        if (forClusterAdmin) {
          rejectByClusterAdmin({id: item.id, remarks, onReject});
        } else if (this.isFromClusterSIC()) {
          rejectByClusterSIC({id: item.id, remarks, onReject});
        } else {
          rejectReservation({id: item.id, remarks, onReject, key});
        }
      },
      allowOutsideClick: () => !rejecting,
    });
  };

  handleRelease = item => {
    const key = this.props.listKey || 'reservations';

    swal({
      title: 'Release Reservation',
      text: 'Are you sure want to release this reservation?',
      type: 'warning',
      showCancelButton: true,
    }).then(result => {
      if (result.value) {
        this.props.releaseReservation(item.id, this.props.onRelease, key);
      }
    });
  };

  handleDone = item => {
    console.log(item.id);
  };

  handleAddGuest = reservation => {
    this.props.setGuestReservationId(reservation.id);
    this.props.openModal('modalAddGuest');
  };

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  getDetailURL = item => {
    const type = this.props.type;

    switch (type) {
      case 'liason-officer':
        return `/reservations/${item.reservation_id}/${type}`;

      case 'approval-request':
      case 'cluster-admin':
      case 'cluster-sic':
      case 'receptionist':
        return `/reservations/${item.id}/${type}`;

      default:
        return `/reservations/${item.id}`;
    }
  };

  getEditURL = item => {
    const type = this.props.type;
    if (type === 'me' && FeatureFlag.USE_RESERVATION_BOOKER_RESCHEDULE) {
      return `/reservations/${item.id}/reschedule`;
    }
    return `/reservations/${item.id}/edit/cluster-admin`;
  };

  getType = () => {
    return this.props.type;
  };

  isFromReceptionist = () => {
    return this.getType() === 'receptionist';
  };

  isFromClusterAdmin = () => {
    return this.props.type === 'cluster-admin';
  };

  isFromClusterSIC = () => {
    return this.props.type === 'cluster-sic';
  };

  canApprove = item => {
    const missed = isReservationMissed(item);

    const allowedApprove = this.isFromClusterSIC()
      ? [RESERVATION_STATUS.WAITING_CLUSTER_ADMIN].includes(item.status)
      : canApprove(item);

    return this.props.canApprove && !missed && allowedApprove;
  };

  canReject = item => {
    const missed = isReservationMissed(item);

    const cancellableStatus = [RESERVATION_STATUS.WAITING_CLUSTER_ADMIN];

    // only allow admin to override explicitly defined approvers
    // other role can only reject/approve only when status is waiting for approval
    if (!this.isFromClusterSIC()) {
      cancellableStatus.push(RESERVATION_STATUS.PENDING);
    }

    return (
      !missed && this.props.canReject && cancellableStatus.includes(item.status)
    );
  };

  canRelease = item => {
    return this.props.canRelease && canReleaseReservation(item);
  };

  canCancel = item => {
    return (
      this.props.canCancel &&
      canCancelReservation(
        item,
        this.props.forClusterAdmin || this.isFromClusterSIC()
      )
    );
  };

  canEdit = reservation => {
    const {canEdit} = this.props;

    // if (isFromClusterAdmin && +status === -1) {
    //   const missed = isReservationMissed(reservation);
    //   return canEdit && !missed;
    // }

    return (
      canEdit && this.isFromClusterAdmin() && canEditReservation(reservation)
    );
  };

  canReschedule = () => {
    const {item: reservation, type} = this.props;

    if (this.isFromClusterSIC()) {
      return false;
    }

    return canRescheduleReservation(reservation);
  };
  render() {
    let {item, forPantry, onGuestAdded} = this.props;

    if (!item) return null;

    const missed = isReservationMissed(item);
    const canApprove = this.canApprove(item);
    const canReject = this.canReject(item);
    const canRelease = this.canRelease(item);
    const canCancel = this.canCancel(item);
    const isFromClusterAdmin = this.isFromClusterAdmin();
    const canEdit = this.canEdit(item);
    const canReschedule =
      FeatureFlag.USE_RESERVATION_BOOKER_RESCHEDULE && this.canReschedule(item);
    const detailURL = this.getDetailURL(item);
    const canEditOrReschedule = isFromClusterAdmin ? canEdit : canReschedule;
    const editTarget = isFromClusterAdmin ? 'blank' : '';
    const isForReceptionist = this.isFromReceptionist();

    return (
      <Fragment>
        <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
          <StyledDropdownToggle size="sm" caret />
          <DropdownMenu right>
            <Link className="dropdown-item" target="_blank" to={detailURL}>
              Details
            </Link>
            {isForReceptionist && (
              <DropdownItem onClick={() => this.handleAddGuest(item)}>
                Add Guest
              </DropdownItem>
            )}
            {!missed && (
              <Fragment>
                {canEditOrReschedule && (
                  <Link
                    target={editTarget}
                    className="dropdown-item"
                    to={this.getEditURL(item)}>
                    {isFromClusterAdmin ? 'Edit' : 'Reschedule'}
                  </Link>
                )}
                {canApprove && (
                  <DropdownItem onClick={() => this.handleApprove(item)}>
                    Approve
                  </DropdownItem>
                )}
                {canReject && (
                  <DropdownItem onClick={() => this.handleReject(item)}>
                    Reject
                  </DropdownItem>
                )}
                {forPantry && (
                  <DropdownItem onClick={() => this.handleDone(item)}>
                    Done
                  </DropdownItem>
                )}
                {canCancel && (
                  <DropdownItem onClick={() => this.handleCancel(item)}>
                    Cancel
                  </DropdownItem>
                )}
                {canRelease && (
                  <DropdownItem onClick={() => this.handleRelease(item)}>
                    Release
                  </DropdownItem>
                )}
              </Fragment>
            )}
          </DropdownMenu>
        </ButtonDropdown>
        <ModalAddGuest onSuccess={onGuestAdded} />
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const {reservation} = state;
  return {
    reservation,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setGuestReservationId: bindActionCreators(setGuestReservationId, dispatch),
    openModal: bindActionCreators(openModal, dispatch),
    closeModal: bindActionCreators(closeModal, dispatch),
    toggleModal: bindActionCreators(toggleModal, dispatch),
    cancelReservation: bindActionCreators(cancelReservationRequest, dispatch),
    approveReservation: bindActionCreators(approveReservationRequest, dispatch),
    releaseReservation: bindActionCreators(releaseReservationRequest, dispatch),
    rejectReservation: bindActionCreators(rejectReservationRequest, dispatch),
    approveByClusterAdmin: bindActionCreators(
      approveByClusterAdminReservationRequest,
      dispatch
    ),
    approveByClusterSIC: bindActionCreators(
      approveByClusterSICReservationRequest,
      dispatch
    ),
    rejectByClusterAdmin: bindActionCreators(
      rejectByClusterAdminReservationRequest,
      dispatch
    ),
    rejectByClusterSIC: bindActionCreators(
      rejectByClusterSICReservationRequest,
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ActionButtons);
