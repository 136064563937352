import {MessageApi} from '../utils/api';

export default {
  needAction(params) {
    params = params || {};
    return MessageApi.get('/v1/message/need/action', {
      params: {
        ...params,
        page: params.page || 1,
        perPage: params.perPage || 10,
      },
    })
      .then(response => (response || {}).data)
      .catch(err => err && (err.response || {}).data);
  },
  noNeedAction(params) {
    params = params || {};
    return MessageApi.get('/v1/message/noneed/action', {
      params: {
        ...params,
        page: params.page || 1,
        perPage: params.perPage || 10,
      },
    })
      .then(response => (response || {}).data)
      .catch(err => err && (err.response || {}).data);
  },
  storeDeviceToken(token, userId) {
    return MessageApi.post(`/v1/fcm/device_token`, {
      device_token: token,
      users_id: userId,
    })
      .then(response => (response || {}).data)
      .catch(err => err && (err.response || {}).data);
  },
  removeDeviceToken(token, userId) {
    return MessageApi.delete(`/v1/fcm/device_token`, {
      data: {
        device_token: token,
        users_id: userId,
      },
    })
      .then(response => (response || {}).data)
      .catch(err => err && (err.response || {}).data);
  },
};
