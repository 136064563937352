export const FCM_TOKEN = 'FCM_TOKEN';

export const getProtocol = () => {
  let protocol = window.location.protocol;
  return protocol.substring(0, protocol.length - 1);
};

export const isLocalhost = () => {
  return window.location.hostname === 'localhost';
};

export function supported() {
  const hasPushManager = 'PushManager' in window;
  const isHttps = getProtocol() === 'https';
  return (hasPushManager && isHttps) || isLocalhost();
}

export function storeFCMTokenLocally(token) {
  localStorage.setItem(FCM_TOKEN, token);
}

export function removeFCMTokenLocally() {
  localStorage.removeItem(FCM_TOKEN);
}

export function getLocalFCMToken() {
  return localStorage.getItem(FCM_TOKEN);
}
