import React, {Component} from 'react';
import {Button, Col, FormGroup} from 'reactstrap';
import {AddButton, RowWorkspace, TextError} from '../../styles';
import {FormLabel} from '../Profile/edit';
import MaterialIcon from '../UIKit/MaterialIcon';
import {Field} from 'redux-form';
import {InputField} from './Fields';
import styled from 'styled-components';

class ParticipantField extends Component {
  render() {
    const {
      fields,
      onRemove,
      onAdd,
      meta: {submitFailed, error},
    } = this.props;

    return (
      <FormGroup>
        <RowWorkspace>
          <Col>
            <FormLabel>Participant</FormLabel>
            <AddButton
              type="button"
              onClick={() => onAdd(fields)}
              color="primary">
              <MaterialIcon icon="plus" />
              {` Add Participant`}
            </AddButton>
          </Col>
          <Col sm={1} xs={1} />
        </RowWorkspace>
        {fields.map((field, index) => (
          <RowWorkspace key={index}>
            <Col sm={6}>
              <Field
                component={InputField}
                name={`${field}.user`}
                autoComplete="off"
                placeholder="Enter participant name..."
                id={`participant${index}`}
              />
            </Col>
            <Col sm={1} xs={1}>
              <RemoveButton
                type="button"
                color="primary"
                size="small"
                onClick={() => onRemove(fields, index)}>
                <MaterialIcon icon="trash" />
              </RemoveButton>
            </Col>
          </RowWorkspace>
        ))}
        {submitFailed && error && <TextError>{error}</TextError>}
      </FormGroup>
    );
  }
}

const RemoveButton = styled(Button)`
  i {
    font-size: 1rem !important;
  }
`;

export default ParticipantField;
