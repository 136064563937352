import {EulaApi} from '../utils/api';

export default {
  /**
   * Get Eula Now.
   *
   * @returns {Promise<AxiosResponse<any>>}
   */
  getEulaNow() {
    return EulaApi.get('/v1/eula')
      .then(response => (response || {}).data)
      .catch(err => err && (err.response || {}).data);
  },
};
