import React, {Component} from 'react';
import {Button as StyledButton, buttonStyles} from '../../styles/index';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

class Button extends Component {
  render() {
    const {props} = this;
    const {to} = props;
    if (to) {
      return <StyledLink {...props} />;
    }
    return <StyledButton {...props} />;
  }
}

export const StyledLink = styled(Link)`
  ${props => buttonStyles(props)};
`;

export default Button;
