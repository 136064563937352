import {takeEvery, put, call} from 'redux-saga/effects';
import {
  SUBMIT_OON_REQUEST,
  SUBMIT_OON_SUCCESS,
  SUBMIT_OON_FAILED,
  LOADING,
  LOADED,
  RELEASE_OON_REQUEST,
  RELEASE_OON_LOADING,
  RELEASE_OON_SUCCESS,
  RELEASE_OON_FAILED,
} from '../actions/actionsList';
import {closeModal} from '../actions/modal';
import {profileRequest} from '../actions/profile';
import {getCurrentUser} from '../utils/helpers';
import OON from '../api/OON';
import config from '../config';
import {setGlobalError} from '../actions/global';

function* submitOonRequestHandler(action) {
  const {payload} = action;

  try {
    yield put({type: LOADING});

    const {data: dataPayload} = payload || {};
    const responseData = yield call(OON.submit, dataPayload);
    const {code, data, message} = responseData || {};

    if (code === 200) {
      yield put({type: LOADED});
      yield put({type: SUBMIT_OON_SUCCESS, payload: {data}});
      yield put(closeModal('modalOON'));

      const userId = getCurrentUser().id;
      yield put(profileRequest(userId));
    } else {
      yield put({type: LOADED});
      yield put(setGlobalError(message));
      yield put({type: SUBMIT_OON_FAILED});
    }
  } catch (error) {
    yield put({type: LOADED});
    yield put(setGlobalError(config.INTERNAL_SERVER_ERROR));
    yield put({type: SUBMIT_OON_FAILED});
  }
}

function* releaseOONRequestHandler(action) {
  const {payload} = action;
  const {id} = payload || {};

  try {
    yield put({type: RELEASE_OON_LOADING});

    const responseData = yield call(OON.release, id);
    const {code, data, message} = responseData || {};

    const userId = getCurrentUser().id;
    if (code === 200) {
      yield put({type: RELEASE_OON_SUCCESS, payload: {data}});
      yield put(closeModal('modalOON'));
      yield put(closeModal('modalReleaseOON'));
      yield put(profileRequest(userId));
    } else {
      yield put({type: RELEASE_OON_FAILED});
      yield put(setGlobalError(message));
    }
  } catch (error) {
    yield put({type: RELEASE_OON_FAILED});
    yield put(setGlobalError(config.INTERNAL_SERVER_ERROR));
  }
}

export default function*() {
  yield takeEvery(SUBMIT_OON_REQUEST, submitOonRequestHandler);
  yield takeEvery(RELEASE_OON_REQUEST, releaseOONRequestHandler);
}
