import {BeverageApi} from '../utils/api';

export default {
  /**
   * Get API Order List Pantry.
   *
   * @param page
   * @param limit
   * @param sortBy
   * @param descending
   * @param search
   * @param status
   * @returns {Promise<*>}
   */
  async getList({limit, page, sortBy, descending, search, status}) {
    page = page || 1;
    limit = limit || 12;
    sortBy = sortBy || 'id';
    descending = descending || false;

    try {
      const {data} =
        (await BeverageApi.get(`/v1/admin/order/pantry`, {
          params: {page, limit, sortBy, descending, search, status},
        })) || {};
      return data;
    } catch (e) {
      return e && (e.response || {}).data;
    }
  },

  /**
   * Get Order Menu by Reservation ID.
   *
   * @param reservationId
   * @returns {Promise<{data: *}>}
   */
  getOrdersByReservationId(reservationId) {
    return BeverageApi.get(`/v1/order/reservation/list/${reservationId}`)
      .then(response => (response || {}).data)
      .catch(err => err && (err.response || {}).data);
  },

  /**
   * Update Order.
   *
   * @param id
   * @param data
   * @returns {Promise<{data: *}>}
   */
  update(id, data) {
    return BeverageApi.put(`/v1/admin/order`, data, {params: {id}})
      .then(response => (response || {}).data)
      .catch(err => err && (err.response || {}).data);
  },

  /**
   * Cancel order.
   *
   * @param id
   * @returns {AxiosPromise<any>}
   */
  cancel(id) {
    return BeverageApi.post(`/v1/admin/order/cancel`, {
      order_id: id,
    })
      .then(response => (response || {}).data)
      .catch(err => err && (err.response || {}).data);
  },
};
