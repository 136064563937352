import {PoiApi} from '../utils/api';

export default {
  /**
   * Find room.
   *
   * @param page
   * @param limit
   * @param perPage
   * @param sortBy
   * @param key
   * @param descending
   * @param reservation_type
   * @returns {AxiosPromise<any>}
   */
  findRoom({
    page = 1,
    limit = 5,
    perPage = 5,
    sortBy = 'id',
    key,
    descending = 'false',
    reservation_type,
  }) {
    return PoiApi.get(`/v1/find/room`, {
      params: {page, limit, perPage, sortBy, key, descending, reservation_type},
    })
      .then(response => (response || {}).data)
      .catch(err => err && (err.response || {}).data);
  },

  /**
   * Get heat map.
   *
   * @returns {Promise<AxiosResponse<any> | never>}
   */
  getHeatMap() {
    return PoiApi.get(`/v2/heatmap`)
      .then(response => (response || {}).data)
      .catch(err => err && (err.response || {}).data);
  },

  /**
   * Get rooms with reservations (for gantt chart).
   *
   * @param date
   * @param floor_id
   * @returns {Promise<AxiosResponse<any> | never>}
   */
  getRoomsWithReservation(date, floor_id, order_col, order_dir) {
    return PoiApi.get(`/v1/poi/reservation`, {
      params: {date, floor_id, sortBy: order_col, descending: order_dir},
    })
      .then(response => (response || {}).data)
      .catch(err => err && (err.response || {}).data);
  },
};
