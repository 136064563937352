import React from 'react';
import {Button} from 'reactstrap';
import MaterialIcon from '../../UIKit/MaterialIcon';

const ShowPasswordToggle = props => {
  const {show, ...btnProps} = props;

  const icon = show ? 'eye-slash' : 'eye';

  return (
    <Button
      color={'link'}
      data-qa={'toggle-show-password'}
      data-pass-visible={show}
      {...btnProps}>
      <MaterialIcon icon={icon} data-qa={`ic-${icon}`} />
    </Button>
  );
};

export default ShowPasswordToggle;
