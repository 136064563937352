import {NewsApi} from '../utils/api';

export default {
  /**
   * Get Home News.
   *
   * @returns {Promise<AxiosResponse<any> | never | never>}
   */
  getHome() {
    return NewsApi.get(`v1/news/home`)
      .then(response => (response || {}).data)
      .catch(err => err && (err.response || {}).data);
  },

  /**
   * Get news detail.
   *
   * @param id
   * @returns {Promise<AxiosResponse<any> | never | never>}
   */
  getDetail(id) {
    return NewsApi.get(`v1/news/detail/${id}`)
      .then(response => (response || {}).data)
      .catch(err => err && (err.response || {}).data);
  },

  /**
   * Get news by category id.
   *
   * @param department_id
   * @param key
   * @param orderBy
   * @param descending
   * @param page
   * @param limit
   * @returns {Promise<AxiosResponse<any>}
   */
  getNews({
    department_id: departement_id,
    key,
    orderBy,
    descending,
    page,
    limit = 10,
    type = '',
  }) {
    return NewsApi.get(`v1/news/all`, {
      params: {departement_id, key, orderBy, descending, page, limit, type},
    })
      .then(response => (response || {}).data)
      .catch(err => err && (err.response || {}).data);
  },

  /**
   * Get external news by category.
   *
   * @param department_id
   * @param page
   * @param limit
   * @returns {Promise<AxiosResponse<any> | never>}
   */
  getHomepageAnnouncement() {
    return NewsApi.get(`v1/news/homepage`)
      .then(response => (response || {}).data)
      .catch(err => err && (err.response || {}).data);
  },

  /**
   * Get external news by category.
   *
   * @param department_id
   * @param page
   * @param limit
   * @returns {Promise<AxiosResponse<any> | never>}
   */
  getExternalNewsByCategory({department_id = '', page = 1, limit = 10}) {
    return NewsApi.get(`v1/news/external/${department_id}`, {
      params: {page, limit},
    })
      .then(response => (response || {}).data)
      .catch(err => err && (err.response || {}).data);
  },

  /**
   * Get dashboard external news.
   *
   * @param page
   * @param limit
   * @param key
   * @returns {Promise<AxiosResponse<any> | never>}
   */
  getExternalNews({page = 1, limit = 10, key = ''}) {
    return NewsApi.get(`v1/news/dashboard/external`, {
      params: {page, limit, key},
    })
      .then(response => (response || {}).data)
      .catch(err => err && (err.response || {}).data);
  },

  /**
   * Get dashboard news.
   *
   * @param type
   * @param page
   * @param limit
   * @param descending
   * @param orderBy
   * @param key
   * @returns {Promise<AxiosResponse<any>}
   */
  getDashboardNews({
    type = 0,
    page = 1,
    limit = 3,
    descending = false,
    orderBy = 'createdAt',
    key = '',
  }) {
    return NewsApi.get(`v1/news/dashboard`, {
      params: {type, page, limit, descending, orderBy, key},
    })
      .then(response => (response || {}).data)
      .catch(err => err && (err.response || {}).data);
  },

  /**
   * Get category list.
   *
   * @returns {Promise<AxiosResponse<any>>}
   */
  getDepartments() {
    return NewsApi.get(`v1/departement`)
      .then(response => (response || {}).data)
      .catch(err => err && (err.response || {}).data);
  },

  /**
   * Get external news categories.
   *
   * @returns {Promise<AxiosResponse<any> | never>}
   */
  getExternalNewsCategories() {
    return NewsApi.get(`v1/news/external/categories`)
      .then(response => (response || {}).data)
      .catch(err => err && (err.response || {}).data);
  },

  /**
   * Get external news detail.
   *
   * @param id
   * @returns {Promise<AxiosResponse<any> | never>}
   */
  getExternalNewsDetail(id) {
    return NewsApi.get(`v1/news/external/detail/${id}`)
      .then(response => (response || {}).data)
      .catch(err => err && (err.response || {}).data);
  },
};
