import {takeEvery, put, call} from 'redux-saga/effects';
import {
  APPEND_NOTIFICATIONS,
  GET_NOTIFICATION_NEED_ACTION,
  GET_NOTIFICATION_NEED_ACTION_FAILED,
  GET_NOTIFICATION_NEED_ACTION_REQUEST,
  GET_NOTIFICATION_NEED_ACTION_SUCCESS,
  GET_NOTIFICATION_NO_NEED_ACTION,
  GET_NOTIFICATION_NO_NEED_ACTION_FAILED,
  GET_NOTIFICATION_NO_NEED_ACTION_REQUEST,
  GET_NOTIFICATION_NO_NEED_ACTION_SUCCESS,
  REMOVE_DEVICE_TOKEN_ACTION,
  REMOVE_DEVICE_TOKEN_FAILED,
  REMOVE_DEVICE_TOKEN_REQUEST,
  REMOVE_DEVICE_TOKEN_SUCCESS,
  SET_NOTIFICATIONS,
  SET_PAGINATION,
  STORE_DEVICE_TOKEN_ACTION,
  STORE_DEVICE_TOKEN_FAILED,
  STORE_DEVICE_TOKEN_REQUEST,
  STORE_DEVICE_TOKEN_SUCCESS,
} from '../actions/actionsList';
import Notification from '../api/Notification';
import config from '../config';
import {removeFCMTokenLocally} from '../utils/firebaseMessaging';
import {setGlobalError} from '../actions/global';

function* getNotificationNeedActionHandler(action) {
  const {payload} = action;

  try {
    const {params} = payload || {};
    const loadMore = params.loadMore;
    yield put({
      type: GET_NOTIFICATION_NEED_ACTION_REQUEST,
      payload: {loadMore},
    });
    const responseData = yield call(Notification.needAction, params);
    const {code, data, message} = responseData || {};
    const {content, pagginate} = data || {};
    if (code === 200) {
      yield put({type: GET_NOTIFICATION_NEED_ACTION_SUCCESS});
      if (loadMore) {
        yield put({type: APPEND_NOTIFICATIONS, payload: {data: content}});
      } else {
        yield put({type: SET_NOTIFICATIONS, payload: {data: content}});
      }
      yield put({type: SET_PAGINATION, payload: {pagination: pagginate}});
    } else {
      yield put({type: GET_NOTIFICATION_NEED_ACTION_FAILED});
      yield put(setGlobalError(message));
    }
  } catch (e) {
    yield put({type: GET_NOTIFICATION_NEED_ACTION_FAILED});
    yield put(setGlobalError(config.INTERNAL_SERVER_ERROR));
  }
}

function* getNotificationNoNeedActionHandler(action) {
  const {payload} = action;

  try {
    const {params} = payload || {};
    const loadMore = params.loadMore;
    yield put({
      type: GET_NOTIFICATION_NO_NEED_ACTION_REQUEST,
      payload: {loadMore},
    });
    const responseData = yield call(Notification.noNeedAction, params);
    const {code, data, message} = responseData || {};
    const {content, pagginate} = data || {};
    if (code === 200) {
      yield put({type: GET_NOTIFICATION_NO_NEED_ACTION_SUCCESS});
      if (loadMore) {
        yield put({type: APPEND_NOTIFICATIONS, payload: {data: content}});
      } else {
        yield put({type: SET_NOTIFICATIONS, payload: {data: content}});
      }
      yield put({type: SET_PAGINATION, payload: {pagination: pagginate}});
    } else {
      yield put({type: GET_NOTIFICATION_NO_NEED_ACTION_FAILED});
      yield put(setGlobalError(message));
    }
  } catch (e) {
    yield put({type: GET_NOTIFICATION_NO_NEED_ACTION_FAILED});
    yield put(setGlobalError(config.INTERNAL_SERVER_ERROR));
  }
}

function* storeDeviceTokenActionHandler(action) {
  const {payload} = action;

  yield put({type: STORE_DEVICE_TOKEN_REQUEST});
  const {token, userId} = payload || {};
  const responseData = yield call(Notification.storeDeviceToken, token, userId);
  const {code, data, message} = responseData || {};
  if (code === 200) {
    yield put({type: STORE_DEVICE_TOKEN_SUCCESS, payload: {data}});
  } else {
    yield put({type: STORE_DEVICE_TOKEN_FAILED});
    yield put(setGlobalError(message));
  }
}

function* removeDeviceTokenActionHandler(action) {
  const {payload} = action;

  yield put({type: REMOVE_DEVICE_TOKEN_REQUEST});
  const {token, userId} = payload || {};
  const responseData = yield call(
    Notification.removeDeviceToken,
    token,
    userId
  );
  const {code, data, message} = responseData || {};
  if (code === 200) {
    removeFCMTokenLocally();
    yield put({type: REMOVE_DEVICE_TOKEN_SUCCESS, payload: {data}});
  } else {
    yield put({type: REMOVE_DEVICE_TOKEN_FAILED});
    yield put(setGlobalError(message));
  }
}

/**
 * Reservation Saga.
 *
 * @returns {IterableIterator<*|ForkEffect>}
 */
export default function*() {
  yield takeEvery(
    GET_NOTIFICATION_NEED_ACTION,
    getNotificationNeedActionHandler
  );
  yield takeEvery(
    GET_NOTIFICATION_NO_NEED_ACTION,
    getNotificationNoNeedActionHandler
  );
  yield takeEvery(STORE_DEVICE_TOKEN_ACTION, storeDeviceTokenActionHandler);
  yield takeEvery(REMOVE_DEVICE_TOKEN_ACTION, removeDeviceTokenActionHandler);
}
