import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import {Card, CardBody, Row, Col} from 'reactstrap';
import {PageTitle, SubHeading} from '../../../styles/typography';
import {getUserMonitoringStat} from '../../../actions/user';
import {media} from '../../../styles/grid';

function MonitorUserInOffice(props) {
  // store
  const dispatch = useDispatch();
  const {statOfficeCapacity, statReserveQuota} = useSelector(
    store => store.user
  );

  const {totalPeople: peopleDetected} = statOfficeCapacity;
  const {totalReserve, totalCheckin, reserveQuota} = statReserveQuota;

  const showReservation = false;
  const showCheckIn = false;

  // effects
  useEffect(() => {
    dispatch(getUserMonitoringStat());
  }, []);

  // render
  return (
    <section {...props}>
      <SectionTitle as={'h1'}>Office Capacity Monitoring</SectionTitle>
      <Card style={{border: 0, borderRadius: '0.75em'}}>
        <CardBody style={{padding: '0 2.25rem'}}>
          <Row>
            <Col
              xs={12}
              lg={6}
              style={{marginTop: '1rem', marginBottom: '1rem'}}>
              <Row data-qa={'monitor-people'}>
                <StatIcon data-qa={'monitor-people-img'}>
                  <img
                    src={'/assets/images/account-circle-fill.png'}
                    style={{width: '100%', height: '100%'}}
                  />
                </StatIcon>
                <StatContentWrapper>
                  <StatContent>
                    <StatCell>
                      <StatLabel>Detected People</StatLabel>
                      <StatValue
                        style={{textAlign: 'right'}}
                        data-qa={'detected-people-value'}>
                        {peopleDetected || peopleDetected === 0
                          ? peopleDetected
                          : '-'}
                      </StatValue>
                    </StatCell>
                  </StatContent>
                </StatContentWrapper>
              </Row>
            </Col>

            <Col
              xs={12}
              lg={6}
              style={{marginTop: '1rem', marginBottom: '1rem'}}>
              <Row
                className={'justify-content-md-end'}
                data-qa={'monitor-reservation'}>
                <StatIcon data-qa={'monitor-reservation-img'}>
                  <img
                    src={'/assets/images/building.png'}
                    style={{width: '100%', maxWidth: '48px', height: '100%'}}
                  />
                </StatIcon>
                <StatContentWrapper>
                  <StatContentReserve>
                    {showReservation && (
                      <StatCell>
                        <StatLabel>Today's Reservation</StatLabel>
                        <StatValue data-qa={'total-reservation-value'}>
                          {totalReserve || totalReserve === 0
                            ? totalReserve
                            : '-'}
                        </StatValue>
                      </StatCell>
                    )}

                    {showCheckIn && (
                      <StatCell>
                        <StatLabel>Today's Check In</StatLabel>
                        <StatValue data-qa={'total-checkin-value'}>
                          {totalCheckin || totalCheckin === 0
                            ? totalCheckin
                            : '-'}
                        </StatValue>
                      </StatCell>
                    )}

                    <StatCell>
                      <StatLabel>Total Quota</StatLabel>
                      <StatValue data-qa={'total-quota-value'}>
                        {reserveQuota || reserveQuota === 0
                          ? reserveQuota
                          : '-'}
                      </StatValue>
                    </StatCell>
                  </StatContentReserve>
                </StatContentWrapper>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </section>
  );
}

MonitorUserInOffice.propTypes = {};

export default MonitorUserInOffice;

export const SectionTitle = styled(SubHeading)`
  color: #d64541 !important;
  font-weight: bold;
  padding: 0.5rem 0.75rem;
  margin-top: 0.75rem;
`;

export const StatIcon = styled.div`
  align-self: center;
  padding: 0;
  width: 3rem;
`;

const respStatContent = {
  xs: media.xs`
    display: block;
    
    > div {
      display: block;
      text-align: right;
      
      &:nth-child(2n) {
        display: none;     
      }
   `,
};
export const StatContent = styled.div`
  display: table-row;
  padding: 0 1rem 0 0.5rem;
`;
export const StatContentReserve = styled(StatContent)`
  ${respStatContent.xs}
`;

const respStatContentWrapper = {
  xs: media.xs`
    flex: 1;
    display: flex;
    justify-content: flex-end;
  `,
  sm: media.sm`
    flex: 1;
    display: flex;
    justify-content: flex-end;
  `,
  lg: media.lg`
    flex: initial;
    display: flex;
    justify-content: flex-end;
  `,
};
export const StatContentWrapper = styled.div`
  ${respStatContentWrapper.xs}
  ${respStatContentWrapper.sm}
  ${respStatContentWrapper.lg}
`;

export const StatCell = styled.div`
  display: table-cell;
  text-align: center;
  vertical-align: bottom;
  padding: 0 0.5rem;
`;

const respStatCellGap = {
  xs: media.xs`
  `,
};
export const StatCellGap = styled(StatCell)`
  padding: 0 0.5rem;

  ${respStatCellGap.xs}
`;

export const StatLabel = styled(SubHeading)`
  font-size: 13px;
`;

export const StatValue = styled(PageTitle)`
  color: #28a745 !important;
  font-size: 2.5rem;
  margin: 0;
`;
