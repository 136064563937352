import {
  OPEN_MODAL,
  CLOSE_MODAL,
  TOGGLE_MODAL,
  UPDATE_MODAL,
  AUTH_OUT_SUCCESS,
} from '../actions/actionsList';

let initialState = {
  modalOON: false,
  editProfile: false,
  bookReservationRoom: false,
  bookingSuccess: false,
  searchMeetingRoom: false,
  beverageOrder: false,
  reservationConfirmation: false,
  beverageOrderSuccess: false,
  modalReleaseOON: false,
  detailUser: false, // detail user/people
  modalHeatMap: false,
  modalAbout: false,
  changePassword: {open: false, config: {}},
  forgotPassword: {open: false, loading: '', config: {}},
  modalAddGuest: false,
  modalInfoNoWorkspace: false,
  modalMapInfo: false,
  success: {open: false, config: {}},
};

export default function(state = initialState, action) {
  const {type, payload} = action;
  const {name, config, ...modalState} = payload || {};

  const configurableModal = ['changePassword', 'forgotPassword', 'success'];
  const isConfigurable = name && configurableModal.find(e => e === name);

  switch (type) {
    case AUTH_OUT_SUCCESS: {
      return {
        ...initialState,
      };
    }

    case OPEN_MODAL: {
      if (isConfigurable) return {...state, [name]: {open: true, config}};
      else return {...state, [name]: true};
    }

    case CLOSE_MODAL: {
      if (isConfigurable) return {...state, [name]: {open: false, config}};
      else return {...state, [name]: false};
    }

    case TOGGLE_MODAL: {
      const modal = state[name];
      if (isConfigurable)
        return {...state, [name]: {open: !modal.open, config}};
      else return {...state, [name]: !modal};
    }

    case UPDATE_MODAL: {
      const modal = state[name];
      if (isConfigurable) return {...state, [name]: {...modal, ...modalState}};
      else return state;
    }

    default:
      return state;
  }
}
