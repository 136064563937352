import {takeEvery, takeLatest, put, call} from 'redux-saga/effects';
import {
  SEARCH_USERS_REQUEST,
  SET_USERS,
  SET_USER,
  SET_PAGINATION,
  CLEAR_USER_REQUEST,
  SET_LAST_SEARCH,
  CLEAR_RECENT_SEARCH,
  LOAD_RECENT_SEARCH,
  EMPTY_USER_DATA_REQUEST,
  ADD_ADDITIONAL_EDITOR_REQUEST,
  REMOVE_ADDITIONAL_EDITOR_REQUEST,
  REMOVE_ADDITIONAL_EDITOR_FAILED,
  ADDITIONAL_EDITOR_REMOVED,
  REMOVING_ADDITIONAL_EDITOR,
  ADDING_ADDITIONAL_EDITOR,
  ADD_ADDITIONAL_EDITOR_SUCCESS,
  ADD_ADDITIONAL_EDITOR_FAILED,
  CHANGE_PASSWORD_ACTION,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILED,
  REMOVE_CONTACT_PERSON,
  REMOVE_CONTACT_PERSON_REQUEST,
  REMOVE_CONTACT_PERSON_SUCCEED,
  REMOVE_CONTACT_PERSON_FAILED,
  ADD_CONTACT_PERSON,
  ADD_CONTACT_PERSON_REQUEST,
  ADD_CONTACT_PERSON_SUCCEED,
  ADD_CONTACT_PERSON_FAILED,
  SET_USER_SEARCH_RESULT,
  SEARCH_USERS_LITE_ACTION,
  SEARCH_USERS_LITE_REQUEST,
  SEARCH_USERS_LITE_SUCCEED,
  CLEAR_USER_SEARCH_RESULT,
  SEARCH_USERS_FAILED,
  SEARCH_USERS_SUCCEED,
  SEARCH_USERS_ACTION,
  GET_USER_DETAIL_ACTION,
  GET_USER_DETAIL_SUCCEED,
  GET_USER_DETAIL_FAILED,
  GET_USER_DETAIL_REQUEST,
  GET_STAT_USER_MONITORING,
  SET_STAT_OFFICE_CAPACITY,
  SET_STAT_RESERVE_QUOTA,
  VALIDATE_AUTH,
} from '../actions/actionsList';
import RecentSearch from '../utils/RecentSearch';
import User from '../api/User';
import {closeModal} from '../actions/modal';
import {toast} from '../utils/helpers';
import config from '../config';
import Reservation from '../api/Reservation';
import {setGlobalError} from '../actions/global';

function* searchUserRequestHandler(action) {
  const {payload} = action;

  try {
    const {keyword, page, perPage, except, sortBy, isTypeahead} = payload || {};
    yield put({type: SEARCH_USERS_REQUEST});
    yield put({type: SET_LAST_SEARCH, payload: {lastSearch: keyword}});

    const responseData = yield call(User.search, {
      keyword,
      page,
      perPage,
      except,
      sortBy,
    });
    const {code, data, message} = responseData || {};

    if (code === 200) {
      const {users, pagginate: pagination} = data;
      yield put({type: SEARCH_USERS_SUCCEED, payload: {users}});
      if (isTypeahead) {
        yield put({type: SET_USER_SEARCH_RESULT, payload: {users}});
      } else {
        yield put({type: SET_USERS, payload: {users}});
      }
      yield put({type: SET_PAGINATION, payload: {pagination}});
    } else {
      yield put(setGlobalError(message));
      yield put({type: SEARCH_USERS_FAILED});
    }
  } catch (error) {
    yield put(setGlobalError(config.INTERNAL_SERVER_ERROR));
    yield put({type: SEARCH_USERS_FAILED});
  }
}

function* clearUserRequestHandler() {
  yield put({type: SET_USER, payload: {user: null}});
}

function* clearRecentSearchHandler() {
  RecentSearch.clear();
  yield put({type: SET_USERS, payload: {users: []}});
  yield put({type: SET_PAGINATION, payload: {pagination: {}}});
}

function* emptyUserDataRequestHandler() {
  yield put({type: SET_USERS, payload: {users: []}});
  yield put({type: SET_PAGINATION, payload: {pagination: {}}});
}

function* loadRecentSearchHandler() {
  const users = RecentSearch.getSorted().map(item => {
    item.recentSearch = true;
    return item;
  });
  yield put({type: SET_USERS, payload: {users}});
}

function* detailUserRequestHandler(action) {
  const {payload} = action;
  const {id} = payload || {};

  try {
    yield put({type: GET_USER_DETAIL_REQUEST});

    const responseData = yield call(User.find, id);
    const {code, data: user, message} = responseData || {};

    if (code === 200) {
      yield put({type: GET_USER_DETAIL_SUCCEED});
      yield put({type: SET_USER, payload: {user}});
    } else {
      yield put({type: GET_USER_DETAIL_FAILED});
      yield put(setGlobalError(message));
    }
  } catch (error) {
    yield put({type: GET_USER_DETAIL_FAILED});
    yield put(setGlobalError(config.INTERNAL_SERVER_ERROR));
  }
}

function* removeAdditionalEditorRequestHandler(action) {
  const {payload} = action;

  try {
    const {additionalEditorId: id, profileUserId} = payload || {};
    yield put({type: REMOVING_ADDITIONAL_EDITOR, payload: {id}});
    const responseData = yield call(
      User.removeAdditionalEditor,
      id,
      profileUserId
    );
    const {code, data, message} = responseData || {};
    if (code === 200) {
      yield put({type: ADDITIONAL_EDITOR_REMOVED, payload: {id, data}});
    } else {
      yield put({type: REMOVE_ADDITIONAL_EDITOR_FAILED, payload: {message}});
    }
  } catch (error) {
    yield put(setGlobalError(config.INTERNAL_SERVER_ERROR));
    yield put({type: REMOVE_ADDITIONAL_EDITOR_FAILED});
  }
}

function* addAdditionalEditorRequestHandler(action) {
  const {payload} = action;

  try {
    const {additionalEditor: user, profileUserId} = payload || {};
    const {id} = user || {};
    yield put({type: ADDING_ADDITIONAL_EDITOR, payload: {id}});
    const responseData = yield call(
      User.addAdditionalEditor,
      id,
      profileUserId
    );
    const {code, message} = responseData || {};
    if (code === 200) {
      yield put({
        type: ADD_ADDITIONAL_EDITOR_SUCCESS,
        payload: {id, data: user},
      });
      yield put({
        type: CLEAR_USER_SEARCH_RESULT,
        payload: {},
      });
    } else {
      yield put({type: ADD_ADDITIONAL_EDITOR_FAILED, payload: {message}});
    }
  } catch (error) {
    yield put(setGlobalError(config.INTERNAL_SERVER_ERROR));
    yield put({type: ADD_ADDITIONAL_EDITOR_FAILED});
  }
}

function* changePasswordActionHandler(action) {
  const {payload} = action;

  const {data: formData} = payload || {};
  yield put({type: CHANGE_PASSWORD_REQUEST});

  try {
    const responseData = yield call(User.changePassword, formData);
    const {code, data, message} = responseData || {};

    if (code === 200) {
      yield put({
        type: CHANGE_PASSWORD_SUCCESS,
        payload: {data},
      });

      // revalidate token to make sure user data is up-to-date
      yield put({type: VALIDATE_AUTH, payload: {isSilent: true}});

      toast({title: message, type: 'success'});
      yield put(closeModal('changePassword'));
    } else {
      yield put({type: CHANGE_PASSWORD_FAILED, payload: {message}});
      yield put(setGlobalError(message));
    }
  } catch (err) {
    yield put({type: CHANGE_PASSWORD_FAILED, payload: {message: err?.message}});
    yield put(setGlobalError(err?.message));
  }
}

function* addContactPersonHandler(action) {
  const {payload} = action;
  const {currentUserId, contactPersonUser} = payload || {};
  yield put({
    type: ADD_CONTACT_PERSON_REQUEST,
    payload: {currentUserId, contactPersonUser},
  });
  const responseData = yield call(
    User.addContactPerson,
    currentUserId,
    contactPersonUser.id
  );
  const {
    code,
    data: {created: data},
    message,
  } = responseData || {};
  if (code === 200) {
    data.user = contactPersonUser;
    yield put({
      type: ADD_CONTACT_PERSON_SUCCEED,
      payload: {data},
    });
  } else {
    yield put({type: ADD_CONTACT_PERSON_FAILED, payload: {message}});
    toast({title: message, type: 'error', position: 'top'});
  }
}

function* removeContactPersonHandler(action) {
  const {payload} = action;

  const {contactPersonId, userId} = payload || {};
  yield put({
    type: REMOVE_CONTACT_PERSON_REQUEST,
    payload: {contactPersonId, userId},
  });
  const responseData = yield call(
    User.removeContactPerson,
    contactPersonId,
    userId
  );
  const {code, message} = responseData || {};
  if (code === 200) {
    yield put({
      type: REMOVE_CONTACT_PERSON_SUCCEED,
      payload: {contactPersonId, userId},
    });
  } else {
    yield put({type: REMOVE_CONTACT_PERSON_FAILED, payload: {message}});
    toast({title: message, type: 'error', position: 'top'});
  }
}

function* searchUsersLiteHandler(action) {
  const {payload} = action;

  yield put({type: SEARCH_USERS_LITE_REQUEST});

  const {sortBy, descending, limit, page, key, except, attributes} =
    payload || {};

  const response = yield call(User.liteSearch, {
    sortBy,
    descending,
    limit,
    page,
    key,
    except,
    attributes,
  });

  const {code, data, message} = response || {};

  if (code === 200) {
    const {users} = data || {};
    yield put({type: SEARCH_USERS_LITE_SUCCEED, payload: {users}});
  } else {
    yield put(setGlobalError(message));
  }
}

function* getStatUserMonitoring() {
  const responseData = yield call(Reservation.getUserMonitoringStat);
  const {data} = responseData || {};
  const {mappedHeatMap, todayWfoQuota} = data || {};
  const {quota, total_reservation, total_checkin} = todayWfoQuota || {};
  const {detected_people, capacity} = mappedHeatMap || {};

  yield put({
    type: SET_STAT_RESERVE_QUOTA,
    payload: {
      totalReserve: total_reservation,
      totalCheckin: total_checkin,
      reserveQuota: quota,
    },
  });

  yield put({
    type: SET_STAT_OFFICE_CAPACITY,
    payload: {
      totalPeople: detected_people,
      officeCapacity: capacity,
    },
  });
}

export default function*() {
  yield takeEvery(SEARCH_USERS_ACTION, searchUserRequestHandler);
  yield takeEvery(GET_USER_DETAIL_ACTION, detailUserRequestHandler);
  yield takeEvery(CLEAR_USER_REQUEST, clearUserRequestHandler);
  yield takeEvery(CLEAR_RECENT_SEARCH, clearRecentSearchHandler);
  yield takeEvery(LOAD_RECENT_SEARCH, loadRecentSearchHandler);
  yield takeEvery(EMPTY_USER_DATA_REQUEST, emptyUserDataRequestHandler);
  yield takeEvery(
    REMOVE_ADDITIONAL_EDITOR_REQUEST,
    removeAdditionalEditorRequestHandler
  );
  yield takeEvery(
    ADD_ADDITIONAL_EDITOR_REQUEST,
    addAdditionalEditorRequestHandler
  );
  yield takeEvery(CHANGE_PASSWORD_ACTION, changePasswordActionHandler);
  yield takeEvery(REMOVE_CONTACT_PERSON, removeContactPersonHandler);
  yield takeEvery(ADD_CONTACT_PERSON, addContactPersonHandler);
  yield takeEvery(SEARCH_USERS_LITE_ACTION, searchUsersLiteHandler);
  yield takeLatest(GET_STAT_USER_MONITORING, getStatUserMonitoring);
}
