import {
  RELEASE_OON_REQUEST,
  SUBMIT_OON_REQUEST,
  SUBMIT_OON_SUCCESS,
} from './actionsList';

export const submitOonRequest = data => {
  return {type: SUBMIT_OON_REQUEST, payload: {data}};
};

export const setOON = data => {
  return {type: SUBMIT_OON_SUCCESS, payload: {data}};
};

export const releaseOONRequest = id => {
  return {type: RELEASE_OON_REQUEST, payload: {id}};
};
