import React, {Component} from 'react';
import ContactList from '../ContactList';
import {Row} from '../../styles/grid';
import styled from 'styled-components';

class ContactProfileBadge extends Component {
  render() {
    const {profileData} = this.props;

    return (
      <ContactWithProfileBadgeBox>
        <ContactList withHiris withOon profileData={profileData} />
      </ContactWithProfileBadgeBox>
    );
  }
}

const ContactWithProfileBadgeBox = styled(Row)``;

export default ContactProfileBadge;
