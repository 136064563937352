import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

class MaterialIcon extends Component {
  render() {
    const {icon, size, color, material, ...rest} = this.props;
    let type = this.props.type || 'fas';
    if (material) {
      type = 'material';
    }

    let className;
    if (type === 'fas') {
      className = `${type} fa-${icon} fa-${size}x`;
    } else if (type === 'material') {
      className = 'material-icons';
    }

    if (material) {
      return (
        <StyledIcon color={color} className={className} {...rest}>
          {icon}
        </StyledIcon>
      );
    }

    return <StyledIcon color={color} className={className} {...rest} />;
  }
}

const StyledIcon = styled.i`
  color: ${props => props.theme.colors[props.color]};
`;

MaterialIcon.defaultProps = {
  icon: '',
  size: 1,
};

MaterialIcon.propTypes = {
  icon: PropTypes.string,
  size: PropTypes.number,
};

export const SpinnerIcon = props => {
  const {type, className, pulse, show, indeterminate, noMargin, size} = props;
  const spinType = pulse ? 'fa-pulse' : 'fa-spin';
  const margin = noMargin ? '' : 'mr-1';
  const spinnerSize = `fa-${size}x`;
  const iconName = `${type} ${spinType} ${spinnerSize} ${className} ${margin}`;
  const isShow = show || indeterminate;

  return isShow ? <MaterialIcon icon={iconName} /> : null;
};

SpinnerIcon.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  pulse: PropTypes.bool,
  show: PropTypes.bool,
  indeterminate: PropTypes.bool,
  noMargin: PropTypes.bool,
  size: PropTypes.number,
  material: PropTypes.bool,
};

SpinnerIcon.defaultProps = {
  type: 'spinner',
  className: '',
  pulse: false,
  show: false,
  indeterminate: false,
  noMargin: false,
  size: 1,
  material: false,
};

export default MaterialIcon;
