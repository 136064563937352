import {
  takeEvery,
  takeLatest,
  takeLeading,
  put,
  call,
} from 'redux-saga/effects';
import {
  AUTH_IN_REQUEST,
  AUTH_IN_FAIL,
  AUTH_IN_SUCCESS,
  AUTH_OUT_REQUEST,
  AUTH_OUT_SUCCESS,
  SET_USER_DATA,
  AUTHENTICATING,
  AUTHENTICATED,
  FORGOT_PASSWORD_ACTION,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILED,
  FORGOT_PASSWORD_DONE,
  VALIDATE_AUTH,
  VALIDATE_AUTH_REQUEST,
  VALIDATE_AUTH_SUCCESS,
  VALIDATE_AUTH_FAILED,
  OAUTH_CALLBACK,
  VERIFIED_OTP_REQ,
  VERIFIED_OTP,
  FORGOT_PASSWORD_MODAL_ACTION,
  UPDATE_MODAL,
} from '../actions/actionsList';
import {setAccessToken} from '../utils/api';
import User from '../api/User';
import {removeDeviceTokenAction} from '../actions/notification';
import {getLocalFCMToken} from '../utils/firebaseMessaging';
import {getCurrentUser} from '../utils/helpers';
import config from '../config';
import {
  getAccessToken,
  getLoginToken,
  removeAccessToken,
  removeLoginToken,
  removeUserData,
  storeAccessToken,
  storeLoginToken,
  storeUserData,
} from '../utils/TokenStorage';
import RecentSearch from '../utils/RecentSearch';
import {FeatureFlag} from '../config/constants';

export function* cleanData() {
  const user = getCurrentUser();
  const token = getLocalFCMToken();
  if (token) {
    yield put(removeDeviceTokenAction(token, user ? user.id : null));
  }

  removeAccessToken();
  removeUserData();
  RecentSearch.clear();

  // yield put({type: RESET_REDUX_STORE});
  yield put({type: AUTH_OUT_SUCCESS});
}

export function* setUserData(action) {
  const {payload} = action;
  const {userData} = payload || {};
  yield put({type: SET_USER_DATA, payload: {userData}});
}

function* processLoginSuccess(data) {
  const {token} = data || {};
  const res = yield call(User.me, {
    headers: {
      accessToken: token,
      user_token: token,
    },
  });
  const {roles, functions} = res.data || {};
  let userData = {
    ...res.data,
    auth: {
      roles,
      functions,
    },
  };
  yield put({type: AUTH_IN_SUCCESS, payload: {token, userData}});

  setAccessToken(token);
  storeAccessToken(token);
  storeUserData(userData);

  if (FeatureFlag.USE_OTP_VERIFICATION) {
    removeLoginToken();
  }

  yield put({type: AUTHENTICATED});
}

function* handleLoginWOTP(loginResponse, history) {
  const {code, data, message} = loginResponse || {};

  if (code !== 200) {
    const defMsg =
      'Something wrong happened while trying to log you in. Try again or contact us if this keep happening.';
    yield put({type: AUTHENTICATED});
    yield put({
      type: AUTH_IN_FAIL,
      payload: {
        message: message || defMsg,
      },
    });
  } else {
    const {token} = data || {};

    // store the temporary token from login
    // to authorize the following otp request
    setAccessToken(token);
    storeLoginToken(token);

    // navigate to otp page
    if (history) {
      history.push('/otp');
    } else {
      window.location.href = '/otp';
    }
  }
}

function* requestLogin(action) {
  const {payload} = action;
  const {credentials, history} = payload || {};

  // try {
  yield put({type: AUTHENTICATING});

  const responseData = yield call(User.login, credentials);
  const {code, data} = responseData || {};

  if (FeatureFlag.USE_OTP_VERIFICATION) {
    yield call(handleLoginWOTP, responseData, history);
  } else {
    if (code === 200) {
      yield call(processLoginSuccess, data);
    } else {
      const message =
        responseData.message ||
        'Your email or password is incorrect. If you forget your password, please contact GTS. ';
      yield put({type: AUTHENTICATED});
      yield put({
        type: AUTH_IN_FAIL,
        payload: {
          message,
        },
      });
    }
    // } catch (error) {
    //   const message = config.INTERNAL_SERVER_ERROR;
    //   yield put({
    //     type: VALIDATE_AUTH_FAILED,
    //     payload: {error: message},
    //   });
    //   yield put({type: AUTHENTICATED});
    //   yield put({
    //     type: AUTH_IN_FAIL,
    //     payload: {
    //       message,
    //     },
    //   });
    // }
  }
}

function* forgotPasswordActionHandler(action) {
  const {payload} = action;
  try {
    yield put({type: FORGOT_PASSWORD_REQUEST});

    const responseData = yield call(User.forgotPassword, payload.email);
    const {code, data, message} = responseData || {};

    if (code === 200) {
      yield put({type: FORGOT_PASSWORD_SUCCESS, payload: {message, data}});
      yield put({type: FORGOT_PASSWORD_DONE});
    } else {
      yield put({type: FORGOT_PASSWORD_FAILED, payload: {message}});
    }
  } catch (error) {
    yield put({type: FORGOT_PASSWORD_FAILED});
    yield put({
      type: AUTH_IN_FAIL,
      payload: {message: config.INTERNAL_SERVER_ERROR},
    });
  }
}

function* forgotPasswordModalActionHandler(action) {
  const {payload} = action;

  try {
    yield put({
      type: UPDATE_MODAL,
      payload: {name: 'forgotPassword', loading: 'loading'},
    });

    const responseData = yield call(User.forgotPassword, payload.email);
    const {code, data, message} = responseData || {};

    console.log('modalforgotpasssaga', {responseData});

    if (code === 200) {
      yield put({
        type: UPDATE_MODAL,
        payload: {name: 'forgotPassword', loading: 'success', message, data},
      });
    } else {
      yield put({
        type: UPDATE_MODAL,
        payload: {name: 'forgotPassword', loading: 'failed', message},
      });
    }
  } catch (error) {
    yield put({
      type: UPDATE_MODAL,
      payload: {name: 'forgotPassword', loading: 'failed'},
    });
  }
}

function* validateAuthRequestHandler(action) {
  try {
    const {payload} = action;

    let {token, isSilent} = payload || {};

    if (!isSilent) {
      yield put({type: VALIDATE_AUTH_REQUEST});
    }

    token = token || getAccessToken();

    setAccessToken(token);

    const responseData = yield call(User.validateToken, token);
    const {code, data, message} = responseData || {};

    if (code === 200) {
      let userData = data || {};
      const {roles, functions, ...rest} = data || {};
      userData.auth = {
        roles,
        functions,
      };
      userData = {...userData, ...rest};

      yield put({type: VALIDATE_AUTH_SUCCESS, payload: {userData}});
      storeUserData(userData);

      const {status, id} = userData;
      const currentUser = getCurrentUser();

      if (!currentUser) return;

      if (status === 'inactive' && id === currentUser.id) {
        yield put(cleanData());
        yield put({
          type: AUTH_IN_FAIL,
          payload: {
            message: 'Your profile is inactive. Please contact administrator.',
          },
        });
        removeAccessToken();
        removeUserData();
      }
    } else {
      yield put({type: VALIDATE_AUTH_FAILED, payload: {error: message}});
      removeAccessToken();
      removeUserData();
    }
  } catch (error) {
    yield put({
      type: VALIDATE_AUTH_FAILED,
      payload: {error: config.INTERNAL_SERVER_ERROR},
    });
    removeAccessToken();
    removeUserData();
  }
}

function* oauthCallbackHandler(action) {
  const {payload} = action;

  // yield put({type: AUTHENTICATING});

  setAccessToken(payload);
  storeAccessToken(payload);

  yield put({type: VALIDATE_AUTH, payload: {token: payload}});
  // yield put({type: OAUTH_SUCCESS, payload: {token: payload}});
  // yield put({type: AUTHENTICATED});
}

function* verifyOTP(action) {
  const {payload} = action;
  const {code: otpCode} = payload;

  const token = getLoginToken();

  const responseData = yield call(
    User.verifyOTP,
    {
      code: otpCode,
    },
    {
      headers: {
        accessToken: token,
        user_token: token,
      },
    }
  );
  const {code, data, error} = responseData || {};

  if (error || code !== 200) {
    const message = error.message || config.INTERNAL_SERVER_ERROR;

    yield put({
      type: VERIFIED_OTP,
      payload: {
        result: false,
        error: message,
      },
    });
  } else {
    yield call(processLoginSuccess, data);
    yield put({type: VERIFIED_OTP, payload: {result: true}});
  }

  return true;
}

export default function*() {
  yield takeEvery(AUTH_IN_REQUEST, requestLogin);
  yield takeLeading(AUTH_OUT_REQUEST, cleanData);
  yield takeEvery(FORGOT_PASSWORD_ACTION, forgotPasswordActionHandler);
  yield takeEvery(
    FORGOT_PASSWORD_MODAL_ACTION,
    forgotPasswordModalActionHandler
  );
  yield takeEvery(VALIDATE_AUTH, validateAuthRequestHandler);
  yield takeEvery(OAUTH_CALLBACK, oauthCallbackHandler);

  if (FeatureFlag.USE_OTP_VERIFICATION) {
    yield takeLatest(VERIFIED_OTP_REQ, verifyOTP);
  }
}
