import React, {useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import Logo from '../Logo';
import {
  AppHeader,
  Container,
  IconNav,
  LogoSection,
  MainNav,
  TopNav,
} from '../../styles';
import styled from 'styled-components';
import {openModal, toggleModal} from '../../actions/modal';
import {authOutRequest} from '../../actions/auth';
import {Col, media, Row} from '../../styles/grid';
import {getCurrentUser, getCurrentUserId, imageURL} from '../../utils/helpers';
import {editProfileAction} from '../../actions/profile';
import MainNavbar from './MainNavbar';
import {
  DropdownToggle,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import {FeatureFlag} from '../../config/constants';
import swal from 'sweetalert2';

const Navigation = () => {
  // store
  const dispatch = useDispatch();
  const {userData, passwordChanged, changingPassword} = useSelector(store => {
    const userData = store.auth?.userData;
    const {passwordChanged, changingPassword} = store?.user || {};
    return {
      userData,
      passwordChanged,
      changingPassword,
    };
  });

  // ref
  const forceNewPassword = useRef();

  // state
  const [userId, setUserId] = useState(null);

  // effects
  useEffect(() => {
    setUserId(getCurrentUserId(userData));
    triggerPassNeedChange();
  }, [userData]);

  const onSignOut = () => {
    dispatch(authOutRequest());
  };
  const editProfile = () => {
    const user = getCurrentUser();

    dispatch(openModal('editProfile'));

    if (user) {
      dispatch(editProfileAction(getCurrentUserId()));
    }
  };
  const handleAboutClicked = event => {
    event.preventDefault();
    dispatch(toggleModal('modalAbout'));
  };
  const handleChangePasswordClicked = event => {
    event && event.preventDefault();
    dispatch(
      toggleModal(
        'changePassword',
        forceNewPassword.current
          ? {
              main: {backdrop: 'static'},
              header: {closeButton: false, toggle: null},
            }
          : null
      )
    );
  };
  const triggerPassNeedChange = () => {
    const {is_need_pw_change} = userData || {};

    // check if there is already alert visible and it's a session expire alert
    const isSessionExpired =
      swal.isVisible() &&
      swal.getTitle()?.textContent?.indexOf('Session Expired') > -1;

    // show force change password alert
    if (is_need_pw_change && !passwordChanged && !isSessionExpired) {
      swal({
        title: 'Your password is due for a change',
        text:
          'We noticed your password age is more than 90 days already. Please change your password.',
        showCancelButton: false,
        showLoaderOnConfirm: true,
        type: 'warning',
        cancelButtonText: 'Later',
        confirmButtonText: 'Okay',
        preConfirm: () => {
          forceNewPassword.current = true;
          handleChangePasswordClicked();
        },
        allowOutsideClick: false,
      });
    }
  };

  // render
  const {profile} = userData || {};
  const avatarURL = imageURL(
    profile ? profile.avatar : '/assets/images/account-circle.png'
  );

  return (
    <AppHeader>
      <TopNav>
        <Container>
          <Row justifyContent="space-between" alignItems={'center'}>
            <Col lg={3}>
              <LogoSection>
                <Link to="/">
                  <Logo />
                </Link>
              </LogoSection>
            </Col>
            <FlexColIconNav lg={4}>
              <IconNav>
                {/*<LinkItem to="/news/search">*/}
                {/*<LinkIcon src="/assets/images/search.png" alt="search" />*/}
                {/*</LinkItem>*/}
                <LinkItem to="/notifications">
                  <LinkIcon
                    src="/assets/images/notification.png"
                    alt="notification"
                  />
                </LinkItem>
                <UncontrolledDropdown>
                  <StyledDropdownToggle color="link">
                    <UserName to="#">
                      <AvatarIcon src={avatarURL} />
                      {userData && <span>{userData.name}</span>}
                    </UserName>
                  </StyledDropdownToggle>
                  <StyledDropdownMenu right>
                    {userData && (
                      <BSDropdownItem tag={Link} to={`/user/${userId}`}>
                        <LinkItemTitle>My Profile</LinkItemTitle>
                        <LinkItemDescription>
                          Show My Profile
                        </LinkItemDescription>
                      </BSDropdownItem>
                    )}
                    <BSDropdownItem onClick={editProfile}>
                      <LinkItemTitle>Edit Account</LinkItemTitle>
                      <LinkItemDescription>
                        Add or change photo, mobile phone number, message and
                        additional editor
                      </LinkItemDescription>
                    </BSDropdownItem>
                    {FeatureFlag.USE_CHANGE_PASSWORD ? (
                      <BSDropdownItem onClick={handleChangePasswordClicked}>
                        <LinkItemTitle>Change Password</LinkItemTitle>
                        <LinkItemDescription>
                          Choose unique password to protect your account
                        </LinkItemDescription>
                      </BSDropdownItem>
                    ) : null}
                    <BSDropdownItem onClick={handleAboutClicked}>
                      About
                    </BSDropdownItem>
                    <BSDropdownItem tag={Link} to="/eula">
                      End user license agreement
                    </BSDropdownItem>
                    <BSDropdownItem onClick={onSignOut}>
                      Sign Out
                    </BSDropdownItem>
                  </StyledDropdownMenu>
                </UncontrolledDropdown>
              </IconNav>
            </FlexColIconNav>
          </Row>
        </Container>
      </TopNav>
      <MainNav>
        <NavbarContainer>
          <MainNavbar />
        </NavbarContainer>
      </MainNav>
    </AppHeader>
  );
};

const FlexColIconNav = styled(Col)`
  display: flex;
  justify-content: flex-end;
`;
const LinkItem = styled(Link)`
  padding: 10px;
  box-sizing: border-box;
`;
const LinkItemTitle = styled.div`
  color: #ee9c34;
  font-size: 16px;
`;
const LinkItemDescription = styled.div`
  color: #a2a2a2;
  font-size: 12px;
`;
const LinkIcon = styled.img`
  height: 20px;
`;

const respAvatarIcon = {
  xs: media.xs`
    margin-right: 0;
  `,
};
const AvatarIcon = styled.div`
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-image: url('${props => props.src}');
  background-size: cover;
  background-position: center;
  background-color: #dedede;
  margin-right: 10px;
  
  ${respAvatarIcon.xs}
`;

const respUserName = {
  xs: media.xs`
    padding-right: 5px;
    justify-content: flex-end;

    img {
      margin-right: 0!important;
    }
  `,
  spanSM: media.sm`
      display: block;
    `,
  spanXS: media.xs`
      display: none;
    `,
};
const UserName = styled(Link)`
  font-size: 16px;
  color: #818181;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &:hover {
    color: #818181;
  }

  span {
    position: relative;
    top: 2px;
  }

  ${respUserName.xs}

  span {
    ${respUserName.spanSM};
    ${respUserName.spanXS};
  }
`;

const respNavbarContainer = {
  xs: media.xs`
    padding: 0 10px;
  `,
  sm: media.sm`
    padding: 0 10px;
  `,
  md: media.md`
    padding: 0 10px!important;
  `,
  xl: media.xl`
    padding: 0!important;
  `,
};
const NavbarContainer = styled(Container)`
  padding: 0;
  ${respNavbarContainer.xs};
  ${respNavbarContainer.sm};
  ${respNavbarContainer.md};
  ${respNavbarContainer.xl};
`;

const StyledDropdownMenu = styled(DropdownMenu)`
  width: 300px;
`;

const BSDropdownItem = styled(DropdownItem)`
  white-space: normal !important;
  &:not(:last-child) {
    border-bottom: 1px solid #f0f0f0 !important;
  }
`;

const StyledDropdownToggle = styled(DropdownToggle)`
  padding-right: 0 !important;
`;

export default Navigation;
