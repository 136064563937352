import {Col, media, Row} from '../../styles/grid';
import React, {Component} from 'react';
import styled, {css} from 'styled-components';
import {Card, CardBody} from 'reactstrap';
import {postDate} from '../../utils/helpers';
import {Link} from 'react-router-dom';
import {Post} from '../../models/Post';
import {ColImage, PostImage} from '../../styles';

class PostCard extends Component {
  render() {
    const {post: postData, isExternal} = this.props;
    const post =
      postData instanceof Post ? postData : new Post(postData, isExternal);
    const {
      imageURL,
      thumbnailImageURL,
      title,
      isAnnouncement,
      category,
      createdAt,
      data: {publish_date},
      url,
      textOnly,
    } = post;
    const bullHornIcon = isAnnouncement ? <BullhornIcon /> : '';
    const LinkComponent = isExternal ? 'a' : Link;
    const linkParams = isExternal ? {href: url} : {to: url};

    return (
      <PostingCard target="_blank" tag={LinkComponent} {...linkParams}>
        <PostBody>
          <PostRow>
            {!isAnnouncement && (
              <ColImage xs={12} sm={5}>
                <PostImage src={thumbnailImageURL || imageURL} />
              </ColImage>
            )}
            {/*{!isAnnouncement && (*/}
            {/*  <Col xs={12} sm={5}>*/}
            {/*    <img src={thumbnailImageURL || imageURL} alt={title} className="img-fluid"/>*/}
            {/*  </Col>*/}
            {/*)}*/}
            <ColPost xs={12} sm={isAnnouncement ? 12 : 7}>
              <PostTitleWrapper>
                {isAnnouncement ? (
                  <PostTitle title={title}>
                    {bullHornIcon} {title}
                  </PostTitle>
                ) : (
                  <PostTitle
                    title={title}
                    dangerouslySetInnerHTML={{
                      __html: `${bullHornIcon} ${title}`,
                    }}
                  />
                )}
              </PostTitleWrapper>
              <PostDescriptionWrapper>
                <PostDescription dangerouslySetInnerHTML={{__html: textOnly}} />
              </PostDescriptionWrapper>
              <PostInfo>
                <Row>
                  <Col>
                    <AuthorName
                      dangerouslySetInnerHTML={{
                        __html: category.name,
                      }}
                    />
                    {publish_date && (
                      <PostInfoTime>{postDate(publish_date)}</PostInfoTime>
                    )}
                  </Col>
                  {/*<Col className="text-right">*/}
                  {/*<BookmarkButton>*/}
                  {/*<BookmarkIcon />*/}
                  {/*</BookmarkButton>*/}
                  {/*</Col>*/}
                </Row>
              </PostInfo>
            </ColPost>
          </PostRow>
        </PostBody>
      </PostingCard>
    );
  }
}

const ColPost = styled(Col)`
  ${media.xs`
    padding: 0!important;
  `};
`;

const PostBody = styled(CardBody)`
  padding: 0 8px !important;
  flex: 1;
  display: flex;
`;

const PostTitle = styled.h3`
  margin-top: 16px;
  margin-bottom: 10px;
  margin-left: 8px;
  font-weight: bold;
  font-size: 24px;
  color: #505050;
  line-height: 1.2;
  display: block;
  //white-space: nowrap !important;
  //overflow: hidden !important;
  //text-overflow: ellipsis !important;

  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  ${media.xs`
    margin-left: 0;
  `};
`;

const PostTitleWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const PostDescriptionWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: auto;
  min-height: 72px;
`;

const PostDescription = styled.div`
  margin-left: 8px;
  //white-space: nowrap !important;
  //overflow: hidden !important;
  //text-overflow: ellipsis !important;

  //word-break: break-word;

  display: -webkit-box;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;

  ${media.xs`
    margin-left: 0;
  `};
`;

const PostInfo = styled.div`
  margin-top: 20px;
  margin-bottom: 15px;
  margin-left: 8px;

  ${media.xs`
    margin-left: 0;
    margin-bottom: 0;
  `};
`;

const PostInfoTime = styled.div`
  color: #757575;
  font-size: 14px;
`;

// const BookmarkButton = styled(Button).attrs({
//   color: 'link',
// })``;

const PostingCard = styled(Card)`
  height: auto;
  margin-bottom: 10px;
  color: #333333;
  transition: box-shadow 0.5s;
  width: 100%;

  &:hover {
    text-decoration: none !important;
    color: #333333;
    box-shadow: 0px 0px 4px 2px ${props => props.theme.colors.primary};
  }

  h5 {
    text-decoration: none !important;
  }

  ${media.xs`
    padding: 16px;
  `};
`;

const AuthorName = styled.h5`
  font-weight: bold;
  font-size: 1em;
  color: #505050;
  &:hover {
    color: #505050;
    text-decoration: underline;
  }
`;

export const BullhornIcon = styled.i.attrs({
  className: 'fas fa-bullhorn',
})`
  color: ${props => props.color || props.theme.colors.warning};
`;

const PostRow = styled(Row)`
  width: 100%;
`;

export default PostCard;
