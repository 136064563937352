import {createStore, applyMiddleware, compose} from 'redux';
import createSagaMiddleWare from 'redux-saga';
import rootSaga from '../sagas';
import reducers from '../reducers';
import {saveState, loadState} from './localStorage';
import {throttle} from 'lodash/function';

const persistedState = loadState();

const SagaMiddleWare = createSagaMiddleWare();

let composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const createAppStore = (state = {}) => {
  const sagaMiddleware = createSagaMiddleWare();
  const enhancers =
    process.env.REACT_APP_NODE_ENV === 'production'
      ? compose(applyMiddleware(sagaMiddleware))
      : composeEnhancers(applyMiddleware(sagaMiddleware));

  const store = createStore(reducers, {...persistedState, ...state}, enhancers);

  store.subscribe(
    throttle(() => {
      const savedState = {};
      saveState(savedState);
    }, 1000)
  );

  sagaMiddleware.run(rootSaga);

  return store;
};

let createStoreWithMiddleware = createStore(
  reducers,
  persistedState,
  composeEnhancers(applyMiddleware(SagaMiddleWare))
);

if (process.env.REACT_APP_NODE_ENV === 'production') {
  createStoreWithMiddleware = createStore(
    reducers,
    persistedState,
    compose(applyMiddleware(SagaMiddleWare))
  );
}

createStoreWithMiddleware.subscribe(
  throttle(() => {
    const reduxStore = createStoreWithMiddleware.getState();

    const {auth: storeAuth} = reduxStore || {};

    const savedState = {
      // externalNews: reduxStore.externalNews,
      auth: {
        rememberMe: storeAuth?.rememberMe,
        credentials: storeAuth?.rememberMe
          ? reduxStore.auth?.credentials
          : null,
      },
    };
    saveState(savedState);
  }, 1000)
);

SagaMiddleWare.run(rootSaga);

export default createStoreWithMiddleware;
