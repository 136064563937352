import React from 'react';

import {NotifTabs, NotifTabItem, NotifTabItemLink} from '../../styles';
import {requestSegment} from '../../utils/helpers';

const NotificationTabs = () => {
  const type = requestSegment(2);
  return (
    <NotifTabs>
      <NotifTabItem active={type === null}>
        <NotifTabItemLink to="/notifications">
          Waiting Your Action
        </NotifTabItemLink>
      </NotifTabItem>
      <NotifTabItem active={type === 'general'}>
        <NotifTabItemLink to="/notifications/general">General</NotifTabItemLink>
      </NotifTabItem>
    </NotifTabs>
  );
};

export default NotificationTabs;
