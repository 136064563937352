import {AUTH_OUT_SUCCESS, SET_GROUP_MENU} from '../actions/actionsList';

let initialState = {
  groupMenu: [],
};

export default function(state = initialState, action) {
  const {type, payload} = action;

  switch (type) {
    case AUTH_OUT_SUCCESS: {
      return {...initialState};
    }

    case SET_GROUP_MENU:
      const {groupMenu} = payload;
      return {...state, groupMenu};

    default:
      return state;
  }
}
