import axios from 'axios';

export default {
  /**
   * Get my profile.
   *
   * @returns {Promise<AxiosResponse<any>>}
   */
  me(config = {}) {
    return axios
      .get(`/v1/users/me`, config)
      .then(response => response?.data)
      .catch(err => err?.response?.data);
  },

  /**
   * Get user detail (profile).
   *
   * @param id
   * @returns {Promise<AxiosResponse<any>>}
   */
  find(id) {
    return axios
      .get(`/v1/users/detail/${id}`)
      .then(response => response.data)
      .catch(err => err.response.data);
  },

  /**
   * Update current user.
   *
   * @param data
   * @returns {Promise<AxiosResponse<any>>}
   */
  update(data) {
    return axios
      .post('/v1/users/me/update', data)
      .then(response => response.data)
      .catch(err => err.response.data);
  },

  /**
   * Update additional editor.
   *
   * @param id
   * @param data
   * @returns {Promise<AxiosResponse<any>>}
   */
  updateAdditionalEditor(id, data) {
    return axios
      .post(`/v1/users/me/update/${id}/additional`, data)
      .then(response => response.data)
      .catch(err => err.response.data);
  },

  /**
   * Search people directory.
   *
   * @param keyword
   * @param page
   * @param perPage
   * @param descending
   * @param except
   * @param sortBy
   * @returns {Promise<AxiosResponse<any>>}
   */
  async search({keyword, page, perPage, descending, except, sortBy}) {
    try {
      const {data} = await axios.get(`v1/users/search/${page || 1}`, {
        params: {
          sortBy: sortBy || 'createdAt',
          descending: descending || 0,
          perPage: perPage || 9,
          key: keyword,
          except,
        },
      });
      return data;
    } catch (e) {
      return e.response.data;
    }
  },

  /**
   * Login.
   *
   * @param email
   * @param password
   * @returns {Promise<AxiosResponse<any>>}
   */
  login({email, password}) {
    return axios
      .post(`v1/auth/login`, {email, password})
      .then(response => response?.data)
      .catch(err => err?.response?.data);
  },

  /**
   * Verify OTP Code.
   *
   * @param code
   * @returns {Promise<AxiosResponse<any>>}
   */
  verifyOTP({code}, config) {
    return axios
      .post(`v1/auth/otp/validate`, {token: code}, config)
      .then(response => {
        return response?.data;
      })
      .catch(err => {
        const error = err?.response?.data || err?.message;
        return {error};
      });
  },

  /**
   * Remove additional editor.
   *
   * @param userId
   * @param profileUserId
   *
   * @returns {Promise<AxiosResponse<any>>}
   */
  removeAdditionalEditor(userId, profileUserId) {
    return axios
      .get(`/v1/users/me/additional/delete/${userId}`, {
        params: {my_users_id: profileUserId},
      })
      .then(response => response.data)
      .catch(err => err.response.data);
  },

  /**
   * Add additional editor.
   *
   * @param additionalEditorId
   * @param my_users_id
   *
   * @returns {Promise<AxiosResponse<any>>}
   */
  addAdditionalEditor(additionalEditorId, my_users_id) {
    return axios
      .get(`/v1/users/me/additional/add/${additionalEditorId}`, {
        params: {my_users_id},
      })
      .then(response => response.data)
      .catch(err => err.response.data);
  },

  /**
   * Forgot password.
   *
   * @param email
   * @returns {Promise<AxiosResponse<any> | never | never>}
   */
  forgotPassword(email) {
    return axios
      .post(`/v1/auth/forgot`, {email})
      .then(response => response.data)
      .catch(err => err.response.data);
  },

  /**
   * Change password.
   *
   * @param data
   * @returns {Promise<AxiosResponse<any> | never>}
   */
  changePassword(data) {
    return axios
      .post(`/v1/users/change/password`, data)
      .then(response => response.data)
      .catch(err => err.response.data);
  },

  /**
   * Remove contact person.
   *
   * @param contactPersonId
   * @param userId
   * @returns {Promise<AxiosResponse<any> | never>}
   */
  removeContactPerson(contactPersonId, userId) {
    return axios
      .delete('/v1/admin/users/contact_person', {
        data: {
          contact_person: contactPersonId,
          users_id: userId,
        },
      })
      .then(response => response.data)
      .catch(err => err.response.data);
  },

  /**
   * Add contact person.
   *
   * @param contactPersonId
   * @param userId
   * @returns {Promise<AxiosResponse<any> | never>}
   */
  addContactPerson(userId, contactPersonId) {
    return axios
      .post('/v1/admin/users/contact_person', {
        contact_person: contactPersonId,
        users_id: userId,
      })
      .then(response => response.data)
      .catch(err => err.response.data);
  },

  validateToken(accessToken) {
    return axios
      .get('/v1/users/me', {
        headers: {accessToken},
      })
      .then(response => response.data)
      .catch(err => err.response.data);
  },

  liteSearch({
    sortBy = 'name',
    descending = 0,
    limit = 10,
    page = 1,
    key,
    attributes,
    except,
  }) {
    return axios
      .get('/v1/users/lite/search', {
        params: {sortBy, descending, limit, page, key, attributes, except},
      })
      .then(response => (response || {}).data)
      .catch(err => err && (err.response || {}).data);
  },
};
