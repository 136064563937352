import {
  MediaBody,
  MediaHeading,
  MediaList,
  NotifMessage,
} from '../../../styles';
import React, {useEffect, useState} from 'react';
import MediaPOIInfo from '../MediaPOIInfo';
import MediaObject from '../MediaObject';
import {parseBodyMessage, reservationURL} from '../../../utils/helpers';
import {Link} from 'react-router-dom';

const MediaReminder = props => {
  const {notification} = props;

  const [bodyMessage, setBodyMessage] = useState({});
  const [message, setMessage] = useState();

  useEffect(() => {
    const {message: msg, body} = parseBodyMessage(notification);

    setBodyMessage(body || {});
    setMessage(msg || (body || {}).message);
  }, [notification]);

  const id = bodyMessage.reservation_id || bodyMessage.id_reservation;
  const to = reservationURL(id);

  return (
    <MediaList>
      <MediaObject notification={notification} />
      <MediaBody tag={Link} to={to} target="_blank">
        <MediaHeading heading>You Info Reservation</MediaHeading>
        <NotifMessage>{message}</NotifMessage>
        <div>
          <MediaPOIInfo reservation={bodyMessage} />
        </div>
      </MediaBody>
    </MediaList>
  );
};

export default MediaReminder;
