import React, {Component} from 'react';
import styled from 'styled-components';
import InlineSVG from 'svg-inline-react';
import MaterialSpinner from './MaterialSpinner';

const spinnerSvg = `
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
width="24px" height="30px" viewBox="0 0 24 30" style="enable-background:new 0 0 50 50;" xml:space="preserve">
<rect x="0" y="13" width="4" height="5" fill="#333">
 <animate attributeName="height" attributeType="XML"
   values="5;21;5" 
   begin="0s" dur="0.6s" repeatCount="indefinite" />
 <animate attributeName="y" attributeType="XML"
   values="13; 5; 13"
   begin="0s" dur="0.6s" repeatCount="indefinite" />
</rect>
<rect x="10" y="13" width="4" height="5" fill="#333">
 <animate attributeName="height" attributeType="XML"
   values="5;21;5" 
   begin="0.15s" dur="0.6s" repeatCount="indefinite" />
 <animate attributeName="y" attributeType="XML"
   values="13; 5; 13"
   begin="0.15s" dur="0.6s" repeatCount="indefinite" />
</rect>
<rect x="20" y="13" width="4" height="5" fill="#333">
 <animate attributeName="height" attributeType="XML"
   values="5;21;5" 
   begin="0.3s" dur="0.6s" repeatCount="indefinite" />
 <animate attributeName="y" attributeType="XML"
   values="13; 5; 13"
   begin="0.3s" dur="0.6s" repeatCount="indefinite" />
</rect>
</svg>`;

class LoadingComponent extends Component {
  render() {
    const {noMargin, ...props} = this.props;

    return (
      <Wrapper noMargin={noMargin}>
        {/*<InlineSVG src={spinnerSvg} />*/}
        <MaterialSpinner indeterminate {...props} />
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  text-align: center;
  margin: ${props => (props.noMargin ? 0 : '1em')};

  svg path,
  svg rect {
    fill: #47c4e5;
  }
`;

export default LoadingComponent;
