import {encrypt, decrypt} from './encryption';

const LS = window.localStorage;
const TOKEN_STORAGE = 'token';
const TOKEN_LOGIN_STORAGE = 'log';
const USER_STORAGE = 'user';

export const storeAccessToken = token => {
  LS.setItem(TOKEN_STORAGE, token);
};

export const storeLoginToken = token => {
  LS.setItem(TOKEN_LOGIN_STORAGE, token);
};

export const getAccessToken = () => LS.getItem(TOKEN_STORAGE);

export const getLoginToken = () => LS.getItem(TOKEN_LOGIN_STORAGE);

export const removeAccessToken = () => LS.removeItem(TOKEN_STORAGE);

export const removeLoginToken = () => LS.removeItem(TOKEN_LOGIN_STORAGE);

export const storeUserData = data =>
  LS.setItem(USER_STORAGE, encrypt(JSON.stringify(data)));

export const getUserData = () => {
  const data = LS.getItem(USER_STORAGE);
  return data ? JSON.parse(decrypt(data)) : data;
};

export const removeUserData = () => LS.removeItem(USER_STORAGE);
