export default {
  timezone: 'Asia/Jakarta',

  dateFormat: 'YYYY-MM-DD',

  reservationDateFormat: 'DD MMM YYYY',

  timeFormat: 'HH:mm:ss',

  shortTimeFormat: 'HH:mm',

  datepickerFormat: 'DD-MMM-YYYY',

  roles: {
    CLUSTER_ADMIN: 'Super Admin Cluster',
    PANTRY_ADMIN: 'Pantry Admin',
    SUPER_ADMIN: 'Super Admin',
    RECEPTIONIST: 'Receptionist',
    LIAISON_OFFICER: 'Liaison Officer',
  },

  reservationStatus: {
    PENDING: 'pending',
    WAITING_CLUSTER_ADMIN: 'waiting_cluster_admin',
    APPROVED: 'approved',
    RESCHEDULED: 'rescheduled',
    RESCHEDULLED: 'reschedulled',
    CANCELLED: 'cancelled',
    CANCELED: 'canceled',
    RELEASED: 'released',
    REJECTED: 'rejected',
  },

  firebase: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  },

  INTERNAL_SERVER_ERROR: 'Internal Server Error',

  STAFF: 'staff',
  NON_STAFF: 'nonstaff',

  ENCRYPTION_KEY: process.env.REACT_APP_ENCRYPTION_KEY,
  ENCRYPTION_ENABLED: process.env.REACT_APP_ENCRYPTION_ENABLED,

  DEBUG: process.env.REACT_APP_DEBUG === 'true',

  ALLOWED_IMAGE_TYPES: ['image/png', 'image/jpeg', 'image/jpg'],

  allowedReservationTypes: ['single', 'group'],

  CONFERENCE_TYPES: [
    {
      id: 1,
      text: 'Video Conference',
    },
    {
      id: 2,
      text: 'Web Conference',
    },
  ],

  NEWS_TYPES: {
    INTERNAL: {
      id: 0,
      name: 'internal',
    },
    EXTERNAL: {
      id: 1,
      name: 'external',
    },
    ANNOUNCEMENT: {
      id: 2,
      name: 'announcement',
    },
  },
};
