import React, {Component} from 'react';
import styled from 'styled-components';
import {Col, Row, media} from '../../styles/grid';
import {
  getSeeMapURL,
  isNonStaff,
  lastClockInFormat,
  parseLastLocation,
  toast,
} from '../../utils/helpers';
import {Button} from 'reactstrap';
import {Link} from 'react-router-dom';

class RoomInfoBox extends Component {
  handleSeeMap = (event, hasLastDetected) => {
    if (!hasLastDetected) {
      toast({
        title: 'This person is not detected in the office',
        type: 'warning',
      });
      event.preventDefault();
    }
  };

  render() {
    const {profileData} = this.props;

    if (!profileData) return null;

    let {
      clock_in: clockIn,
      check_in: checkIn,
      last_location: lastLocation,
    } = profileData;
    const lastDetected = parseLastLocation(lastLocation);

    clockIn = clockIn || {};

    const hasClockIn = clockIn ? Object.keys(clockIn).length > 0 : false;
    const hasCheckIn = checkIn ? Object.keys(checkIn).length > 0 : false;
    const {zone} = lastDetected;
    const hasLastDetected = zone && zone !== '-';
    const nonStaff = isNonStaff(profileData.profile);
    const history_location = lastLocation ? lastLocation.name || '-' : '-';
    const seeMapURL = getSeeMapURL(lastDetected);

    if (nonStaff) return null;

    return (
      <RoomInfoWrapper>
        <RoomCol xs={12}>
          <LastClockInPoint>
            Last detected location {lastClockInFormat(lastDetected.createdAt)}
          </LastClockInPoint>
          <RoomName>{history_location}</RoomName>
          {/*<FloorInfo>*/}
          {/*{hasFloor ? `Floor ${lastDetected.floor}` : ''}*/}
          {/*{hasArea ? ` - ${lastDetected.area}` : ''}*/}
          {/*</FloorInfo>*/}
        </RoomCol>
        <RoomCol xs={12}>
          <ButtonSeeMap
            tag={Link}
            color="default"
            outline={true}
            target="_blank"
            to={seeMapURL}
            onClick={event => this.handleSeeMap(event, hasLastDetected)}>
            See Map
          </ButtonSeeMap>
        </RoomCol>
        <BorderedRoomCol xs={12}>
          <LastClockInPoint>
            Last clock-in point &middot; {lastClockInFormat(clockIn.createdAt)}
          </LastClockInPoint>
          <RoomName>{clockIn ? clockIn.name : '-'}</RoomName>
          <FloorInfo>
            {hasClockIn ? `Floor ${clockIn.floor}` : '-'}
            {hasClockIn ? ` - ${clockIn.area}` : '-'}
          </FloorInfo>
        </BorderedRoomCol>
        <BorderedRoomCol xs={12}>
          <LastClockInPoint>
            Last check-in point &middot;{' '}
            {checkIn ? lastClockInFormat(checkIn.createdAt) : '-'}
          </LastClockInPoint>
          <RoomName>{checkIn ? checkIn.name : '-'}</RoomName>
          <FloorInfo>
            {hasCheckIn ? `Floor ${checkIn.floor}` : '-'}
            {hasCheckIn ? ` - ${checkIn.area}` : '-'}
          </FloorInfo>
        </BorderedRoomCol>
      </RoomInfoWrapper>
    );
  }
}

const RoomInfoWrapper = styled(Row)`
  background: #ee9c34;
  border-radius: 3px;
  display: flex;
  color: #fff;
  padding: 15px 15px 15px;
  align-items: center;
  margin-top: 15px;
  margin-left: 0;
  margin-right: 0;
  ${media.xs`
    justify-content: center!important;
    text-align: center;
    margin: 0;
    padding: 10px;
  `};
`;
const RoomCol = styled(Col)`
  ${media.xs`
    padding: 0;
     &:not(:first-child) {
        margin-top: 15px;
     }
  `};
`;
const BorderedRoomCol = styled(RoomCol)`
  border-left: 0.5px solid #dadada;
  padding-left: 15px;

  ${media.xs`
    border: none;
    padding-left: 0;
  `};
`;
// const LastRoomCol = styled(Col)``;
const LastClockInPoint = styled.div`
  font-weight: normal;
  font-size: 12px;
`;
const FloorInfo = styled.div`
  font-size: 15px;
`;
const RoomName = styled.h3`
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 18px;
  height: auto;
  min-height: 43px;
`;

const ButtonSeeMap = styled(Button)`
  border-radius: 5px;
  padding: 8px 15px;
  font-size: 14px;
  font-weight: bold;
  display: inline-block;
  width: 80%;
  text-align: center;
  border-color: #fff;
  color: ${props => props.theme.colors.warning}!important;
  background-color: #fff !important;

  &:hover,
  &:active,
  &:focus {
    color: ${props => props.theme.colors.warning}!important;
  }

  ${media.xs`
    margin-bottom: 0;
  `};
`;

export default RoomInfoBox;
