import React, {Component, Fragment} from 'react';
import styled from 'styled-components';
import {AsyncTypeahead} from 'react-bootstrap-typeahead';
import {TextError} from '../../styles';

class MultiTypeaheadField extends Component {
  state = {
    selected: [],
  };

  handleChange = selected => {
    const {valueKey} = this.props;
    const typeahead = this.typeahead;
    const instance = typeahead ? typeahead.getInstance() : null;
    // console.log({typeahead, instance, selected});
    if (selected.length) {
      let value = selected[0];
      if (valueKey) {
        value = value[valueKey];
      }
      this.props.input.onChange(value);
      this.handleSelected(value, selected, instance);
      this.setState({selected});
    } else {
      this.props.input.onChange('');
      this.handleSelected('', selected, instance);
      if (instance) {
        instance.clear();
      }
      this.setState({selected: []});
    }
  };

  handleSelected = (value, selected, instance) => {
    const {onSelected} = this.props;
    if (onSelected) {
      onSelected(value, selected, instance);
    }
  };

  componentWillReceiveProps(nextProps, nextContext) {
    if (
      nextProps.input.value &&
      nextProps.input.value !== this.props.input.value
    ) {
      let value = nextProps.input.value;
      if (this.isValidValue(value)) {
        const selected = [value];
        this.setState({selected});
      }
    }
  }

  isValidValue(value) {
    return value && Boolean(value.id) && Boolean(value.name);
  }

  render() {
    const {
      input,
      meta: {touched, error},
      ...rest
    } = this.props;
    // const value = input.value;
    // let defaultSelected = [];
    // let options = [];
    // if (this.isValidValue(value)) {
    // defaultSelected = [input.value];
    // options = [input.value];
    // }
    // const defaultSelected = value ? [input.value] : [];
    // console.log({defaultSelected, rest, options, state: this.state});

    return (
      <Fragment>
        <StyledAsyncTypeahead
          ref={typeahead => (this.typeahead = typeahead)}
          onChange={this.handleChange}
          // options={options}
          selected={this.state.selected}
          {...rest}
        />
        {touched && error && <TextError>{error}</TextError>}
      </Fragment>
    );
  }
}

const StyledAsyncTypeahead = styled(AsyncTypeahead)`
  input:focus {
    color: #495057 !important;
    background-color: #fff !important;
    border-color: #e0301e !important;
    outline: 0 !important;
    box-shadow: 0 0 0 0.2rem rgba(224, 48, 30, 0.56) !important;
  }
`;

export default MultiTypeaheadField;
