import React, {Component, Fragment} from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import MeetingRoomCard from './MeetingRoomCard';
import {
  findRoomForClusterAdminRequest,
  findRoomRequest,
} from '../../actions/reservation';
import {
  ModalHeader,
  ModalBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  InputGroup,
  Input,
  InputGroupAddon,
  Col,
  Row,
} from 'reactstrap';
import moment from 'moment';
import LoadingComponent from '../UIKit/LoadingComponent';
import {Alert, ButtonIcon} from '../../styles';
import config from '../../config';
import {media} from '../../styles/grid';
import {toggleModal} from '../../actions/modal';
import MaterialIcon from '../UIKit/MaterialIcon';
import {capitalize, getConferenceText} from '../../utils/helpers';

class SearchMeetingRoom extends Component {
  state = {
    searchCluster: '',
    selectedCluster: null,
    searchFloor: '',
    selectedFloor: null,
    searchCapacity: '',
    selectedCapacity: null,
    searchRoom: '',
  };

  componentDidMount() {
    const {params, findRoom, findRoomForClusterAdmin} = this.props;
    if (params.isClusterAdmin) {
      findRoomForClusterAdmin(params);
    } else {
      findRoom(params);
    }
  }

  selectCluster = item => {
    this.setState({selectedCluster: item});
  };

  removeSelectedCluster = event => {
    this.setState({selectedCluster: null});
    event.preventDefault();
  };

  handleSearchClusterChange = event => {
    const searchCluster = event.target.value;
    this.setState({searchCluster});
  };

  selectFloor = item => {
    this.setState({selectedFloor: item});
  };

  removeSelectedFloor = event => {
    this.setState({selectedFloor: null});
    event.preventDefault();
  };

  handleSearchFloorChange = event => {
    const searchFloor = event.target.value;
    this.setState({searchFloor});
  };

  selectCapacity = item => {
    this.setState({selectedCapacity: item});
  };

  removeSelectedCapacity = event => {
    this.setState({selectedCapacity: null});
    event.preventDefault();
  };

  handleSearchCapacityChange = event => {
    const searchCapacity = event.target.value;
    this.setState({searchCapacity});
  };

  toggle = () => {
    this.props.toggleModal('searchMeetingRoom');
  };

  getMappedRooms = rooms => {
    const {
      selectedCluster,
      selectedFloor,
      selectedCapacity,
      searchRoom,
    } = this.state;

    let mappedRooms = [...(rooms || [])].map(item => ({
      ...item,
      clusterItem: item.cluster[0],
      floor: item.zone.floor,
    }));

    if (selectedCluster) {
      mappedRooms = mappedRooms.filter(item => {
        const {clusterItem} = item;
        return (
          clusterItem !== undefined &&
          item.clusterItem.id === selectedCluster.id
        );
      });
    }

    if (selectedFloor) {
      mappedRooms = mappedRooms.filter(item => {
        const {floor} = item;
        return floor !== undefined && item.floor.id === selectedFloor.id;
      });
    }

    if (selectedCapacity) {
      mappedRooms = mappedRooms.filter(item => {
        const {capacity} = item;
        return capacity !== undefined && item.capacity === selectedCapacity;
      });
    }

    if (searchRoom) {
      mappedRooms = mappedRooms.filter(
        item => item.name.toLowerCase().indexOf(searchRoom.toLowerCase()) > -1
      );
    }

    return mappedRooms;
  };

  getClusters = rooms => {
    const {searchCluster} = this.state;

    return (rooms || [])
      .map(item => ({
        ...item,
        clusterItem: item.cluster[0],
      }))
      .filter(item => !!item.clusterItem)
      .map(item => item.clusterItem)
      .filter(
        (item, index, self) => index === self.findIndex(j => item.id === j.id)
      )
      .filter(
        item =>
          item.name.toLowerCase().indexOf(searchCluster.toLowerCase()) > -1
      );
  };

  getFloors = mappedRooms => {
    const {searchFloor} = this.state;

    return (mappedRooms || [])
      .filter(item => !!item.floor)
      .map(item => item.floor)
      .filter(
        (item, index, self) => index === self.findIndex(j => item.id === j.id)
      )
      .filter(
        item => item.name.toLowerCase().indexOf(searchFloor.toLowerCase()) > -1
      );
  };

  getCapacities = mappedRooms => {
    const {searchCapacity} = this.state;

    return (mappedRooms || [])
      .map(item => +item.capacity)
      .filter((item, index, self) => index === self.findIndex(j => item === j))
      .sort((a, b) => a - b)
      .filter(
        item =>
          item &&
          item
            .toString()
            .toLowerCase()
            .indexOf(searchCapacity.toString().toLowerCase()) > -1
      );
  };

  handleSearchRoomChange = event => {
    const searchRoom = event.target.value;
    this.setState({searchRoom});
  };

  getConferenceText = conferenceType => {
    return getConferenceText(conferenceType);
  };

  render() {
    const {
      searchCluster,
      selectedCluster,
      searchFloor,
      selectedFloor,
      searchCapacity,
      selectedCapacity,
      searchRoom,
    } = this.state;

    const timeFormat = config.shortTimeFormat;
    const {rooms, params, loading} = this.props;
    const startTime = moment(params.start_time, timeFormat).format(timeFormat);
    const endTime = moment(params.end_time, timeFormat).format(timeFormat);
    const noRooms = !rooms || rooms.length < 1;

    if (loading) {
      return <LoadingComponent />;
    }

    const mappedRooms = this.getMappedRooms(rooms);
    const clusters = this.getClusters(rooms);
    const floors = this.getFloors(mappedRooms);
    const capacities = this.getCapacities(mappedRooms);
    const {
      video_conference,
      need_conference: needConferenceFacility,
      meeting_type,
    } = params;
    const conferenceText = this.getConferenceText(video_conference);

    return (
      <Fragment>
        <ModalHeader toggle={this.toggle}>Available Meeting Room</ModalHeader>
        <ModalBody>
          <BadgeGroup>
            <BadgeItem>{params.check_in}</BadgeItem>
            <BadgeItem>{params.check_in_release}</BadgeItem>
            <BadgeItem>{startTime}</BadgeItem>
            <BadgeItem>{endTime}</BadgeItem>
            {needConferenceFacility && (
              <BadgeItem>Conference Facility: {conferenceText}</BadgeItem>
            )}
            {meeting_type && (
              <BadgeItem>Meeting Type: {capitalize(meeting_type)}</BadgeItem>
            )}
          </BadgeGroup>
          <div className="mb-3">
            <Row>
              <FlexCol sm={'auto'} xs={12}>
                <FilterText>Filter:</FilterText>
              </FlexCol>
              <FlexCol sm={'auto'} xs={12}>
                <StyledUncontrolledDropdown>
                  <DropdownToggleFilter
                    block
                    color="primary"
                    caret={!selectedCluster}>
                    {selectedCluster ? (
                      <Fragment>
                        {selectedCluster.name}
                        <BadgeClose onClick={this.removeSelectedCluster}>
                          &times;
                        </BadgeClose>
                      </Fragment>
                    ) : (
                      'All Cluster'
                    )}
                  </DropdownToggleFilter>
                  <DropdownMenu>
                    <DropdownItem toggle={false}>
                      <input
                        className="form-control"
                        value={searchCluster}
                        placeholder="Search..."
                        onChange={this.handleSearchClusterChange}
                        autoFocus={true}
                      />
                    </DropdownItem>
                    {clusters.map(item => (
                      <DropdownItem
                        onClick={() => this.selectCluster(item)}
                        key={item.id}>
                        {item.name}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </StyledUncontrolledDropdown>
              </FlexCol>
              <FlexCol sm={'auto'} xs={12}>
                <StyledUncontrolledDropdown>
                  <DropdownToggleFilter
                    block
                    color="primary"
                    caret={!selectedFloor}>
                    {selectedFloor ? (
                      <Fragment>
                        {selectedFloor.name}
                        <BadgeClose onClick={this.removeSelectedFloor}>
                          &times;
                        </BadgeClose>
                      </Fragment>
                    ) : (
                      'All Floor'
                    )}
                  </DropdownToggleFilter>
                  <DropdownMenu>
                    <DropdownItem toggle={false}>
                      <input
                        className="form-control"
                        value={searchFloor}
                        placeholder="Search..."
                        onChange={this.handleSearchFloorChange}
                        autoFocus={true}
                      />
                    </DropdownItem>
                    {floors.map(item => (
                      <DropdownItem
                        onClick={() => this.selectFloor(item)}
                        key={item.id}>
                        {item.name}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </StyledUncontrolledDropdown>
              </FlexCol>
              <FlexCol sm={'auto'} xs={12}>
                <StyledUncontrolledDropdown>
                  <DropdownToggleFilter
                    block
                    color="primary"
                    caret={!selectedCapacity}>
                    {selectedCapacity ? (
                      <Fragment>
                        {selectedCapacity}
                        <BadgeClose onClick={this.removeSelectedCapacity}>
                          &times;
                        </BadgeClose>
                      </Fragment>
                    ) : (
                      'All Capacity'
                    )}
                  </DropdownToggleFilter>
                  <DropdownMenu>
                    <DropdownItem toggle={false}>
                      <input
                        className="form-control"
                        value={searchCapacity}
                        placeholder="Search..."
                        onChange={this.handleSearchCapacityChange}
                        autoFocus={true}
                      />
                    </DropdownItem>
                    {capacities.map(item => (
                      <DropdownItem
                        onClick={() => this.selectCapacity(item)}
                        key={item}>
                        <span>{item}</span>
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </StyledUncontrolledDropdown>
              </FlexCol>
              <Col xs={12} sm={12} className="text-right mb-1">
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <ButtonIcon color="primary">
                      <MaterialIcon icon="search" />
                    </ButtonIcon>
                  </InputGroupAddon>
                  <Input
                    value={searchRoom}
                    placeholder="Search room..."
                    onChange={this.handleSearchRoomChange}
                    autoFocus={true}
                  />
                </InputGroup>
              </Col>
            </Row>
          </div>
          {mappedRooms.map((item, colIndex) => {
            return <MeetingRoomCard key={colIndex} item={item} />;
          })}
          {noRooms && (
            <Alert color="danger">
              Sorry, there is no available room for your request
            </Alert>
          )}
        </ModalBody>
      </Fragment>
    );
  }
}

const BadgeGroup = styled.div`
  margin-bottom: 5px;
`;

const BadgeItem = styled.div`
  border-radius: 25px;
  background: ${props => props.theme.colors.yellow};
  color: #ffffff;
  display: inline-flex;
  padding: 5px 10px;
  margin-right: 10px;
  font-size: 14px;
  margin-bottom: 10px;
  align-items: center;
`;

const FilterText = styled.span`
  font-weight: bold;
`;

const BadgeClose = styled.a.attrs({
  href: '#',
})`
  color: #fff;
  margin-left: 5px;
  border-radius: 100%;
  font-size: 16px;
  background-color: var(--pwc-yellow);
  display: inline-block;
  width: 25px;
  height: 25px;
  text-align: center;
  padding-top: 0;
  &:hover {
    text-decoration: none;
  }
`;

const DropdownToggleFilter = styled(DropdownToggle)`
  border-radius: 25px !important;
  font-size: 14px !important;
  padding: 5px 10px !important;
`;

const respFlexCol = {
  xs: media.xs`
    display: flex;
    flex-grow: 1 1 100%;
  `,
};
const FlexCol = styled(Col)`
  flex-grow: initial;
  margin-bottom: 1em;
  display: inline-flex;
  ${respFlexCol.xs};
`;

const respStyledUncontrolledDropdown = {
  xs: media.xs`
      width: 100%;
  `,
};
const StyledUncontrolledDropdown = styled(UncontrolledDropdown)`
  ${respStyledUncontrolledDropdown.xs};
`;

function mapStateToProps(state) {
  const {meetingRooms: rooms, params, findingRoom: loading} = state.reservation;
  return {
    rooms,
    params,
    loading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    findRoom: bindActionCreators(findRoomRequest, dispatch),
    findRoomForClusterAdmin: bindActionCreators(
      findRoomForClusterAdminRequest,
      dispatch
    ),
    toggleModal: bindActionCreators(toggleModal, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchMeetingRoom);
