import React, {Component} from 'react';
import {PostWrapper} from '../../styles';
import {Col, Row} from 'reactstrap';
import PostPlaceholderCard from '../../components/News/PostPlaceholderCard';
import {range} from 'ramda';

class LatestNewsPlaceholder extends Component {
  render() {
    const posts = range(0, 6);

    return (
      <PostWrapper>
        <Row form>
          {posts.map((post, index) => (
            <Col key={`post-c-${index}`} xs={12} sm={6}>
              <PostPlaceholderCard />
            </Col>
          ))}
        </Row>
      </PostWrapper>
    );
  }
}

export default LatestNewsPlaceholder;
