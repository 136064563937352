import React, {Component, Fragment} from 'react';
import {Col, ListGroup, ListGroupItem, Row} from 'reactstrap';
import {getConferenceText, imageURL} from '../../utils/helpers';
import MaterialIcon from '../UIKit/MaterialIcon';
import styled from 'styled-components';

class POIInfoList extends Component {
  render() {
    const {poi} = this.props;
    const {
      name,
      image,
      zone,
      capacity,
      cluster: clusters,
      video_conference,
      description,
      facilities,
    } = poi;
    const cluster = clusters.length > 0 ? clusters[0] : {};
    const {floor} = zone;
    const videoConferenceText = getConferenceText(video_conference);

    return (
      <ListGroup className="text-muted" flush>
        {image && (
          <Fragment>
            <ListGroupItem>
              <img src={imageURL(image)} alt="image" className="img-fluid" />
            </ListGroupItem>
            <ListGroupItem>
              <strong>{name}</strong>
            </ListGroupItem>
          </Fragment>
        )}
        <ListGroupItem>
          <Row>
            <Col className="text-center" xs={1}>
              <MaterialIcon icon="map-marker-alt" />
            </Col>
            <Col>
              {name} &bull; {floor.name} &bull; {cluster.name}
            </Col>
          </Row>
        </ListGroupItem>
        <ListGroupItem>
          <Row>
            <Col className="text-center" xs={1}>
              <MaterialIcon icon="users" />
            </Col>
            <Col>Up to {capacity}</Col>
          </Row>
        </ListGroupItem>
        {!!video_conference && (
          <ListGroupItem>
            <Row>
              <Col className="text-center" xs={1}>
                <MaterialIcon icon="video" />
              </Col>
              <Col>{videoConferenceText}</Col>
            </Row>
          </ListGroupItem>
        )}
        <ListGroupItem>
          <Row>
            <Col className="text-center" xs={1}>
              <MaterialIcon icon="info-circle" />
            </Col>
            <Col>{description}</Col>
          </Row>
        </ListGroupItem>
        {facilities.length > 0 && (
          <Fragment>
            <ListGroupItem>
              <strong>Facilities</strong>
            </ListGroupItem>
            {facilities.map(item => (
              <ListGroupItem key={item.id}>
                <Row className="align-items-center">
                  <Col className="text-center" xs={2}>
                    {item.icon ? (
                      <img
                        src={imageURL(item.icon)}
                        alt={item.name}
                        className="img-fluid"
                      />
                    ) : (
                      <MaterialIcon icon="building" size={2} />
                    )}
                  </Col>
                  <Col>
                    <FacilityName>
                      {item.pivot.quantity} {item.name}
                    </FacilityName>
                  </Col>
                </Row>
              </ListGroupItem>
            ))}
          </Fragment>
        )}
      </ListGroup>
    );
  }
}

const FacilityName = styled.div`
  font-weight: normal;
`;

export default POIInfoList;
