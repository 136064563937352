import React, {Component} from 'react';
import {connect} from 'react-redux';
import ApplicationLayout from '../../components/Layouts/ApplicationLayout';
import {
  MainContent,
  Container,
  CategoryHeader,
  CategoryTitle,
} from '../../styles';
import {getDepartmentList, getNewsAction} from '../../actions/news';
import {bindActionCreators} from 'redux';
import {createGlobalStyle} from 'styled-components';
import {
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';
import {chunkArray, getNewsCategory, getNewsType} from '../../utils/helpers';
import config from '../../config';
import LatestNewsPlaceholder from './LatestNewsPlaceholder';
import PostCard from '../../components/News/PostCard';
import {Link} from 'react-router-dom';
import NoNewsFound from './NoNewsFound';
import Pagination from '../../components/Pagination/Pagination';
import NewsNav from '../../components/News/NewsNav';
import PostGrid from '../../components/News/PostGrid';

class SeeOthers extends Component {
  state = {
    page: 1,
    limit: 10,
  };

  componentDidMount() {
    this.props.getDepartments();
    const {department_id} = this.props.match.params;
    this.fetch({
      department_id,
    });
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.match.params !== this.props.match.params) {
      const {department_id} = nextProps.match.params;
      this.fetch({
        department_id,
      });
    }
  }

  componentWillUpdate(nextProps, nextState, nextContext) {
    if (nextState.page !== this.state.page) {
      this.fetch({page: nextState.page});
    }
  }

  handlePagination = page => {
    this.setState({page});
  };

  fetch = (params = {}) => {
    const type = getNewsType(this.props);
    const {limit} = this.state;
    const {department_id} = this.props.match.params;

    this.props.getNews({
      key: '',
      orderBy: 'publish_date',
      descending: true,
      type,
      limit,
      department_id,
      ...params,
    });
  };

  getDepartmentText = () => {
    const {department_id} = this.props.match.params;
    const {department} = this.props.news;

    if (department_id) {
      return department ? department.departement || 'Loading...' : 'Filtered';
    }

    return 'All Departments';
  };

  getPageTitle = () => {
    const category = getNewsCategory();
    if (category !== config.NEWS_TYPES.ANNOUNCEMENT.name) {
      return `${category} News`;
    }
    return category;
  };

  render() {
    const {page: currentPage} = this.state;
    const departmentId = +this.props.match.params.department_id;
    const category = getNewsCategory();
    const {
      pagination,
      departments,
      posts,
      loadingDepartment,
      loading,
    } = this.props.news;
    const departmentText = this.getDepartmentText();
    const hasNews = posts.length > 0;
    const showPagination =
      Object.keys(pagination).length > 0 &&
      pagination &&
      pagination.last_page > 1;
    const pageTitle = this.getPageTitle();

    if (config.DEBUG) {
      console.log({showPagination, pagination, currentPage, posts});
    }

    return (
      <ApplicationLayout withoutFooter>
        <NewsNav />
        <MainContent>
          <Container>
            <CategoryHeader>
              <CategoryTitle>{pageTitle}</CategoryTitle>
              <UncontrolledDropdown>
                <DropdownToggle
                  disabled={loading || loadingDepartment}
                  color="primary"
                  caret>
                  {departmentText}
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem
                    active={!departmentId}
                    tag={Link}
                    to={`/news/${category}/others`}>
                    All Departments
                  </DropdownItem>
                  {departments.map(department => (
                    <DropdownItem
                      active={department.id === departmentId}
                      tag={Link}
                      to={`/news/${category}/others/${department.id}`}
                      key={department.id}>
                      {department.departement}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </UncontrolledDropdown>
            </CategoryHeader>

            {loading ? (
              <LatestNewsPlaceholder />
            ) : hasNews ? (
              <PostGrid posts={posts} />
            ) : (
              <NoNewsFound />
            )}

            {showPagination && (
              <Pagination
                currentPage={currentPage}
                onChange={this.handlePagination}
                totalPage={pagination.last_page}
              />
            )}
          </Container>
        </MainContent>
        <CreateGlobalStyle />
      </ApplicationLayout>
    );
  }
}

const CreateGlobalStyle = createGlobalStyle`
  body {
    background: #F8f8f8!important;
  }
`;

function mapStateToProps(state) {
  const {news} = state;
  return {
    news,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getDepartments: bindActionCreators(getDepartmentList, dispatch),
    getNews: bindActionCreators(getNewsAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SeeOthers);
