import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import MaterialIcon from '../UIKit/MaterialIcon';
import PropTypes from 'prop-types';
import {Row, Col, Button, UncontrolledTooltip} from 'reactstrap';
import {media} from '../../styles/grid';

const TablePagination = props => {
  const {
    rowsPerPage: initRowsPerPage,
    totalItems,
    onChange,
    rowsPerPageItems,
    disabled,
    ...restProps
  } = props;

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(initRowsPerPage || 10);

  const handleRowsPerPageChange = event => {
    const value = event.target.value;
    const nuRowsPerPage = value === 'all' ? value : Number(value);

    setRowsPerPage(nuRowsPerPage);
    setCurrentPage(1);
  };

  const handleChange = () => {
    if (onChange) {
      onChange({
        currentPage,
        rowsPerPage,
        disabled,
      });
    }
  };

  const handleNext = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePrev = () => {
    setCurrentPage(currentPage - 1);
  };

  const getTotalItems = () => {
    return totalItems || 0;
  };

  const getPerPage = () => {
    return rowsPerPage === 'all' ? getTotalItems() : rowsPerPage;
  };

  const getFrom = () => {
    const fromValue = Number((currentPage - 1) * rowsPerPage) + 1;
    const total = getTotalItems();
    return total < fromValue ? total : fromValue;
  };

  const getTo = () => {
    if (rowsPerPage === -1) return getTotalItems();
    return Number(currentPage * rowsPerPage);
  };

  useEffect(() => {
    setRowsPerPage(initRowsPerPage);
  }, [initRowsPerPage]);

  useEffect(() => {
    handleChange();
  }, [currentPage, rowsPerPage]);

  const renderRowsPerPageSelect = () => {
    return (
      <RowsPerPageSelect
        data-qa={'paging-perpage'}
        disabled={disabled}
        onChange={handleRowsPerPageChange}
        value={rowsPerPage}>
        {(rowsPerPageItems || []).map(item => {
          const key = typeof item === 'object' ? item.text : item;
          const value = typeof item === 'object' ? item.value : item;
          return (
            <option key={key} value={value}>
              {key}
            </option>
          );
        })}
      </RowsPerPageSelect>
    );
  };

  // render
  const perPage = getPerPage();
  const currTotalItem = getTotalItems();
  const from = getFrom();
  const toItem = getTo();
  const to = toItem > currTotalItem ? currTotalItem : toItem;
  const totalPages = Math.ceil(currTotalItem / perPage);

  return (
    <Row data-qa={'tb-pagination'} {...restProps}>
      <Col xs={12} sm={4} md={4} lg={7} />
      <Col xs={12} sm={8} md={8} lg={5} className="pr-3">
        <PaginationControl>
          <PaginationRowsPerPage>Rows per page</PaginationRowsPerPage>
          <PaginationRowsPerPageSelect>
            {renderRowsPerPageSelect()}
          </PaginationRowsPerPageSelect>
          <PaginationPageInfo>
            <span data-qa={'paging-from'}>{from}</span>-
            <span data-qa={'paging-to'}>{to}</span>
            <span>&nbsp;of&nbsp;</span>
            <span data-qa={'paging-total'}>{currTotalItem}</span>
          </PaginationPageInfo>
          <PaginationPrevWrapper>
            <IconButton
              data-qa={'paging-prev'}
              id="paginationPrevButton"
              color="link"
              onClick={handlePrev}
              disabled={currentPage <= 1 || disabled}
              title="Prev">
              <MaterialIcon icon="chevron-left" />
            </IconButton>
            <UncontrolledTooltip target="paginationPrevButton">
              Previous
            </UncontrolledTooltip>
          </PaginationPrevWrapper>
          <PaginationNextWrapper>
            <IconButton
              data-qa={'paging-next'}
              id="paginationNextButton"
              color="link"
              onClick={handleNext}
              disabled={currentPage >= totalPages || disabled}>
              <MaterialIcon icon="chevron-right" />
            </IconButton>
            <UncontrolledTooltip target="paginationNextButton">
              Next
            </UncontrolledTooltip>
          </PaginationNextWrapper>
        </PaginationControl>
      </Col>
    </Row>
  );
};

const RowsPerPageSelect = styled.select`
  display: inline-block;
  width: auto;
  min-width: 50px;
  border-bottom: 1px solid #bdbdbd;
  border-top: none;
  border-right: none;
  border-left: none;
  background: transparent;
  padding: 6px;
  outline: none;
  box-shadow: 0px 3px 4px -3px rgba(0, 0, 0, 0.24);
`;

const IconButton = styled(Button)``;

const PaginationNextWrapper = styled(Col).attrs({
  xs: 3,
  sm: 'auto',
})`
  display: flex;
  justify-content: flex-end;
`;
const PaginationPrevWrapper = styled(Col).attrs({
  xs: 3,
  sm: 'auto',
})`
  display: flex;
  justify-content: flex-start;
`;
const PaginationPageInfo = styled(Col)`
  display: flex;
  justify-content: center;
`;
const PaginationRowsPerPage = styled(Col).attrs({
  xs: 6,
  sm: 'auto',
})`
  ${media.xs`
  margin-bottom: 1em;
`};
`;
const PaginationRowsPerPageSelect = styled(Col).attrs({
  xs: 6,
  sm: 'auto',
})`
  ${media.xs`
  margin-bottom: 1em;
  display: flex;
  justify-content: center;
  
  select {
    width: 80%;
  }
`};
`;

const PaginationControl = styled(Row)`
  display: flex;
  align-items: center;
  justify-items: flex-end;
`;

TablePagination.propTypes = {
  rowsPerPageItems: PropTypes.array,
  rowsPerPage: PropTypes.number,
  totalItems: PropTypes.number,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

TablePagination.defaultProps = {
  rowsPerPageItems: [5, 10, 15, 20, 25, 50, 100, {text: 'All', value: -1}],
  rowsPerPage: 10,
  onChange: () => {},
  disabled: false,
};

export default TablePagination;
