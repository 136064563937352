import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import Loadable from 'react-loadable';
import {FeatureFlag} from '../config/constants';

import LoadingRoutes from './UIKit/PageLoader';
import requireAuth from './Utils/RequireAuth';
import requireGuest from './Utils/RequireGuest';
import RequireRole from './Utils/RequireRole';
import PageNotFound from '../pages/errors/PageNotFound';
import config from '../config';
import NotificationGeneral from '../pages/notifications/NotificationGeneral';
import Notifications from '../pages/notifications/Notifications';
import CreateProjectReservation from '../pages/reservations/CreateReservation/CreateProjectReservation';
import CreateSingleReservationClusterAdmin from '../pages/reservations/CreateReservation/ClusterAdmin/CreateSingleReservationClusterAdmin';
import Maps from '../pages/maps/Maps';
import News from '../pages/news/index';
import Users from '../pages/users/Users';
import SeeOthers from '../pages/news/SeeOthers';
import ExternalNews from '../pages/news/ExternalNews';
import ExternalSeeOthers from '../pages/news/ExternalSeeOthers';
import ExternalPostDetail from '../pages/news/ExternalPostDetail';
import SearchNews from '../pages/news/SearchNews';

// const Home = Loadable({
//   loader: () => import('../pages/home'),
//   loading: LoadingRoutes,
// });
const Login = Loadable({
  loader: () => import('../pages/login'),
  loading: LoadingRoutes,
});
const OAuthCallback = Loadable({
  loader: () => import('../pages/login/OAuthCallback'),
  loading: LoadingRoutes,
});
const ForgotPassword = FeatureFlag.USE_FORGOT_PASSWORD
  ? Loadable({
      loader: () => import('../pages/auth/ForgotPassword'),
      loading: LoadingRoutes,
    })
  : null;
const AuthOTP = Loadable({
  loader: () => import('../pages/auth/AuthOTP'),
  loading: LoadingRoutes,
});
// const Users = Loadable({
//   loader: () => import('../pages/users/Users'),
//   loading: LoadingRoutes,
// });
const Profile = Loadable({
  loader: () => import('../pages/profile'),
  loading: LoadingRoutes,
});
const Reservations = Loadable({
  loader: () => import('../pages/reservations/Me/MyReservationList'),
  loading: LoadingRoutes,
});
const WFOReservations = FeatureFlag.USE_WFO_RESERVE
  ? Loadable({
      loader: () =>
        import(
          '../pages/reservations/Me/MyWFOReservationList/MyWFOReservationList'
        ),
      loading: LoadingRoutes,
    })
  : null;
const CreateWFOReservation = FeatureFlag.USE_WFO_RESERVE
  ? Loadable({
      loader: () =>
        import(
          '../pages/reservations/CreateReservation/CreateWFOReservation/CreateWFOReservation'
        ),
      loading: LoadingRoutes,
    })
  : null;
const ReserveMeetingRoom = Loadable({
  loader: () =>
    import('../pages/reservations/CreateReservation/CreateReservation'),
  loading: LoadingRoutes,
});
const ReservationDetail = Loadable({
  loader: () => import('../pages/reservations/ReservationDetail'),
  loading: LoadingRoutes,
});
const ReservationDetailReceptionist = Loadable({
  loader: () =>
    import('../pages/reservations/Receptionist/ReservationDetailReceptionist'),
  loading: LoadingRoutes,
});
const ReservationDetailLiasonOfficer = Loadable({
  loader: () =>
    import(
      '../pages/reservations/LiasonOfficer/ReservationDetailLiasonOfficer'
    ),
  loading: LoadingRoutes,
});
const ReservationConfirmation = Loadable({
  loader: () => import('../pages/reservations/ReservationConfirmation'),
  loading: LoadingRoutes,
});
const ReservationClusterAdmin = Loadable({
  loader: () =>
    import('../pages/reservations/ClusterAdmin/ReservationClusterAdmin'),
  loading: LoadingRoutes,
});
const ReservationClusterSIC = Loadable({
  loader: () => import('../pages/reservations/ClusterSIC/ReservationList'),
  loading: LoadingRoutes,
});
const BeverageList = Loadable({
  loader: () => import('../pages/reservations/Pantry/BeverageList'),
  loading: LoadingRoutes,
});
const ReservationLiasonOfficer = Loadable({
  loader: () =>
    import(
      '../pages/reservations/LiasonOfficer/ReservationLiasonOfficerModule'
    ),
  loading: LoadingRoutes,
});
const ReservationReceptionist = Loadable({
  loader: () =>
    import('../pages/reservations/Receptionist/ReservationReceptionist'),
  loading: LoadingRoutes,
});
// const Maps = Loadable({
//   loader: () => import('../pages/errors/UnderConstruction'),
//   loading: LoadingRoutes,
// });
// const Maps = Loadable({
//   loader: () => import('../pages/maps/Maps'),
//   loading: LoadingRoutes,
// });
const CreateBeverage = Loadable({
  loader: () => import('../pages/menus/CreateMenu'),
  loading: LoadingRoutes,
});
const EditMenu = Loadable({
  loader: () => import('../pages/menus/EditMenu'),
  loading: LoadingRoutes,
});
const EditReservation = Loadable({
  loader: () => import('../pages/reservations/EditReservation'),
  loading: LoadingRoutes,
});
const RescheduleReservation = Loadable({
  loader: () => import('../pages/reservations/RescheduleReservation'),
  loading: LoadingRoutes,
});
const OrderList = Loadable({
  loader: () => import('../pages/reservations/Pantry/OrderList'),
  loading: LoadingRoutes,
});
const ReservationApprovalRequest = Loadable({
  loader: () =>
    import('../pages/reservations/ApprovalRequest/ReservationApprovalRequest'),
  loading: LoadingRoutes,
});
// const News = Loadable({
//   loader: () => import('../pages/news/index'),
//   loading: LoadingRoutes,
// });
// const SearchNews = Loadable({
//   loader: () => import('../pages/news/SearchNews'),
//   loading: LoadingRoutes,
// });
const ScanQRCode = Loadable({
  loader: () => import('../pages/reservations/Receptionist/ScanQRCode'),
  loading: LoadingRoutes,
});
const ScanQRCodeResult = Loadable({
  loader: () => import('../pages/reservations/Receptionist/ScanQRCodeResult'),
  loading: LoadingRoutes,
});
const ReceptionistReport = Loadable({
  loader: () => import('../pages/reservations/Receptionist/ReceptionistReport'),
  loading: LoadingRoutes,
});
const PostDetail = Loadable({
  loader: () => import('../pages/news/PostDetail'),
  loading: LoadingRoutes,
});
const NewsByDepartment = Loadable({
  loader: () => import('../pages/news/NewsByDepartment'),
  loading: LoadingRoutes,
});
const Eula = Loadable({
  loader: () => import('../pages/eula/Eula'),
  loading: LoadingRoutes,
});
const CreateReservationGroupClusterAdmin = Loadable({
  loader: () =>
    import(
      '../pages/reservations/CreateReservation/ClusterAdmin/CreateReservationGroupClusterAdmin'
    ),
  loading: LoadingRoutes,
});

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/login" component={requireGuest(Login)} />
        <Route
          exact
          path="/oauth/callback"
          component={requireGuest(OAuthCallback)}
        />
        {FeatureFlag.USE_FORGOT_PASSWORD ? (
          <Route
            exact
            path="/forgot-password"
            component={requireGuest(ForgotPassword)}
          />
        ) : null}
        <Route exact path="/otp" component={requireGuest(AuthOTP)} />
        <Route exact path="/people" component={requireAuth(Users)} />
        <Route exact path="/user/:id" component={requireAuth(Profile)} />
        <Route
          exact
          path="/reservations"
          component={requireAuth(Reservations)}
        />
        {FeatureFlag.USE_WFO_RESERVE ? (
          <Route
            exact
            path="/reservations/wfo"
            component={requireAuth(WFOReservations)}
          />
        ) : null}
        <Route
          exact
          path="/reservations/create/single"
          component={requireAuth(ReserveMeetingRoom)}
        />
        <Route
          exact
          path="/reservations/create/project"
          component={requireAuth(CreateProjectReservation)}
        />
        {FeatureFlag.USE_WFO_RESERVE ? (
          <Route
            exact
            path="/reservations/create/wfo"
            component={requireAuth(CreateWFOReservation)}
          />
        ) : null}
        <Route
          exact
          path="/reservations/confirmation"
          component={requireAuth(ReservationConfirmation)}
        />
        <Route
          exact
          path="/reservations/pantry"
          component={requireAuth(
            RequireRole(OrderList, [
              config.roles.PANTRY_ADMIN,
              config.roles.SUPER_ADMIN,
            ])
          )}
        />
        <Route
          exact
          path="/reservations/pantry/beverages"
          component={requireAuth(BeverageList)}
        />
        <Route
          exact
          path="/reservations/liason-officer"
          component={requireAuth(ReservationLiasonOfficer)}
        />
        <Route
          exact
          path="/reservations/receptionist"
          component={requireAuth(ReservationReceptionist)}
        />
        <Route
          exact
          path="/reservations/approval-request"
          component={requireAuth(ReservationApprovalRequest)}
        />
        <Route
          exact
          path="/reservations/:id"
          component={requireAuth(ReservationDetail)}
        />
        <Route
          exact
          path="/reservations/:id/liason-officer"
          component={requireAuth(ReservationDetailLiasonOfficer)}
        />
        <Route
          exact
          path="/reservations/:id/receptionist"
          component={requireAuth(ReservationDetailReceptionist)}
        />
        <Route
          exact
          path="/reservations/:id/approval-request"
          component={requireAuth(ReservationDetail)}
        />
        <Route
          exact
          path="/reservations/:id/cluster-admin"
          component={requireAuth(ReservationDetail)}
        />
        <Route
          exact
          path="/reservations/:id/cluster-sic"
          component={requireAuth(ReservationDetail)}
        />
        <Route exact path="/maps" component={requireAuth(Maps)} />
        <Route
          exact
          path="/beverages/create"
          component={requireAuth(CreateBeverage)}
        />
        {/* CLUSTER ADMIN */}
        <Route
          exact
          path="/reservations/cluster/admin"
          component={requireAuth(ReservationClusterAdmin)}
        />
        {/* CLUSTER SIC */}
        {FeatureFlag.USE_CLUSTER_SIC ? (
          <Route
            exact
            path="/reservations/cluster/sic"
            component={requireAuth(ReservationClusterSIC)}
          />
        ) : null}
        <Route
          exact
          path="/pantry/menus/:id/edit"
          component={requireAuth(
            RequireRole(EditMenu, [
              config.roles.PANTRY_ADMIN,
              config.roles.SUPER_ADMIN,
            ])
          )}
        />
        <Route
          exact
          path="/reservation/create/cluster-admin/single"
          component={requireAuth(CreateSingleReservationClusterAdmin)}
        />
        <Route
          exact
          path="/reservation/create/cluster-admin/group"
          component={requireAuth(CreateReservationGroupClusterAdmin)}
        />
        <Route
          exact
          path="/reservations/:id/edit/cluster-admin"
          component={requireAuth(EditReservation)}
        />
        {FeatureFlag.USE_RESERVATION_BOOKER_RESCHEDULE ? (
          <Route
            exact
            path="/reservations/:id/reschedule"
            component={requireAuth(RescheduleReservation)}
          />
        ) : null}
        <Route
          exact
          path="/reservations/receptionist/report"
          component={requireAuth(ReceptionistReport)}
        />
        <Route
          exact
          path="/reservations/receptionist/scan"
          component={requireAuth(ScanQRCode)}
        />
        <Route
          exact
          path="/reservations/receptionist/scan/result"
          component={requireAuth(ScanQRCodeResult)}
        />
        {/*<Route exact path="/" component={requireAuth(Home)} />*/}
        <Route exact path="/" component={requireAuth(Users)} />
        <Route
          exact
          path="/news/external/post/:id"
          component={requireAuth(ExternalPostDetail)}
        />
        <Route
          exact
          path="/news/post/:id"
          component={requireAuth(PostDetail)}
        />
        <Route exact path="/news/search" component={requireAuth(SearchNews)} />
        <Route
          exact
          path="/news/external"
          component={requireAuth(ExternalNews)}
        />
        <Route
          path="/news/external/others/:department_id?"
          component={requireAuth(ExternalSeeOthers)}
        />
        <Route
          path="/news/:category/others/:department_id?"
          component={requireAuth(SeeOthers)}
        />
        <Route path="/news/:category?" component={requireAuth(News)} />
        <Route
          exact
          path="/news/department/:id"
          component={requireAuth(NewsByDepartment)}
        />
        <Route
          exact
          path="/notifications"
          component={requireAuth(Notifications)}
        />
        <Route
          exact
          path="/notifications/general"
          component={requireAuth(NotificationGeneral)}
        />
        <Route exact path="/eula" component={requireAuth(Eula)} />
        <Route path="*" component={PageNotFound} />
      </Switch>
    </BrowserRouter>
  );
};

export default AppRoutes;
