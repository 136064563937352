import {avatarURL, notificationSender} from '../../utils/helpers';
import React from 'react';
import {MediaImage} from '../../styles';
import {Media, UncontrolledTooltip} from 'reactstrap';
import moment from 'moment';
import styled, {css} from 'styled-components';

const MediaObject = props => {
  const {notification, children} = props;
  const {created_at} = notification || {};
  const {avatar, name} = notificationSender(notification) || {};

  const notifDate = created_at ? moment(created_at) : null;
  const date = notifDate ? notifDate.format('DD MMM') : null;
  const time = notifDate ? notifDate.format('HH:mm') : null;
  const dateInfo = notifDate ? notifDate.format('DD MMM YYYY hh:mm') : null;

  return (
    <MediaLeft>
      {children ? (
        children
      ) : (
        <MediaImage object src={avatarURL(avatar)} alt={name} />
      )}
      <MediaDateTime id={`dateInfo${notification.id}`}>
        <MediaDate>{date}</MediaDate>
        <MediaDate>{time}</MediaDate>
      </MediaDateTime>
      <UncontrolledTooltip
        placement="right"
        target={`dateInfo${notification.id}`}>
        {dateInfo}
      </UncontrolledTooltip>
    </MediaLeft>
  );
};

const muted = css`
  color: #757575;
  font-size: 12px;
`;

const MediaLeft = styled(Media).attrs({
  left: true,
  href: '#',
})`
  text-align: center;
  width: 50px;
`;

const MediaDateTime = styled.div`
  ${muted};
`;
const MediaDate = styled.div`
  ${muted};
`;

export default MediaObject;
