import React from 'react';
import {notificationSender} from '../../../utils/helpers';
import {
  MediaBody,
  MediaHeading,
  MediaList,
  MediaNotifText,
  MediaUserPosition,
} from '../../../styles';
import MediaObject from '../MediaObject';

const MediaReplyAskLocation = props => {
  const {notification} = props;
  const user = notificationSender(notification);

  return (
    <MediaList>
      <MediaObject notification={notification} />
      <MediaBody>
        <MediaHeading heading>
          {user.name}
          <MediaNotifText>Shared Current Location</MediaNotifText>
        </MediaHeading>
        <MediaUserPosition>{notification.body_message}</MediaUserPosition>
      </MediaBody>
    </MediaList>
  );
};

export default MediaReplyAskLocation;
