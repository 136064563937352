import {takeEvery, put, call} from 'redux-saga/effects';
import {
  GET_FLOOR_ACTION,
  GET_FLOOR_FAILED,
  GET_FLOOR_REQUEST,
  GET_FLOOR_SUCCESS,
  GET_FLOORS_FOR_GANTT_CHART,
  GET_FLOORS_FOR_GANTT_CHART_FAILED,
  GET_FLOORS_FOR_GANTT_CHART_REQUEST,
  GET_FLOORS_FOR_GANTT_CHART_SUCCESS,
  GET_POI_BY_FLOOR_ACTION,
  GET_POI_BY_FLOOR_FAILED,
  GET_POI_BY_FLOOR_REQUEST,
  GET_POI_BY_FLOOR_SUCCESS,
  SET_FLOORS,
  SET_PAGINATION,
} from '../actions/actionsList';
import Floor from '../api/Floor';
import config from '../config';
import {setGlobalError} from '../actions/global';

/**
 * Get Floor List.
 *
 * @returns {IterableIterator<*>}
 */
function* getFloorActionHandler(action) {
  const {payload} = action;

  try {
    yield put({type: GET_FLOOR_REQUEST});
    const {page, limit, perPage, sortBy, key, descending} = payload || {};
    const responseData = yield call(Floor.fetch, {
      page,
      limit,
      perPage,
      sortBy,
      key,
      descending,
    });
    const {code, data, message} = responseData || {};
    if (code === 200) {
      yield put({type: GET_FLOOR_SUCCESS});
      yield put({type: SET_FLOORS, payload: {data: (data || {}).floor}});
      yield put({
        type: SET_PAGINATION,
        payload: {pagination: (data || {}).pagginate},
      });
    } else {
      yield put({type: GET_FLOOR_FAILED});
      yield put(setGlobalError(message));
    }
  } catch (e) {
    yield put({type: GET_FLOOR_FAILED});
    yield put(setGlobalError(config.INTERNAL_SERVER_ERROR));
  }
}

function* getPoiByFloorActionHandler(action) {
  const {payload} = action;
  const {id, page, key, perPage, loadMore, callback} = payload || {};

  yield put({type: GET_POI_BY_FLOOR_REQUEST, payload: {loadMore}});

  const responseData = yield call(Floor.getPoiByFloor, {
    id,
    page,
    key,
    perPage,
  });
  const {code, data, message} = responseData || {};

  if (code === 200) {
    const {content, pagginate} = data || {};
    yield put({
      type: GET_POI_BY_FLOOR_SUCCESS,
      payload: {
        data: content,
        pagination: {
          currentPage: +pagginate.page,
          lastPage: +pagginate.lastPage,
          total: +pagginate.total,
          perPage: +pagginate.perPage,
        },
        loadMore,
      },
    });
    if (callback) {
      callback();
    }
  } else {
    yield put({type: GET_POI_BY_FLOOR_FAILED});
    yield put(setGlobalError(message));
  }
}

function* getFloorsForGanttChartHandler() {
  yield put({type: GET_FLOORS_FOR_GANTT_CHART_REQUEST});
  const responseData = yield call(Floor.getForGanttChart);
  const {code, data, message} = responseData || {};
  if (code === 200) {
    yield put({type: GET_FLOORS_FOR_GANTT_CHART_SUCCESS});
    yield put({type: SET_FLOORS, payload: {data}});
  } else {
    yield put({type: GET_FLOORS_FOR_GANTT_CHART_FAILED});
    yield put(setGlobalError(message));
  }
}

/**
 * Reservation Saga.
 *
 * @returns {IterableIterator<*|ForkEffect>}
 */
export default function*() {
  yield takeEvery(GET_FLOOR_ACTION, getFloorActionHandler);
  yield takeEvery(GET_POI_BY_FLOOR_ACTION, getPoiByFloorActionHandler);
  yield takeEvery(GET_FLOORS_FOR_GANTT_CHART, getFloorsForGanttChartHandler);
}
