import React from 'react';
import {notificationSender} from '../../../utils/helpers';
import {
  MediaBody,
  MediaHeading,
  MediaList,
  MediaNotifText,
} from '../../../styles';
import MediaObject from '../MediaObject';

const MediaAskLocation = props => {
  const {notification} = props;
  const user = notificationSender(notification);

  return (
    <MediaList>
      <MediaObject notification={notification} />
      <MediaBody>
        <MediaHeading heading>
          {user.name}
          <MediaNotifText>Asked your location</MediaNotifText>
        </MediaHeading>
        {/*<MediaUserPosition>{user.businnes_name}</MediaUserPosition>*/}
        {/*<Button color="primary" small>*/}
        {/*Share My Location*/}
        {/*</Button>*/}
      </MediaBody>
    </MediaList>
  );
};

export default MediaAskLocation;
