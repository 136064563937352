import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Col, Row} from '../../styles/grid';
import {Field, reduxForm} from 'redux-form';
import styled from 'styled-components';
import {FormGroup} from '../Profile/FormSubmitOON';
import {FormLabel} from '../Profile/edit';
import {InputFieldStyle} from '../Profile/edit/Fields';
import {Button, TextError} from '../../styles';
import {closeModal} from '../../actions/modal';
import {bindActionCreators} from 'redux';

class BookReservationRoom extends Component {
  componentDidMount() {
    console.log(this.props);
  }

  onSubmit = values => {
    console.log(values);
  };

  render() {
    const {onSubmit} = this;
    const {handleSubmit} = this.props;
    const startTimes = [...Array(24)].map((item, index) => index + 1);
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <FormLabel>Event Title</FormLabel>
          <Field name="title" component={TitleField} />
        </FormGroup>
        <FormGroup>
          <Row>
            <Col size={3}>
              <FormLabel>Event Date</FormLabel>
              <Field name="date" component={DateField} />
            </Col>
            <Col md={2}>
              <FormLabel>Starts</FormLabel>
              <Field
                name="startTime"
                component={StartTimeField}
                options={startTimes}
              />
            </Col>
            <Col md={2}>
              <FormLabel>Ends</FormLabel>
              <Field
                name="endTime"
                component={EndTimeField}
                options={startTimes}
              />
            </Col>
            <Col />
          </Row>
        </FormGroup>
        <FormGroup>
          <FormLabel>Event Attendance</FormLabel>
          <Field name="attendance" component={AttendanceField} />
        </FormGroup>
        <FormGroup>
          <FormLabel>Meeting Rooms</FormLabel>
          <Field name="meetingRoom" component={MeetingRoomField} />
        </FormGroup>
        <FormGroup>
          <Row>
            <Col>
              <Button>Cancel</Button>
            </Col>
            <Col flex justifyContent="flex-end">
              <Button color="primary" uppercase>
                Reserve
              </Button>
            </Col>
          </Row>
        </FormGroup>
      </form>
    );
  }
}

const StyledTitleField = styled(Field).attrs({
  name: 'title',
  component: 'input',
})`
  ${props => InputFieldStyle};
`;

const TitleField = ({
  input,
  placeholder,
  defaultValue,
  meta: {touched, error},
}) => {
  return (
    <div>
      <StyledTitleField />
      {touched && error && <TextError>{error}</TextError>}
    </div>
  );
};

const StyledDateField = styled(Field).attrs({
  name: 'date',
  component: 'input',
})`
  ${props => InputFieldStyle};
`;

const DateField = ({
  input,
  placeholder,
  defaultValue,
  meta: {touched, error},
}) => {
  return (
    <div>
      <StyledDateField />
      {touched && error && <TextError>{error}</TextError>}
    </div>
  );
};

const StyledStartTimeField = styled(Field).attrs({
  name: 'startTime',
  component: 'select',
})`
  ${props => InputFieldStyle};
`;

const StartTimeField = ({
  input,
  placeholder,
  defaultValue,
  options,
  meta: {touched, error},
}) => {
  return (
    <div>
      <StyledStartTimeField>
        {options.map(item => (
          <option key={item} value={item}>
            {item}.00
          </option>
        ))}
      </StyledStartTimeField>
      {touched && error && <TextError>{error}</TextError>}
    </div>
  );
};

const StyledEndTimeField = styled(Field).attrs({
  name: 'endTime',
  component: 'select',
})`
  ${props => InputFieldStyle};
`;

const EndTimeField = ({
  input,
  placeholder,
  defaultValue,
  options,
  meta: {touched, error},
}) => {
  return (
    <div>
      <StyledEndTimeField>
        {options.map(item => (
          <option key={item} value={item}>
            {item}.00
          </option>
        ))}
      </StyledEndTimeField>
      {touched && error && <TextError>{error}</TextError>}
    </div>
  );
};

const StyledAttendanceField = styled(Field).attrs({
  name: 'attendance',
  component: 'input',
})`
  ${props => InputFieldStyle};
`;

const AttendanceField = ({
  input,
  placeholder,
  defaultValue,
  meta: {touched, error},
}) => {
  return (
    <div>
      <StyledAttendanceField />
      {touched && error && <TextError>{error}</TextError>}
    </div>
  );
};

const StyledMeetingRoomField = styled(Field).attrs({
  name: 'meetingRoom',
  component: 'input',
})`
  ${props => InputFieldStyle};
`;

const MeetingRoomField = ({
  input,
  placeholder,
  defaultValue,
  meta: {touched, error},
}) => {
  return (
    <div>
      <StyledMeetingRoomField />
      {touched && error && <TextError>{error}</TextError>}
    </div>
  );
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    closeModal: bindActionCreators(closeModal, dispatch),
  };
}

const validate = values => {
  const errors = {};
  if (!values.title) {
    errors.title = 'Event title is required';
  }
  if (!values.date) {
    errors.date = 'Event date is required';
  }
  if (!values.startTime) {
    errors.startTime = 'Start time is required';
  }
  if (!values.endTime) {
    errors.endTime = 'End time is required';
  }
  if (!values.attendance) {
    errors.attendance = 'Attendance is required';
  }
  if (!values.meetingRoom) {
    errors.meetingRoom = 'Meeting room is required';
  }
  return errors;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'book-reservation-room',
    validate,
    // enableReinitialize: true,
  })(BookReservationRoom)
);
