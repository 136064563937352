import React, {Component} from 'react';
import {
  ContentHeader,
  MainContent,
  ButtonAddonSearch,
  EmptyAlert,
  EmptyRow,
  InputGroupSearch,
  SearchIcon,
  TableCard,
  TableCardHeader,
} from '../../../styles';
import {PageHeading, SubHeading} from '../../../styles/typography';
import styled, {createGlobalStyle} from 'styled-components';
import {Col, Row, media} from '../../../styles/grid';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {openModal} from '../../../actions/modal';
import LoadingComponent from '../../../components/UIKit/LoadingComponent';
import {getReservationListForLiasonOfficerRequest} from '../../../actions/reservation';
import ApplicationLayout from '../../../components/Layouts/ApplicationLayout';
import {Container} from '../../../styles/index';
import TablePagination from '../../../components/Pagination/TablePagination';
import {CardBody, CardTitle} from '../../../styles/card';
import ReservationRow from './ReservationRow';
import {
  UncontrolledTooltip,
  InputGroup,
  InputGroupAddon,
  Table,
} from 'reactstrap';
import AccessDeniedPage from '../../../components/AccessDeniedPage';

class ReservationLiasonOfficerModule extends Component {
  timer = null;

  componentDidMount() {
    this.fetch();
  }

  handlePagination = pagination => {
    this.fetch({page: pagination.currentPage});
  };

  fetch = (params = {}) => {
    this.props.getReservationList({
      page: params.page || 1,
      descending: false,
      sortBy: 'updated_at',
      ...params,
    });
  };

  handleSearch = event => {
    clearTimeout(this.timer);
    const key = event.target.value;
    if (key) {
      if (key.length > 2) {
        this.timer = setTimeout(() => {
          this.fetch({key});
        }, 500);
      }
    } else {
      this.timer = setTimeout(() => {
        this.fetch();
      }, 500);
    }
  };

  hasFunctions = functions => {
    const {userData} = this.props;
    const userFunctions = (userData.functions || []).map(item => item.key);
    return functions.filter(fun => userFunctions.includes(fun)).length > 0;
  };

  render() {
    const {reservations, loading, pagination} = this.props;
    const isEmpty = !reservations || reservations.length < 1;
    const isLO = this.hasFunctions(['lo']);

    if (!isLO) {
      return <AccessDeniedPage />;
    }

    return (
      <ApplicationLayout withoutFooter>
        <ContentHeader>
          <Container>
            <Row>
              <Col>
                <CustomPageHeading>Liason Officer</CustomPageHeading>
                <CustomSubHeading>Today's reservation.</CustomSubHeading>
              </Col>
            </Row>
          </Container>
        </ContentHeader>
        <MainContent>
          <Container>
            <PageHeader>Visitor / client meeting of the day</PageHeader>
            <ContentSpacer />
            <TableCard>
              <TableCardHeader>
                <Row>
                  <Col flex alignItems="center" xs={12} sm={3}>
                    <CardTitle noMargin>Liason Officer Module</CardTitle>
                  </Col>
                  <Col flex alignItems="center" xs={12} sm={4}>
                    <InputGroup>
                      <InputGroupAddon addonType="append">
                        <ButtonAddonSearch
                          onClick={this.handleSearch}
                          disabled={loading}
                          color="primary">
                          <SearchIcon />
                        </ButtonAddonSearch>
                      </InputGroupAddon>
                      <InputGroupSearch
                        id="searchField"
                        onKeyUp={this.handleSearch}
                        title="Search by guest name, meeting name, meeting room or host name"
                        placeholder="Search by guest name, meeting name, meeting room or host name"
                        ref={input => (this.searchInput = input)}
                        disabled={loading}
                      />
                    </InputGroup>
                    <UncontrolledTooltip placement="top" target="searchField">
                      Search by guest name, meeting name, meeting room or host
                      name
                    </UncontrolledTooltip>
                  </Col>
                </Row>
              </TableCardHeader>
              <Table responsive>
                <thead>
                  <tr>
                    <th>Arrival Time</th>
                    <th>Guest Name</th>
                    <th>Meeting Name</th>
                    <th>Meeting Room</th>
                    <th>Host Name</th>
                    <th className="center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {loading && (
                    <tr>
                      <td colSpan={6}>
                        <LoadingComponent />
                      </td>
                    </tr>
                  )}
                  {isEmpty && !loading && (
                    <EmptyRow>
                      <td colSpan={8} className="text-center">
                        <EmptyAlert>No data available</EmptyAlert>
                      </td>
                    </EmptyRow>
                  )}
                  {!loading &&
                    (reservations || []).map((item, index) => {
                      return (
                        <ReservationRow
                          type="liason-officer"
                          item={item}
                          key={index}
                        />
                      );
                    })}
                </tbody>
              </Table>
              <CardBody>
                <TablePagination
                  onChange={this.handlePagination}
                  totalItems={pagination.total}
                />
              </CardBody>
            </TableCard>
          </Container>
        </MainContent>
        <CreateGlobalStyle />
      </ApplicationLayout>
    );
  }
}

const CreateGlobalStyle = createGlobalStyle`
  body {
    background-color: #f8f8f8!important;
  }
`;

export const CustomPageHeading = styled(PageHeading)`
  margin: 0;
  padding: 0;
  font-size: 20px;
  font-weight: bold;
`;

export const CustomSubHeading = styled(SubHeading)`
  margin: 0;
  padding: 0;
  color: #505050;
  font-size: 16px;
`;

const respCustomCol = {
  xs: media.xs`
    margin-top: 10px;
    justify-content: flex-start;
  `,
};
export const CustomCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  ${respCustomCol.xs};
`;

export const ContentSpacer = styled.div`
  clear: both;
  margin-bottom: 20px;
`;

export const PageHeader = styled.h2`
  font-size: 24px;
  color: #757575;
  font-weight: normal;
  margin-top: 15px;
`;

function mapStateToProps(state) {
  const {liasonOfficers: reservations, loading} = state.reservation;
  const {pagination} = state.global;
  return {
    reservations,
    loading,
    pagination,
    userData: state.auth.userData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    openModal: bindActionCreators(openModal, dispatch),
    getReservationList: bindActionCreators(
      getReservationListForLiasonOfficerRequest,
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReservationLiasonOfficerModule);
