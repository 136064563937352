import React, {Component} from 'react';
import {connect} from 'react-redux';
import {userHasRole} from '../../utils/helpers';
import AccessDeniedPage from '../AccessDeniedPage';

export default function(ComposedComponent, roles) {
  class RequireRole extends Component {
    render() {
      const authorized = userHasRole(roles);

      if (!authorized) {
        return <AccessDeniedPage />;
      }

      return <ComposedComponent {...this.props} />;
    }
  }

  function mapStateToProps(state) {
    return {};
  }

  return connect(mapStateToProps, null)(RequireRole);
}
