import {
  GET_EULA_NOW_FAILED,
  GET_EULA_NOW_REQUEST,
  GET_EULA_NOW_SUCCESS,
} from '../actions/actionsList';

let initialState = {
  eula: null,
  loading: false,
};

export default function(state = initialState, action) {
  const {type, payload} = action;

  switch (type) {
    case GET_EULA_NOW_REQUEST: {
      return {...state, loading: true, eula: null};
    }

    case GET_EULA_NOW_SUCCESS: {
      const {data} = payload;
      return {...state, loading: false, eula: data};
    }

    case GET_EULA_NOW_FAILED: {
      return {...state, loading: false};
    }

    default:
      return state;
  }
}
