import {
  MediaBody,
  MediaHeading,
  MediaList,
  NotifMessage,
  NotifMessageLink,
} from '../../../styles';
import React, {useEffect, useState} from 'react';
import MediaObject from '../MediaObject';
import {newsDetailURL, parseBodyMessage} from '../../../utils/helpers';
import MaterialIcon from '../../../components/UIKit/MaterialIcon';

const NewsAnnouncement = props => {
  const {notification} = props;

  const [bodyMessage, setBodyMessage] = useState({});
  const [message, setMessage] = useState();

  useEffect(() => {
    const {message: msg, body} = parseBodyMessage(notification);

    setBodyMessage(body || {});
    setMessage(msg);
  }, [notification]);

  const {news_id: id, news_title} = bodyMessage;
  const to = newsDetailURL(id);

  return (
    <MediaList>
      <MediaObject notification={notification}>
        <MaterialIcon color="warning" size={2} icon="bullhorn" />
      </MediaObject>
      <MediaBody>
        <MediaHeading heading>News Announcement</MediaHeading>
        <NotifMessage>{message}</NotifMessage>
        <NotifMessageLink to={to} target="_blank">
          {news_title}
        </NotifMessageLink>
      </MediaBody>
    </MediaList>
  );
};

export default NewsAnnouncement;
