import styled from 'styled-components';

export const PageHeading = styled.h1`
  font-size: 20px;
  font-weight: bold;
  margin: 0;
  padding: 0;
`;

export const SubHeading = styled.h3`
  font-weight: normal;
  color: #949494;
  padding: 0;
  margin: 0;
  font-size: 16px;
`;

export const PageTitle = styled.h1`
  font-size: 30px;
  font-weight: normal;
  color: #505050;
`;
