import {
  ADD_GUEST_ACTION,
  APPROVE_RESERVATION_BY_CLUSTER_ADMIN_REQUEST,
  APPROVE_RESERVATION_BY_CLUSTER_SIC_REQUEST,
  APPROVE_RESERVATION_REQUEST,
  BOOK_GROUP_RESERVATION_CLUSTER_ADMIN_REQUEST,
  BOOK_RESERVATION_CLUSTER_ADMIN_REQUEST,
  BOOK_RESERVATION_REQUEST,
  BOOK_RESERVATION_WFO_REQUEST,
  BOOKING_DONE,
  BOOKING_RESET,
  CANCEL_RESERVATION_REQUEST,
  CANCEL_WFO_RESERVATION_REQUEST,
  CLEAR_GUEST_RESERVATION_ID,
  FIND_MEETING_ROOM_CLUSTER_ADMIN_REQUEST,
  FIND_MEETING_ROOM_REQUEST,
  FIND_ROOM_WITH_QRCODE_REQUEST,
  GET_MY_CLUSTER_ADMIN_ACTION,
  GET_MY_CLUSTER_SIC_ACTION,
  GET_RESERVATION_BY_POI_ACTION,
  GET_RESERVATION_CLUSTER_ADMIN_ACTION,
  GET_RESERVATION_CLUSTER_SIC_ACTION,
  GET_RESERVATION_DETAIL_ACTION,
  GET_RESERVATION_LIST_APPROVAL_REQUEST,
  GET_RESERVATION_LIST_BY_DATE_REQUEST,
  GET_RESERVATION_LIST_FOR_LIASON_OFFICER_REQUEST,
  GET_RESERVATION_LIST_FOR_RECEPTIONIST_REQUEST,
  GET_RESERVATION_LIST_REQUEST,
  GET_WFO_RESERVATION_LIST,
  REJECT_RESERVATION_BY_CLUSTER_ADMIN_REQUEST,
  REJECT_RESERVATION_BY_CLUSTER_SIC_REQUEST,
  REJECT_RESERVATION_REQUEST,
  RELEASE_RESERVATION_REQUEST,
  RESCHEDULE_RESERVATION_ACTION,
  SET_GUEST_RESERVATION_ID,
  SET_MEETING_ROOM,
  SET_MEETING_ROOM_PARAMS,
  SET_RESERVATION,
  UPDATE_RESERVATION_REQUEST,
  WFO_FORM_RESET,
} from './actionsList';

export const selectMeetingRoom = room => {
  return {type: SET_MEETING_ROOM, payload: {room}};
};

/**
 * Request for getting reservation cluster admin.
 *
 * @param params
 * @returns {{type: string, payload: {page: *, perPage: *, sortBy: *, keyword: *, descending: *}}}
 */
export const getReservationClusterAdminRequest = params => {
  params = params || {};
  const {
    page,
    perPage,
    sortBy,
    keyword,
    descending,
    clusterId,
    status,
  } = params;
  return {
    type: GET_RESERVATION_CLUSTER_ADMIN_ACTION,
    payload: {page, perPage, sortBy, keyword, descending, clusterId, status},
  };
};

/**
 * Request for getting reservation for cluster sic.
 *
 * @param params
 * @returns {{type: string, payload: {page: *, perPage: *, sortBy: *, keyword: *, descending: *}}}
 */
export const getReservationClusterSICRequest = params => {
  params = params || {};
  const {
    page,
    perPage,
    sortBy,
    keyword,
    descending,
    clusterId,
    status,
  } = params;
  return {
    type: GET_RESERVATION_CLUSTER_SIC_ACTION,
    payload: {page, perPage, sortBy, keyword, descending, clusterId, status},
  };
};

/**
 * Request for getting reservation data.
 *
 * @param params
 * @returns {{type: string, payload: {page: *, perPage: *, sortBy: *, keyword: *, descending: *}}}
 */
export const getReservationListRequest = params => {
  params = params || {};
  const {page, perPage, sortBy, keyword, descending, withPaginate} = params;
  return {
    type: GET_RESERVATION_LIST_REQUEST,
    payload: {page, perPage, sortBy, keyword, descending, withPaginate},
  };
};

/**
 * Request for getting wfo reservation data.
 *
 * @param params
 * @returns {{type: string, payload: {page: *, perPage: *, sortBy: *, keyword: *, descending: *}}}
 */
export const getWFOList = params => {
  return {
    type: GET_WFO_RESERVATION_LIST,
    payload: params || {},
  };
};

/**
 * Cancel WFO reservation
 *
 * @param params
 * @returns {{type: string, payload: {id: string|number}}}
 */
export const cancelWFOReserve = id => {
  return {
    type: CANCEL_WFO_RESERVATION_REQUEST,
    payload: {id},
  };
};
/**
 * Reset WFO reservation form
 * @returns {{type: string}}
 */
export const resetWFOForm = () => {
  return {
    type: WFO_FORM_RESET,
  };
};

/**
 * Get reservation for admin.
 *
 * @param params
 * @returns {{type: string, payload: {page: *, perPage: *, sortBy: *, key: *, descending: *}}}
 */
export const getReservationListForReceptionistRequest = params => {
  params = params || {};
  const {page, perPage, sortBy, key, descending} = params;
  return {
    type: GET_RESERVATION_LIST_FOR_RECEPTIONIST_REQUEST,
    payload: {page, perPage, sortBy, key, descending},
  };
};

/**
 * Get Reservation Detail by ID.
 *
 * @param id
 * @returns {{type: string, payload: {id: *}}}
 */
export const getReservationDetailRequest = id => {
  return {type: GET_RESERVATION_DETAIL_ACTION, payload: {id}};
};

/**
 * Find available meeting rooms.
 *
 * @param params
 * @returns {*}
 */
export const findRoomRequest = params => {
  params = params || {};
  const {
    start_time,
    end_time,
    check_in,
    check_in_release,
    booking_type,
    total_attendance,
    book_platform,
    key,
    reservation_type,
    video_conference,
    meeting_type,
  } = params;
  return {
    type: FIND_MEETING_ROOM_REQUEST,
    payload: {
      start_time,
      end_time,
      check_in,
      check_in_release,
      booking_type,
      total_attendance,
      book_platform,
      key,
      reservation_type,
      video_conference,
      meeting_type,
    },
  };
};

export const findRoomWithQRCodeRequest = params => {
  params = params || {};
  const {
    start_time,
    end_time,
    check_in,
    check_in_release,
    total_attendance,
    book_platform,
    key,
    qrcode,
  } = params;
  return {
    type: FIND_ROOM_WITH_QRCODE_REQUEST,
    payload: {
      start_time,
      end_time,
      check_in,
      check_in_release,
      total_attendance,
      book_platform,
      key,
      qrcode,
    },
  };
};

export const setMeetingRoomParams = params => {
  return {type: SET_MEETING_ROOM_PARAMS, payload: {params}};
};

export const setReservation = reservation => {
  return {type: SET_RESERVATION, payload: {reservation}};
};

export const bookReservationRequest = data => {
  return {type: BOOK_RESERVATION_REQUEST, payload: {data}};
};

export const reserveWFO = data => {
  return {type: BOOK_RESERVATION_WFO_REQUEST, payload: {data}};
};

export const bookReservationForClusterAdminRequest = data => {
  return {type: BOOK_RESERVATION_CLUSTER_ADMIN_REQUEST, payload: {data}};
};

export const bookGroupReservationClusterAdminRequest = data => {
  return {type: BOOK_GROUP_RESERVATION_CLUSTER_ADMIN_REQUEST, payload: {data}};
};

export const bookingDone = () => {
  return {type: BOOKING_DONE, payload: {}};
};

export const bookingReset = () => {
  return {type: BOOKING_RESET, payload: {}};
};

export const cancelReservationRequest = ({
  id,
  remarks,
  onCancel,
  key = 'reservations',
}) => {
  return {
    type: CANCEL_RESERVATION_REQUEST,
    payload: {id, remarks, onCancel, key},
  };
};

export const approveReservationRequest = (
  id,
  onApprove,
  key = 'reservations'
) => {
  return {type: APPROVE_RESERVATION_REQUEST, payload: {id, onApprove, key}};
};

export const approveByClusterAdminReservationRequest = (id, onApprove) => {
  return {
    type: APPROVE_RESERVATION_BY_CLUSTER_ADMIN_REQUEST,
    payload: {id, onApprove},
  };
};

export const approveByClusterSICReservationRequest = (id, onApprove) => {
  return {
    type: APPROVE_RESERVATION_BY_CLUSTER_SIC_REQUEST,
    payload: {id, onApprove},
  };
};

export const releaseReservationRequest = (
  id,
  onRelease,
  key = 'reservations'
) => {
  return {type: RELEASE_RESERVATION_REQUEST, payload: {id, onRelease, key}};
};

export const rejectReservationRequest = ({
  id,
  remarks,
  onReject,
  key = 'reservations',
}) => {
  return {
    type: REJECT_RESERVATION_REQUEST,
    payload: {id, remarks, onReject, key},
  };
};

export const rejectByClusterAdminReservationRequest = ({
  id,
  remarks,
  onReject,
}) => {
  return {
    type: REJECT_RESERVATION_BY_CLUSTER_ADMIN_REQUEST,
    payload: {id, remarks, onReject},
  };
};

export const rejectByClusterSICReservationRequest = ({
  id,
  remarks,
  onReject,
}) => {
  return {
    type: REJECT_RESERVATION_BY_CLUSTER_SIC_REQUEST,
    payload: {id, remarks, onReject},
  };
};

export const findRoomForClusterAdminRequest = params => {
  params = params || {};
  const {
    start_time,
    end_time,
    check_in,
    check_in_release,
    booking_type,
    total_attendance,
    book_platform,
    key,
    is_workspace,
    reservation_type,
    video_conference,
    meeting_type,
  } = params;
  return {
    type: FIND_MEETING_ROOM_CLUSTER_ADMIN_REQUEST,
    payload: {
      start_time,
      end_time,
      check_in,
      check_in_release,
      booking_type,
      total_attendance,
      book_platform,
      key,
      is_workspace,
      reservation_type,
      video_conference,
      meeting_type,
    },
  };
};

/**
 * Get reservation list for LO.
 *
 * @param params
 * @returns {{type: string, payload: {page: *, perPage: *, sortBy: *, key: *, descending: *, limit: *}}}
 */
export const getReservationListForLiasonOfficerRequest = (params = {}) => {
  const {page, perPage, sortBy, key, descending, limit} = params;
  return {
    type: GET_RESERVATION_LIST_FOR_LIASON_OFFICER_REQUEST,
    payload: {page, perPage, sortBy, key, descending, limit},
  };
};

/**
 * Get reservation list approval request.
 *
 * @param params
 * @returns {{type: string, payload: {page: *, perPage: *, sortBy: *, key: *, descending: *, limit: *}}}
 */
export const getReservationListApprovalRequest = params => {
  params = params || {};
  const {page, perPage, sortBy, key, descending, limit, is_pending} = params;
  return {
    type: GET_RESERVATION_LIST_APPROVAL_REQUEST,
    payload: {page, perPage, sortBy, key, descending, limit, is_pending},
  };
};

/**
 * Get reservation list by date.
 *
 * @param params
 * @returns {{type: string, payload: {page: *, perPage: *, sortBy: *, key: *, descending: *, limit: *}}}
 */
export const getReservationListByDateRequest = params => {
  params = params || {};
  const {
    page,
    perPage,
    sortBy,
    key,
    descending,
    limit,
    fromDate,
    toDate,
    status,
    show_all,
    meeting_type,
  } = params;
  return {
    type: GET_RESERVATION_LIST_BY_DATE_REQUEST,
    payload: {
      page,
      perPage,
      sortBy,
      key,
      descending,
      limit,
      fromDate,
      toDate,
      status,
      show_all,
      meeting_type,
    },
  };
};

/**
 * Update reservation.
 *
 * @param id
 * @param data
 * @returns {{type: string, payload: {id: *, data: *}}}
 */
export const updateReservationRequest = (id, data) => {
  return {
    type: UPDATE_RESERVATION_REQUEST,
    payload: {id, data},
  };
};

/**
 * Get My Cluster Admin.
 *
 * @returns {{type: string, payload: {}}}
 */
export const getMyClusterAdminRequest = () => {
  return {
    type: GET_MY_CLUSTER_ADMIN_ACTION,
    payload: {},
  };
};

/**
 * Get My Cluster SIC.
 *
 * @returns {{type: string, payload: {}}}
 */
export const getMyClusterSICRequest = () => {
  return {
    type: GET_MY_CLUSTER_SIC_ACTION,
    payload: {},
  };
};

/**
 * Reschedule reservation action.
 *
 * @param data
 * @returns {{type: *, payload: {data: *}}}
 */
export const rescheduleReservationAction = data => {
  return {
    type: RESCHEDULE_RESERVATION_ACTION,
    payload: {data},
  };
};

/**
 * Add guest action.
 *
 * @param id
 * @param guest
 * @param onSuccess
 * @returns {{payload: {guest: *, id: *, onSuccess: *}, type: string}}
 */
export const addGuestAction = ({id, guest, onSuccess}) => {
  return {
    type: ADD_GUEST_ACTION,
    payload: {id, guest, onSuccess},
  };
};

/**
 * Save reservation id to redux for add guest purpose.
 *
 * @param id
 * @returns {{payload: {id: *}, type: string}}
 */
export const setGuestReservationId = id => {
  return {type: SET_GUEST_RESERVATION_ID, payload: {id}};
};

/**
 * Clear guest reservation ID.
 *
 * @returns {{payload: {}, type: string}}
 */
export const clearGuestReservationId = () => {
  return {type: CLEAR_GUEST_RESERVATION_ID, payload: {}};
};

/**
 * Get reservation By POI.
 *
 * @param id
 * @returns {{payload: {id: *}, type: string}}
 */
export const getReservationByPOI = id => {
  return {
    type: GET_RESERVATION_BY_POI_ACTION,
    payload: {id},
  };
};
