import React, {Component} from 'react';
import styled from 'styled-components';
import {Card} from '../../styles/card';
import moment from 'moment-timezone';
import config from '../../config';
import {normalizeDateTime} from '../../utils/helpers';

const DATE_FORMAT = 'DD MMM YYYY';

class OONInfo extends Component {
  render() {
    const {oon} = this.props;
    const startDate = moment(normalizeDateTime(oon.start_date))
      .tz(config.timezone)
      .format(DATE_FORMAT);
    const endDate = moment(normalizeDateTime(oon.end_date))
      .tz(config.timezone)
      .format(DATE_FORMAT);

    return (
      <OrangeCard>
        <OONTitle>OUT OFF OFFICE</OONTitle>
        <OONMessage>{oon.message}</OONMessage>
        <OONDate>
          {startDate} to {endDate}
        </OONDate>
      </OrangeCard>
    );
  }
}

const OrangeCard = styled(Card)`
  background-color: ${props => props.theme.colors.warning};
  background-image: url('/assets/images/oon.png');
  background-repeat: no-repeat;
  background-position: right;
  padding: 1em;
  color: #ffffff;
  font-size: 14px;
`;
const OONTitle = styled.h3`
  font-size: 18px;
  margin: 0;
  font-weight: bold;
`;
const OONMessage = styled.div`
  margin-top: 5px;
`;
const OONDate = styled.div``;

export default OONInfo;
