import {
  APPEND_NOTIFICATIONS,
  AUTH_OUT_SUCCESS,
  GET_NOTIFICATION_NEED_ACTION_FAILED,
  GET_NOTIFICATION_NEED_ACTION_REQUEST,
  GET_NOTIFICATION_NEED_ACTION_SUCCESS,
  GET_NOTIFICATION_NO_NEED_ACTION_FAILED,
  GET_NOTIFICATION_NO_NEED_ACTION_REQUEST,
  GET_NOTIFICATION_NO_NEED_ACTION_SUCCESS,
  SET_NOTIFICATIONS,
} from '../actions/actionsList';

let initialState = {
  loading: false,
  loadingMore: false,
  notifications: [],
};

export default function(state = initialState, action) {
  const {type, payload} = action;

  switch (type) {
    case AUTH_OUT_SUCCESS: {
      return {...initialState};
    }

    case SET_NOTIFICATIONS:
      const {data} = payload;
      return {...state, notifications: data};

    case APPEND_NOTIFICATIONS:
      let {notifications} = state;
      const {data: newNotifications} = payload;
      notifications = [...notifications, ...newNotifications];
      return {...state, notifications};

    case GET_NOTIFICATION_NEED_ACTION_REQUEST:
      if (payload && !payload.loadMore) {
        return {...state, loading: true, notifications: []};
      } else {
        return {...state, loadingMore: true};
      }

    case GET_NOTIFICATION_NEED_ACTION_SUCCESS:
      return {...state, loading: false, loadingMore: false};

    case GET_NOTIFICATION_NEED_ACTION_FAILED:
      return {...state, loading: false, loadingMore: false};

    case GET_NOTIFICATION_NO_NEED_ACTION_REQUEST:
      if (payload && !payload.loadMore) {
        return {...state, loading: true, notifications: []};
      } else {
        return {...state, loadingMore: true};
      }

    case GET_NOTIFICATION_NO_NEED_ACTION_SUCCESS:
      return {...state, loading: false, loadingMore: false};

    case GET_NOTIFICATION_NO_NEED_ACTION_FAILED:
      return {...state, loading: false, loadingMore: false};

    default:
      return state;
  }
}
