import React from 'react';
import ReactDom from 'react-dom';
import {Provider} from 'react-redux';
import {setupAxios} from './utils/api';
import createStoreWithMiddleware from './utils/configureStore';
import './assets/scss/styles.scss';
import 'react-bootstrap-typeahead/css/Typeahead-bs4.css';
import App from './components/App';

import 'jquery';
import 'bootstrap';

setupAxios(createStoreWithMiddleware);

require('core-js');

ReactDom.render(
  <Provider store={createStoreWithMiddleware}>
    <App />
  </Provider>,
  document.getElementById('root')
);
