import React, {Component} from 'react';
import styled, {css} from 'styled-components';
import Button from './Button';
import {InputFieldStyle} from '../Profile/edit/Fields';
import {TextError} from '../../styles';
import {media} from '../../styles/grid';
import ReactDOM from 'react-dom';
import {roundedTime} from '../../utils/helpers';
import moment from 'moment';

const TIME_FORMAT = 'HH:mm';

class TimePicker extends Component {
  constructor(props) {
    super(props);

    const value = props.originalValue || props.input.value;

    this.state = {
      value,
      isOpen: false,
      cursorHour: -1,
      cursorMinute: -1,
    };
  }

  componentDidMount() {
    window.addEventListener('click', this.handleDocumentClick);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleDocumentClick);
  }

  handleDocumentClick = event => {
    const wrapper = ReactDOM.findDOMNode(this.refs.timePickerWrapper);
    if (!wrapper.contains(event.target)) {
      this.setState({
        isOpen: false,
      });
    }
  };

  togglePicker = event => {
    const {isOpen} = this.state;
    this.setState({
      isOpen: !isOpen,
    });
  };

  handleTimeInputChanged = event => {
    this.setState({value: event.target.value});
  };

  getRoundedTime = value => {
    return roundedTime(value);
  };

  getTime = value => {
    const {edit, originalValue} = this.props;

    if (edit && originalValue) {
      return moment(originalValue, 'HH:mm');
    }

    value = value || this.state.value;
    return this.getRoundedTime(value);
  };

  getExtractedTime = () => {
    const time = this.getTime();
    const hour = +time.hour();
    const minute = +time.minute();
    return {hour, minute};
  };

  setHour = hour => {
    const {minute} = this.getExtractedTime();
    const time = this.getTime();
    time.set('hour', hour);
    time.set('minute', minute);
    this.setState({value: time.format(TIME_FORMAT)});
  };

  setMinute = (minute, isOpen = false) => {
    const {hour} = this.getExtractedTime();
    const time = this.getTime();
    time.set('hour', hour);
    time.set('minute', minute);
    this.setState({value: time.format(TIME_FORMAT), isOpen});
  };

  handleChange = () => {
    const {
      input: {onChange},
    } = this.props;
    onChange(this.state.value);
  };

  componentWillUpdate(props, states) {
    if (states.value !== this.state.value) {
      const value = this.getRoundedTime(states.value).format(TIME_FORMAT);
      this.props.input.onChange(value);
    }
  }

  componentWillReceiveProps(props) {
    this.setState({
      value: this.getRoundedTime(props.input.value).format(TIME_FORMAT),
    });
  }

  handleKeyDown = e => {
    const {hour: currentHour, minute: currentMinute} = this.getExtractedTime();
    const hours = this.getHours();
    const minutes = this.getMinutes();
    let cursorHour = hours.findIndex(h => h === currentHour);
    let cursorMinute = minutes.findIndex(m => m === currentMinute);
    const hour = hours[cursorHour];

    this.setState({
      cursorHour,
      cursorMinute,
    });

    if (e.key === 'ArrowUp' && cursorHour > 0 && +hour > -1) {
      this.setState(prevState => ({
        cursorHour: prevState.cursorHour - 1,
        isOpen: true,
        hour,
      }));
      this.setHour(hour - 1);
    } else if (
      e.key === 'ArrowDown' &&
      cursorHour < hours.length - 1 &&
      +hour > -1
    ) {
      this.setState(prevState => ({
        cursorHour: prevState.cursorHour + 1,
        isOpen: true,
        hour,
      }));
      this.setHour(hour + 1);
    } else if (e.key === 'ArrowLeft') {
      cursorMinute = cursorMinute - 1;
      const minute = minutes.find((item, index) => index === cursorMinute);
      if (+minute > -1) {
        this.setState(prevState => ({
          cursorMinute: prevState.cursorMinute - 1,
          minute,
        }));
        this.setMinute(minute, true);
      }
    } else if (e.key === 'ArrowRight' && cursorMinute < hours.length - 1) {
      cursorMinute = cursorMinute + 1;
      const minute = minutes.find((item, index) => index === cursorMinute);
      if (+minute > -1) {
        this.setState(prevState => ({
          cursorMinute: prevState.cursorMinute + 1,
          minute,
        }));
        this.setMinute(minute, true);
      }
    }
  };

  getHours = () => {
    return [...Array(24)].map((item, index) => index);
  };

  getMinutes = () => {
    // return [...Array(12)].map((item, index) => index * 5);
    return [0, 30];
  };

  isHourSelected = (hour, index) => {
    const {cursorHour} = this.state;
    const {hour: currentHour} = this.getExtractedTime();
    return hour === currentHour || index === cursorHour;
  };

  isMinuteSelected = (minute, index) => {
    const {minute: currentMinute} = this.getExtractedTime();
    const {cursorMinute} = this.state;

    return minute === currentMinute || index === cursorMinute;
  };

  render() {
    const hours = this.getHours();
    const minutes = this.getMinutes();
    const {isOpen} = this.state;
    const {
      meta: {touched, error},
      input,
      right,
    } = this.props;
    let value = this.getTime(input.value).format(TIME_FORMAT);

    delete input.value;

    return (
      <TimePickerWrapper ref="timePickerWrapper">
        <TimeInput
          onFocus={this.togglePicker}
          onClick={this.togglePicker}
          onChange={this.handleTimeInputChanged}
          onKeyDown={this.handleKeyDown}
          autoComplete="off"
          value={value}
          {...input}
        />
        {touched && error && <TextError>{error}</TextError>}
        <TimePickerDialog open={isOpen} right={right}>
          <TimePickerHeading>Hour</TimePickerHeading>
          <NumberWrapper>
            {hours.map((hour, index) => (
              <NumberButton
                color={this.isHourSelected(hour, index) ? 'primary' : 'default'}
                onClick={() => this.setHour(hour)}
                key={hour}>
                {hour}
                {/*{index === cursor ? 'active' : 'not active'}*/}
              </NumberButton>
            ))}
          </NumberWrapper>
          <TimePickerHeading>Minute</TimePickerHeading>
          <NumberWrapper>
            {minutes.map((minute, index) => (
              <NumberButton
                color={
                  this.isMinuteSelected(minute, index) ? 'primary' : 'default'
                }
                onClick={() => this.setMinute(minute)}
                key={minute}>
                {minute}
              </NumberButton>
            ))}
          </NumberWrapper>
        </TimePickerDialog>
      </TimePickerWrapper>
    );
  }
}

const NumberWrapper = styled.div`
  margin-bottom: 1em;
  &:last-child {
    margin-bottom: 0;
  }
`;

const NumberButton = styled(Button).attrs({
  type: 'button',
})`
  display: inline-block;
  width: 26px;
  height: 26px;
  text-align: center;
  color: #727272;
  border-radius: 50%;
  text-decoration: none;
  font-size: 12px;
  line-height: 26px;
  font-weight: 700;
  cursor: pointer;
  padding: initial;
  outline: none;
  margin-bottom: 10px;

  &:active,
  &:focus,
  &:hover {
    background-color: ${props => props.theme.colors.primary};
    color: #ffffff !important;
  }
`;

const TimePickerWrapper = styled.div`
  position: relative;
`;

const TimeInput = styled.input.attrs({
  type: 'text',
})`
  ${props => InputFieldStyle};
`;

const TimePickerDialog = styled.div`
  position: absolute;
  width: 350px;
  background: #ffffff;
  z-index: 980;
  padding: 10px;
  border: 1px solid #dedede;
  border-radius: 5px;
  display: ${props => (props.open ? 'block' : 'none')};

  ${props =>
    props.right &&
    css`
      right: 0;
    `} ${media.xs`
    width: 85vw;
  `};
`;

const TimePickerHeading = styled.h4`
  margin-top: 0;
  margin-bottom: 10px;
  padding: 0;
  font-size: 18px;
`;

export default TimePicker;
