import React, {Component} from 'react';
import {Redirect} from 'react-router';
import {connect} from 'react-redux';
import {FeatureFlag} from '../../config/constants';

export default function(ComposedComponent) {
  class RequireGuest extends Component {
    componentWillMount() {
      const {userIsLoggedIn} = this.props.auth;

      if (userIsLoggedIn) {
        return <Redirect push to="/" />;
      }
    }

    componentWillUpdate(nextProps) {
      const {userIsLoggedIn, otpVerification} = this.props.auth;

      const isVerified = FeatureFlag.USE_OTP_VERIFICATION
        ? otpVerification.isVerified
        : true;

      if (userIsLoggedIn && isVerified) {
        return <Redirect push to="/" />;
      }
    }

    render() {
      const {userIsLoggedIn, otpVerification} = this.props.auth;
      const isVerified = FeatureFlag.USE_OTP_VERIFICATION
        ? otpVerification.isVerified
        : true;

      if (userIsLoggedIn && isVerified) {
        return <Redirect push to="/" />;
      }

      return <ComposedComponent {...this.props} />;
    }
  }

  function mapStateToProps(state) {
    return {
      auth: state.auth,
    };
  }

  return connect(mapStateToProps, null)(RequireGuest);
}
