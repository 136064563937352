import React from 'react';
import {MediaList} from '../../styles';
import MediaNeedApproval from './items/MediaNeedApproval';
import MediaReminder from './items/MediaReminder';
import MediaInfoReservation from './items/MediaInfoReservation';
import MediaReservationInfo from './items/MediaReservationInfo';
import MediaAskLocation from './items/MediaAskLocation';
import MediaReplyAskNotification from './items/MediaReplyAskLocation';
import NewsAnnouncement from './items/NewsAnnouncement';

const NotificationItem = props => {
  const {notification} = props;
  const {type} = notification || {};

  switch (type) {
    case 'ask_location':
      return <MediaAskLocation notification={notification} />;

    case 'reply_ask_location':
      return <MediaReplyAskNotification notification={notification} />;

    case 'request_reservation':
      return <MediaNeedApproval notification={notification} />;

    case 'info_reservation':
    case 'reject_reservation':
    case 'guest_receptionist_reservation':
      return <MediaReservationInfo notification={notification} />;

    case 'reminder':
      return <MediaReminder notification={notification} />;

    case 'reservation':
    case 'reschedule':
      return <MediaInfoReservation notification={notification} />;

    case 'news_publish':
      return <NewsAnnouncement notification={notification} />;

    default:
      return <MediaList>{type}</MediaList>;
  }
};

export default NotificationItem;
