import {imageURL, postImageURL, strLimit} from '../utils/helpers';
import striptags from 'striptags';
import {Category} from './Category';

export class Post {
  _title = '';

  _imageURL = '';

  _createdAt = null;

  _isAnnouncement = false;

  _isExternal = false;

  _body = '';

  _excerpt = '';

  _category: Category = new Category();

  url = '';

  id = null;

  _data = null;

  _authorName = null;

  _thumbnailImageURL = null;

  constructor(data, isExternal = false) {
    if (data) {
      if (isExternal) {
        this.imageURL = data.pub_image;
        this.body = data.pub_desc;
        this.excerpt = strLimit(striptags(data.pub_desc));
        this.title = data.pub_title;
        if (data.categories) {
          const cat = data.categories;
          this.category = new Category(
            cat.category_id,
            cat.category_name,
            cat.category_icon,
            cat.category_image
          );
        }
        this._id = data.pub_id;
        this.createdAt = false;
        // this._url = `/news/external/post/${data.pub_id}`;
        this._url = data.url;
      } else {
        this.imageURL = postImageURL(data);
        this.excerpt = strLimit(striptags(data.detail_news));
        this.body = data.detail_news;
        this.title = data.title ? data.title.trim() : data.title;
        this.authorName = data.user ? data.user.name : 'PwC Official';
        this.isAnnouncement = data.is_announcement;
        if (data.departement) {
          const cat = data.departement;
          this.category = new Category(cat.id, cat.departement);
        }
        this.id = data.id;
        this.createdAt = data.createdAt;
        this.url = `/news/post/${data.id}`;
        this._thumbnailImageURL = imageURL(data.thumbnail);
      }
      this._data = data;
      this._isExternal = isExternal;
    }
  }

  get textOnly() {
    return striptags(this.body);
  }

  get thumbnailImageURL() {
    return this._thumbnailImageURL;
  }

  set thumbnailImageURL(value) {
    this._thumbnailImageURL = value;
  }

  get authorName() {
    return this._authorName;
  }

  set authorName(value) {
    this._authorName = value;
  }

  get data() {
    return this._data;
  }

  set data(value) {
    this._data = value;
  }

  get isExternal() {
    return this._isExternal;
  }

  set isExternal(value) {
    this._isExternal = value;
  }

  get id() {
    return this._id;
  }

  set id(value) {
    this._id = value;
  }

  get url() {
    return this._url;
  }

  set url(value) {
    this._url = value;
  }

  get title(): string {
    return this._title;
  }

  set title(value: string) {
    this._title = value;
  }

  get imageURL(): string {
    return this._imageURL;
  }

  set imageURL(value: string) {
    this._imageURL = value;
  }

  get createdAt(): null {
    return this._createdAt;
  }

  set createdAt(value: null) {
    this._createdAt = value;
  }

  get isAnnouncement(): boolean {
    return this._isAnnouncement;
  }

  set isAnnouncement(value: boolean) {
    this._isAnnouncement = value;
  }

  get body(): string {
    return this._body;
  }

  set body(value: string) {
    this._body = value;
  }

  get excerpt(): string {
    return this._excerpt;
  }

  set excerpt(value: string) {
    this._excerpt = value;
  }

  get category(): Category {
    return this._category;
  }

  set category(value: Category) {
    this._category = value;
  }
}
