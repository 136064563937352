import React, {Component} from 'react';
import {loadingOrStatus, reservationTime} from '../../../utils/helpers';
import {connect} from 'react-redux';
import ActionButtons from '../ActionButtons';
import ReservationDate from '../../../components/Reservations/ReservationDate';

class ReservationRow extends Component {
  render() {
    const {item, reservation} = this.props;

    if (!item) {
      return null;
    }

    const {poi} = item;
    const room = poi ? poi.name : '-';

    return (
      <tr>
        <td>{room}</td>
        <td>
          <ReservationDate reservation={item} />
        </td>
        <td>{reservationTime(item.start_time)}</td>
        <td>{reservationTime(item.end_time)}</td>
        <td>{item.booker ? item.booker.name : '-'}</td>
        <td>{item.host_name}</td>
        <td>{item.meeting_description}</td>
        <td>{loadingOrStatus(reservation, item)}</td>
        <td className="text-center">
          <ActionButtons {...this.props} />
        </td>
      </tr>
    );
  }
}

function mapStateToProps(state) {
  const {reservation} = state;

  return {
    reservation,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(ReservationRow);
