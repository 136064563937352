import React from 'react';
import styled from 'styled-components';
import {ModalBody, ModalHeader} from 'reactstrap';

const BookingSuccess = props => {
  const {title, message, onToggle} = props;

  return (
    <>
      <ModalHeader toggle={onToggle}>
        {title || 'Meeting Room Reservation'}
      </ModalHeader>
      <ModalBody>
        <SuccessMessageWrapper>
          <SuccessMessage>
            <CheckListImage />
            <ThankYouText>Success!</ThankYouText>
            <MessageText>
              {message || 'Success! Your reservation has been submitted'}
            </MessageText>
          </SuccessMessage>
        </SuccessMessageWrapper>
      </ModalBody>
    </>
  );
};

export const SuccessMessageWrapper = styled.div`
  text-align: center;
`;

export const SuccessMessage = styled.div`
  margin: 100px auto;
`;

export const ThankYouText = styled.h1`
  color: #e0301e;
  margin-bottom: 5px;
  padding-bottom: 0;
`;

export const MessageText = styled.p``;

export const CheckListImage = styled.img.attrs({
  src: '/assets/images/checklist.svg',
  alt: 'checklist',
})``;

export default BookingSuccess;
