import {
  PROFILE_REQUEST,
  UPDATE_PROFILE_REQUEST,
  PROFILE_EDIT_REQUEST,
  UPDATE_ADDITIONAL_EDITOR,
  GET_MY_PROFILE_REQUEST,
  GET_PROFILE_FOR_EDIT_ACTION,
  EDIT_PROFILE_ACTION,
  REMOVE_ADDITIONAL_EDITOR_REQUEST,
  ADD_ADDITIONAL_EDITOR_REQUEST,
  ADD_ADDITIONAL_EDITOR_DONE,
} from './actionsList';

export const profileRequest = userId => {
  return {type: PROFILE_REQUEST, payload: {userId}};
};

export const editProfileAction = id => {
  return {type: EDIT_PROFILE_ACTION, payload: {id}};
};

export const getProfileForEditRequest = id => {
  return {type: GET_PROFILE_FOR_EDIT_ACTION, payload: {id}};
};

export const getMyProfileRequest = () => {
  return {type: GET_MY_PROFILE_REQUEST};
};

export const updateProfileRequest = data => {
  return {type: UPDATE_PROFILE_REQUEST, payload: {data}};
};

export const updateAdditionalEditorRequest = ({id, data}) => {
  return {type: UPDATE_ADDITIONAL_EDITOR, payload: {id, data}};
};

export const setProfileToEdit = (profileData, isEditedByEditor = false) => {
  return {type: PROFILE_EDIT_REQUEST, payload: {profileData, isEditedByEditor}};
};

export const removeAdditionalEditorRequest = (
  additionalEditorId,
  profileUserId
) => {
  return {
    type: REMOVE_ADDITIONAL_EDITOR_REQUEST,
    payload: {additionalEditorId, profileUserId},
  };
};

export const addAdditionalEditorRequest = (additionalEditor, profileUserId) => {
  return {
    type: ADD_ADDITIONAL_EDITOR_REQUEST,
    payload: {additionalEditor, profileUserId},
  };
};

export const addAdditionalEditorDoneRequest = () => {
  return {
    type: ADD_ADDITIONAL_EDITOR_DONE,
    payload: {},
  };
};
