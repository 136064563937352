import React, {Component, Fragment} from 'react';
import styled from 'styled-components';
import {Field, reduxForm} from 'redux-form';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {
  addAdditionalEditorDoneRequest,
  addAdditionalEditorRequest,
  getProfileForEditRequest,
  profileRequest,
  removeAdditionalEditorRequest,
  setProfileToEdit,
  updateAdditionalEditorRequest,
  updateProfileRequest,
} from '../../../actions/profile';
import {closeModal, toggleModal} from '../../../actions/modal';
import {BioInput, MobilePhoneInput, WhatsAppInput} from './Fields';
import {Col, Row} from '../../../styles/grid';
import Avatar from '../../Avatar';
import {
  getCurrentUser,
  getCurrentUserId,
  isMyProfile,
  toast,
} from '../../../utils/helpers';
import {InputField} from '../../Forms/Fields';
import {
  Button,
  ListGroup,
  ListGroupItem,
  ModalBody,
  ModalHeader,
  UncontrolledTooltip,
} from 'reactstrap';
import MaterialIcon from '../../UIKit/MaterialIcon';
import LoadingComponent from '../../UIKit/LoadingComponent';
import TypeaheadField from '../../../components/Forms/TypeaheadField';
import {
  addContactPerson,
  removeContactPerson,
  searchUsersRequest,
} from '../../../actions/user';
import swal from 'sweetalert2';
import {encrypt} from '../../../utils/encryption';
import MaterialSpinner from '../../UIKit/MaterialSpinner';

class EditProfile extends Component {
  timer = null;

  typeahead = null;

  typeahead2 = null;

  constructor(props) {
    super(props);
    this.state = {
      file: null,
      imageURL: null,
    };
  }

  componentDidMount() {
    const id = this.props.profile.profileUserId;
    if (id) {
      this.fetch(id);
    }
  }

  fetch = id => {
    this.props.getProfileToEdit(id);
  };

  fetchProfileData = () => {
    const user = getCurrentUser();
    this.props.profileRequest(user.id);
  };

  setImageURL = imageURL => {
    this.setState({imageURL});
  };

  handleAvatarChange = event => {
    const file = event.target.files[0];
    if (file) {
      this.setState({file});
      const fileReader = new FileReader();
      fileReader.addEventListener('load', () => {
        const imageURL = fileReader.result;
        this.setState({imageURL, imageSource: true});
      });
      fileReader.readAsDataURL(file);
    }
  };

  onSubmit = values => {
    const {updateProfile, updateAdditionalEditor, profile} = this.props;
    const {profileToEdit: profileData} = profile;
    const {file} = this.state;
    const data = new FormData();
    const user = profileData.profile;
    const isUpdatingAdditionalEditor = !isMyProfile(user);

    if (file) {
      data.append('file', file);
    }

    const encryptedData = {};
    for (let key in values) {
      if (values.hasOwnProperty(key)) {
        const value = values[key];
        encryptedData[key] = encrypt(value);
      }
    }

    data.append('nick_name', encryptedData.nick_name);
    data.append('mobile_phone', encryptedData.mobile_phone);
    // data.append('contact_person', encryptedData.contact_person);
    data.append('wa_numbers', encryptedData.wa_numbers);
    data.append('message', encryptedData.message);

    if (isUpdatingAdditionalEditor) {
      if (user) {
        updateAdditionalEditor({id: user.id, data});
      }
    } else {
      updateProfile(data);
    }
  };

  handleAdditionalEditorChange = event => {
    const editorId = Number(event.target.value);
    const {profileData} = this.props;
    const {profile: user} = profileData;
    const additionalEditors = this.getAdditionalEditors();
    const editor = additionalEditors.find(item => item.id === editorId);
    if (editor) {
      this.setProfileToEdit(editor, true);
    } else {
      this.setProfileToEdit(user, false);
    }
  };

  setProfileToEdit = (user, isUpdatingAdditionalEditor) => {
    const {setProfileToEdit} = this.props;
    setProfileToEdit(user);
    this.setState({
      imageURL: user.avatar,
      isUpdatingAdditionalEditor,
    });
  };

  handleEditAvatar = () => {
    this.avatarFileInput.click();
  };

  searchUser = value => {
    if (value && value.length > 2) {
      const {profileData} = this.props;
      const profileUserId = profileData.profile.id;
      const currentAdditionalEditors = profileData.additional_editor.map(
        item => item.id
      );
      clearTimeout(this.timer);
      const user = getCurrentUser();
      const me = user.id;
      const except = [me, profileUserId, ...currentAdditionalEditors].join(',');
      this.timer = setTimeout(() => {
        this.props.searchUsers({
          keyword: value,
          page: 1,
          perPage: 10,
          except,
          isTypeahead: true,
        });
      }, 500);
    }
  };

  searchContactPersonUsers = value => {
    if (value && value.length > 2) {
      const {profileData} = this.props;
      // const profileUserId = profileData.profile.id;
      const currentContactPersons = profileData.contact_person.map(
        item => item.contact_person
      );
      clearTimeout(this.timer);
      // const user = getCurrentUser();
      // const me = user.id;
      const except = [...currentContactPersons].join(',');
      this.timer = setTimeout(() => {
        this.props.searchUsers({
          keyword: value,
          page: 1,
          perPage: 10,
          except,
          isTypeahead: true,
        });
      }, 500);
    }
  };

  getProfileUserId = () => {
    const {profile} = this.props;
    const {profileToEdit: profileData} = profile;
    return profileData.profile.id;
  };

  addAdditionalEditor = user => {
    const isExist = this.getAdditionalEditors().find(
      item => user.id === item.id
    );
    if (!isExist) {
      this.props.addAdditionalEditor(user, this.getProfileUserId());
    } else {
      toast({
        title: `${user.name} has already on your additional editor list`,
        type: 'warning',
      });
    }
  };

  getContactPersons = () => {
    const {profile} = this.props;
    const {profileToEdit: profileData} = profile;

    return profileData ? profileData.contact_person : [];
  };

  addContactPerson = (userId, contactPersonUser) => {
    const contactPersons = this.getContactPersons();
    const isExist = contactPersons.find(
      user => user.contact_person === contactPersonUser.id
    );

    if (!isExist) {
      this.props.addContactPerson(userId, contactPersonUser);
    } else {
      toast({
        title: `${contactPersonUser.name} has already on your contact person list`,
        type: 'warning',
      });
    }
  };

  removeAdditionalEditor = user => {
    this.props.removeAdditionalEditor(user.id, this.getProfileUserId());
  };

  removeContactPerson = contact => {
    this.props.removeContactPerson(contact.contact_person, contact.users_id);
  };

  componentWillReceiveProps(props) {
    const userId = props.profile.profileUserId;
    if (userId && userId !== this.props.profile.profileUserId) {
      this.fetch(userId);
    }

    const {profileUpdated} = props.profile;
    if (
      profileUpdated !== this.props.profile.profileUpdated &&
      profileUpdated
    ) {
      swal({
        title: 'Profile updated successfully!',
        showConfirmButton: false,
        showCloseButton: true,
        type: 'success',
        position: 'top',
        toast: true,
        timer: 3000,
      });
    }
  }

  toggleModal = () => {
    this.props.toggleModal('editProfile');
  };

  handleTypeaheadChange = user => {
    this.addAdditionalEditor(user);
    this.clearTypeahead();
  };

  getAdditionalEditors = () => {
    const {profile} = this.props;
    const {profileToEdit: profileData} = profile;
    return profileData ? profileData.additional_editor : [];
  };

  isAdditionalEditor = () => {
    const {profile} = this.props;
    const {profileToEdit: profileData} = profile;
    const additionalEditors = profileData ? profileData.additional_editor : [];
    const editors = additionalEditors.filter(
      user => user.id === getCurrentUserId()
    );
    return editors.length > 0;
  };

  handleTypeaheadContactPersonChange = contactPersonUser => {
    const {profile} = this.props;
    const {profileToEdit: profileData} = profile;
    const profileUserId = profileData.profile.id;
    const userId = this.isAdditionalEditor()
      ? profileUserId
      : getCurrentUserId();
    this.addContactPerson(userId, contactPersonUser);
    this.clearTypeahead2();
  };

  clearTypeahead = () => {
    this.typeahead
      .getRenderedComponent()
      .typeahead.getInstance()
      .clear();
  };

  clearTypeahead2 = () => {
    this.typeahead2
      .getRenderedComponent()
      .typeahead.getInstance()
      .clear();
  };

  getRenderedAdditionalEditors = () => {
    const {profile} = this.props;
    const {profileToEdit: profileData, removingAdditionalEditor} = profile;

    return profileData
      ? profileData.additional_editor.map(user => (
          <CustomListGroupItem key={`additional-editor-${user.id}`}>
            <span>{user.name}</span>
            {removingAdditionalEditor === user.id ? (
              <LoadingComponent small />
            ) : (
              <DeleteButton
                id={`tooltipUser${user.id}`}
                onClick={() => this.removeAdditionalEditor(user)}>
                <MaterialIcon icon="trash" />
              </DeleteButton>
            )}
            <UncontrolledTooltip
              placement="left"
              target={`tooltipUser${user.id}`}>
              Remove {user.name}
            </UncontrolledTooltip>
          </CustomListGroupItem>
        ))
      : null;
  };

  renderContactPersons = () => {
    const {profile} = this.props;
    const {profileToEdit: profileData, removingContactPerson} = profile;

    return profileData
      ? profileData.contact_person.map(contact => (
          <CustomListGroupItem key={`contact-person-${contact.id}`}>
            <span>{contact.user.name}</span>
            {removingContactPerson === contact.user.id ? (
              <LoadingComponent small />
            ) : (
              <DeleteButton
                id={`tooltipContactPerson${contact.id}`}
                onClick={() => this.removeContactPerson(contact)}>
                <MaterialIcon icon="trash" />
              </DeleteButton>
            )}
            <UncontrolledTooltip
              placement="left"
              target={`tooltipContactPerson${contact.id}`}>
              Remove {contact.user.name}
            </UncontrolledTooltip>
          </CustomListGroupItem>
        ))
      : null;
  };

  render() {
    const {handleAvatarChange, onSubmit} = this;
    const {handleSubmit, closeModal, user: userReducer, profile} = this.props;
    const {searchResult: users, searching} = userReducer;
    const {
      isLoadingProfile,
      profileToEdit: profileData,
      updating,
      addingAdditionalEditor,
      addingContactPerson,
    } = profile;
    const additionalEditors = this.getRenderedAdditionalEditors();
    const user = profileData ? profileData.profile : null;
    const imageURL = this.state.imageURL || (user ? user.avatar : null);
    const isFromUpload = !!this.state.imageURL;
    const contactPersons = this.renderContactPersons();

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader toggle={this.toggleModal}>Edit Profile</ModalHeader>
        <ModalBody>
          {isLoadingProfile && <LoadingComponent />}
          {!isLoadingProfile && (
            <Fragment>
              <Row>
                <Col>
                  <FormGroupAvatar>
                    <Avatar image={imageURL} source={isFromUpload} circle />
                    <FileInputButtons>
                      <EditAvatarButton onClick={this.handleEditAvatar}>
                        Edit Avatar
                      </EditAvatarButton>
                      <FileInputField
                        ref={input => (this.avatarFileInput = input)}
                        onChange={handleAvatarChange}
                      />
                    </FileInputButtons>
                  </FormGroupAvatar>
                  <FormGroup>
                    <FormLabel>Nickname</FormLabel>
                    <Field name="nick_name" component={InputField} />
                  </FormGroup>
                  <FormGroup>
                    <FormLabel>Mobile Phone</FormLabel>
                    <Field name="mobile_phone" component={MobilePhoneInput} />
                  </FormGroup>
                  <FormGroup>
                    <FormLabel>WhatsApp Number</FormLabel>
                    <Field name="wa_numbers" component={WhatsAppInput} />
                  </FormGroup>
                  <FormGroup>
                    <FormLabel>Additional Editors</FormLabel>
                    <Field
                      id="additionalEditors"
                      component={TypeaheadField}
                      name="additional_editor"
                      onSearch={this.searchUser}
                      onChange={this.handleTypeaheadChange}
                      isLoading={searching}
                      filterBy={['name']}
                      options={users}
                      labelKey="name"
                      useCache={false}
                      placeholder="Search user name..."
                      ref={ref => (this.typeahead = ref)}
                      forwardRef={true}
                    />
                    <CustomListGroup>
                      {additionalEditors}
                      {addingAdditionalEditor && (
                        <CustomListGroupItem className="justify-content-center">
                          <LoadingComponent noMargin />
                        </CustomListGroupItem>
                      )}
                    </CustomListGroup>
                  </FormGroup>
                  <FormGroup>
                    <FormLabel>Contact Person</FormLabel>
                    <Field
                      id="contactPerson"
                      component={TypeaheadField}
                      name="contact_person"
                      onSearch={this.searchContactPersonUsers}
                      onChange={this.handleTypeaheadContactPersonChange}
                      isLoading={searching}
                      options={users}
                      labelKey="name"
                      placeholder="Search user name..."
                      useCache={false}
                      filterBy={['name']}
                      ref={ref => (this.typeahead2 = ref)}
                      forwardRef={true}
                    />
                    <CustomListGroup>
                      {contactPersons}
                      {addingContactPerson && (
                        <CustomListGroupItem>
                          <LoadingComponent />
                        </CustomListGroupItem>
                      )}
                    </CustomListGroup>
                  </FormGroup>
                  <FormGroup>
                    <FormLabel>Bio</FormLabel>
                    <Field name="message" component={BioInput} />
                  </FormGroup>
                </Col>
              </Row>
              <FormGroupAction>
                <Button
                  type="button"
                  color="secondary"
                  onClick={() => closeModal('editProfile')}>
                  Cancel
                </Button>
                <Button disabled={updating} type="submit" color="primary">
                  <MaterialSpinner indeterminate={updating} small button />
                  {updating ? 'Saving...' : 'Save'}
                </Button>
              </FormGroupAction>
            </Fragment>
          )}
        </ModalBody>
      </form>
    );
  }
}

const DeleteButton = styled(Button).attrs({
  size: 'sm',
  color: 'primary',
  type: 'button',
})`
  float: right;
  margin-bottom: 0;
`;

const CustomListGroupItem = styled(ListGroupItem)`
  display: flex !important;
  align-items: center !important;
  justify-content: space-between;
`;

const CustomListGroup = styled(ListGroup)`
  margin-top: 1em !important;
`;

const FileInputButtons = styled.div``;

const EditAvatarButton = styled(Button).attrs({
  color: 'link',
  type: 'button',
})`
  color: var(--pwc-yellow) !important;
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const FormGroupAction = styled(FormGroup)`
  justify-content: space-between;
  display: flex;
  margin-bottom: 0;
  border-top: 1px solid #dedede;
  padding-top: 20px;
`;

const FormGroupAvatar = styled(FormGroup)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
export const FormLabel = styled.label`
  display: block;
  font-weight: ${props => (props.normal ? 'normal' : 'bold')};
  color: #212121;
  margin-bottom: 5px;
  input[type='radio'] {
    margin-right: 3px;
  }
`;
const FileInputField = styled.input.attrs({
  type: 'file',
  name: 'file',
  accept: 'image/*',
})`
  margin-left: 10px;
  display: none;
`;

function mapStateToProps(state) {
  const {profile, user, modal} = state;
  const {profileToEdit: profileData} = profile;
  const userData = profileData ? profileData.profile : {};
  return {
    profile,
    user,
    modal,
    profileData,
    initialValues: {
      nick_name: userData.nick_name,
      mobile_phone: userData.mobile_phone,
      wa_numbers: userData.wa_numbers,
      message: userData.message,
    },
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateProfile: bindActionCreators(updateProfileRequest, dispatch),
    profileRequest: bindActionCreators(profileRequest, dispatch),
    closeModal: bindActionCreators(closeModal, dispatch),
    setProfileToEdit: bindActionCreators(setProfileToEdit, dispatch),
    searchUsers: bindActionCreators(searchUsersRequest, dispatch),
    updateAdditionalEditor: bindActionCreators(
      updateAdditionalEditorRequest,
      dispatch
    ),
    removeAdditionalEditor: bindActionCreators(
      removeAdditionalEditorRequest,
      dispatch
    ),
    addAdditionalEditor: bindActionCreators(
      addAdditionalEditorRequest,
      dispatch
    ),
    addAdditionalEditorDone: bindActionCreators(
      addAdditionalEditorDoneRequest,
      dispatch
    ),
    toggleModal: bindActionCreators(toggleModal, dispatch),
    getProfileToEdit: bindActionCreators(getProfileForEditRequest, dispatch),
    addContactPerson: bindActionCreators(addContactPerson, dispatch),
    removeContactPerson: bindActionCreators(removeContactPerson, dispatch),
  };
}

const validatePhoneNumber = val => {
  return /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\\s\\./0-9]*$/g.test(val);
};

const validate = values => {
  const maxLengthPhoneNumber = 15;
  const minLengthPhoneNumber = 10;
  const errors = {};
  if (!values.nick_name) {
    errors.nick_name = 'Nick name is required';
  }
  if (!values.mobile_phone) {
    errors.mobile_phone = 'Mobile Phone is required';
  }
  if (values.mobile_phone) {
    if (values.mobile_phone.length < minLengthPhoneNumber) {
      errors.mobile_phone = `Mobile Phone number must be at least ${minLengthPhoneNumber} characters`;
    }
    if (values.mobile_phone.length > maxLengthPhoneNumber) {
      errors.mobile_phone = `Mobile Phone number must be no longer than ${maxLengthPhoneNumber} characters`;
    }
    if (!validatePhoneNumber(values.mobile_phone)) {
      errors.mobile_phone = 'The mobile number you entered is invalid';
    }
  }
  if (values.wa_numbers) {
    if (values.wa_numbers.length < minLengthPhoneNumber) {
      errors.wa_numbers = `WhatsApp number must be at least ${minLengthPhoneNumber} characters`;
    }
    if (values.wa_numbers.length > maxLengthPhoneNumber) {
      errors.wa_numbers = `WhatsApp number must be no longer than ${maxLengthPhoneNumber} characters`;
    }
    if (!validatePhoneNumber(values.wa_numbers)) {
      errors.wa_numbers = 'The WhatsApp number you entered is invalid';
    }
  }
  return errors;
};

const form = reduxForm({
  form: 'edit-Profile',
  validate,
  enableReinitialize: true,
})(EditProfile);

export default connect(mapStateToProps, mapDispatchToProps)(form);
