import React, {Component, Fragment} from 'react';
import styled from 'styled-components';
import RoomInfoBox from '../RoomInfo';
import ContactProfileBadge from '../ContactProfileBadge';
import RecentSearch from '../../utils/RecentSearch';
import {ModalHeader, ModalBody} from 'reactstrap';
import LoadingComponent from '../UIKit/LoadingComponent';
import {CenteredContent, DetailBox, DetailContent} from '../../styles';
import connect from 'react-redux/es/connect/connect';
import {bindActionCreators} from 'redux';
import {toggleModal} from '../../actions/modal';
import ProfileCard from '../Profile/ProfileCard';
import OONInfo from '../Profile/OONInfo';

class UserDetail extends Component {
  componentDidMount() {
    const {user} = this.props;
    this.saveToRecentSearch(user);
  }

  componentWillReceiveProps(props) {
    if (props.user !== this.props.user) {
      this.saveToRecentSearch(props.user);
    }
  }

  saveToRecentSearch = user => {
    if (user) {
      const {profile} = user;
      const item = {...user, ...profile};
      RecentSearch.push(item);
    }
  };

  toggleModal = () => {
    this.props.toggleModal('detailUser');
  };

  render() {
    const {user, title, loading} = this.props;
    let lastOON = null;
    if (user) {
      lastOON = user && user.last_oon;
    }

    return (
      <Fragment>
        <ModalHeader toggle={this.toggleModal}>{title}</ModalHeader>
        <ModalBody>
          {loading && (
            <CenteredContent>
              <LoadingComponent />
            </CenteredContent>
          )}
          {!loading && (
            <Fragment>
              <ProfileCard profileData={user} noAction />
              <DetailBox>
                {user && user.message && (
                  <DetailContent>"{user.message}"</DetailContent>
                )}
              </DetailBox>
              {lastOON ? (
                <OONInfo oon={lastOON} />
              ) : (
                <RoomInfoBox profileData={user} />
              )}
              <ContentSpacer />
              <ContactProfileBadge profileData={user} />
            </Fragment>
          )}
        </ModalBody>
      </Fragment>
    );
  }
}

const ContentSpacer = styled.div`
  margin-top: 15px;
`;

// const UserBox = styled(Row)`
//   ${media.xs`
//      margin-bottom: 15px;
//   `};
// `;
// const UserAvatar = styled(Col).attrs({
//   sm: 2,
//   xs: 6,
// })`
//   padding-right: 10px;
// `;
// const UserInfo = styled(Col).attrs({
//   sm: 3,
//   xs: 6,
// })``;
// const UserName = styled.h3`
//   margin-top: 0;
//   margin-bottom: 10px;
//   font-size: 22px;
//   color: #505050;
// `;
// const UserPosition = styled.h6`
//   margin-top: 0;
//   margin-bottom: 10px;
//   font-size: 14px;
//   color: rgba(80, 80, 80, 0.87);
//   font-weight: normal;
// `;
// const UserDivision = styled(UserPosition)`
//   color: rgba(117, 117, 117, 0.87);
// `;
// const UserDetails = styled(Col).attrs({
//   sm: 5,
//   xs: 12,
// })`
//   ${media.xs`
//     margin-top: 15px;
//   `};
// `;
// const DetailText = styled.div`
//   color: #757575;
//   font-size: 15px;
// `;
// const UserSpacer = styled(Col).attrs({
//   sm: 1,
//   xs: 12,
// })``;

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    toggleModal: bindActionCreators(toggleModal, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDetail);
