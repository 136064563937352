import {takeEvery, put, call} from 'redux-saga/effects';
import {
  MENU_CREATED,
  CREATE_MENU_REQUEST,
  CREATING_MENU,
  FIND_MENU_REQUEST,
  GET_LIST_MENU_REQUEST,
  MENU_REMOVED,
  MENU_UPDATED,
  REMOVE_MENU_REQUEST,
  REMOVING_MENU,
  SET_MENU,
  SET_MENUS,
  SET_PAGINATION,
  UPDATE_MENU_REQUEST,
  UPDATING_MENU,
  GET_BEVERAGE_LIST_REQUEST,
  LOADING,
  LOADED,
  SUBMITTING_ORDER,
  ORDER_SUBMITTED,
  ORDER_MENU_FAILED,
  ORDER_MENU_REQUEST,
  MENU_UPDATE_DONE,
  MENU_CREATED_DONE,
  GET_MENU_LIST_REQUEST,
  GET_MENU_LIST_SUCCEED,
  GET_MENU_LIST_FAILED,
} from '../actions/actionsList';
import Menu from '../api/Menu';
import {getMenuListRequest} from '../actions/menu';
import {closeModal, openModal} from '../actions/modal';
import config from '../config';
import {setGlobalError} from '../actions/global';

/**
 * Action handler for creating new beverage.
 *
 * @returns {IterableIterator<*>}
 */
function* createMenuActionHandler(action) {
  const {payload} = action;

  try {
    yield put({type: CREATING_MENU});
    const {data: formData} = payload || {};
    const responseData = yield call(Menu.create, formData);
    const {code, data, message} = responseData || {};
    if (code === 201) {
      yield put({type: MENU_CREATED, payload: {data}});
      yield put({type: MENU_CREATED_DONE});
    } else {
      yield put(setGlobalError(message));
    }
  } catch (e) {
    yield put(setGlobalError(config.INTERNAL_SERVER_ERROR));
  }
}

/**
 * Get Menus.
 *
 * @param action
 * @returns {IterableIterator<*>}
 */
function* getMenuListActionHandler(action) {
  const {payload} = action;

  try {
    yield put({type: GET_MENU_LIST_REQUEST});
    const {search, limit, sortBy, page, descending} = payload || {};
    const responseData = yield call(Menu.getList, {
      page,
      limit,
      sortBy,
      search,
      descending,
    });
    const {code, data, message} = responseData || {};
    if (code === 200) {
      const {content: menus, paginate: pagination} = data || {};
      yield put({type: GET_MENU_LIST_SUCCEED, payload: {menus}});
      yield put({type: SET_PAGINATION, payload: {pagination}});
    } else {
      yield put({type: GET_MENU_LIST_FAILED});
      yield put(setGlobalError(message));
    }
  } catch (e) {
    yield put({type: GET_MENU_LIST_FAILED});
    yield put(setGlobalError(config.INTERNAL_SERVER_ERROR));
  }
}

/**
 * Remove menu.
 *
 * @param action
 * @returns {IterableIterator<*>}
 */
function* removeMenuActionHandler(action) {
  const {payload} = action;

  try {
    const {id, pagination} = payload || {};
    yield put({type: REMOVING_MENU, payload: {id}});
    const responseData = yield call(Menu.remove, id);
    const {code, data, message} = responseData || {};
    if (code === 200) {
      yield put({type: MENU_REMOVED, payload: {data}});
      yield put(getMenuListRequest(pagination));
    } else {
      yield put(setGlobalError(message));
    }
  } catch (e) {
    yield put(setGlobalError(config.INTERNAL_SERVER_ERROR));
  }
}

/**
 * Find menu by ID.
 *
 * @param action
 * @returns {IterableIterator<*>}
 */
function* findMenuActionHandler(action) {
  const {payload} = action;

  try {
    const {id} = payload || {};
    const responseData = yield call(Menu.find, id);
    const {code, data, message} = responseData || {};
    if (code === 200) {
      yield put({type: SET_MENU, payload: {menu: data}});
    } else {
      yield put(setGlobalError(message));
    }
  } catch (e) {
    yield put(setGlobalError(config.INTERNAL_SERVER_ERROR));
  }
}

/**
 * Action handler for creating new beverage.
 *
 * @returns {IterableIterator<*>}
 */
function* updateMenuActionHandler(action) {
  const {payload} = action;

  try {
    const {data: formData, id} = payload || {};
    yield put({type: UPDATING_MENU, payload: {id}});
    const responseData = yield call(Menu.update, id, formData);
    const {code, data, message} = responseData || {};
    if (code === 200) {
      yield put({type: MENU_UPDATED, payload: {data}});
      yield put({type: MENU_UPDATE_DONE});
    } else {
      yield put(setGlobalError(message));
    }
  } catch (e) {
    yield put(setGlobalError(config.INTERNAL_SERVER_ERROR));
  }
}

function* getBeverageListRequestHandler(action) {
  const {payload} = action;

  try {
    yield put({type: LOADING});
    const {search, limit, sortBy, page, descending} = payload || {};
    const responseData = yield call(Menu.getBeverageList, {
      page,
      limit,
      sortBy,
      search,
      descending,
    });
    const {code, data, message} = responseData || {};
    if (code === 200) {
      yield put({type: LOADED});
      yield put({type: SET_MENUS, payload: {menus: data.content}});
      yield put({type: SET_PAGINATION, payload: {pagination: data.paginate}});
    } else {
      yield put({type: LOADED});
      yield put(setGlobalError(message));
    }
  } catch (e) {
    yield put({type: LOADED});
    yield put(setGlobalError(config.INTERNAL_SERVER_ERROR));
  }
}

function* orderMenuRequestHandler(action) {
  const {payload} = action;

  try {
    yield put({type: SUBMITTING_ORDER});
    const responseData = yield call(Menu.order, payload.data);
    const {code, data, message} = responseData || {};
    if (code === 201) {
      yield put({type: ORDER_SUBMITTED, payload: {data}});
      yield put(closeModal('beverageOrder'));
      yield put(openModal('beverageOrderSuccess'));
    } else {
      yield put({type: ORDER_MENU_FAILED});
      yield put(setGlobalError(message));
    }
  } catch (e) {
    yield put({type: ORDER_MENU_FAILED});
    yield put(setGlobalError(config.INTERNAL_SERVER_ERROR));
  }
}

/**
 * Reservation Saga.
 *
 * @returns {IterableIterator<*|ForkEffect>}
 */
export default function*() {
  yield takeEvery(CREATE_MENU_REQUEST, createMenuActionHandler);
  yield takeEvery(GET_LIST_MENU_REQUEST, getMenuListActionHandler);
  yield takeEvery(REMOVE_MENU_REQUEST, removeMenuActionHandler);
  yield takeEvery(FIND_MENU_REQUEST, findMenuActionHandler);
  yield takeEvery(UPDATE_MENU_REQUEST, updateMenuActionHandler);
  yield takeEvery(GET_BEVERAGE_LIST_REQUEST, getBeverageListRequestHandler);
  yield takeEvery(ORDER_MENU_REQUEST, orderMenuRequestHandler);
}
