import moment from 'moment';
import config from '../config';

const STORAGE_NAME = 'recentSearch';

class RecentSearch {
  all() {
    return this.fetch();
  }

  sort(data, descending = true) {
    data = data || this.fetch();

    data.sort(function(a, b) {
      const typeA = a.profile.type.toUpperCase();
      const typeB = b.profile.type.toUpperCase();

      const createdAtA = moment(a.createdAt);
      const createdAtB = moment(b.createdAt);

      if (createdAtA < createdAtB) return -1;
      if (createdAtA > createdAtB) return 1;

      if (typeA < typeB) return 1;
      if (typeA > typeB) return -1;

      return 0;
    });

    if (descending) {
      data.reverse();
    }

    return data;
  }

  fetch() {
    return JSON.parse(localStorage.getItem(STORAGE_NAME)) || [];
  }

  getSorted(descending = true) {
    const {staff, nonStaff} = this.getByTypes(descending);
    return [...staff, ...nonStaff];
  }

  getByType(type) {
    const types = typeof type === 'string' ? [type] : type;
    return this.all().filter(user => types.includes(user.profile.type));
  }

  getByTypes(descending = true) {
    const staff = this.sort(
      this.getByType([config.STAFF, 'admin']),
      descending
    );
    const nonStaff = this.sort(this.getByType(config.NON_STAFF), descending);
    return {staff, nonStaff};
  }

  add(item) {
    if (this.has(item)) return;
    const items = this.fetch();
    item.createdAt = moment();
    items.push(item);
    this.save(items);
  }

  has(item) {
    const items = this.fetch();
    const {profile} = item;
    return items.find(user => user.profile.id === profile.id);
  }

  push(item) {
    this.add(item);
  }

  save(items) {
    localStorage.setItem(STORAGE_NAME, JSON.stringify(items));
  }

  clear() {
    localStorage.removeItem(STORAGE_NAME);
  }

  notEmpty() {
    return this.fetch().length > 0;
  }

  empty() {
    return !this.notEmpty();
  }
}

export default new RecentSearch();
