import React, {Component, Fragment} from 'react';
import {TextError} from '../../styles';
import {HelpText} from './Fields';
import styled from 'styled-components';
import {InputFieldStyle} from '../Profile/edit/Fields';

class LoginField extends Component {
  render() {
    const {
      input,
      type,
      placeholder,
      helpText,
      onKeyUp,
      autoComplete,
      meta: {touched, error},
    } = this.props;

    if (type) {
      input.type = type;
    }
    input.placeholder = placeholder;
    input.onKeyUp = onKeyUp;
    input.autoComplete = autoComplete;

    return (
      <Fragment>
        <StyledLoginInput {...input} data-qa={`field-${input.name}`} />
        {touched && error && (
          <TextError data-qa={`field-error-${input.name}`}>{error}</TextError>
        )}
        {helpText && (
          <HelpText data-qa={`field-helper-${input.name}`}>{helpText}</HelpText>
        )}
      </Fragment>
    );
  }
}

const StyledLoginInput = styled.input`
  ${props => InputFieldStyle};
`;

export default LoginField;
