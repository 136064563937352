import React, {Component, Fragment} from 'react';
import {bindActionCreators} from 'redux';
import {openModal, toggleModal} from '../../actions/modal';
import connect from 'react-redux/es/connect/connect';
import {ModalHeader, Button, ModalBody} from 'reactstrap';
import {oonDate} from '../../utils/helpers';
import {OONDate, OONText} from '../../styles';
import LoadingComponent from '../UIKit/LoadingComponent';
import {releaseOONRequest} from '../../actions/oon';
import MaterialSpinner from '../UIKit/MaterialSpinner';

class ModalReleaseOON extends Component {
  handleRelease = () => {
    const oon = this.getOONData();
    if (oon) {
      this.props.releaseOON(oon.id);
    }
  };

  toggle = () => {
    this.props.toggleModal('modalReleaseOON');
  };

  getOONData = () => {
    const {profile} = this.props;
    const {profileData} = profile;
    const {last_oon: oon} = profileData;
    return oon;
  };

  render() {
    const {profile, global, releasing} = this.props;
    const {loading} = global;
    const {profileData} = profile;
    const oon = this.getOONData();

    if (loading) {
      return <LoadingComponent />;
    }

    if (!oon) return null;

    const userName = profileData.profile.name;
    const startDate = oonDate(oon.start_date);
    const endDate = oonDate(oon.end_date);

    return (
      <Fragment>
        <ModalHeader toggle={this.toggle}>Welcome Back to Office</ModalHeader>
        <ModalBody className="text-center">
          <h1>{userName}</h1>
          <OONText>You are out of office from</OONText>
          <OONDate>
            {startDate} - {endDate}
          </OONDate>
          <Button
            color="primary"
            onClick={this.handleRelease}
            disabled={releasing}
            block>
            <MaterialSpinner indeterminate={releasing} button small />
            {releasing ? 'Releasing... ' : 'Release Out of Office'}
          </Button>
        </ModalBody>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const {profile, global, oon} = state;
  const {releasing} = oon;

  return {profile, global, releasing};
}

function mapDispatchToProps(dispatch) {
  return {
    openModal: bindActionCreators(openModal, dispatch),
    toggleModal: bindActionCreators(toggleModal, dispatch),
    releaseOON: bindActionCreators(releaseOONRequest, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalReleaseOON);
