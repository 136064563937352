import {InputFieldStyle} from '../Profile/edit/Fields';
import styled, {css} from 'styled-components';
import React, {Fragment, useState} from 'react';
import {TextError} from '../../styles';
import {Field} from 'redux-form';
import ShowPasswordToggle from '../buttons/ShowPasswordToggle/ShowPasswordToggle';

/**
 * InputField component.
 *
 * @param input
 * @param type
 * @param placeholder
 * @param defaultValue
 * @param helpText
 * @param onKeyUp
 * @param autoComplete
 * @param rest
 * @param touched
 * @param error
 * @returns {*}
 * @constructor
 */
export const InputField = ({
  input,
  type,
  placeholder,
  defaultValue,
  helpText,
  onKeyUp,
  autoComplete,
  meta: {touched, error},
  ...rest
}) => {
  if (type) {
    input.type = type;
  }
  input.placeholder = placeholder;
  input.onKeyUp = onKeyUp;
  input.autoComplete = autoComplete;
  const attributes = {...input, ...rest};
  const isRadio = type === 'radio';
  return (
    <Fragment>
      {isRadio && <StyledRadio {...attributes} />}
      {!isRadio && <StyledInput {...attributes} />}
      {touched && error && <TextError>{error}</TextError>}
      {helpText && <HelpText>{helpText}</HelpText>}
    </Fragment>
  );
};

/**
 * Password component.
 *
 * @param input
 * @param type
 * @param placeholder
 * @param defaultValue
 * @param helpText
 * @param onKeyUp
 * @param autoComplete
 * @param rest
 * @param touched
 * @param error
 * @param showTogglePasswordVisibility
 * @returns {*}
 * @constructor
 */
export const PasswordField = ({
  input,
  placeholder,
  defaultValue,
  helpText,
  onKeyUp,
  autoComplete,
  showTogglePasswordVisibility,
  meta: {touched, error},
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const onShowPassword = () => {
    setShowPassword(!showPassword);
  };

  input.placeholder = placeholder;
  input.onKeyUp = onKeyUp;
  input.autoComplete = autoComplete;
  const attributes = {
    ...input,
    ...rest,
    type: showPassword ? 'text' : 'password',
  };

  return (
    <>
      <div className={'d-flex position-relative'}>
        {
          <StyledPasswordInput
            {...attributes}
            showTogglePasswordVisibility={showTogglePasswordVisibility}
          />
        }
        {showTogglePasswordVisibility && (
          <StyledPasswordBtn show={showPassword} onClick={onShowPassword} />
        )}
      </div>
      {touched && error && <TextError>{error}</TextError>}
      {helpText && <HelpText>{helpText}</HelpText>}
    </>
  );
};

export const TextAreaField = ({
  input,
  type,
  placeholder,
  defaultValue,
  helpText,
  onKeyUp,
  autoComplete,
  meta: {touched, error},
}) => {
  if (type) {
    input.type = type;
  }
  input.placeholder = placeholder;
  input.onKeyUp = onKeyUp;
  input.autoComplete = autoComplete;
  const isRadio = type === 'radio';
  return (
    <Fragment>
      {!isRadio && <StyledTextArea {...input} />}
      {touched && error && <TextError>{error}</TextError>}
      {helpText && <HelpText>{helpText}</HelpText>}
    </Fragment>
  );
};

/**
 * SelectField component.
 *
 * @param input
 * @param placeholder
 * @param defaultValue
 * @param items
 * @param itemText
 * @param itemValue
 * @param touched
 * @param error
 * @param disabled
 * @returns {*}
 * @constructor
 */
export const SelectField = ({
  input,
  placeholder,
  defaultValue,
  items,
  itemText,
  itemValue,
  disabled,
  meta: {touched, error},
}) => {
  items = items || [];
  itemText = itemText || 'text';
  itemValue = itemValue || 'id';
  if (disabled) {
    input.disabled = disabled;
  }
  return (
    <Fragment>
      <StyledSelect {...input}>
        {items.map((item, index) => {
          return (
            <option key={index} value={item[itemValue]}>
              {item[itemText]}
            </option>
          );
        })}
      </StyledSelect>
      {touched && error && <TextError>{error}</TextError>}
    </Fragment>
  );
};

export const TimeField = ({
  input,
  placeholder,
  defaultValue,
  options,
  meta: {touched, error},
}) => {
  return (
    <Fragment>
      <StyledTimeField {...input}>
        {options &&
          options.map(item => (
            <option key={item} value={item}>
              {item}.00
            </option>
          ))}
      </StyledTimeField>
      {touched && error && <TextError>{error}</TextError>}
    </Fragment>
  );
};

const StyledTimeField = styled(Field).attrs({
  component: 'select',
})`
  ${props => InputFieldStyle};
`;

// const LoginInputStyles = css`
//   border-radius: 0;
//   font-size: 14px;
//   outline: none;
//   border: 1px solid #afafaf;
//   width: 100%;
//   padding: 10px 25px;
//   box-sizing: border-box;
// `;

export const TextField = styled(Field)`
  ${props => InputFieldStyle};
`;

export const HelpText = styled.small`
  color: #6c757d !important;
  margin-top: 5px;
  display: block;
`;

export const StyledSelect = styled.select.attrs({
  className: 'form-control',
})`
  &:focus {
    color: #495057 !important;
    border-color: #e0301e !important;
    outline: 0 !important;
    box-shadow: 0 0 0 0.2rem rgba(224, 48, 30, 0.56) !important;
  }
`;

export const StyledTextArea = styled.textarea`
  ${props => InputFieldStyle};
`;

export const StyledInput = styled.input`
  ${props => InputFieldStyle};
`;

const StyledPasswordInputCss = {
  hidePasswordVisibility: css`
    padding-right: 3em;

    &::-ms-reveal {
      display: none;
    }
  `,
};
export const StyledPasswordInput = styled.input`
  ${props => InputFieldStyle};
  ${props =>
    props.showTogglePasswordVisibility &&
    StyledPasswordInputCss.hidePasswordVisibility}
`;
const StyledPasswordBtn = styled(ShowPasswordToggle)`
  position: absolute;
  top: 0;
  right: 0;
  color: #949494;
`;

export const StyledRadio = styled.input`
  margin-right: 5px;
`;
