import React, {Fragment, useEffect, useState} from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  // NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import {Link} from 'react-router-dom';
import styled, {css} from 'styled-components';
import {useLocation} from 'react-router';
import {hasCluster, requestSegment} from '../../utils/helpers';
import {media} from '../../styles/grid';
import {
  getMyClusterAdminRequest,
  getMyClusterSICRequest,
} from '../../actions/reservation';
import {FeatureFlag} from '../../config/constants';
import {useDispatch, useSelector} from 'react-redux';

const storeSelector = store => {
  const {reservation, auth} = store;
  const {userData} = auth;
  const {myClusterAdmin, fetchingMyClusterAdmin, clusterSIC} = reservation;
  const isClusterAdmin = hasCluster(myClusterAdmin) && !fetchingMyClusterAdmin;

  const isClusterSIC = clusterSIC
    ? !clusterSIC.clustersLoading &&
      clusterSIC.clusters &&
      clusterSIC.clusters.length > 0
    : false;

  return {
    userData,
    isClusterAdmin,
    myClusterAdmin,
    isClusterSIC,
    clusterSIC,
  };
};

const MainNavbar = props => {
  const dispatch = useDispatch();
  const {userData, isClusterAdmin, isClusterSIC, myClusterAdmin} = useSelector(
    storeSelector
  );

  const location = useLocation();

  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    fetchMyCluster();
  }, []);

  const fetchMyCluster = () => {
    if (!myClusterAdmin || !myClusterAdmin.length) {
      dispatch(getMyClusterAdminRequest());
    }

    dispatch(getMyClusterSICRequest());
  };

  const toggle = () => {
    setOpen(!isOpen);
  };

  const getClassName = item => {
    if (item.header) return 'dropdown-item-header';
    return null;
  };

  const isActive = item => {
    const {to, active} = item || {};
    const {pathname} = location;

    if (active) return active;

    return pathname === to || `/${requestSegment(1)}` === to;
  };

  const isItemActive = item => {
    if (item?.items) {
      const hasActive = item.items?.filter(subItem => isActive(subItem));
      return hasActive?.length > 0;
    } else {
      return isActive(item);
    }
  };

  // const hasRoles = roles => {
  //   return userHasRole(roles, userData);
  // };

  const getMenus = () => {
    const isPantryAdmin =
      // hasRoles([config.roles.SUPER_ADMIN]) ||
      hasFunctions(['pantry']);

    const isReceptionist =
      // hasRoles([config.roles.SUPER_ADMIN]) ||
      hasFunctions(['receptionist']);

    const isLiaisonOfficer =
      // hasRoles([config.roles.SUPER_ADMIN]) ||
      hasFunctions(['lo']);

    // || hasRoles([config.roles.SUPER_ADMIN]);

    const isOnNewsPage = requestSegment(1) === 'news';

    return [
      // {
      //   title: 'Home',
      //   to: '/',
      // },
      {
        title: 'People',
        to: '/',
      },
      {
        title: 'Maps',
        to: '/maps',
      },
      {
        title: 'Tracker',
        href: '//peopletracker.pwcidportal.com/',
        target: '_blank',
      },
      {
        title: 'News',
        to: '/news/internal',
        active: isOnNewsPage,
      },
      {
        title: 'Workspace Check-in',
        href: '//checkin.pwcidportal.com/',
        target: '_blank',
      },
      {
        title: 'Reservations',
        to: '/reservations',
        items: [
          {
            header: true,
            title: 'Create Reservation',
            show: true,
          },
          {
            icon: '/assets/images/meeting_icon.png',
            alt: 'meeting_icon',
            title: 'Single Reservation',
            caption: 'Create one day reservation for one room',
            to: '/reservations/create/single',
            show: true,
          },
          {
            icon: '/assets/images/meeting_icon.png',
            alt: 'meeting_icon',
            title: 'Project Reservation',
            caption: 'Create reservation for multiple days in one room',
            to: '/reservations/create/project',
            show: true,
          },
          FeatureFlag.USE_WFO_RESERVE
            ? {
                icon: '/assets/images/meeting_icon.png',
                alt: 'meeting_icon',
                title: 'WFO Reservation',
                caption: 'Create reservation for working in office',
                to: '/reservations/create/wfo',
                show: true,
              }
            : undefined,
          {
            header: true,
            title: 'Cluster Admin',
            show: isClusterAdmin,
          },
          {
            icon: '/assets/images/meeting_icon.png',
            alt: 'meeting_icon',
            title: 'Single Reservation',
            caption: 'Create one day reservation for one room',
            to: '/reservation/create/cluster-admin/single',
            show: isClusterAdmin,
          },
          {
            icon: '/assets/images/meeting_icon.png',
            alt: 'meeting_icon',
            title: 'Group Booking',
            caption: 'Reserve a Meeting room in PwC office',
            to: '/reservation/create/cluster-admin/group',
            show: isClusterAdmin,
          },
          {
            header: true,
            title: 'Reservation List',
            show: true,
          },
          {
            icon: '/assets/images/meeting_icon.png',
            alt: 'meeting_icon',
            title: 'My Reservation',
            caption: 'List all of your reservation',
            to: '/reservations',
            show: true,
          },
          FeatureFlag.USE_WFO_RESERVE
            ? {
                icon: '/assets/images/meeting_icon.png',
                alt: 'meeting_icon',
                title: 'My WFO Reservation',
                caption: 'List all of your WFO reservation',
                to: '/reservations/wfo',
                show: true,
              }
            : undefined,
          {
            icon: '/assets/images/approval.png',
            alt: 'meeting_icon',
            title: 'Approval Request',
            caption: 'Reservation request that needs your approval',
            to: '/reservations/approval-request',
            show: true,
          },
          {
            icon: '/assets/images/radar.png',
            alt: 'meeting_icon',
            title: 'Cluster Admin',
            caption: 'Manage reservation in your cluster',
            to: '/reservations/cluster/admin',
            show: isClusterAdmin,
          },
          {
            icon: '/assets/images/radar.png',
            alt: 'meeting_icon',
            title: 'Cluster SIC',
            caption: 'Manage reservation in your cluster',
            to: '/reservations/cluster/sic',
            show: FeatureFlag.USE_CLUSTER_SIC && isClusterSIC,
          },
          {
            icon: '/assets/images/receptionist.png',
            alt: 'meeting_icon',
            title: 'Receptionist',
            caption: "Today's reservation",
            to: '/reservations/receptionist',
            show: isReceptionist,
          },
          {
            icon: '/assets/images/collaboration.png',
            alt: 'meeting_icon',
            title: 'Liaison Officer',
            caption: "Today's reservation",
            to: '/reservations/liason-officer',
            show: isLiaisonOfficer,
          },
          {
            icon: '/assets/images/cooking.png',
            alt: 'meeting_icon',
            title: 'Pantry',
            caption: 'Manage beverage order',
            to: '/reservations/pantry',
            show: isPantryAdmin,
          },
          {
            icon: '/assets/images/receptionist.png',
            alt: 'meeting_icon',
            title: 'Reservation Schedule',
            caption: 'Reservation Schedule',
            to: '/reservations/receptionist/report',
            show: true,
          },
        ],
      },
    ];
  };

  const renderSubItems = item => {
    return item?.items?.map((subItem, index) => {
      const show = subItem?.show !== undefined ? subItem.show : true;
      if (show) {
        return (
          subItem?.show && (
            <StyledDropdownItem
              tag={subItem.to ? Link : 'button'}
              header={subItem.header}
              key={`${index}-${subItem.title}`}
              to={subItem.to}
              className={getClassName(subItem)}>
              {subItem.icon ? (
                <>
                  <DropdownImage src={subItem.icon} alt={subItem.alt} />
                  <DropdownText>
                    {subItem.title}
                    <DropdownItemText>{subItem.caption}</DropdownItemText>
                  </DropdownText>
                </>
              ) : (
                subItem.title
              )}
            </StyledDropdownItem>
          )
        );
      } else {
        return null;
      }
    });
  };

  const hasFunctions = functions => {
    const userFunctions = (userData || {})?.functions?.map(item => item.key);
    return (
      functions?.filter(fun => (userFunctions || []).includes(fun)).length > 0
    );
  };

  const menus = getMenus();

  return (
    <StyledNavbar color="primary" dark expand="md">
      {/*<StyledNavbarBrand tag={Link} to="/">*/}
      {/*Explore*/}
      {/*</StyledNavbarBrand>*/}
      <StyledNavbarToggler onClick={toggle} />
      <Collapse isOpen={isOpen} navbar>
        <Nav className="ml-auto" navbar>
          {menus?.map(item => (
            <Fragment key={item.title}>
              {item.items ? (
                <StyledUncontrolledDropdown
                  active={isItemActive(item)}
                  nav
                  inNavbar>
                  <StyledDropdownToggle nav caret>
                    {item.title}
                  </StyledDropdownToggle>
                  <DropdownMenu right>{renderSubItems(item)}</DropdownMenu>
                </StyledUncontrolledDropdown>
              ) : (
                <StyledNavItem active={isItemActive(item)}>
                  <StyledNavLink
                    tag={item.href ? 'a' : Link}
                    href={item.href}
                    target={item.target}
                    to={item.to}>
                    {item.title}
                  </StyledNavLink>
                </StyledNavItem>
              )}
            </Fragment>
          ))}
        </Nav>
      </Collapse>
    </StyledNavbar>
  );
};

const respAnimatedHover = {
  sm: media.sm`
    border-top: solid 3px var(--primary);
  `,
  afterSM: media.sm`
    border-bottom: solid 3px #fff;
`,
  hoverAfterSM: media.sm`
    transform: scaleX(1);
    `,
  activeAfterSM: media.sm`
      transform: scaleX(1);
     `,
};
const animatedHover = css`
  ${respAnimatedHover.sm} :after {
    display: block;
    content: '';
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;

    ${respAnimatedHover.afterSM};
  }

  :hover:after {
    ${respAnimatedHover.hoverAfterSM};
  }

  &.active .nav-link {
    font-weight: bold;
  }

  &.active:after {
    ${respAnimatedHover.activeAfterSM};
  }
`;

const StyledNavbar = styled(Navbar)`
  padding: 0 !important;
`;

// const StyledNavbarBrand = styled(NavbarBrand)`
//   font-size: 24px;
//   font-family: Georgia, 'Times New Roman', Times, serif;
//   font-style: italic;
//   font-weight: bold;
// `;

const StyledDropdownItem = styled(DropdownItem)`
  display: flex !important;
  align-items: center;

  &.dropdown-header {
    color: ${props => props.theme.colors.warning};
  }
`;

const DropdownImage = styled.img`
  width: 32px;
  height: 32px;
  margin-right: 10px;
`;

const DropdownItemText = styled.div`
  font-size: 10px;
  color: #a2a2a2;
  border-bottom: 1px solid #dedede;
  display: block;
`;

const DropdownText = styled.div`
  flex-grow: 1;
`;

const StyledNavLink = styled(NavLink)`
  color: #ffffff !important;
`;

const StyledDropdownToggle = styled(DropdownToggle)`
  color: #ffffff !important;
`;

const StyledNavItem = styled(NavItem)`
  ${animatedHover};
`;

const StyledUncontrolledDropdown = styled(UncontrolledDropdown)`
  ${animatedHover};
`;

const StyledNavbarToggler = styled(NavbarToggler)`
  padding: 2px 4px !important;
  border-color: #ffffff !important;
  color: #ffffff !important;
  margin: 5px 0;
`;

export default MainNavbar;
