import {AppContainer} from '../../styles';
import React, {useEffect, useRef} from 'react';
import LoadingComponent from '../UIKit/LoadingComponent';
import Loadable from 'react-loadable';
import firebase from '../../utils/firebase';
import {
  removeDeviceTokenAction,
  storeDeviceTokenAction,
} from '../../actions/notification';
import {
  getLocalFCMToken,
  removeFCMTokenLocally,
  storeFCMTokenLocally,
  supported,
} from '../../utils/firebaseMessaging';
import {createGlobalStyle} from 'styled-components';
import {dump, getCurrentUserId} from '../../utils/helpers';
import {useDispatch, useSelector} from 'react-redux';

const Navigation = Loadable({
  loader: () => import('../../components/Navigation'),
  loading: LoadingComponent,
});

const AppFooter = Loadable({
  loader: () => import('../../components/Footer'),
  loading: LoadingComponent,
});

const storeSelector = store => {
  const {auth} = store;
  const {userData} = auth;

  return {userData};
};

const ApplicationLayout = props => {
  const {withoutFooter, children} = props;

  const messaging = useRef(null);
  const token = useRef(null);

  const dispatch = useDispatch();
  const {userData} = useSelector(storeSelector);

  const storeToken = newToken => {
    dump('Storing token: ', newToken);
    storeFCMTokenLocally(newToken);
    dispatch(storeDeviceTokenAction(newToken, getCurrentUserId(userData)));
  };

  const onRefreshToken = () => {
    if (!messaging.current) {
      return;
    }

    messaging.current.onTokenRefresh(() => {
      messaging.current
        .getToken()
        .then(refreshedToken => {
          const currentToken = getLocalFCMToken();
          if (refreshedToken !== currentToken) {
            storeToken(refreshedToken);
          }
        })
        .catch(function(err) {});
    });
  };

  const requestPermission = () => {
    if (!messaging.current) {
      dump('Error: Messaging not found');
      return;
    }

    messaging.current
      .requestPermission()
      .then(() => {
        getToken();
      })
      .catch(function(err) {
        dump(err);
      });
  };

  const getToken = () => {
    if (!messaging.current) {
      dump('Error: Messaging no found');
      return;
    }

    const fcmToken = getLocalFCMToken();
    if (fcmToken) {
      dump('Info: FCM Token already stored on local storage.');
      dump('Info: Removing local token...');
      const userId = getCurrentUserId();
      dispatch(removeDeviceTokenAction(fcmToken, userId));
      removeFCMTokenLocally();
    }

    messaging.current
      .getToken()
      .then(currentToken => {
        token.current = currentToken;
        dump('FCM Token: ', currentToken);
        if (currentToken) {
          storeToken(currentToken);
        } else {
          requestPermission();
        }
      })
      .catch(function(err) {
        dump(err);
      });
  };

  useEffect(() => {
    if (supported()) {
      messaging.current = firebase.messaging();
      requestPermission();
      onRefreshToken();
    }

    token.current = getLocalFCMToken();
  }, []);

  return (
    <AppContainer>
      <Navigation />
      {children}
      {!withoutFooter && <AppFooter />}
      <CreateGlobalStyle />
    </AppContainer>
  );
};

const CreateGlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    //border: 1px dotted;
  }
  html {
    font-size: ${props => props.theme.global.fontSize};
  }
  body {
    color: #747474;
    position: relative;
  }
  body.modal-open {
    overflow: hidden;
  }
  h1, h2, h3, h4, h5, h6 {
    font-family: ${props => props.theme.global.fontFamily},
    color: ${props => props.theme.global.fontColor}
  }
`;

export default ApplicationLayout;
