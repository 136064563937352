import React, {Component} from 'react';
import ApplicationLayout from './Layouts/ApplicationLayout';
import {Container, MainContent} from '../styles';
import {AccessDeniedAlert} from './AccessDeniedAlert';
import styled, {createGlobalStyle} from 'styled-components';
import {media} from '../styles/grid';

class AccessDeniedPage extends Component {
  render() {
    return (
      <ApplicationLayout withoutFooter>
        <StyledMainContent>
          <Container>
            <AccessDeniedAlert />
          </Container>
        </StyledMainContent>
        <CreateGlobalStyle />
      </ApplicationLayout>
    );
  }
}

const CreateGlobalStyle = createGlobalStyle`
  body {
    background: #F8f8f8!important;
  }
`;

const StyledMainContent = styled(MainContent)`
  ${media.lg` 
    padding-top: 1em;
  `};
`;

export default AccessDeniedPage;
