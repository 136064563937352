import {
  GET_NOTIFICATION_NEED_ACTION,
  GET_NOTIFICATION_NO_NEED_ACTION,
  REMOVE_DEVICE_TOKEN_ACTION,
  STORE_DEVICE_TOKEN_ACTION,
} from './actionsList';

export const getNotificationNeedActionRequest = params => {
  return {
    type: GET_NOTIFICATION_NEED_ACTION,
    payload: {params},
  };
};

export const getNotificationNoNeedActionRequest = params => {
  return {
    type: GET_NOTIFICATION_NO_NEED_ACTION,
    payload: {params},
  };
};

export const storeDeviceTokenAction = (token, userId) => {
  return {
    type: STORE_DEVICE_TOKEN_ACTION,
    payload: {token, userId},
  };
};

export const removeDeviceTokenAction = (token, userId) => {
  return {
    type: REMOVE_DEVICE_TOKEN_ACTION,
    payload: {token, userId},
  };
};
