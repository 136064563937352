import ApplicationLayout from '../../components/Layouts/ApplicationLayout';
import React, {Component, Fragment} from 'react';
import {bindActionCreators} from 'redux';
import {getExternalNewsDetail} from '../../actions/news';
import connect from 'react-redux/es/connect/connect';
import {Container, MainContent, QlEditorViewer} from '../../styles';
import styled, {css, createGlobalStyle} from 'styled-components';
import {media} from '../../styles/grid';
import {
  postDepartmentURL,
  postImageURL,
  publishedDate,
} from '../../utils/helpers';
import LoadingComponent from '../../components/UIKit/LoadingComponent';
import {Link} from 'react-router-dom';
import PageNotFound from '../errors/PageNotFound';
import {BullhornIcon} from '../../components/News/PostCard';
import {Post} from '../../models/Post';
import config from '../../config';

class ExternalPostDetail extends Component {
  componentDidMount() {
    this.fetch();
  }

  componentWillUnmount(): void {
    localStorage.removeItem('EXTERNAL_NEWS_DETAIL');
  }

  fetch = () => {
    const {id} = this.props.match.params;
    this.props.getNewsDetail(id);
  };

  render() {
    const {id} = this.props.match.params;
    // const postData = ExternalNewsDetail.find(id);
    const {news} = this.props;
    const {loading, post: postData} = news;
    const post = new Post(postData, true);
    const hasPost = !loading && !!post;

    if (config.DEBUG) {
      console.log({post, loading, hasPost, postData});
    }

    if (!post) {
      return <PageNotFound message="Post not found" />;
    }

    const isAnnouncement = post.is_announcement;

    return (
      <ApplicationLayout withoutFooter>
        {loading && <LoadingComponent />}
        {hasPost && (
          <Fragment>
            {post.imageURL && <PostImage src={postImageURL(post)} />}
            <PostHeader>
              <Container>
                <PostHeaderTitle>
                  {isAnnouncement ? <BullhornIcon /> : ''}
                  <span dangerouslySetInnerHTML={{__html: post.title}} />
                </PostHeaderTitle>
                {post.createdAt && (
                  <PostTime>
                    Published on {publishedDate(post.createdAt)}
                  </PostTime>
                )}
              </Container>
            </PostHeader>
            <PostMainContent>
              <PostContainer>
                {post.category ? (
                  <DepartmentName to={postDepartmentURL(post.category.id)}>
                    {post.category.name}
                  </DepartmentName>
                ) : (
                  ''
                )}
                {/*<PublishedBy>Published by {post.user.name}</PublishedBy>*/}
                <QlEditorViewer>
                  <PostContent dangerouslySetInnerHTML={{__html: post.body}} />
                </QlEditorViewer>
              </PostContainer>
            </PostMainContent>
          </Fragment>
        )}
        <CreateGlobalStyle />
      </ApplicationLayout>
    );
  }
}

const CreateGlobalStyle = createGlobalStyle`
  body {
    background: #FFFFFF!important;
  }
`;

const PostMainContent = styled(MainContent)`
  background: #ffffff;
`;
const PostContainer = styled(Container)`
  padding: 0.5em;

  ${media.xl`
    padding: .5em 0;
  `};
`;

const PostImage = styled.div`
  height: 350px;
  ${props =>
    props.src &&
    css`
      background-size: cover;
      background-color: #dee2e6;
      background-image: url('${props.src}');
      background-repeat: no-repeat;
      background-position: center;
`};
`;

const PostHeader = styled.div`
  background-color: #d04a02;
  color: #ffffff;
  display: flex;
  justify-content: center;
  padding: 1em 0;

  ${media.xs`
    padding: 0;
  `};
`;
const PostHeaderTitle = styled.h1`
  font-size: 32px;

  //&:after {
  //  content: '';
  //  border-bottom: 2px solid #ffffff;
  //  display: block;
  //  margin-bottom: 12px;
  //  margin-top: 12px;
  //  width: 62px;
  //  max-width: 50%;
  //}
`;
const PostTime = styled.div`
  font-size: 1em;
`;
const PostContent = styled.article`
  margin: 1em 0;
  img {
    max-width: 100%;
  }
`;
const DepartmentName = styled(Link)`
  color: #eda300;
  font-size: 20px;
  margin-top: 0.5em;
  &:hover {
    color: var(--pwc-orange);
  }
`;

function mapStateToProps(state) {
  const {externalNews} = state;
  return {
    news: externalNews,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getNewsDetail: bindActionCreators(getExternalNewsDetail, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ExternalPostDetail);
