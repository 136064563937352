import {loadState} from './localStorage';
import axios from 'axios';
import swal from 'sweetalert2';
import hosts from './hosts';
import {
  decryptResponse,
  decryptResponseError,
  encryptRequest,
  isEncryptionEnabled,
} from './encryption';
import {FeatureFlag} from '../config/constants';
import {authOutRequest} from '../actions/auth';

export let host;
host = hosts.people_directory;

axios.defaults.baseURL = host;
axios.defaults.headers.common = {
  Pragma: 'no-cache', // disable axios cache
};

/**
 * Axios instance for Service Reservation.
 *
 * @type {AxiosInstance}
 */
export const ReservationApi = axios.create({
  baseURL: hosts.reservation,
});

/**
 * Axios instance for Service Beverage.
 *
 * @type {AxiosInstance}
 */
export const BeverageApi = axios.create({
  baseURL: hosts.beverage,
});

/**
 * Axios instance for POI Reservation.
 *
 * @type {AxiosInstance}
 */
export const PoiApi = axios.create({
  baseURL: hosts.kiosk,
});

/**
 * Axios instance for Community service.
 *
 * @type {AxiosInstance}
 */
export const CommunityApi = axios.create({
  baseURL: hosts.community,
});

/**
 * Axios instance for Message/Notification service.
 *
 * @type {AxiosInstance}
 */
export const MessageApi = axios.create({
  baseURL: hosts.message,
});

/**
 * Axios instance for News service.
 *
 * @type {AxiosInstance}
 */
export const NewsApi = axios.create({
  baseURL: hosts.news,
});

/**
 * Axios instance for Eula service.
 *
 * @type {AxiosInstance}
 */
export const EulaApi = axios.create({
  baseURL: hosts.eula,
});

export const TOKEN_NAME = 'accessToken';

export const getAccessToken = () => {
  const state = loadState();
  if (state && state.auth) {
    const {auth} = state;
    const {token} = auth;
    return token;
  }

  return localStorage.getItem('token');
};

export const setAccessToken = token => {
  axios.defaults.headers.common[TOKEN_NAME] = token;
  axios.defaults.headers.common['usertoken'] = token;
  ReservationApi.defaults.headers.common[TOKEN_NAME] = token;
  PoiApi.defaults.headers.common[TOKEN_NAME] = token;
  BeverageApi.defaults.headers.common[TOKEN_NAME] = token;
  MessageApi.defaults.headers.common[TOKEN_NAME] = token;
  EulaApi.defaults.headers.common[TOKEN_NAME] = token;
  CommunityApi.defaults.headers.common[TOKEN_NAME] = token;
  NewsApi.defaults.headers.common[TOKEN_NAME] = token;
};

const accessToken = getAccessToken();
if (accessToken) {
  setAccessToken(accessToken);
}

window.progress = {
  state: {
    show: true,
    value: 0,
  },

  show() {
    this.state.show = true;
  },

  hide() {
    this.state.show = false;
  },

  set(value) {
    this.state.value = value;
  },

  increment(num) {
    this.state.value += num;
  },

  decrement(num) {
    this.state.value -= num;
  },

  start() {
    this.state.show = true;
    this.state.value = 0;
  },

  finish() {
    this.state.value = 100;
    setTimeout(() => {
      this.state = {
        show: true,
      };
    }, 1000);
  },
};

const handle401Error = (fn, store) => error => {
  const {config, response} = error || {};
  const {baseURL, url} = config || {};

  const excludedApi = [host + 'v1/auth/otp/validate', host + 'v1/auth/login'];

  if (
    FeatureFlag.USE_AUTO_LOGOUT_SESS_EXPIRE &&
    (response || {}).status === 401
  ) {
    const isExcluded = excludedApi.includes(baseURL + url);
    if (!isExcluded) {
      swal({
        title: 'Session Expired',
        text: 'You will be redirect to login page',
        type: 'warning',
        showConfirmButton: true,
        confirmButtonText: 'OK',
      }).then(() => {
        if (store) {
          store.dispatch(authOutRequest());
        }
      });
    }
  }

  return fn(error) || Promise.reject(error);
};

const setupAxiosEncrypted = (isEncrypted, axiosInstance, store) => {
  const on401Error = fn => handle401Error(fn, store);

  if (isEncrypted) {
    axiosInstance.onRequest(encryptRequest);
    axiosInstance.onResponse(decryptResponse, on401Error(decryptResponseError));

    ReservationApi.interceptors.request.use(encryptRequest);
    ReservationApi.interceptors.response.use(
      decryptResponse,
      on401Error(decryptResponseError)
    );

    PoiApi.interceptors.request.use(encryptRequest);
    PoiApi.interceptors.response.use(
      decryptResponse,
      on401Error(decryptResponseError)
    );

    BeverageApi.interceptors.request.use(encryptRequest);
    BeverageApi.interceptors.response.use(
      decryptResponse,
      on401Error(decryptResponseError)
    );

    CommunityApi.interceptors.request.use(encryptRequest);
    CommunityApi.interceptors.response.use(
      decryptResponse,
      on401Error(decryptResponseError)
    );

    MessageApi.interceptors.request.use(encryptRequest);
    MessageApi.interceptors.response.use(
      decryptResponse,
      on401Error(decryptResponseError)
    );

    NewsApi.interceptors.request.use(encryptRequest);
    NewsApi.interceptors.response.use(
      decryptResponse,
      on401Error(decryptResponseError)
    );

    EulaApi.interceptors.request.use(encryptRequest);
    EulaApi.interceptors.response.use(
      decryptResponse,
      on401Error(decryptResponseError)
    );
  }
};

export const setupAxios = store => {
  const on401Error = fn => handle401Error(fn, store);

  // Axios.prototype cannot be modified
  const axiosExtra = {
    setHeader(name, value, scopes = 'common') {
      for (let scope of Array.isArray(scopes) ? scopes : [scopes]) {
        if (!value) {
          delete this.defaults.headers[scope][name];
          return;
        }
        this.defaults.headers[scope][name] = value;
      }
    },
    setToken(token, type, scopes = 'common') {
      const value = !token ? null : (type ? type + ' ' : '') + token;
      this.setHeader('Authorization', value, scopes);
    },
    onRequest(fn) {
      this.interceptors.request.use(config => fn(config) || config);
    },
    onResponse(fn) {
      this.interceptors.response.use(response => fn(response) || response);
    },
    onRequestError(fn) {
      this.interceptors.request.use(
        undefined,
        error => fn(error) || Promise.reject(error)
      );
    },
    onResponseError(fn) {
      this.interceptors.response.use(undefined, on401Error(fn));
    },
    onError(fn) {
      this.onRequestError(fn);
      this.onResponseError(fn);
    },
  };

  const extendAxiosInstance = axiosInstance => {
    for (let key in axiosExtra) {
      axiosInstance[key] = axiosExtra[key].bind(axiosInstance);
    }
  };

  extendAxiosInstance(axios);

  // -- START --
  // Handle encrypt for request and response
  setupAxiosEncrypted(isEncryptionEnabled(), axios, store);
  // -- END --

  let currentRequests = 0;

  axios.onError(error => {
    // if (error && error.config && error.config.progress === false) {
    //   return
    // }

    currentRequests--;
    // $loading().fail()
    window.progress.finish();
  });

  const onProgress = e => {
    // if (!currentRequests) {
    //   return;
    // }
    const progress = (e.loaded * 100) / (e.total * currentRequests);
    const value = Math.min(100, progress);
    // console.log(progress, value);
    // $loading().set()
    // setInterval(() => {
    window.progress.set(value);
    // })

    while (window.progress.state.value < 100) {
      // setTimeout(() => {
      window.progress.increment(10);
      // }, 300)
    }
  };

  axios.defaults.onDownloadProgress = onProgress;

  axios.defaults.onUploadProgress = onProgress;
};
