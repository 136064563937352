import {
  AUTH_OUT_SUCCESS,
  CLEAR_POI_BY_FLOOR,
  GET_FLOOR_FAILED,
  GET_FLOOR_REQUEST,
  GET_FLOOR_SUCCESS,
  GET_POI_BY_FLOOR_FAILED,
  GET_POI_BY_FLOOR_REQUEST,
  GET_POI_BY_FLOOR_SUCCESS,
  SET_FLOORS,
} from '../actions/actionsList';

let initialState = {
  floors: [],
  poiList: [],
  loadingFloor: false,
  loadingPOI: false,
  loadingMorePOI: false,
  pagination: {
    total: 0,
    currentPage: 1,
    lastPage: 1,
    perPage: 10,
  },
};

export default function(state = initialState, action) {
  const {type, payload} = action;

  switch (type) {
    case AUTH_OUT_SUCCESS: {
      return {...initialState};
    }

    case GET_FLOOR_REQUEST: {
      return {...state, loadingFloor: true};
    }

    case GET_FLOOR_SUCCESS: {
      return {...state, loadingFloor: false};
    }

    case GET_FLOOR_FAILED: {
      return {...state, loadingFloor: false};
    }

    case SET_FLOORS: {
      const {data} = payload;
      return {...state, floors: data};
    }

    case GET_POI_BY_FLOOR_REQUEST: {
      const {loadMore} = payload;
      const loadingMorePOI = !!loadMore;
      const loadingPOI = !loadMore;
      return {...state, loadingPOI, loadingMorePOI};
    }

    case GET_POI_BY_FLOOR_SUCCESS: {
      const {data, pagination, loadMore} = payload;
      if (loadMore) {
        let {poiList} = state;
        poiList = [...poiList, ...data];
        return {
          ...state,
          poiList,
          pagination,
          loadingPOI: false,
          loadingMorePOI: false,
        };
      }
      return {...state, poiList: data, pagination, loadingPOI: false};
    }

    case GET_POI_BY_FLOOR_FAILED: {
      return {...state, loadingPOI: false, loadingMorePOI: false};
    }

    case CLEAR_POI_BY_FLOOR: {
      return {
        ...state,
        poiList: [],
        pagination: {
          total: 0,
          currentPage: 1,
          lastPage: 1,
          perPage: 10,
        },
        loadingPOI: false,
        loadingMorePOI: false,
      };
    }

    default:
      return state;
  }
}
