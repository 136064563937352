export const FeatureFlag = {
  USE_OPENAM: false,
  USE_OTP_VERIFICATION: true,
  USE_FORGOT_PASSWORD: true,
  USE_CHANGE_PASSWORD: true,
  USE_WFO_RESERVE: false,
  USE_HELVETICA: true,
  USE_AUTO_LOGOUT_SESS_EXPIRE: true,
  USE_RESERVATION_BOOKER_RESCHEDULE: false,
  USE_RESERVATION_CHECKIN_MEETING_ROOM: true,
  USE_CLUSTER_SIC: true,
};

const version = (process.env.REACT_APP_VERSION || '').split('-');
export const APP_VERSION =
  (version.length > 1 ? version.slice(1) : version).join('-') || '';
