import moment from 'moment';
import {requestSegment} from '../../../utils/helpers';
import config from '../../../config';
import _ from 'lodash';

export const validate = values => {
  const meetingTypes = ['internal', 'external'];
  const errors = {};
  const now = moment();
  const {
    meeting_type: meetingType,
    reservation_type: reservationType,
    number_of_participant: numberOfParticipant,
    check_in: startDate,
    check_in_release: endDate,
    start_time,
    end_time,
    is_req_client_info: isReqClientInfo,
  } = values;
  const startTime = moment(start_time, config.shortTimeFormat);
  const endTime = moment(end_time, config.shortTimeFormat);
  const isExternalMeeting = meetingType === 'external';
  const isProjectBooking = reservationType === 'project';
  const isGroupBooking = reservationType === 'group';

  if (!values.host_name) {
    errors.host_name = 'Host Name is required';
  }
  if (!values.meeting_type) {
    errors.meeting_type = 'Meeting Type is required';
  }
  if (!meetingTypes.includes(meetingType)) {
    errors.meeting_type = 'Meeting type is unknown';
  }
  const timeRegex = /^\d\d:\d\d$/;
  if (!startTime) {
    errors.start_time = 'Start time is required';
    if (!timeRegex.test(start_time)) {
      errors.start_time = 'Start time is invalid';
    }
  }
  if (!endTime) {
    errors.end_time = 'End time is required';
    if (!timeRegex.test(end_time)) {
      errors.end_time = 'End time is invalid';
    }
  }
  if (start_time && end_time) {
    if (startTime >= endTime) {
      errors.start_time = 'Meeting start time must be earlier than end time';
    }
    if (endTime <= startTime) {
      errors.end_time = 'Meeting end time must be later than start time';
    }
  }
  if (!values.meeting_description || !values.meeting_description.trim()) {
    errors.meeting_description = 'Meeting Name is required';
  }
  if (!values.poi_id) {
    errors.poi_id = 'Meeting Room is required';
  }
  if (!+numberOfParticipant && !isGroupBooking) {
    errors.number_of_participant = 'Total Participant is required';
  }
  // if (!values.remarks_reservation) {
  //   errors.remarks_reservation = 'Remarks is required';
  // }
  if (isExternalMeeting) {
    if (!values.participant || !values.participant.length) {
      errors.participant = {_error: 'At least one participant must be entered'};
    } else {
      let participantArrayErrors = [];
      values.participant.forEach((item, index) => {
        const field = participantArrayErrors[index];
        if (item.user == null || !item.user.trim()) {
          if (field && !field.user) {
            field['user'] = 'User is required';
          } else {
            participantArrayErrors.push({
              user: 'Participant is required',
            });
          }
        }
      });
      if (participantArrayErrors.length) {
        errors.participant = participantArrayErrors;
      }
    }
    if (!values.client_name && isReqClientInfo) {
      errors.client_name = 'Client name is required';
    }
  }

  if (!values.engagement_partner && isProjectBooking) {
    errors.engagement_partner = 'Engagement Partner is required';
  }

  if (isProjectBooking || isGroupBooking) {
    if (!values.check_in) {
      errors.check_in = 'Start date is required';
    }
    if (!values.check_in_release) {
      errors.check_in_release = 'End date is required';
    }
  }

  let checkInDate, checkInField;
  if (isProjectBooking || isGroupBooking) {
    if (startDate && endDate) {
      const momentStartDate = moment(startDate);
      const momentEndDate = moment(endDate);
      if (momentStartDate > momentEndDate) {
        errors.check_in_release =
          'Meeting end date must be later than start date';
      }
    }
    checkInDate = startDate;
    checkInField = 'check_in';
  } else {
    if (!values.meeting_date) {
      errors.meeting_date = 'Meeting Date is required';
    }
    checkInDate = values.meeting_date;
    checkInField = 'meeting_date';
  }
  const checkIn = moment(`${checkInDate} ${start_time}`, 'YYYY-MM-DD HH:mm');
  if (checkIn < now) {
    errors[checkInField] =
      'Cannot reserve on past date and time, please choose different time!';
  }

  if (isGroupBooking) {
    if (!values.workspaces || !values.workspaces.length) {
      errors.workspaces = {_error: 'At least one participant must be entered'};
    } else {
      const workspacesArrayErrors = values.workspaces.map(item => {
        const errorItem = {};
        if (!item.user) {
          errorItem.user = 'User is required';
        }
        if (!+item.poi) {
          errorItem.poi = 'Workspace is required';
        }
        return errorItem;
      });
      if (workspacesArrayErrors.length) {
        errors.workspaces = workspacesArrayErrors;
      }
    }
  }
  const isClusterAdmin = requestSegment(3) === 'cluster-admin';
  if (!isClusterAdmin) {
    const approvalUsers = values.approvalUsers;
    const approverCount = approvalUsers ? approvalUsers.length : 0;
    const numberOfApprover = values.number_of_approver;
    if (approverCount < numberOfApprover) {
      errors.approvalUsers = {_error: `Please input the approvers`};
    } else {
      if (approvalUsers) {
        if (approvalUsers.length < numberOfApprover) {
          errors.approvalUsers = {_error: `Please select the other approver.`};
        } else if (approvalUsers.length) {
          let approvalUsersArrayErrors = [];
          approvalUsers.forEach((item, index) => {
            if (!item || !item.user) {
              approvalUsersArrayErrors[index] = {
                user: 'Approver name is required',
              };
            }
          });

          const userIds = approvalUsers
            .filter(item => !!item.user)
            .map(item => item.user.id);
          const duplicates = _.filter(userIds, (val, i, iteratee) =>
            _.includes(iteratee, val, i + 1)
          );

          if (duplicates.length > 0) {
            errors.approvalUsers = {_error: `Approver must be unique.`};
          }
          if (approvalUsersArrayErrors.length) {
            errors.approvalUsers = approvalUsersArrayErrors;
          }
        }
      }
    }
  }
  if (config.DEBUG) {
    console.log('FORM VALIDATION', {values, errors});
  }
  return errors;
};
