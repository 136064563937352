import {takeEvery, put, call, takeLatest} from 'redux-saga/effects';
import {
  GET_DASHBOARD_NEWS_ACTION,
  GET_DASHBOARD_NEWS_FAILED,
  GET_DASHBOARD_NEWS_REQUEST,
  GET_DASHBOARD_NEWS_SUCCEED,
  GET_DEPARTMENT_LIST_ACTION,
  GET_DEPARTMENT_LIST_FAILED,
  GET_DEPARTMENT_LIST_REQUEST,
  GET_DEPARTMENT_LIST_SUCCEED,
  GET_EXTERNAL_NEWS_ACTION,
  GET_EXTERNAL_NEWS_BY_CATEGORY_ACTION,
  GET_EXTERNAL_NEWS_BY_CATEGORY_FAILED,
  GET_EXTERNAL_NEWS_BY_CATEGORY_REQUEST,
  GET_EXTERNAL_NEWS_BY_CATEGORY_SUCCEED,
  GET_EXTERNAL_NEWS_CATEGORIES_ACTION,
  GET_EXTERNAL_NEWS_CATEGORIES_FAILED,
  GET_EXTERNAL_NEWS_CATEGORIES_REQUEST,
  GET_EXTERNAL_NEWS_CATEGORIES_SUCCEED,
  GET_EXTERNAL_NEWS_DETAIL_ACTION,
  GET_EXTERNAL_NEWS_DETAIL_FAILED,
  GET_EXTERNAL_NEWS_DETAIL_REQUEST,
  GET_EXTERNAL_NEWS_DETAIL_SUCCEED,
  GET_EXTERNAL_NEWS_FAILED,
  GET_EXTERNAL_NEWS_REQUEST,
  GET_EXTERNAL_NEWS_SUCCEED,
  GET_HOME_NEWS_ACTION,
  GET_HOME_NEWS_FAILED,
  GET_HOME_NEWS_REQUEST,
  GET_HOME_NEWS_SUCCEED,
  GET_NEWS_BY_DEPARTMENT_ACTION,
  GET_NEWS_BY_DEPARTMENT_REQUEST,
  GET_NEWS_BY_DEPARTMENT_SUCCESS,
  GET_NEWS_DETAIL_ACTION,
  GET_NEWS_DETAIL_FAILED,
  GET_NEWS_DETAIL_REQUEST,
  GET_NEWS_DETAIL_SUCCESS,
  SEARCH_NEWS_ACTION,
  SEARCH_NEWS_REQUEST,
  SEARCH_NEWS_SUCCEED,
  SET_DEPARTMENT,
  SET_DEPARTMENTS_NEWS,
  SET_HIGHLIGHT_NEWS,
  SET_PAGINATION,
  GET_HOME_ANNOUNCEMENT,
  SET_HOME_ANNOUNCEMENT,
} from '../actions/actionsList';
import News from '../api/News';
import config from '../config';
import ExternalNewsDetail from '../utils/ExternalNewsDetail';
import {Post} from '../models/Post';
import {setGlobalError} from '../actions/global';

/**
 * Get home news.
 *
 * @param action
 * @returns {IterableIterator<*>}
 */
function* getHomeNewsActionHandler(action) {
  try {
    yield put({type: GET_HOME_NEWS_REQUEST});
    const responseData = yield call(News.getHome);
    const {code, data, message} = responseData || {};

    if (code === 200) {
      const {highlight, departements} = data || {};
      yield put({type: GET_HOME_NEWS_SUCCEED});
      yield put({type: SET_HIGHLIGHT_NEWS, payload: {data: highlight}});
      yield put({
        type: SET_DEPARTMENTS_NEWS,
        payload: {data: departements},
      });
    } else {
      yield put(setGlobalError(message));
      yield put({type: GET_HOME_NEWS_FAILED});
    }
  } catch (e) {
    yield put({type: GET_HOME_NEWS_FAILED});
    yield put(setGlobalError(config.INTERNAL_SERVER_ERROR));
  }
}

/**
 * Get news by category.
 *
 * @returns {IterableIterator<*>}
 */
function* getNewsByDepartmentActionHandler(action) {
  const {payload} = action;

  try {
    const {department_id, key, orderBy, descending, page, limit, type} =
      payload || {};
    yield put({type: GET_NEWS_BY_DEPARTMENT_REQUEST});
    const responseData = yield call(News.getNews, {
      department_id,
      descending,
      orderBy,
      limit,
      page,
      key,
      type,
    });
    const {code, data, message} = responseData || {};

    if (code === 200) {
      const {result, departement: department} = data || {};
      const {rows: posts, paginate: pagination} = result || {};

      yield put({
        type: GET_NEWS_BY_DEPARTMENT_SUCCESS,
        payload: {data: posts, pagination},
      });
      yield put({
        type: SET_DEPARTMENT,
        payload: {department},
      });
      yield put({type: SET_PAGINATION, payload: {pagination}});
    } else {
      yield put({type: GET_NEWS_BY_DEPARTMENT_SUCCESS});
      yield put(setGlobalError(message));
    }
  } catch (e) {
    yield put({type: GET_NEWS_BY_DEPARTMENT_SUCCESS});
    yield put(setGlobalError(config.INTERNAL_SERVER_ERROR));
  }
}

function* getNewsDetailActionHandler(action) {
  const {payload} = action;

  const {id} = payload || {};
  yield put({type: GET_NEWS_DETAIL_REQUEST});

  if (ExternalNewsDetail.has(id)) {
    const post = ExternalNewsDetail.find(id);
    yield put({type: GET_NEWS_DETAIL_SUCCESS, payload: {post}});
  } else {
    const detail = yield call(News.getDetail, id);
    const {code, data, message} = detail || {};

    if (code === 200) {
      yield put({
        type: GET_NEWS_DETAIL_SUCCESS,
        payload: {post: data.result},
      });
    } else {
      yield put({type: GET_NEWS_DETAIL_FAILED, payload: {message}});
      yield put(setGlobalError(message));
    }
  }
}

function* getDashboardNewsActionHandler(action) {
  const {payload} = action;

  try {
    yield put({type: GET_DASHBOARD_NEWS_REQUEST});
    const {type, page, limit, descending, orderBy} = payload || {};
    const responseData = yield call(News.getDashboardNews, {
      type,
      page,
      limit,
      descending,
      orderBy,
    });
    const {code, data, message} = responseData || {};

    if (code === 200) {
      const {pagination_latest_news: pagination, latest_news: news, highlight} =
        data || {};
      yield put({
        type: GET_DASHBOARD_NEWS_SUCCEED,
        payload: {pagination, news, highlight},
      });
    } else {
      yield put({type: GET_DASHBOARD_NEWS_FAILED, payload: {message}});
      yield put(setGlobalError(message));
    }
  } catch (e) {
    yield put(setGlobalError(config.INTERNAL_SERVER_ERROR));
  }
}

function* getDepartmentListActionHandler(action) {
  try {
    yield put({type: GET_DEPARTMENT_LIST_REQUEST});
    const responseData = yield call(News.getDepartments);
    const {code, data, message} = responseData || {};
    if (code === 200) {
      const {departements: departments} = data || {};
      yield put({
        type: GET_DEPARTMENT_LIST_SUCCEED,
        payload: {departments},
      });
    } else {
      yield put({type: GET_DEPARTMENT_LIST_FAILED, payload: {message}});
      yield put(setGlobalError(message));
    }
  } catch (e) {
    yield put(setGlobalError(config.INTERNAL_SERVER_ERROR));
  }
}

function* getExternalNewsActionHandler(action) {
  const {payload} = action;

  try {
    yield put({type: GET_EXTERNAL_NEWS_REQUEST});
    const {page, limit} = payload || {};
    const responseData = yield call(News.getExternalNews, {
      page,
      limit,
    }) || {};
    const {code, data, message} = responseData || {};
    if (code === 200) {
      const {pagination, news} = data || {};
      yield put({
        type: GET_EXTERNAL_NEWS_SUCCEED,
        payload: {pagination, news},
      });
    } else {
      yield put({type: GET_EXTERNAL_NEWS_FAILED, payload: {message}});
      yield put(setGlobalError(message));
    }
  } catch (e) {
    yield put(setGlobalError(config.INTERNAL_SERVER_ERROR));
  }
}

function* getExternalNewsByCategoryActionHandler(action) {
  const {payload} = action;

  yield put({type: GET_EXTERNAL_NEWS_BY_CATEGORY_REQUEST});
  const {department_id, page, limit} = payload || {};
  const responseData = yield call(News.getExternalNewsByCategory, {
    department_id: department_id || '',
    page,
    limit,
  });
  const {code, data, message} = responseData || {};

  if (code === 200) {
    const {pagination, news} = data || {};
    yield put({
      type: GET_EXTERNAL_NEWS_BY_CATEGORY_SUCCEED,
      payload: {pagination, news},
    });
  } else {
    yield put({
      type: GET_EXTERNAL_NEWS_BY_CATEGORY_FAILED,
      payload: {message},
    });
    yield put(setGlobalError(message));
  }
}

function* getExternalNewsCategoriesActionHandler(action) {
  try {
    yield put({type: GET_EXTERNAL_NEWS_CATEGORIES_REQUEST});

    const responseData = yield call(News.getExternalNewsCategories);
    const {code, data, message} = responseData || {};

    if (code === 200) {
      const {categories} = data || {};
      yield put({
        type: GET_EXTERNAL_NEWS_CATEGORIES_SUCCEED,
        payload: {categories},
      });
    } else {
      yield put({
        type: GET_EXTERNAL_NEWS_CATEGORIES_FAILED,
        payload: {message},
      });
      yield put(setGlobalError(message));
    }
  } catch (e) {
    yield put(setGlobalError(config.INTERNAL_SERVER_ERROR));
  }
}

function* getExternalNewsDetailActionHandler(action) {
  const {payload} = action;

  yield put({type: GET_EXTERNAL_NEWS_DETAIL_REQUEST});
  const {id} = payload || {};
  const responseData = yield call(News.getExternalNewsDetail, id);
  const {code, data, message} = responseData || {};

  if (code === 200) {
    yield put({
      type: GET_EXTERNAL_NEWS_DETAIL_SUCCEED,
      payload: {post: data},
    });
  } else {
    yield put({
      type: GET_EXTERNAL_NEWS_DETAIL_FAILED,
      payload: {message},
    });
    yield put(setGlobalError(message));
  }
}

function* searchNewsActionHandler(action) {
  const {payload} = action;

  const {keyword, orderBy, descending, page, limit, type, append, loadMore} =
    payload || {};
  yield put({type: SEARCH_NEWS_REQUEST, payload: {loadMore}});
  const resNews = yield call(News.getNews, {
    descending,
    orderBy,
    key: keyword,
    limit,
    page,
    type,
  });
  const resExternalNews = yield call(News.getExternalNews, {
    key: keyword,
    limit,
    page,
  });

  const {data: resNewsData, code: codeNews, message: messageNews} =
    resNews || {};
  const {data: resExternalNewsData, code: codeExtMews} = resExternalNews || {};
  const newsData = (resNewsData || {}).result || {};
  const extNewsData = resExternalNewsData || {};

  const result = [
    ...new Set([
      ...(newsData.rows || []).map(item => new Post(item)),
      ...(extNewsData.news || []).map(item => new Post(item, true)),
    ]),
  ];

  if (codeNews === 200 || codeExtMews === 200) {
    const pagination = newsData.paginate;

    yield put({
      type: SEARCH_NEWS_SUCCEED,
      payload: {data: result, append, loadMore},
    });
    yield put({type: SET_PAGINATION, payload: {pagination}});
  } else {
    yield put({type: GET_NEWS_BY_DEPARTMENT_SUCCESS});
    yield put(setGlobalError(messageNews));
  }
}

function* getHomeAnnouncement(action) {
  const res = yield call(News.getHomepageAnnouncement);

  const {result} = res?.data || {};

  yield put({
    type: SET_HOME_ANNOUNCEMENT,
    payload: result,
  });
}

/**
 * Reservation Saga.
 *
 * @returns {IterableIterator<*>}
 */
export default function*() {
  yield takeEvery(GET_HOME_NEWS_ACTION, getHomeNewsActionHandler);
  yield takeEvery(GET_NEWS_DETAIL_ACTION, getNewsDetailActionHandler);
  yield takeEvery(
    GET_NEWS_BY_DEPARTMENT_ACTION,
    getNewsByDepartmentActionHandler
  );
  yield takeEvery(GET_DASHBOARD_NEWS_ACTION, getDashboardNewsActionHandler);
  yield takeEvery(GET_DEPARTMENT_LIST_ACTION, getDepartmentListActionHandler);
  yield takeEvery(GET_EXTERNAL_NEWS_ACTION, getExternalNewsActionHandler);
  yield takeEvery(
    GET_EXTERNAL_NEWS_BY_CATEGORY_ACTION,
    getExternalNewsByCategoryActionHandler
  );
  yield takeEvery(
    GET_EXTERNAL_NEWS_CATEGORIES_ACTION,
    getExternalNewsCategoriesActionHandler
  );
  yield takeEvery(
    GET_EXTERNAL_NEWS_DETAIL_ACTION,
    getExternalNewsDetailActionHandler
  );
  yield takeEvery(SEARCH_NEWS_ACTION, searchNewsActionHandler);
  yield takeLatest(GET_HOME_ANNOUNCEMENT, getHomeAnnouncement);
}
