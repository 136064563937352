import {
  AUTH_OUT_SUCCESS,
  GET_DASHBOARD_NEWS_FAILED,
  GET_DASHBOARD_NEWS_REQUEST,
  GET_DASHBOARD_NEWS_SUCCEED,
  GET_DEPARTMENT_LIST_FAILED,
  GET_DEPARTMENT_LIST_REQUEST,
  GET_DEPARTMENT_LIST_SUCCEED,
  GET_NEWS_BY_DEPARTMENT_FAILED,
  GET_NEWS_BY_DEPARTMENT_REQUEST,
  GET_NEWS_BY_DEPARTMENT_SUCCESS,
  GET_NEWS_DETAIL_FAILED,
  GET_NEWS_DETAIL_REQUEST,
  GET_NEWS_DETAIL_SUCCESS,
  SEARCH_NEWS_FAILED,
  SEARCH_NEWS_REQUEST,
  SEARCH_NEWS_SUCCEED,
  SET_DEPARTMENT,
  SET_DEPARTMENTS_NEWS,
  SET_HIGHLIGHT_NEWS,
  GET_HOME_ANNOUNCEMENT,
  SET_HOME_ANNOUNCEMENT,
} from '../actions/actionsList';

let initialState = {
  highlight: [],
  departments: [],
  post: null,
  loading: false,
  posts: [],
  department: null,
  loadingDashboardNews: false,
  latestNews: [],
  pagination: {
    last_page: 1,
    limit: 3,
    perPage: 3,
    total: 0,
  },
  loadingDepartment: false,
  searchResult: [],
  searching: false,
  loadingMore: false,
  homeAnnouncement: {
    loading: false,
    data: null,
  },
};

export default function(state = initialState, action) {
  const {type, payload: actionPayload} = action;

  const payload = actionPayload || {};

  switch (type) {
    case AUTH_OUT_SUCCESS: {
      return {...initialState};
    }

    case SET_DEPARTMENT: {
      const {department} = payload;
      return {...state, department};
    }

    case SET_HIGHLIGHT_NEWS: {
      const {data} = payload;
      return {...state, highlight: data};
    }

    case SET_DEPARTMENTS_NEWS: {
      const {data} = payload;
      return {...state, departments: data};
    }

    case GET_NEWS_DETAIL_REQUEST: {
      return {...state, loading: true};
    }

    case GET_NEWS_DETAIL_SUCCESS: {
      const {post} = payload;
      return {...state, loading: false, post};
    }

    case GET_NEWS_DETAIL_FAILED: {
      return {...state, loading: false};
    }

    case GET_NEWS_BY_DEPARTMENT_REQUEST: {
      return {...state, loading: true, posts: []};
    }

    case GET_NEWS_BY_DEPARTMENT_SUCCESS: {
      const {data: posts, pagination} = payload;
      return {...state, loading: false, posts, pagination};
    }

    case GET_NEWS_BY_DEPARTMENT_FAILED: {
      return {...state, loading: false, posts: []};
    }

    case GET_DASHBOARD_NEWS_REQUEST: {
      return {...state, loadingDashboardNews: true, latestNews: []};
    }

    case GET_DASHBOARD_NEWS_SUCCEED: {
      const {news, pagination, highlight} = payload;
      return {
        ...state,
        loadingDashboardNews: false,
        latestNews: news,
        pagination,
        highlight,
      };
    }

    case GET_DASHBOARD_NEWS_FAILED: {
      return {...state, loadingDashboardNews: false, latestNews: []};
    }

    case GET_DEPARTMENT_LIST_REQUEST: {
      return {...state, loadingDepartment: true, departments: []};
    }

    case GET_DEPARTMENT_LIST_SUCCEED: {
      const {departments} = payload;
      return {...state, loadingDepartment: false, departments};
    }

    case GET_DEPARTMENT_LIST_FAILED: {
      return {...state, loadingDepartment: false, departments: []};
    }

    case SEARCH_NEWS_REQUEST: {
      if (payload && payload.loadMore) {
        return {...state, loadingMore: true};
      } else {
        return {...state, searching: true, posts: [], searchResult: []};
      }
    }

    case SEARCH_NEWS_SUCCEED: {
      const {data, append} = payload;
      if (append) {
        let {searchResult} = state;
        searchResult = [...searchResult, ...data];
        return {...state, searchResult, loadingMore: false};
      } else {
        return {...state, searching: false, searchResult: data};
      }
    }

    case SEARCH_NEWS_FAILED: {
      return {...state, searching: false, searchResult: [], loadingMore: false};
    }

    case GET_HOME_ANNOUNCEMENT:
      return {
        ...state,
        homeAnnouncement: {
          ...state.homeAnnouncement,
          loading: true,
        },
      };

    case SET_HOME_ANNOUNCEMENT:
      return {
        ...state,
        homeAnnouncement: {
          loading: false,
          data: actionPayload,
        },
      };

    default:
      return state;
  }
}
