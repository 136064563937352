import React from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import styled from 'styled-components';
import {InputStyle, TextError} from '../../styles';
import {InputFieldStyle} from '../Profile/edit/Fields';

export const DATEPICKER_FORMAT = 'YYYY-MM-DD';

const DatePickerField = props => {
  const {
    input,
    placeholder,
    defaultValue,
    flat,
    meta,
    dateFormat = DATEPICKER_FORMAT,
    inputValueFormat = DATEPICKER_FORMAT,
    ...rest
  } = props;

  const {touched, error} = meta || {};

  const value = input.value;
  delete input.value;
  return (
    <DatePickerWrapper>
      <StyledDatePicker
        {...input}
        autoComplete="off"
        placeholderText={placeholder}
        value={value ? moment(value).format(dateFormat) : null}
        dateFormat={dateFormat}
        selected={value ? moment(value, inputValueFormat) : null}
        flat={flat}
        {...rest}
      />
      {touched && error && <TextError>{error}</TextError>}
    </DatePickerWrapper>
  );
};

DatePickerField.defaultProps = {
  dateFormat: DATEPICKER_FORMAT,
  inputValueFormat: DATEPICKER_FORMAT,
  meta: {},
};

const DatePickerWrapper = styled.div`
  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    display: block;
  }
`;
const StyledDatePicker = styled(DatePicker)`
  ${props => (props.flat ? InputStyle : InputFieldStyle)};
`;

export default DatePickerField;
