import React, {Component, Fragment} from 'react';
import styled from 'styled-components';
import {
  ListGroup,
  ListGroupItem,
  ListGroupItemBody,
  ListGroupItemIcon,
  ListGroupItemImage,
  ListGroupItemText,
  ListGroupItemTitle,
  ListIconImage,
  TitleText,
} from '../../styles';
import {isNonStaff, requestSegment} from '../../utils/helpers';
import {Row, Col} from 'reactstrap';

class ContactList extends Component {
  render() {
    const {profileData} = this.props;

    if (!profileData) return null;

    const {profile} = profileData;

    if (!profile) return null;

    const {hiris, additional_editor: editors, extension} = profileData;

    const nonStaff = isNonStaff(profileData.profile);
    const isOnProfilePage = requestSegment(1) === 'user';
    const contactPersons = profileData.contact_person || [];

    return (
      <ContactInfoList>
        {!nonStaff && (
          <Fragment>
            <ContactTitleText>Information</ContactTitleText>
            <ListGroup>
              <ListGroupItem>
                <ListGroupItemBody>
                  <ListGroupItemTitle>Nickname</ListGroupItemTitle>
                  <ListGroupItemText>
                    {profile.nick_name || '-'}
                  </ListGroupItemText>
                </ListGroupItemBody>
              </ListGroupItem>
              <ListGroupItem>
                <ListGroupItemBody>
                  <ListGroupItemTitle>Staff Grade</ListGroupItemTitle>
                  <ListGroupItemText>
                    {hiris ? hiris.grade : '-'}
                  </ListGroupItemText>
                </ListGroupItemBody>
              </ListGroupItem>
              <ListGroupItem>
                <ListGroupItemBody>
                  <ListGroupItemTitle>Contact Person</ListGroupItemTitle>
                  {contactPersons.length > 0 ? (
                    contactPersons.map(item => (
                      <ListGroupItemText key={item.id}>
                        {item.user.name}
                      </ListGroupItemText>
                    ))
                  ) : (
                    <ListGroupItemText>-</ListGroupItemText>
                  )}
                </ListGroupItemBody>
              </ListGroupItem>
              {isOnProfilePage && (
                <ListGroupItem>
                  <ListGroupItemBody>
                    <ListGroupItemTitle>Additional Editors</ListGroupItemTitle>
                    {editors.map(item => (
                      <ListGroupItemText key={item.id}>
                        {item.name}
                      </ListGroupItemText>
                    ))}
                  </ListGroupItemBody>
                </ListGroupItem>
              )}
            </ListGroup>
          </Fragment>
        )}
        {!nonStaff && <ContactTitleText>Contact</ContactTitleText>}
        <ListGroup>
          {!nonStaff && (
            <Fragment>
              <ListGroupItem>
                <ListGroupItemBody>
                  <ListGroupItemTitle>Phone</ListGroupItemTitle>
                  <ListGroupItemText>
                    {profile.mobile_phone || '-'}
                  </ListGroupItemText>
                </ListGroupItemBody>
                <ListGroupItemIcon>
                  <ListGroupItemImage src="/assets/images/call.png" />
                </ListGroupItemIcon>
              </ListGroupItem>
              <ListGroupItem>
                <ListGroupItemBody>
                  <ListGroupItemTitle>WhatsApp</ListGroupItemTitle>
                  <ListGroupItemText>
                    {profile.wa_numbers || '-'}
                  </ListGroupItemText>
                </ListGroupItemBody>
                <ListGroupItemIcon>
                  <ListGroupItemImage src="/assets/images/whatsapp.png" />
                </ListGroupItemIcon>
              </ListGroupItem>
            </Fragment>
          )}
        </ListGroup>
        {/*{nonStaff && (*/}
        <Fragment>
          <ContactTitleText>Extension Number</ContactTitleText>
          <ListGroup>
            {extension.map(item => (
              <ListGroupItem key={item.id}>
                <ListGroupItemBody>
                  <ListGroupItemTitle>{item.name}</ListGroupItemTitle>
                  <ListGroupItemText>
                    <Row>
                      <Col xs={10}>{item.extension_number}</Col>
                      <ColRight>
                        <a href={`ciscotel:${item.extension_number}`}>
                          <ListIconImage
                            src="/assets/images/call.png"
                            alt="Call"
                          />
                        </a>
                      </ColRight>
                    </Row>
                  </ListGroupItemText>
                </ListGroupItemBody>
              </ListGroupItem>
            ))}
          </ListGroup>
        </Fragment>
        {/*)}*/}
      </ContactInfoList>
    );
  }
}

const ColRight = styled(Col)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const ContactTitleText = styled(TitleText)`
  color: #000000;
`;

export const ContactInfoList = styled(Col).attrs({
  sm: 12,
  xs: 12,
})``;

export default ContactList;
