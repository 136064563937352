import styled, {css} from 'styled-components';
import {Link} from 'react-router-dom';
import {Col, media} from './grid';
import {Card, CardBody, CardHeader} from './card';
import {
  DropdownToggle,
  Media,
  Button as Btn,
  Input as BSInput,
  Row,
  Container as BSContainer,
  CardColumns,
} from 'reactstrap';
import {Field} from 'redux-form';
import {animatedHover} from '../components/Navigation/MainNavbar';

/* CONTAINER */
export const AppHeader = styled.div``;
export const Container = styled(BSContainer)`
  padding: 10px;

  ${media.xl`
    padding: 0;
  `};
`;

export const LogoSection = styled.div`
  img {
    max-height: 40px;
  }
`;
export const IconNav = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  max-width: 80%;
  ${media.xs`
    max-width: 100%;
    width: 100%;
    justify-content: flex-end;
  `};
`;

export const MainNav = styled.div`
  background-color: #e0301e;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  height: auto;
  ${media.xs`
    height: auto;
  `};
`;
export const TopNav = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #fff;
  padding: 0em;
  position: relative;

  ${media.xs`
    padding: 0;
  `};

  &:after {
    content: '';
    width: 100%;
    height: 1px;
    background: transparent;
    position: absolute;
    bottom: -1px;
    left: 0;
    z-index: 190;
    box-shadow: 0px 2px 4px 0px #6c757d;
    border: none;
  }
`;

export const NavLink = styled(Link)`
  padding: 1.5em;
  font-size: 1em;
  text-align: center;

  ${props =>
    props.active &&
    css`
      border-bottom: 3px solid #fff;
      font-weight: bold;
    `} &:hover {
    border-bottom: 3px solid #fff;
  }
`;

export const Flex = styled.div`
  display: flex;
  ${props =>
    props.spaceBetween &&
    css`
      justify-content: space-between;
    `} ${props =>
    props.justifyCenter &&
    css`
      justify-content: center;
    `};
  ${props =>
    props.flexStart &&
    css`
      justify-content: flex-start;
    `};
`;
export const PageTitle = styled(Col)`
  display: flex;
  align-items: center;
  height: 100%;
  h1 {
    padding: 0;
    margin: 0;
    font-size: 24px;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-style: italic;
    font-weight: bold;
    margin-left: 5%;
  }
`;

export const Menus = styled.ul`
  display: flex;
  justify-content: space-between;

  ${media.xs`
    margin-top: 10px!important;
    display: ${props => (props.open ? 'block' : 'none')};
    width: 100%;
  `};
`;

export const MenuItem = styled.li`
  position: relative;

  &:hover ul {
    display: block;
  }
`;

/* INPUT */
export const Input = styled.input`
  outline: none;
  border-radius: ${props => (props.flat ? '0' : '5px')};
  border: 1px solid #afafaf;
  display: ${props => (props.inline ? 'inline' : 'block')};
  margin-bottom: 10px;
  width: 100%;
  padding: 20px 25px;
  box-sizing: border-box;
  font-size: ${props => (props.size === 'lg' ? '14px' : '14px')};
`;

/* BUTTONS */

export function buttonStyles(props) {
  let {color, variant, outline} = props;
  color = color || variant || 'secondary';
  let {
    backgroundColors: themeBGColors,
    colors: themeColors,
    borderColors: themeBorderColors,
    disabledColors,
    outlineDisabledColors,
    outlineDisabledBackgroundColors,
    disabledBorderColors,
    outlineDisabledBorderColors,
    disabledBackgroundColors,
  } = props.theme.button;
  let backgroundColors,
    colors,
    borderColors = [];
  if (outline) {
    backgroundColors = themeColors;
    colors = themeBGColors;
    borderColors = themeBGColors;
  } else {
    backgroundColors = themeBGColors;
    colors = themeColors;
    borderColors = themeBorderColors;
  }
  return css`
    display: ${props.block ? 'block' : 'inline-block'};
    width: ${props.block ? '100%' : 'auto'};
    border-radius: ${props.flat ? 0 : props.small ? '0.2rem' : '.25rem'};
    box-sizing: border-box;
    background-color: ${backgroundColors[color]};
    color: ${colors[color]}!important;
    border: 1px solid ${borderColors[color]};
    cursor: pointer;
    text-transform: ${props.transform ? props.transform : 'inherit'};
    margin-right: 10px;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    padding: ${props.small ? '0.25rem 0.5rem' : '0.375rem 0.75rem'};
    font-size: ${props.small ? '0.875rem' : '1rem'};
    line-height: 1.5;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:disabled {
      color: ${outline
        ? outlineDisabledColors[color]
        : disabledColors[color]}!important;
      background-color: ${outline
        ? outlineDisabledBackgroundColors[color]
        : disabledBackgroundColors[color]};
      border-color: ${outline
        ? outlineDisabledBorderColors[color]
        : disabledBorderColors[color]};
      cursor: not-allowed;
    }

    &:last-child {
      margin-right: 0;
    }

    ${props.uppercase &&
      css`
        text-transform: uppercase !important;
      `};

    ${props.link &&
      css`
        background: transparent;
        padding: 0;
        color: ${backgroundColors[color]}!important;
        border: none;
      `};
  `;
}

export const Button = styled.button`
  ${props => buttonStyles(props)};
`;

export const AppContainer = styled.div``;

export const TitleText = styled.h3`
  font-size: 16px;
  color: #505050;
  opacity: 0.8;
  margin-bottom: 5px;
  font-weight: normal;
`;

export const InputStyle = css`
  padding: 10px 0;
  color: #747474;
  width: 100%;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #dfdfdf;
  font-size: 16px;
  font-weight: normal;
  outline: none;

  &::-webkit-input-placeholder {
    color: #cdcdcd;
  }
`;

export const TextError = styled.div`
  color: #e40000;
  font-size: 13px;
  margin-top: 10px;
  clear: both;
`;

export const Alert = styled.div`
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;

  .close {
    float: right;
    cursor: pointer;
  }

  ${props => {
    const {color} = props;
    switch (color) {
      case 'danger':
        return css`
          color: #721c24;
          background-color: #f8d7da;
          border-color: #f5c6cb;
        `;
      case 'primary':
        return css`
          color: #004085;
          background-color: #cce5ff;
          border-color: #b8daff;
        `;
      case 'warning':
        return css`
          color: #856404;
          background-color: #fff3cd;
          border-color: #ffeeba;
        `;
      case 'secondary':
        return css`
          color: #383d41;
          background-color: #e2e3e5;
          border-color: #d6d8db;
        `;
      case 'success':
        return css`
          color: #155724;
          background-color: #d4edda;
          border-color: #c3e6cb;
        `;
      case 'info':
        return css`
          color: #0c5460;
          background-color: #d1ecf1;
          border-color: #bee5eb;
        `;
      case 'light':
        return css`
          color: #818182;
          background-color: #fefefe;
          border-color: #fdfdfe;
        `;
      case 'dark':
        return css`
          color: #1b1e21;
          background-color: #d6d8d9;
          border-color: #c6c8ca;
        `;
      default:
        break;
    }
  }};
`;

export const alignStyles = css`
  &.center {
    text-align: center;
  }
  &.right {
    text-align: right;
  }
`;

export const TableResponsive = styled.div`
  max-width: 100%;
  overflow-x: auto;
`;

export const Table = styled.table`
  width: 100%;
  max-width: 100%;
  background-color: transparent;
  border-spacing: 0;
  border-collapse: separate;

  th {
    font-size: 14px;
    vertical-align: bottom;
    padding: 12px;
    border-bottom: 1px solid #c5c5c5;
    text-align: left;

    ${props => alignStyles};
  }

  td {
    font-size: 14px;
    padding: 21px 12px;
    vertical-align: middle;
    border-top: 1px solid #dee2e6;
    text-align: left;

    button {
      margin-bottom: 0 !important;
    }

    ${props => alignStyles};
  }
`;

export const BreadcrumbsWrapper = styled.div`
  margin-top: 20px;
`;

export const Breadcrumbs = styled.ul`
  display: block;
`;

export const BreadcrumbItem = styled.li`
  display: inline;
  padding-right: 10px;
  color: ${props => (props.active ? '#828282' : '#464646')};
  &:last-child:after {
    content: '';
    display: none;
  }
  &:after {
    content: '/';
    margin-left: 10px;
  }
`;

export const BreadcrumbLink = styled(Link)``;

export const CheckBox = styled.label`
  display: inline-flex;
  position: relative;
  padding-left: 30px;
  margin-right: 12px;
  cursor: pointer;
  user-select: none;
  align-items: center;

  ${props =>
    props.initialPosition &&
    css`
      position: initial !important;
    `} input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  &:hover input ~ .checkmark {
    background-color: #ccc;
  }

  input:checked ~ .checkmark {
    background-color: ${props => props.theme.colors.primary};
    border-color: ${props => props.theme.colors.primary};
  }

  input:checked ~ .checkmark:after {
    display: block;
  }
`;

export const CheckBoxText = styled.span`
  position: relative;
  top: 2px;
`;

export const CheckMark = styled.span.attrs({
  className: 'checkmark',
})`
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 3px;
  border: 2px solid #999;

  &:after {
    content: '';
    position: absolute;
    display: none;
    left: 5px;
    top: 0px;
    width: 7px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

export const UploadBox = styled.div``;
export const UploadTitle = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
`;
export const UploadButtons = styled.div``;

export const ListGroup = styled.ul`
  padding-left: 0;
  &:not(:first-child) {
    margin-top: 0;
  }
  &:not(:last-child) {
    margin-bottom: 15px;
  }
`;

export const ListGroupItem = styled.li`
  display: flex;
  align-content: flex-end;
  align-items: center;
  padding: 5px 0;
  margin-bottom: 10px;
  border-bottom: 1px solid #f0f0f0;

  &:last-child {
    border-bottom: 0;
  }
`;

export const ListGroupItemBody = styled.div`
  flex: 1 1 80%;
`;

export const ListGroupItemText = styled.div`
  font-size: 16px;
  color: #a2a2a2;
`;

export const ListGroupItemTitle = styled.h3`
  margin-top: 0;
  margin-bottom: 3px;
  font-size: 16px;
  color: #5e5e5e;
  font-weight: bold;
`;

export const ListGroupItemIcon = styled.div`
  display: flex;
  align-items: center;
`;

export const ListGroupItemImage = styled.img`
  width: 23px;
  height: 22px;
`;

export const ListIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ListIconImage = styled.img``;

export const ListItem = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: ${props => (props.noBorder ? 'none' : '1px solid #dfdfdf')};
  padding: 10px 0;
  box-sizing: border-box;

  &:last-child {
    border-bottom: 0;
  }
`;

export const ListHeader = styled.h4`
  margin: 0;
  padding: 5px 0;
  color: #646464;
  font-weight: bold;
  font-size: 16px;
`;

export const ListText = styled.div`
  color: #949494;
`;

export const Tabs = styled.ul``;

export const TabItem = styled.li`
  display: inline-block;
  line-height: 2;
  border-bottom: 3px solid #fafafa;

  ${props =>
    props.active &&
    css`
      border-bottom: 3px solid #e0301e;
      a {
        color: #e0301e;
        &:hover {
          color: #e0301e;
        }
      }
    `};
`;

export const TabItemLink = styled(Link)`
  text-transform: uppercase;
  display: block;
  padding: 0.5rem 1rem;
  color: #8e8e8e;
  &:hover {
    color: #8e8e8e;
  }
`;

export const TabContent = styled.div`
  margin-top: 1em;
`;

export const TabPane = styled.div`
  display: ${props => (props.active ? 'block' : 'none')};
`;

// Input Group
export const InputGroup = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1px;
  width: 100%;
  border-radius: 3px;
  background: rgba(255, 255, 255, 0.2);

  ${media.xs`
    margin-top: 1em;
  `};
`;

export const InputGroupButton = styled(Button)`
  padding: 8px 10px;
  display: flex;
  align-items: center;
  background: transparent;
  margin: 0;
  border: none;
`;

export const InputGroupField = styled.input`
  border-radius: 3px;
  flex: 0 0 80%;
  width: 80%;
  padding: 3px 0px;
  background: transparent;
  border: none;
  color: rgba(255, 255, 255, 0.8);
  outline: none;

  &::placeholder {
    color: rgba(255, 255, 255, 0.8);
  }
`;

// Data table
export const TableCard = styled(Card)`
  margin-top: 20px;
`;

export const SearchIcon = styled.img.attrs({
  src: '/assets/images/search.svg',
  alt: 'search',
})`
  width: 20px;
`;

export const TableCardHeader = styled(CardHeader)`
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.24);
  {/*background-color: ${props => props.theme.colors.secondary};*/}
  background-color: #a32020;
  padding: 0.5em;
  color: #ffffff;
`;

export const ResponsiveImage = styled.img`
  max-width: 100%;
`;

export const EmptyAlert = styled(Alert)`
  margin-bottom: 0;
`;

export const EmptyRow = styled.tr`
  td {
    padding-bottom: 10px;
  }
`;

export const StyledDropdownToggle = styled(DropdownToggle)`
  &:after {
    margin-left: 0 !important;
    vertical-align: middle !important;
  }
`;

// NOTIFICATION PAGE
export const NotifCardHeader = styled(CardHeader)`
  padding: 1em;
`;

export const NotifCardBody = styled(CardBody)`
  padding: 1em;
`;

export const NotifTitle = styled(PageTitle)`
  font-size: 24px;
  margin-bottom: 3px;
`;

export const NotifInfo = styled.div`
  font-size: 13px;
`;

export const NotifContent = styled.div`
  padding-top: 1em;
`;

export const TextMuted = styled.div`
  color: #757575;
  font-size: 13px;
  margin-bottom: 0.75em;
  display: flex;
  align-items: center;

  .material-icons {
    font-size: 18px;
  }

  &:not(:first-child):last-child {
    margin-bottom: 0;
  }
`;

export const NotifTextMuted = styled.div`
  color: #757575;
  font-size: 13px;
  margin-bottom: 0.75em;
`;

export const NotifTabItem = styled(TabItem)`
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  padding: 0 0.5rem 0;

  ${props =>
    props.active &&
    css`
      border-bottom: 3px solid ${props => props.theme.colors.warning};
      a {
        opacity: 1;
        color: ${props => props.theme.colors.warning};
        &:hover {
          color: ${props => props.theme.colors.warning};
        }
      }
    `};
`;

export const NotifTabItemLink = styled(TabItemLink)`
  font-size: 14px;
  text-align: center;
  color: ${props => props.theme.colors.warning};
  &:hover {
    color: ${props => props.theme.colors.warning};
  }
`;

export const NotifCardMedia = styled(Card)``;

export const NotifCardMediaHeader = styled(CardHeader)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const NotifTabs = styled(Tabs)`
  display: flex;
  justify-content: space-between;
  ${media.xs`
    overflow-x: auto;
    overflow-y: hidden;
    scroll-behavior: smooth;
  `};
`;

export const MediaList = styled(Media)`
  background-color: #ffffff;
  padding: 1em;
  width: 100%;
  border-bottom: 1px solid #eaeaea;
`;

export const MediaGroup = styled(Media)`
  display: block !important;
`;

export const MediaImage = styled(Media)`
  border-radius: 50%;
  width: 48px;
  height: 48px;
  text-align: center;
  margin-bottom: 5px;
`;

export const MediaHeading = styled(Media)`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 3px;
  color: #595959;
`;

export const MediaBody = styled(Media).attrs({
  body: true,
})`
  padding-left: 1em;
  color: #595959;
`;

export const MediaUserPosition = styled.div`
  color: #757575;
  margin-bottom: 10px;
`;

export const MediaNotifText = styled.small`
  font-weight: normal;
  font-size: 13px;
  margin-left: 5px;
`;

export const TextHeader = styled.h6`
  color: ${props => props.theme.colors[props.color]};
  font-size: 16px;
  font-weight: normal;
  margin-top: 10px;
  margin-bottom: 10px;
`;

// ICON
export const IconStartingPoin = styled.img.attrs({
  src: '/assets/images/starting-point.png',
  alt: '',
})`
  width: 15px;
  height: 15px;
  top: 10px;
  position: relative;
  left: 4px;
`;

export const IconMapMarkerGrey = styled.img.attrs({
  src: '/assets/images/map-marker-grey.png',
  alt: '',
})`
  width: 12px;
  height: 14px;
  top: 10px;
  position: relative;
`;

export const IconMapMarkerRed = styled.img.attrs({
  src: '/assets/images/red-map-marker.png',
  alt: '',
})`
  margin-right: 5px;
  margin-bottom: 5px;
`;
export const IconUserGroupRed = styled.img.attrs({
  src: '/assets/images/red-user-group.png',
  alt: '',
})`
  margin-right: 5px;
  margin-bottom: 5px;
`;

export const TextLabel = styled.div`
  font-weight: bold;
  font-size: 18px;
  color: #212121;
  margin-bottom: 5px;
`;

// Search Form

export const SearchBox = styled.form`
  display: flex;
  align-items: flex-end;
  position: relative;
  bottom: 4px;
`;

export const SearchButton = styled(Button)`
  background: transparent;
  border: none;
  border-bottom: 1px solid #dfdfdf;
  padding: 5px;
  border-radius: 0;

  :disabled {
    background: transparent;
  }

  i {
    color: #999;
  }
`;

export const SearchInput = styled(Field)`
  padding: 0px 0;
  color: #747474;
  width: 500px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #dfdfdf;
  font-size: 20px;
  font-weight: normal;
  outline: none;
  max-width: 100%;
  margin-top: 4px;

  &::-webkit-input-placeholder {
    color: #cdcdcd;
  }

  ${media.xs`
    width: 100%;  
  `};

  ${media.sm`
    width: 100%;  
  `};

  ${media.lg`
    width: 500px;
  `};
`;

// export const SearchIcon = styled.img``;

export const NotFoundWrapper = styled.div`
  margin: 6em auto;
  text-align: center;
`;

// news
export const CategoryHeader = styled.div`
  color: #505050;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #d64541;
  margin-bottom: 20px;
  padding-top: 15px;
  padding-bottom: 5px;
`;
export const CategoryTitle = styled.h3`
  font-size: 20px;
  margin: 0;
  padding: 0;
  color: #505050;
  font-weight: bold;
  text-transform: capitalize;
  text-align: left;
`;

export const MoreButton = styled(Link)`
  color: #d64541 !important;
  margin-bottom: 0 !important;
  font-size: 16px;
  font-weight: bold;
`;

// login
export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 2em;
  ${media.xs`
    padding-top: 0;
    margin-bottom: 1em;
  `};
`;

export const LoginBox = styled.div`
  margin: 40px;
  ${media.xs`
    margin: 0;
  `};
`;

export const LoginHeader = styled(CardHeader)`
  border-bottom: 0;
`;

export const DepartmentHeader = styled.div`
  color: #505050;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #a32020;
  margin-bottom: 20px;
  margin-top: 0;
  padding-bottom: 15px;
`;

// oon

export const OONCard = styled(Card)`
  margin-top: 1.5em;
`;

export const OONText = styled.div``;

export const OONDate = styled.div`
  color: var(--pwc-yellow);
  margin-bottom: 1em;
`;

export const ButtonIcon = styled(Btn)``;

export const CenteredContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - ${props => props.height || 300}px);
`;

export const PostsContainer = styled.div`
  margin-top: 1em;
`;

export const NotifMessage = styled.div`
  font-size: 14px;
  margin-bottom: 10px;
`;

export const NotifMessageLink = styled(Link)`
  font-size: 14px;
  margin-bottom: 10px;
  display: block;
  color: #757575;
`;

export const InputGroupSearch = styled(BSInput)`
  border: none !important;
  background: rgba(255, 255, 255, 0.2) !important;
  padding-left: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  color: #ffffff !important;

  &::placeholder {
    color: rgba(255, 255, 255, 0.8) !important;
  }
`;

export const ButtonAddonSearch = styled(Btn)`
  border: none !important;
  background: rgba(255, 255, 255, 0.2) !important;
  border-top-left-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
`;

export const MainContent = styled.div`
  background-color: ${props => (props.white ? '#fff' : '#f8f8f8')};
  display: flex;
  justify-content: center;
`;
export const ContentHeader = styled.div`
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #efefef;
  padding: 8px 0;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
  ${media.xs`
    padding: 0;
  `};
`;

export const Divider = styled.div`
  border-bottom: 1px solid #dadada;
`;

export const DetailBox = styled.div`
  color: #757575;
`;

export const DetailContent = styled.div`
  width: 555px;
  max-width: 100%;
  font-size: 13px;
  color: #757575;
  padding: 16px;
`;

export const RowWorkspace = styled(Row)`
  margin-bottom: 10px;
`;

export const AddButton = styled(Button)`
  display: inline-flex;
  align-items: center;
  i {
    font-size: 1rem !important;
    margin-right: 6px;
  }
`;

/**
 * News Tabs
 * --------------------------------------------------------------------------
 */
const tabHoverAnimation = css`
  :after {
    display: block;
    content: '';
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;

    ${media.sm`
    border-bottom: solid 3px ${props => props.theme.colors.warning};
`};
  }

  :hover:after {
    ${media.sm`
    transform: scaleX(1);
    `};
  }

  ${props =>
    props.active &&
    css`
      font-weight: bold;

      &:after {
        ${media.sm`
      transform: scaleX(1);
     `};
      }
    `};
`;
export const NewsTabItem = styled(TabItem)`
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  border-bottom: none;
  border-top: 3px solid #fff;
  color: #ffc107;

  ${tabHoverAnimation} ${props =>
    props.active &&
    css`
      //border-bottom: 3px solid ${props => props.theme.colors.warning};
      a {
        font-weight: bold;
        color: ${props => props.theme.colors.warning};
        &:hover {
          color: ${props => props.theme.colors.warning};
        }
      }
    `};
`;

export const NewsTabItemLink = styled(TabItemLink)`
  font-size: 14px;
  text-align: center;
  color: ${props => props.theme.colors.warning};

  &:hover {
    color: ${props => props.theme.colors.warning};
  }
`;

export const NewsTabs = styled(Tabs)`
  display: flex;
  justify-content: space-between;
  ${media.xs`
    overflow-x: auto;
    overflow-y: hidden;
    scroll-behavior: smooth;
  `};
`;

export const ContentPlaceholder = styled.div`
  height: ${props => props.height || 20}px;
  background-color: ${props => props.color || '#464646'};
  width: ${props => props.width || '100%'};
  margin-bottom: ${props => props.marginBottom || '5px'};
  border-radius: ${props => props.borderRadius || props.radius || '8px'};
`;

export const QlEditorViewer = styled.div.attrs({
  className: 'ql-editor',
})`
  padding: 0 !important;
`;

export const NewsCardColumns = styled(CardColumns)`
  margin-top: 1em;
  column-count: 2 !important;

  ${media.xs`
    column-count: 1 !important;
  `};
`;

export const FlexCol = styled(Col)`
  flex: 1;
  display: flex;
`;

export const PostWrapper = styled.div`
  margin-top: 1em;
`;

export const PostImage = styled.div`
  ${props =>
    props.src &&
    css`
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('${props.src}');
  background-color: #dee2e6;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  width: 100%;
  height: 100%;
`};
`;

export const PostImagePlaceholder = styled.div`
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #464646 !important;
  width: 100%;
  height: 100%;
`;

export const ColImage = styled(Col)`
  flex: 1;
  display: flex;
  padding: 0;
`;
