import React, {Component} from 'react';
import {
  Button,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import {Field, reduxForm, reset} from 'redux-form';
import {InputField} from '../Forms/Fields';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {closeModal, openModal, toggleModal} from '../../actions/modal';
import MaterialSpinner from '../UIKit/MaterialSpinner';
import {addGuestAction} from '../../actions/reservation';

const FORM_ADD_GUEST = 'FORM_ADD_GUEST';

class ModalAddGuest extends Component {
  toggle = () => {
    this.props.toggleModal('modalAddGuest');
  };

  onSubmit = values => {
    const {guest} = values;
    const {guestReservationId: id} = this.props;
    this.props.addGuest({id, guest, onSuccess: this.handleAddGuestSuccess});
  };

  handleAddGuestSuccess = () => {
    const {onSuccess} = this.props;
    if (onSuccess) {
      onSuccess();
    }
    this.props.dispatch(reset(FORM_ADD_GUEST));
  };

  render() {
    const {modal, handleSubmit, addingGuest} = this.props;
    const {modalAddGuest} = modal;

    return (
      <Modal isOpen={modalAddGuest} toggle={this.toggle}>
        <form onSubmit={handleSubmit(this.onSubmit)}>
          <ModalHeader toggle={this.toggle}>Add Guest</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for="guest">Guest Name:</Label>
              <Field
                component={InputField}
                type="text"
                name="guest"
                id="guest"
                placeholder="Guest Name"
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button disabled={addingGuest} color="primary" type="submit">
              <MaterialSpinner button small indeterminate={addingGuest} />
              {addingGuest ? 'Adding...' : 'Add'}
            </Button>
            <Button color="secondary" onClick={this.toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  const {modal, reservation} = state;
  const {addingGuest, guestReservationId} = reservation;

  return {modal, addingGuest, guestReservationId};
}

function mapDispatchToProps(dispatch) {
  return {
    openModal: bindActionCreators(openModal, dispatch),
    closeModal: bindActionCreators(closeModal, dispatch),
    toggleModal: bindActionCreators(toggleModal, dispatch),
    addGuest: bindActionCreators(addGuestAction, dispatch),
  };
}

const connected = connect(mapStateToProps, mapDispatchToProps)(ModalAddGuest);

const validate = values => {
  const errors = {};
  if (!values.guest) {
    errors.guest = 'Guest name is required';
  }
  return errors;
};

const formed = reduxForm({
  form: FORM_ADD_GUEST,
  validate,
})(connected);

export default formed;
