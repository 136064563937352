import React from 'react';
import styled from 'styled-components';

const SortableLink = ({column, title, parent}) => {
  return (
    <StyledSortLink
      href="#"
      sorted={parent.sortClass(column)}
      onClick={event => parent.handleSort(event, column)}>
      {title}
      {parent.sortIcon(column)}
    </StyledSortLink>
  );
};

const defaultColor = '#595959';

const StyledSortLink = styled.a`
  display: flex;
  align-items: center;
  color: ${props => (props.sorted ? props.theme.colors.warning : defaultColor)};

  &:hover,
  &:active,
  &:focus {
    color: ${props => props.theme.colors.warning}!important;
  }
`;

export default SortableLink;
