import React from 'react';
import styled from 'styled-components';
import {APP_VERSION} from '../../config/constants';

const Logo = props => {
  return <LogoImage {...props} />;
};

const logoURL = `/assets/images/logo.png?v=${APP_VERSION || ''}`;

const LogoImage = styled.img.attrs({
  src: logoURL,
  alt: 'Logo',
})`
  max-width: 100%;
`;

export default Logo;
