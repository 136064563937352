const STORAGE_NAME = 'current-booking';

export default {
  fetch() {
    return JSON.parse(localStorage.getItem(STORAGE_NAME)) || null;
  },
  remove() {
    localStorage.removeItem(STORAGE_NAME);
  },
  save(data) {
    localStorage.setItem(STORAGE_NAME, JSON.stringify(data));
  },
};
