import React, {Component} from 'react';
import {dateReservationLOFormat} from '../../../utils/helpers';
import {connect} from 'react-redux';
import ActionButtons from './ActionButtons';

class ReservationRow extends Component {
  render() {
    const {item} = this.props;
    const {visitor_name, reservation, updated_at} = item;
    const {poi, host_name} = reservation;
    const roomName = poi ? poi.name : '-';

    return (
      <tr>
        <td>{dateReservationLOFormat(updated_at)}</td>
        <td>{visitor_name}</td>
        <td>{reservation.meeting_description}</td>
        <td>{roomName}</td>
        <td>{host_name}</td>
        <td className="text-center">
          <ActionButtons {...this.props} />
        </td>
      </tr>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(ReservationRow);
