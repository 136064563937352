export default {
  reservation: process.env.REACT_APP_HOST_RESERVATION,
  people_directory: process.env.REACT_APP_HOST_PEOPLE_DIRECTORY,
  beverage: process.env.REACT_APP_HOST_BEVERAGE,
  kiosk: process.env.REACT_APP_HOST_KIOSK,
  community: process.env.REACT_APP_HOST_COMMUNITY,
  message: process.env.REACT_APP_HOST_MESSAGE,
  upload: process.env.REACT_APP_HOST_UPLOAD,
  news: process.env.REACT_APP_HOST_NEWS,
  eula: process.env.REACT_APP_HOST_EULA,
  map: process.env.REACT_APP_HOST_MAP,
};
