import React, {Component} from 'react';
import {selectMeetingRoom} from '../../actions/reservation';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {closeModal} from '../../actions/modal';
import styled from 'styled-components';
import CurrentBooking from '../../utils/CurrentBooking';
import {IconUserGroupRed, IconMapMarkerRed} from '../../styles';
import {Card, CardBody} from 'reactstrap';

class RoomCard extends Component {
  selectMeetingRoom = item => {
    const {selectMeetingRoom, closeModal} = this.props;
    selectMeetingRoom(item);
    this.updateCurrentBooking(item);
    closeModal('searchMeetingRoom');
  };

  updateCurrentBooking = item => {
    const reservation = CurrentBooking.fetch();
    if (reservation) {
      reservation.meetingRoom = item;
      CurrentBooking.save(reservation);
    }
  };

  render() {
    const {item} = this.props;

    if (!item) return null;

    const {zone, capacity} = item;
    const floor = zone && zone.floor ? zone.floor : null;
    const floorName = floor ? floor.name : null;
    const {cluster: clusters, name} = item;
    const cluster = clusters && clusters.length > 0 ? clusters[0] : {};

    return (
      <StyledCard>
        <CardBody>
          <div>
            <RoomName>{name}</RoomName>
          </div>
          <div>
            <TextLabel>
              <StyledIconMapMarkerRed />
            </TextLabel>
            <TextMuted>
              {name} &middot; {floorName} &middot; {cluster.name}
            </TextMuted>
          </div>
          <div>
            <TextLabel>
              <StyledIconUserGroupRed />
            </TextLabel>
            <TextMuted>Up to {capacity} People</TextMuted>
          </div>
        </CardBody>
      </StyledCard>
    );
  }
}

const StyledIconMapMarkerRed = styled(IconMapMarkerRed)`
  width: 16px;
  height: auto;
`;

const StyledIconUserGroupRed = styled(IconUserGroupRed)`
  width: 16px;
  height: auto;
`;

const TextMuted = styled.div`
  color: #a2a2a2;
  font-size: 12px;
  display: inline;
`;

const TextLabel = styled.div`
  font-size: 13px;
  color: #505050;
  display: inline;
`;

const StyledCard = styled(Card)`
  margin-bottom: 0.5em !important;
  border: none !important;
  background: transparent !important;
  &:hover {
    background: transparent !important;
  }
`;

const RoomName = styled.h4`
  font-size: 18px;
`;

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    selectMeetingRoom: bindActionCreators(selectMeetingRoom, dispatch),
    closeModal: bindActionCreators(closeModal, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RoomCard);
