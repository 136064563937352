import React, {Component} from 'react';
import {Col, FormGroup, Row} from 'reactstrap';
import {FormLabel} from '../Profile/edit';
import {RowWorkspace, TextError} from '../../styles';
import {Field} from 'redux-form';
import TypeaheadField from './TypeaheadField';

class ApproverField extends Component {
  handleSelected = (value, selected, index) => {
    const {onSelected} = this.props;
    if (onSelected) {
      onSelected(value, selected, index);
    }
  };

  render() {
    const {
      fields,
      onSearch,
      loading,
      users,
      approvers,
      meta: {submitFailed, error},
      ...rest
    } = this.props;

    return (
      <FormGroup>
        {approvers.length > 0 && (
          <Row>
            <Col>
              <FormLabel>Approvers ({approvers.length})</FormLabel>
            </Col>
          </Row>
        )}
        {fields.map((field, index) => (
          <RowWorkspace key={index}>
            <Col xs={12} sm={4}>
              <Field
                id={`approverUser${index}`}
                component={TypeaheadField}
                name={`approvalUsers[${index}].user`}
                onSearch={onSearch}
                isLoading={loading}
                filterBy={['name']}
                options={users}
                labelKey="name"
                placeholder="Approver Name"
                useCache={false}
                delay={0}
                onSelected={(value, selected) =>
                  this.handleSelected(value, selected, index)
                }
                {...rest}
              />
            </Col>
          </RowWorkspace>
        ))}
        {submitFailed && error && <TextError>{error}</TextError>}
      </FormGroup>
    );
  }
}

export default ApproverField;
