import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {ThemeProvider} from 'styled-components';
import {connect} from 'react-redux';
import AppRoutes from './appRoutes';
import Body from '../styles/body';
import theme from '../styles/theme';
import {
  authOutRequest,
  setAuthCredentialsAction,
  validateAuthRequest,
} from '../actions/auth';
import BackgroundProcess from './Utils/BackgroundProcess';
import ModalGlobal from './Modal/global';
import {getAccessToken} from '../utils/TokenStorage';
import LoadingRoutes from './UIKit/LoadingRoutes';

class App extends Component {
  constructor(props) {
    super(props);
    this.initApps = this.initApps.bind(this);
    this.validateAuthData = this.validateAuthData.bind(this);
  }

  validateAuthData() {
    const {auth, authOutRequest} = this.props;
    const {userIsLoggedIn} = auth;
    const token = getAccessToken();

    if (userIsLoggedIn === false || token === null) {
      authOutRequest();
    }

    if (token) {
      this.props.validateAuth(token);
    }
  }

  initApps() {
    this.validateAuthData();
  }

  componentWillMount() {
    this.initApps();
  }

  render() {
    const {validating} = this.props.auth;

    if (validating) {
      return <LoadingRoutes />;
    }

    return [
      <BackgroundProcess key="background process" />,
      <ThemeProvider theme={theme} key="non background process">
        <Body>
          <ModalGlobal />
          <AppRoutes />
        </Body>
      </ThemeProvider>,
    ];
  }
}

function mapDispatchToProps(dispatch) {
  return {
    authOutRequest: bindActionCreators(authOutRequest, dispatch),
    validateAuth: bindActionCreators(validateAuthRequest, dispatch),
    setAuthCredentialsAction,
  };
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
