import React, {Component} from 'react';
import {Modal, ModalBody, ModalHeader} from 'reactstrap';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {toggleModal} from '../../actions/modal';
import {getHeatMapAction} from '../../actions/poi';
import HeatMapList from './HeatMapList';
import LoadingOrEmpty from '../UIKit/LoadingOrEmpty';

class ModalHeatMap extends Component {
  componentDidMount() {
    const {modalHeatMap} = this.props.modal;
    if (modalHeatMap) {
      this.props.getHeatMap();
    }
  }

  componentWillReceiveProps(props) {
    const {modalHeatMap} = props.modal;
    if (modalHeatMap && modalHeatMap !== this.props.modal.modalHeatMap) {
      this.props.getHeatMap();
    }
  }

  toggle = () => {
    this.props.toggleModal('modalHeatMap');
  };

  render() {
    const {onSelected} = this.props;
    const {modalHeatMap} = this.props.modal;
    const {heatMap, loadingHeatMap} = this.props.poi;

    return (
      <Modal size="lg" isOpen={modalHeatMap} toggle={this.toggle}>
        <ModalHeader toggle={this.toggle}>Heat Map</ModalHeader>
        <ModalBody>
          <LoadingOrEmpty loading={loadingHeatMap} data={heatMap} />
          <HeatMapList onSelected={onSelected} heatMap={heatMap} />
        </ModalBody>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  const {modal, poi} = state;

  return {modal, poi};
}

function mapDispatchToProps(dispatch) {
  return {
    toggleModal: bindActionCreators(toggleModal, dispatch),
    getHeatMap: bindActionCreators(getHeatMapAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalHeatMap);
