import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/messaging';
import configuration from '../config';

export const config = configuration.firebase;

firebase.initializeApp(config);

export default firebase;
