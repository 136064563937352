import {CommunityApi} from '../utils/api';

export default {
  getAll() {
    return CommunityApi.get('/v1/admin/setting');
  },
  upsert(data) {
    return CommunityApi.post('/v1/admin/setting', data);
  },
};
