import {
  CLEAR_POI_LIST,
  FIND_ROOM_POI_ACTION,
  GET_HEAT_MAP_ACTION,
  GET_ROOMS_WITH_RESERVATION,
} from './actionsList';

export const findRoomPOIRequest = params => {
  params = params || {};
  const {page, limit, perPage, sortBy, key, descending} = params;
  return {
    type: FIND_ROOM_POI_ACTION,
    payload: {page, limit, perPage, sortBy, key, descending},
  };
};

/**
 * Get heat map.
 *
 * @returns {{type: *, payload: {}}}
 */
export const getHeatMapAction = () => {
  return {
    type: GET_HEAT_MAP_ACTION,
    payload: {},
  };
};

/**
 * Get rooms with reservations (for gantt chart).
 *
 * @param date
 * @param floor_id
 * @returns {{type: string, payload: {date: *, floor_id: *}}}
 */
export const getRoomsWithReservationAction = ({
  date,
  floor_id,
  sort_col,
  sort_dir,
}) => {
  return {
    type: GET_ROOMS_WITH_RESERVATION,
    payload: {date, floor_id, sort_col, sort_dir},
  };
};

/**
 * Clear POI List.
 *
 * @returns {{payload: {}, type: string}}
 */
export const clearPoiListAction = () => {
  return {
    type: CLEAR_POI_LIST,
    payload: {},
  };
};
