import React, {Component} from 'react';
import {lastClockInFormat, parseLastLocation} from '../../utils/helpers';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {ListGroupItem} from 'reactstrap';

class UserLastLocation extends Component {
  render() {
    const {user} = this.props;
    let {last_location} = user;
    let {createdAt, zone, floor, name, offOffice} = parseLastLocation(
      last_location
    );

    return (
      <ListGroupItemLocation>
        <FooterText>
          Last detected location &bull;&nbsp;
          {createdAt === '-' ? createdAt : lastClockInFormat(createdAt)}
        </FooterText>
        {!offOffice ? (
          <FooterText>
            <MapMarkerImage
              src="/assets/images/map-marker.png"
              alt="map-marker"
            />
            <span>
              {name}
              &nbsp; &bull; Floor {floor}
            </span>
          </FooterText>
        ) : (
          name
        )}
      </ListGroupItemLocation>
    );
  }
}

const FooterText = styled.div``;

const MapMarkerImage = styled.img`
  margin-right: 5px;
  height: 12px;
`;

const ListGroupItemLocation = styled(ListGroupItem)`
  color: #949494;
  padding: 1em;
  font-size: 12px;
`;

UserLastLocation.propTypes = {
  user: PropTypes.any,
};

UserLastLocation.defaultProps = {
  user: {},
};

export default UserLastLocation;
