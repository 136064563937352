import moment from 'moment-timezone';
import {loadState} from './localStorage';
import {Badge} from 'reactstrap';
import React from 'react';
import config from '../config';
import hosts from './hosts';
import swal from 'sweetalert2';
import {getUserData} from './TokenStorage';
import MaterialSpinner from '../components/UIKit/MaterialSpinner';
import {Post} from '../models/Post';

const RESERVATION_STATUS = config.reservationStatus;

/**
 * Default date format.
 * Example: May, 7 2018.
 *
 * @type {string}
 */
export const DATE_FORMAT = 'll';

/**
 * Normalize date time string.
 *
 * @param date
 * @returns {*}
 */
export const normalizeDateTime = date => {
  return date
    .replace('T', ' ')
    .replace('Z', '')
    .substr(0, 19);
};

/**
 * Datetime function.
 *
 * @param value
 * @returns {string}
 */
export function datetime(value) {
  return moment(normalizeDateTime(value)).format(DATE_FORMAT);
}

/**
 * Function for get current user data.
 *
 * @returns {null|*}
 */
export const getCurrentUser = () => {
  const user = getUserData();
  return user ? user : null;
};

/**
 * Get current user Id.
 *
 * @returns {null}
 */
export const getCurrentUserId = user => {
  user = user || getCurrentUser();

  if (!user) return null;

  if (user.profile) return user.profile.id;

  return user ? +user.id : null;
};

/**
 * Helper for determining whether the giver user is same with the current logged-in user.
 *
 * @param user
 * @returns {boolean}
 */
export function isMyProfile(user) {
  if (!user) return false;
  const currentUserId = Number(getCurrentUser().id);
  const userId = Number(user.id);
  return currentUserId === userId;
}

/**
 * Reformat time from 'HH:mm:ss' to 'HH:mm' using moment.js.
 * Example: 15.30.10 will become 15.30.
 *
 * @param time
 * @returns {string}
 */
export const getFormattedTime = time => {
  return moment(time, 'HH:mm:ss').format('HH:mm');
};

/**
 * Get formatted reservation date.
 *
 * @param reservation
 * @returns {string}
 */
export const getFormattedReservationDate = reservation => {
  if (!reservation) return null;
  const date = reservation.check_in || reservation.date_reservation;
  const reservationDate = moment(date);
  const day = reservationDate.format('dddd');
  const formattedDate = reservationDate.format('DD MMMM YYYY');
  return `${day}, ${formattedDate}`;
};

/**
 * Full calendar format.
 *
 * @param date
 * @param format
 * @returns {string}
 */
export const fullCalendarFormat = (date, format) => {
  date = !date ? moment() : moment(date, format);
  const day = date.format('dddd');
  const formattedDate = date.format('DD MMMM YYYY');
  return `${day}, ${formattedDate}`;
};

/**
 * Normalize Datepicker value.
 *
 * @param value
 * @returns {any}
 */
export function normalizeDatePickerValue(value) {
  return value ? moment(value).format(config.dateFormat) : null;
}

/**
 * Example format: // 03:00, 03/11/2017
 *
 * @param date
 * @returns {string}
 */
export function dateReservationFormat(date) {
  if (date) {
    date = date.replace('T', ' ').replace('Z', '');
  }
  return moment(date).format(config.reservationDateFormat);
}

/**
 * Submitted Date.
 *
 * @param date
 * @returns {string}
 */
export function submittedDate(date) {
  return moment(date).format('DD MMM YYYY HH:mm');
}

/**
 * Generate Image URL.
 *
 * @param image
 * @returns {string}
 */
export const imageURL = image => {
  return `${hosts.upload}/images/${image}`;
};

export const requestSegment = index => {
  index = index - 1;
  const url = window.location.pathname;
  const paths = url.substring(1).split('/');
  if (!paths.hasOwnProperty(index)) return null;
  return paths[index];
};

export const isStaff = profile => {
  return profile && profile.type === 'staff';
};

export const isNonStaff = profile => {
  return profile && profile.type === 'nonstaff';
};

/**
 * Determine whether the selected meeting room need client info for create reservation.
 *
 * @param selectedMeetingRoom
 * @returns {boolean}
 */
export const isNeedClientInfo = selectedMeetingRoom => {
  return selectedMeetingRoom
    ? selectedMeetingRoom.rules
      ? selectedMeetingRoom.rules.is_req_client_info
      : false
    : false;
};

/**
 * Get formatted reservation status text.
 *
 * @param status
 * @returns {*}
 */
export const reservationStatus = status => {
  let label = status;
  let color = 'default';

  switch (status) {
    case 'rejected':
      color = 'danger';
      break;
    case 'approved':
    case 'complete':
      color = 'success';
      break;
    case 'released':
      color = 'info';
      break;
    case 'reschedulled':
    case 'rescheduled':
      color = 'warning';
      label = 'rescheduled';
      break;
    case 'waiting_cluster_admin':
      label = 'waiting admin';
      color = 'secondary';
      break;
    default:
      color = 'secondary';
      break;
  }

  label = label.toUpperCase();

  return (
    <Badge color={color} className="pl-2 pt-2 pr-2 pb-2">
      {label}
    </Badge>
  );
};

/**
 * Get formatted reservation time.
 *
 * @param time
 * @returns {string}
 */
export const reservationTime = time => {
  return moment(time, 'HH:mm:ss').format('HH:mm');
};

/**
 * Time to hour-only.
 *
 * @param time
 * @returns {*}
 */
export const hourOnly = time => {
  return moment(time, 'HH:mm:ss').format('H');
};

/**
 * Last clock-in date format.
 *
 * @param date
 * @returns {string}
 */
export const lastClockInFormat = date => {
  if (!date || date === '-') return '-';
  return moment(normalizeDateTime(date), 'YYYY-MM-DD HH:mm:ss').format(
    'DD MMM YY HH:mm'
  );
};

/**
 * Avatar URL Helper.
 *
 * @param avatar
 * @returns {string}
 */
export const avatarURL = avatar => {
  if (!avatar) return `/assets/images/person-circle-big.png`;
  return `${hosts.upload}/images/${avatar}`;
};

export const notificationSender = notification => {
  return (notification || {}).fromMessage;
};

/**
 * Parse body message from notification.
 *
 * @param notification
 * @returns {{message: *, body: *}}
 */
export const parseBodyMessage = notification => {
  let message = '';
  let body = notification?.body_message;

  let breakLoop = false;
  while (typeof body === 'string' && !breakLoop) {
    try {
      body = JSON.parse(body);
      message = body.message;
    } catch (e) {
      message = notification.body_message;
      breakLoop = true;
    }
  }

  return {message, body};
};

/**
 * Determine whether the current user the giver roles.
 *
 * @param roles
 * @param user
 * @returns {boolean}
 */
export const userHasRole = (roles = [], user) => {
  user = user || getCurrentUser();
  const auth = user && user.auth;
  const roleList = auth ? auth.roles : [];
  const userRoles = roleList.map(item => item.role_name);
  let allowed = false;
  if (typeof roles === 'string') {
    roles = [roles];
  }
  roles.forEach(value => {
    if (userRoles.includes(value)) {
      allowed = true;
    }
  });
  return allowed;
};

export const userHasFunctions = (functions = [], user) => {
  user = user || getCurrentUser();
  const auth = user && user.auth;
  const roleList = auth ? auth.functions : [];
  const userFunctions = roleList.map(item => item.role_name);
  let allowed = false;
  if (typeof functions === 'string') {
    functions = [functions];
  }
  functions.forEach(value => {
    if (userFunctions.includes(value)) {
      allowed = true;
    }
  });
  return allowed;
};

/**
 * Determine whether the given reservation is beverageable.
 *
 * @param reservation* @returns {boolean}
 */
export const isBeverageAble = reservation => {
  if (!reservation) return false;

  const poi = reservation.poi ? reservation.poi : null;
  if (!poi) return false;

  const type = poi.type ? poi.type : null;
  if (!type) return false;

  const isApproved = RESERVATION_STATUS.APPROVED === reservation.status;
  const isActive = +reservation.is_room_active === 1;
  const isBeverageable = +type.is_beverage_able === 1;
  const isNotStarted = isMeetingNotStarted(reservation);
  const isUserBookerOrHost = isBookerOrHost(reservation);

  // do not remove this comment, its for debugging purpose
  dump({isApproved, isBeverageable, isNotStarted, isActive});

  return (
    isApproved &&
    isBeverageable &&
    isNotStarted &&
    isActive &&
    isUserBookerOrHost
  );
};

/**
 * Check whether the reservation active or not.
 *
 * @param reservation
 * @returns {boolean}
 */
export const isReservationActive = reservation => {
  return +reservation.is_checkin_active === 1;
};

/**
 * Determine whether the reservation is not started.
 *
 * @param reservation
 * @returns {boolean}
 */
export const isReservationNotStartedYet = reservation => {
  const dateFormat = config.dateFormat;
  const timeFormat = config.timeFormat;

  const startDate = moment(reservation.date_reservation).format(dateFormat);
  const fromDate = moment(
    `${startDate} ${reservation.start_time}`,
    `${dateFormat} ${timeFormat}`
  );
  const now = moment();
  return now < fromDate;
};

/**
 * Determine whether the meeting is started or not.
 *
 * @param reservation
 * @returns {boolean}
 */
export const isMeetingNotStarted = reservation => {
  const dateFormat = config.dateFormat;
  const timeFormat = config.timeFormat;

  const endDate = moment(reservation.end_date_reservation).format(dateFormat);
  const endDateWithTime = moment(
    `${endDate} ${reservation.end_time}`,
    `${dateFormat} ${timeFormat}`
  );
  const now = moment();
  return now < endDateWithTime;
};

/**
 * Get reservation type text.
 *
 * @param type
 * @returns {*}
 */
export const reservationTypeText = type => {
  switch (type) {
    case 'group':
      return 'Group Booking';

    case 'single':
      return 'Single Booking';

    case 'project':
      return 'Project Booking';

    default:
      return type;
  }
};

/**
 * Determine whether the given approvers need approval from current user.
 *
 * @param approver
 * @returns {*|boolean}
 */
export const isNeedMyApproval = (approver = []) => {
  const user = getCurrentUser();
  const needMyApproval = approver.find(item => item.users_id === user.id);
  return !!needMyApproval && needMyApproval.is_approve === null;
};

/**
 * Determine whether the current user has cluster.
 *
 * @param myClusterAdmin
 * @returns {boolean}
 */
export const hasCluster = myClusterAdmin => {
  return myClusterAdmin && myClusterAdmin.length > 0;
};

/**
 * Determine whether the given reservation data is active.
 *
 * @param reservation
 * @returns {boolean}
 */
export const isReservationMissed = reservation => {
  if (!reservation) return false;

  const dateFormat = config.dateFormat;
  const timeFormat = config.timeFormat;
  const endDate = moment(reservation.end_date_reservation).format(dateFormat);
  const toDate = moment(
    `${endDate} ${reservation.end_time}`,
    `${dateFormat} ${timeFormat}`
  );
  const now = moment();
  return now > toDate;
};

/**
 * Format participant name. Separated by comma (,).
 *
 * @param participants
 * @returns {null}
 */
export const participantName = participants => {
  return participants
    ? participants
        .filter(item => !!item)
        .map(item => item.visitor_name)
        .join(', ')
    : null;
};

/**
 * Guest List.
 *
 * @param guests
 * @returns {null}
 */
export const guestList = guests => {
  return guests ? guests.map(item => item.visitor_name).join(', ') : null;
};

/**
 * Post Image URL.
 *
 * @param post
 * @returns {string}
 */
export const postImageURL = post => {
  if (!post) return null;
  const url = post instanceof Post ? post.imageURL : post.image_url;
  const isHttp = url ? url.substring(0, 4) : false;
  return isHttp === 'http' ? url : imageURL(url);
};

/**
 * String limit function.
 *
 * @param text
 * @param limit
 * @param suffix
 * @param start
 * @returns {string}
 */
export const strLimit = (text, limit = 150, suffix = '...', start = 0) => {
  const limited = text.substr(start, limit);
  const hasMore = text.length > limit;
  return `${limited}${hasMore ? suffix : ''}`;
};

/**
 * Post Date format.
 *
 * @param date
 * @returns {*}
 */
export const postDate = date => {
  return moment(date).format('DD MMM YYYY');
};

/**
 * Published date format.
 *
 * @param date
 * @returns {string}
 */
export const publishedDate = date => {
  const m = moment(date);
  return `${m.format('DD MMM YYYY')} ${m.format('HH:mm')}`;
};

/**
 * Post URL.
 *
 * @param post
 * @returns {string}
 */
export const postURL = post => {
  return `/news/post/${post.id}`;
};

/**
 * Reservation URL.
 *
 * @param id
 * @returns {string}
 */
export const reservationURL = id => `/reservations/${id}`;

/**
 * News Detail URL.
 *
 * @param id
 * @returns {string}
 */
export const newsDetailURL = id => `/news/post/${id}`;

/**
 * Post by Department URL.
 *
 * @param departmentId
 * @returns {string}
 */
export const postDepartmentURL = departmentId => {
  return `/news/department/${departmentId}`;
};

/**
 * OON Date.
 *
 * @param date
 * @returns {*}
 */
export const oonDate = date => {
  return moment(normalizeDateTime(date)).format('DD MMM YYYY');
};

/**
 * Datepicker date.
 *
 * @param date
 * @returns {*}
 */
export const datepickerDate = date => {
  return moment(normalizeDateTime(date)).format(config.dateFormat);
};

/**
 * Date format in reservation for LO page.
 *
 * @param date
 * @returns {string}
 */
export const dateReservationLOFormat = date => {
  if (!date) return date;
  return moment(normalizeDateTime(date)).format('DD-MMM-YY HH:mm');
};

/**
 * Extract reservation data.
 *
 * @param reservationData
 * @returns {{meetingType: *, reservationType: *, isExternalMeeting: boolean, isSingleBooking: boolean, isProjectBooking: boolean, isGroupBooking: boolean}}
 */
export const extractReservationData = reservationData => {
  const {
    meeting_type: meetingType,
    reservation_type: reservationType,
  } = reservationData;

  const isExternalMeeting = meetingType === 'external';
  const isSingleBooking = reservationType === 'single';
  const isProjectBooking = reservationType === 'project';
  const isGroupBooking =
    reservationType === 'group' || +reservationData.is_group === 1;
  return {
    meetingType,
    reservationType,
    isExternalMeeting,
    isSingleBooking,
    isProjectBooking,
    isGroupBooking,
  };
};

/**
 * Get reservation type.
 *
 * @returns string
 */
export const getReservationType = () => {
  let type;
  const isClusterAdmin = requestSegment(3) === 'cluster-admin';
  if (isClusterAdmin) {
    type = requestSegment(4);
  } else {
    type = requestSegment(3);
  }
  return type;
};

/**
 * Toast.
 *
 * @param title
 * @param type
 * @param position
 * @param options
 */
export const toast = ({title, type = 'info', position = 'top', ...options}) => {
  swal({
    showConfirmButton: false,
    showCloseButton: true,
    timer: 3000,
    toast: true,
    position,
    title,
    type,
    ...options,
  });
};

/**
 * Is same date.
 *
 * @param reservation
 * @returns {boolean}
 */
export const isSameDate = reservation => {
  const startDate = moment(reservation.date_reservation).format(
    config.dateFormat
  );
  const endDate = moment(reservation.end_date_reservation).format(
    config.dateFormat
  );
  return startDate === endDate;
};

export const toInt = time =>
  ((h, m) => h * 2 + m / 30)(...time.split(':').map(parseFloat));

export const toTime = int =>
  [Math.floor(int / 2), int % 2 ? '30' : '00'].join(':');

export const range = (from, to) =>
  Array(to - from + 1)
    .fill()
    .map((_, i) => from + i);

export const eachHalfHour = (t1, t2) =>
  range(...[t1, t2].map(toInt)).map(toTime);

/**
 * Determine weather not the current user is a booker or a host.
 *
 * @param reservation
 * @returns {boolean}
 */
export const isBookerOrHost = reservation => {
  const userId = getCurrentUserId();
  const isBooker = +reservation.created_by === userId;
  const isHost = +reservation.creator_id === userId;
  return isBooker || isHost;
};

/**
 * Can cancel reservation?
 *
 * @param reservation
 * @param isClusterAdmin
 * @returns {boolean}
 */
export const canCancelReservation = (reservation, isClusterAdmin) => {
  const isCancel = +reservation.is_cancel === 1;
  const isNotStarted = +reservation.is_checkin_active === 0;
  const cancelable = [
    RESERVATION_STATUS.APPROVED,
    RESERVATION_STATUS.PENDING,
    RESERVATION_STATUS.WAITING_CLUSTER_ADMIN,
  ].includes(reservation.status);
  const isBookerHost = isBookerOrHost(reservation) || isClusterAdmin;

  return isNotStarted && cancelable && isCancel;
};

/**
 * Can reschedule?
 *
 * @param reservation
 * @returns {boolean}
 */
export const canRescheduleReservation = reservation => {
  // hide reschedule button temporarily
  // return false;

  if (!reservation) return false;

  const isNotProject = reservation.reservation_type !== 'project';
  const isApproved = reservation.status === RESERVATION_STATUS.APPROVED;
  const isNotStarted = +reservation.is_checkin_active === 0;
  const isMissed = isReservationMissed(reservation);
  const isABookerOrAHost = isBookerOrHost(reservation);
  const isNotWorkspace = +reservation.is_workspace !== 1;

  if (config.DEBUG) {
    console.log({
      reservation,
      isNotProject,
      isApproved,
      isMissed,
      isABookerOrAHost,
      isNotWorkspace,
    });
  }

  return (
    isABookerOrAHost &&
    isNotProject &&
    isApproved &&
    isNotStarted &&
    !isMissed &&
    isNotWorkspace
  );
};

/**
 * Logic button Edit reservation.
 *
 * @param reservation
 * @returns {boolean}
 */
export const canEditReservation = reservation => {
  const isNotProject = reservation.reservation_type !== 'project';
  const editable = [
    RESERVATION_STATUS.PENDING,
    RESERVATION_STATUS.WAITING_CLUSTER_ADMIN,
    RESERVATION_STATUS.APPROVED,
  ].includes(reservation.status);
  const notMissed = !isReservationMissed(reservation);
  const isNotStartedYetOrOnGoing =
    +reservation.is_checkin_active === 0 ||
    +reservation.is_checkin_active === 1;

  return editable && isNotProject && notMissed && isNotStartedYetOrOnGoing;
};

/**
 * Can approve?
 *
 * @param reservation
 * @returns {boolean}
 */
export const canApprove = reservation => {
  return [
    RESERVATION_STATUS.PENDING,
    RESERVATION_STATUS.WAITING_CLUSTER_ADMIN,
  ].includes(reservation.status);
};

/**
 * Can release reservation?
 *
 * @param reservation
 * @returns {boolean}
 */
export const canReleaseReservation = reservation => {
  const isApproved = reservation.status === RESERVATION_STATUS.APPROVED;
  const isMissed = isReservationMissed(reservation);

  return isApproved && isReservationActive(reservation) && !isMissed;
};

export function momentRound(date, duration, method) {
  return moment(Math[method](+date / +duration) * +duration);
}

export const timeRound = time => {
  return momentRound(
    moment(time, 'HH:mm:ss'),
    moment.duration(30, 'minutes'),
    'floor'
  );
};

/**
 * JS Ordinal Number.
 *
 * @param n
 * @returns {string}
 */
export const ordinalNumber = n => {
  n = +n;
  const s = ['th', 'st', 'nd', 'rd'];
  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

export const approveStatus = status => {
  switch (status) {
    case null:
      return 'Pending';
    case 0:
      return 'Rejected';
    case 1:
      return 'Approved';
    default:
      return status;
  }
};

/**
 * Round the time.
 *
 * @param value
 * @returns {*}
 */
export const roundedTime = value => {
  const time = moment(value, config.shortTimeFormat);
  let minute = time.minute();
  let hour = time.hour();
  if (minute > 0 && minute <= 29) {
    minute = 30;
  } else if (minute > 30 && minute <= 59) {
    hour += 1;
    minute = 0;
  }
  time.set('hour', hour);
  time.set('minute', minute);
  return time;
};

/**
 * Determine whether the window is scrolled to the bottom of the page.
 *
 * @returns {boolean}
 */
export const scrolledToBottom = () => {
  return window.innerHeight + window.scrollY >= document.body.offsetHeight;
};

/**
 * Parse last location.
 *
 * @param location
 * @returns {*}}
 */
export const parseLastLocation = location => {
  location = location || {};
  const createdAt = location.createdAt || '-';
  const lift = location.lift;
  const zoneData = location.zone;
  const zone = location.zone ? location.zone.name : '-';
  const area = location.area || '-';
  const name = location.name || '-';
  const floor =
    location.zone && location.zone.floor ? location.zone.floor.level : '-';
  const offOffice = ['Out Of Office', 'Outside Office', 'WTC 3 Lobby'].includes(
    location.name
  );
  const floorData = zoneData ? zoneData.floor : null;

  return {
    location,
    createdAt,
    zone,
    area,
    name,
    lift,
    floor,
    offOffice,
    zoneData,
    floorData,
  };
};

/**
 * Replace new line to br.
 *
 * @param str
 * @returns {*}
 */
export const nltobr = str => str.replace(/(?:\r\n|\r|\n)/g, '<br/>');

/**
 * Show loading or status reservation.
 *
 * @param reservation
 * @param item
 * @returns {*}
 */
export function loadingOrStatus(reservation, item) {
  const {approving, rejecting, canceling, releasing} = reservation;
  const {id, status} = item;
  const isLoading =
    id === approving ||
    id === rejecting ||
    id === canceling ||
    id === releasing;

  return isLoading ? (
    <div align="center">
      <MaterialSpinner indeterminate />
    </div>
  ) : (
    reservationStatus(status)
  );
}

export function formatBytes(a, b) {
  if (0 === a) return '0 Bytes';
  let c = 1000,
    d = b || 2,
    e = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    f = Math.floor(Math.log(a) / Math.log(c));
  return parseFloat((a / Math.pow(c, f)).toFixed(d)) + ' ' + e[f];
}

export function allowedFileTypesText(types = []) {
  return types.map(item => item.split('/')[1].toUpperCase()).join(', ');
}

export function filterWorkspaceRooms(rooms, selectedWorkspaces, index) {
  let items = rooms;
  const workspaces = selectedWorkspaces[index];
  if (workspaces) {
    items = rooms.filter(item => workspaces && !workspaces.includes(item.id));
  }
  return [{name: 'Select Workspace', id: 0}, ...items];
}

export const getConferenceText = (conferenceType, $default = 'None') => {
  let text = '';
  switch (+conferenceType) {
    case 1:
      text = 'Video Conference';
      break;
    case 2:
      text = 'Web Conference';
      break;
    default:
      text = $default;
      break;
  }

  return text;
};

export const getConferenceIcon = conferenceType => {
  let text = '';
  switch (+conferenceType) {
    case 1:
      text = 'video';
      break;
    case 2:
      text = 'globe';
      break;
    default:
      text = 'unknown';
      break;
  }

  return text;
};

export const getSeeMapURL = (lastLocation = {}) => {
  const {zoneData: zone, floorData: floor} = lastLocation;
  console.log({floor});
  let url = '/maps';
  if (zone && zone !== '-') {
    url += `?zone=${zone.id}&floor=${floor.level}`;
  }
  return url;
};

export const dump = (...props) => {
  if (config.DEBUG) {
    console.log(...props);
  }
};

export const getNewsCategory = () => {
  const cat = requestSegment(2);
  return cat ? cat : config.NEWS_TYPES.INTERNAL.name.toString().toLowerCase();
};

export const getNewsType = props => {
  const category = props.match.params.category;
  const upperCategory = category.toString().toUpperCase();
  return config.NEWS_TYPES[upperCategory].id;
};

export function chunkArray(arr, n) {
  let chunkLength = Math.max(arr.length / n, 1);
  let chunks = [];
  for (let i = 0; i < n; i++) {
    if (chunkLength * (i + 1) <= arr.length)
      chunks.push(arr.slice(chunkLength * i, chunkLength * (i + 1)));
  }
  return chunks;
}

export const getFloorFromLocation = location => {
  if (!location.zone) return null;

  const {zone} = location;
  if (!zone.floor) return null;

  const {floor} = zone;
  return floor && floor.level ? floor.level : null;
};

export const handleKeyDownNumberOfParticipant = e => {
  const notAllowed = ['e', '.'].includes(e.key);
  return notAllowed && e.preventDefault();
};

export function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
