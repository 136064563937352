import {takeEvery, put, call} from 'redux-saga/effects';
import {
  GET_LIST_GROUP_MENU_REQUEST,
  SET_GROUP_MENU,
} from '../actions/actionsList';
import GroupMenu from '../api/GroupMenu';
import config from '../config';
import {setGlobalError} from '../actions/global';

/**
 * Action handler for getting group menu list.
 *
 * @returns {IterableIterator<*>}
 */
function* getGroupMenuListActionHandler(action) {
  const {payload} = action;

  try {
    const {search, limit, sortBy, page, descending} = payload || {};
    const responseData = yield call(GroupMenu.getList, {
      page,
      limit,
      sortBy,
      search,
      descending,
    });
    const {code, data, message} = responseData || {};
    if (code === 200) {
      yield put({
        type: SET_GROUP_MENU,
        payload: {groupMenu: (data || {}).content},
      });
    } else {
      yield put(setGlobalError(message));
    }
  } catch (e) {
    yield put(setGlobalError(config.INTERNAL_SERVER_ERROR));
  }
}

/**
 * Reservation Saga.
 *
 * @returns {IterableIterator<*|ForkEffect>}
 */
export default function*() {
  yield takeEvery(GET_LIST_GROUP_MENU_REQUEST, getGroupMenuListActionHandler);
}
