import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import swal from 'sweetalert2';
import {forgotPasswordModalAction} from '../../actions/auth';
import {openModal, toggleModal} from '../../actions/modal';
import connect from 'react-redux/es/connect/connect';
import {
  ModalHeader,
  Button,
  ModalBody,
  Label,
  FormGroup,
  UncontrolledAlert,
} from 'reactstrap';
import {Field, reduxForm} from 'redux-form';
import {decrypt} from '../../utils/encryption';
import {validateEmail} from '../../utils/validations';
import LoginField from '../Forms/LoginField';
import MaterialSpinner from '../UIKit/MaterialSpinner';

class ModalForgotPassword extends Component {
  state = {
    showOriPassword: false,
    showNewPassword: false,
    showConfirmPassword: false,
  };
  toggle = () => {
    this.props.toggleModal('forgotPassword');
  };

  onSubmit = values => {
    this.props.forgotPassword(values.email);
  };

  render() {
    const {handleSubmit, headerConfig, modal} = this.props;
    const {forgotPassword} = modal;

    const {message, loading: loadingForgotPassword} = forgotPassword;
    const hasError =
      loadingForgotPassword === 'failed' &&
      message !== null &&
      message !== undefined;

    if (loadingForgotPassword === 'success') {
      swal(
        'Password Sent',
        'Please check your email address, we send new password for you to login',
        'success'
      );
      this.toggle();
    }

    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <ModalHeader toggle={this.toggle} {...(headerConfig || {})}>
          Forgot Password
        </ModalHeader>
        <ModalBody>
          {hasError && (
            <UncontrolledAlert color="danger">{message}</UncontrolledAlert>
          )}

          <FormGroup>
            <Label for="currentPassword">Email</Label>
            <Field
              name="email"
              placeholder="Email"
              type="email"
              autoComplete="off"
              disabled
              component={LoginField}
            />
          </FormGroup>

          <Button
            color="primary"
            disabled={loadingForgotPassword === 'loading'}
            block>
            <MaterialSpinner
              indeterminate={loadingForgotPassword === 'loading'}
              small
              button
            />
            {loadingForgotPassword === 'loading' ? 'Submitting...' : 'Submit'}
          </Button>
        </ModalBody>
      </form>
    );
  }
}

function mapStateToProps(state) {
  const {auth, modal} = state;

  const credentials = auth.credentials
    ? JSON.parse(decrypt(auth.credentials))
    : {};
  delete credentials.password;

  return {
    modal,
    initialValues: {
      email: auth?.userData?.email,
      ...credentials,
    },
  };
}

function mapDispatchToProps(dispatch) {
  return {
    openModal: bindActionCreators(openModal, dispatch),
    toggleModal: bindActionCreators(toggleModal, dispatch),
    forgotPassword: bindActionCreators(forgotPasswordModalAction, dispatch),
  };
}

const validate = values => {
  const {email} = values;
  const errors = {};
  if (!email) {
    errors.email = 'Please fill your email address';
  }
  if (email && !validateEmail(email)) {
    errors.email = 'Email format is not valid';
  }
  return errors;
};

const formed = reduxForm({
  form: 'forgot-password-modal',
  validate,
  enableReinitialize: true,
})(ModalForgotPassword);

export default connect(mapStateToProps, mapDispatchToProps)(formed);
