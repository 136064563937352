import React, {Component, Fragment} from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {Flex, ListHeader, ListIconImage} from '../../styles';
import Avatar from '../Avatar';
import {
  getSeeMapURL,
  isNonStaff,
  parseLastLocation,
  toast,
} from '../../utils/helpers';
import UserLastLocation from './UserLastLocation';
import {
  ListGroup,
  ListGroupItem,
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Row,
} from 'reactstrap';

class UserCard extends Component {
  handleSeeMap = (event, hasLastDetected) => {
    if (!hasLastDetected) {
      toast({
        title: 'This person is not detected in the office',
        type: 'warning',
      });
      event.preventDefault();
    }
  };

  handleClick = () => {
    const {onClickHandler, user} = this.props;
    if (onClickHandler) {
      onClickHandler(user);
    }
  };

  render() {
    const {handleClick} = this;
    const {user} = this.props;
    const {last_location: lastLocation} = user;
    const isFromRecentSearch = user.recentSearch;
    const profile = isFromRecentSearch ? user.profile : user;
    const hiris = isFromRecentSearch ? user.hiris : profile.hiris;
    const image = profile.avatar;
    const userId = profile.id;
    const nonStaff = isNonStaff(profile);
    const extensions = user.extensions || user.extension || [];
    const haveExtensions = extensions.length > 0;
    const contactPerson = user.contact_person;
    const contactPersons =
      typeof contactPerson === 'string' ? [] : user.contact_person || [];
    const lastDetected = parseLastLocation(lastLocation);
    const {zone} = lastDetected;
    const hasLastDetected = zone && zone !== '-';
    const seeMapURL = getSeeMapURL(lastDetected);

    return (
      <StyledCard onClick={handleClick}>
        <StyledCardHeader>
          <Flex spaceBetween>
            {nonStaff ? (
              <Avatar image={image} />
            ) : (
              <Link to={`/user/${userId}`}>
                <Avatar image={image} />
              </Link>
            )}
            <UserInfo>
              {nonStaff ? (
                <UserName>{profile.name}</UserName>
              ) : (
                <Link to={`/user/${userId}`}>
                  <UserName>{profile.name}</UserName>
                </Link>
              )}
              {hiris && hiris.jobTitle && (
                <UserPosition>{hiris.jobTitle}</UserPosition>
              )}
              {hiris && hiris.bunName && (
                <UserDivision>{hiris.bunName}</UserDivision>
              )}
            </UserInfo>
          </Flex>
        </StyledCardHeader>
        <ListGroup flush>
          {!nonStaff && (
            <Fragment>
              <ListGroupItemTitle>Contact</ListGroupItemTitle>
              <ListGroupItemAfterHeader>
                <Row>
                  <Col>
                    <ListHeader>Phone</ListHeader>
                    <ListItemText>{profile.mobile_phone || '-'}</ListItemText>
                  </Col>
                  <ColListIcon>
                    <ListIconImage src="/assets/images/call.png" alt="Call" />
                  </ColListIcon>
                </Row>
              </ListGroupItemAfterHeader>
              <ListGroupItem>
                <Row>
                  <Col>
                    <ListHeader>WhatsApp</ListHeader>
                    <ListItemText>{profile.wa_numbers || '-'}</ListItemText>
                  </Col>
                  <ColListIcon>
                    <ListIconImage
                      src="/assets/images/whatsapp.png"
                      alt="Call"
                    />
                  </ColListIcon>
                </Row>
              </ListGroupItem>
            </Fragment>
          )}
          {haveExtensions && (
            <ListGroupItem>
              <Row>
                <Col xs={10}>
                  <ListHeader>Extension Number</ListHeader>
                  {extensions.map(extension => (
                    <ListItemText key={extension.id}>
                      {extension.extension_number}
                    </ListItemText>
                  ))}
                </Col>
                <ColListIcon>
                  <ListIconImage src="/assets/images/call.png" alt="Call" />
                </ColListIcon>
              </Row>
            </ListGroupItem>
          )}
          {contactPersons.length > 0 && (
            <ListGroupItem>
              <Row>
                <Col>
                  <ListHeader>Contact Person</ListHeader>
                  {contactPersons.map(contact => (
                    <ListItemText key={contact.id}>
                      {contact.user.name}
                    </ListItemText>
                  ))}
                </Col>
              </Row>
            </ListGroupItem>
          )}
          {!nonStaff && <UserLastLocation user={user} />}
        </ListGroup>
        {!nonStaff && (
          <StyledCardFooter>
            <SeeMapButton
              size="sm"
              tag={Link}
              block
              color="link"
              target="_blank"
              onClick={event => this.handleSeeMap(event, hasLastDetected)}
              to={seeMapURL}>
              SEE MAP
            </SeeMapButton>
          </StyledCardFooter>
        )}
      </StyledCard>
    );
  }
}

const UserInfo = styled.div`
  flex: 1;
  margin-left: 10px;
  margin-top: 20px;
`;

const UserName = styled.h4`
  margin-bottom: 4px;
  margin-top: 0;
  color: #505050;
  font-size: 1em;
  font-weight: bold;
`;

const UserPosition = styled.h6`
  margin-top: 0;
  margin-bottom: 4px;
  font-weight: normal;
  color: #939393;
  font-size: 13px;
`;

const UserDivision = styled.h6`
  margin: 0;
  font-weight: normal;
  color: #939393;
  font-size: 13px;
`;

const SeeMapButton = styled(Button)`
  font-weight: bold !important;
  color: #eda300 !important;
  font-size: 1em !important;
`;

export const ListItemText = styled.div`
  color: rgba(80, 80, 80, 0.8);
`;

const ColListIcon = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const StyledCardFooter = styled(CardFooter)`
  display: flex;
  justify-content: center;
  background-color: #fff !important;
  padding: 5px !important;
  border-top: 0 !important;
  &:last-child {
    //border-radius: 0 0 9px 9px !important;
  }
`;

const StyledCard = styled(Card)`
  box-shadow: 0px 0px 1px 0px #d9d9d9;
  cursor: pointer;
  margin-bottom: 1em;
  //border-radius: 10px !important;
  //height: 97%;
`;

const ListGroupItemAfterHeader = styled(ListGroupItem)`
  border-top: 0 !important;
  padding-top: 0.3em !important;
`;

const ListGroupItemTitle = styled(ListGroupItem)`
  color: rgba(80, 80, 80, 0.8);
  padding-bottom: 0 !important;
  border-bottom: none !important;
`;

const StyledCardHeader = styled(CardHeader)`
  background: #fff !important;
  &:first-child {
    //border-radius: 9px 9px 0 0 !important;
  }
`;

export default UserCard;
