import React, {Component, Fragment} from 'react';
import {selectMeetingRoom} from '../../actions/reservation';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {closeModal} from '../../actions/modal';
import styled from 'styled-components';
import CurrentBooking from '../../utils/CurrentBooking';
import {IconUserGroupRed, IconMapMarkerRed} from '../../styles';
import {Col, Row, Button, Card, CardBody, ButtonGroup} from 'reactstrap';
import MaterialIcon from '../UIKit/MaterialIcon';
import {getConferenceIcon, getConferenceText} from '../../utils/helpers';
import {media} from '../../styles/grid';

class MeetingRoomCard extends Component {
  selectMeetingRoom = item => {
    const {selectMeetingRoom, closeModal} = this.props;
    selectMeetingRoom(item);
    this.updateCurrentBooking(item);
    closeModal('searchMeetingRoom');
  };

  updateCurrentBooking = item => {
    const reservation = CurrentBooking.fetch();
    if (reservation) {
      reservation.meetingRoom = item;
      CurrentBooking.save(reservation);
    }
  };

  render() {
    const {selectMeetingRoom} = this;
    const {item, noAction, withoutClear} = this.props;

    if (!item) return null;

    const {zone, capacity, video_conference} = item;
    const floor = zone && zone.floor ? zone.floor : null;
    // const zoneName = zone ? zone.name : null;
    const floorName = floor ? floor.name : null;
    const cluster = item.cluster ? item.cluster[0] : {};
    const onChange = this.props.change;
    const conferenceText = getConferenceText(video_conference);
    const conferenceIcon = getConferenceIcon(video_conference);

    return (
      <StyledCard>
        <CardBody>
          <Row>
            <Col xs={12} sm={noAction ? 4 : 2}>
              <TextMuted>Room Name</TextMuted>
              <RoomName>{item.name}</RoomName>
            </Col>
            <Col xs={12} sm={noAction ? 5 : 3}>
              <TextLabel>
                <IconMapMarkerRed /> POI Name
              </TextLabel>
              <TextMuted>
                {item.name} &middot; {floorName} &middot; {cluster.name}
              </TextMuted>
            </Col>
            <Col xs={12} sm={noAction ? 3 : 2}>
              <TextLabel>
                <IconUserGroupRed /> Capacity
              </TextLabel>
              <TextMuted>Up to {capacity} People</TextMuted>
            </Col>
            <Col xs={12} sm={3}>
              {!!video_conference && (
                <VideoConferenceLabel>
                  <MaterialIcon size={2} color="danger" icon={conferenceIcon} />
                  <span className="ml-2">{conferenceText}</span>
                </VideoConferenceLabel>
              )}
            </Col>
            {!noAction ? (
              <Col xs={12} sm={2} className="text-right">
                {onChange ? (
                  <ButtonGroup>
                    <Button
                      size="sm"
                      onClick={onChange}
                      type="button"
                      color="warning">
                      <MaterialIcon icon="edit" />
                      <IconText>Change</IconText>
                    </Button>
                    {!withoutClear && (
                      <Button
                        size="sm"
                        color="danger"
                        onClick={() => selectMeetingRoom(null)}>
                        <MaterialIcon icon="trash" />
                        <IconText>Clear</IconText>
                      </Button>
                    )}
                  </ButtonGroup>
                ) : (
                  <Button
                    size="sm"
                    color="primary"
                    onClick={() => selectMeetingRoom(item)}>
                    Choose
                  </Button>
                )}
              </Col>
            ) : (
              ''
            )}
          </Row>
        </CardBody>
      </StyledCard>
    );
  }
}

const IconText = styled.span.attrs({
  className: 'ml-1',
})`
  ${media.xs`
    display: none;
  `} ${media.sm`
    display: none;
  `};
`;

const TextMuted = styled.div`
  color: #a2a2a2;
  font-size: 12px;
`;

const TextLabel = styled.div`
  font-size: 13px;
  color: #505050;
`;

const VideoConferenceLabel = styled.div`
  font-size: 13px;
  color: #505050;
  display: flex;
  align-items: center;
`;

const StyledCard = styled(Card)`
  margin-bottom: 1em;

  &:hover,
  &:focus,
  &:active {
    background-color: #f4f4f4;
  }
`;

const RoomName = styled.h4`
  font-size: 18px;
`;

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    selectMeetingRoom: bindActionCreators(selectMeetingRoom, dispatch),
    closeModal: bindActionCreators(closeModal, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MeetingRoomCard);
