import React from 'react';
import {Field} from 'redux-form';
import styled, {css} from 'styled-components';
import {TextError} from '../../../styles';

export const MobilePhoneInput = ({
  input,
  placeholder,
  defaultValue,
  meta: {touched, error},
}) => {
  return (
    <div>
      <MobilePhoneField />
      {touched && error && <TextError>{error}</TextError>}
    </div>
  );
};

export const WhatsAppInput = ({
  input,
  placeholder,
  defaultValue,
  meta: {touched, error},
}) => {
  return (
    <div>
      <WhatsAppField />
      {touched && error && <TextError>{error}</TextError>}
    </div>
  );
};

export const ContactPersonInput = ({
  input,
  placeholder,
  defaultValue,
  meta: {touched, error},
}) => {
  return (
    <div>
      <ContactPersonField />
      {touched && error && <TextError>{error}</TextError>}
    </div>
  );
};

export const BioInput = ({
  input,
  placeholder,
  defaultValue,
  meta: {touched, error},
}) => {
  return (
    <div>
      <BioField />
      {touched && error && <TextError>{error}</TextError>}
    </div>
  );
};

export const InputFieldStyle = css`
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:focus {
    color: #495057;
    background-color: #fff;
    border-color: #e0301e;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(224, 48, 30, 0.56);
  }
`;
const ContactPersonField = styled(Field).attrs({
  name: 'contact_person',
  component: 'input',
  type: 'number',
  placeholder: 'Contact Person',
})`
  ${props => InputFieldStyle};
`;
const WhatsAppField = styled(Field).attrs({
  name: 'wa_numbers',
  component: 'input',
  type: 'text',
  placeholder: 'WhatsApp Number',
})`
  ${props => InputFieldStyle};
`;
const BioField = styled(Field).attrs({
  name: 'message',
  component: 'textarea',
  placeholder: 'Your Bio...',
})`
  ${props => InputFieldStyle};
`;
const MobilePhoneField = styled(Field).attrs({
  name: 'mobile_phone',
  component: 'input',
  type: 'text',
  placeholder: 'Mobile Phone',
})`
  ${props => InputFieldStyle};
`;
