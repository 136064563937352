import React, {Component} from 'react';
import {dump, postImageURL, postURL} from '../../utils/helpers';
import styled, {css} from 'styled-components';
import {media} from '../../styles/grid';
import {
  CarouselIndicators,
  CarouselItem,
  Carousel,
  CarouselControl,
} from 'reactstrap';
import {Link} from 'react-router-dom';
import LoadingComponent from '../UIKit/LoadingComponent';
import {Post} from '../../models/Post';

class CarouselNews extends Component {
  state = {
    sliderActive: 0,
    activeIndex: 0,
  };

  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
  }

  slideTo = item => {
    this.setState({
      sliderActive: item,
    });
  };

  // carousel
  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === this.getItems().length - 1
        ? 0
        : this.state.activeIndex + 1;
    this.setState({activeIndex: nextIndex});
  }

  previous() {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === 0
        ? this.getItems().length - 1
        : this.state.activeIndex - 1;
    this.setState({activeIndex: nextIndex});
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({activeIndex: newIndex});
  }

  imageURL = item => {
    return postImageURL(item);
  };

  getItems = () => {
    return this.props.items || [];
  };

  getFormattedItems = () => {
    return this.getItems().map(item => new Post(item));
  };

  getCarouselIndicatorItems = () => {
    return this.getFormattedItems().map((item, index) => ({
      key: index,
      src: item.imageURL,
      caption: item.excerpt,
      altText: item.title,
    }));
  };

  render() {
    const {loading} = this.props;
    const items = this.getFormattedItems();
    const carouselIndicatorItems = this.getCarouselIndicatorItems();

    dump({items});

    const {activeIndex} = this.state;

    if (loading) {
      return (
        <CarouselPlaceholderContainer>
          <LoadingComponent />
        </CarouselPlaceholderContainer>
      );
    }

    if (!items.length) return '';

    return (
      <Carousel
        activeIndex={activeIndex}
        next={this.next}
        previous={this.previous}>
        {/*<CarouselIndicatorsContainer onClickHandler={() => {}}>*/}
        <StyledCarouselIndicators
          items={carouselIndicatorItems}
          activeIndex={activeIndex}
          onClickHandler={this.goToIndex}
        />
        {/*</CarouselIndicatorsContainer>*/}
        {items.map(post => (
          <StyledCarouselItem
            onExiting={this.onExiting}
            onExited={this.onExited}
            key={`highlight-${post.id}`}
            src={post.imageURL}>
            <CarouselIndicatorsContainer>
              <StyledCarouselCaption>
                <CarouselTitle to={postURL(post)}>{post.title}</CarouselTitle>
                <CarouselButton to={postURL(post)}>See More</CarouselButton>
              </StyledCarouselCaption>
            </CarouselIndicatorsContainer>
          </StyledCarouselItem>
        ))}
        <StyledCarouselControl
          direction="prev"
          directionText="Previous"
          onClickHandler={this.previous}
        />
        <StyledCarouselControl
          direction="next"
          directionText="Next"
          onClickHandler={this.next}
        />
      </Carousel>
    );
  }
}

const StyledCarouselControl = styled(CarouselControl)`
  z-index: 1200 !important;
`;

const StyledCarouselIndicators = styled(CarouselIndicators)`
  //right: 10px !important;
  //left: auto !important;
  //position: relative;
  z-index: 1200 !important;
  bottom: 1em !important;
  li {
    border-radius: 100%;
    width: 1em !important;
    height: 1em !important;
    padding: 0 !important;
    border-top: none !important;
    border-bottom: none !important;

    &.active {
      background: ${props => props.theme.colors.primary} !important;
    }
  }
`;

const CarouselTitle = styled(Link)`
  color: #ffffff;
  margin-bottom: 13px;
  font-weight: bold;
  font-size: 32px;
  display: block;
  line-height: 1.2;

  ${media.xs`
    font-size: 1em;
  `} &:hover {
    color: #ffffff;
  }
`;

// const CarouselSubTitle = styled.div`
//   color: #ffffff;
//   margin-bottom: 13px;
// `;

const StyledCarouselCaption = styled.div`
  position: absolute;
  width: 100%;
  bottom: -33px;
  text-align: center;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(20%, rgba(0, 0, 0, 0)),
    color-stop(100%, rgba(0, 0, 0, 1))
  );
  padding-bottom: 40px;
  padding-top: 80px;
`;

const CarouselButton = styled(Link).attrs({
  className: 'btn btn-outline-secondary btn-sm',
})`
  background-color: transparent;
  color: #ffffff !important;
  border-color: #ffffff !important;
  margin-bottom: 1em;
`;

const CarouselContainer = styled.div`
  width: 1200px;
  max-width: 100%;
  display: block;
  margin: 0 auto;
  position: relative;

  ${media.xs`
    width: 100%;
  `};
`;

const CarouselIndicatorsContainer = styled(CarouselContainer)`
  position: absolute !important;
  bottom: 33px;
  z-index: 998;
  left: 0px !important;
  right: 0px !important;
  background: #666 !important;
`;

const StyledCarouselItem = styled(CarouselItem)`
  height: 400px;
  ${props =>
    props.src &&
    css`
  background-image: url('${props.src}');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #fff;
  
  ${media.xs`
    height: 200px;
  `}
`};
`;

const CarouselPlaceholderContainer = styled.div`
  height: 400px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;

  ${media.xs`
    height: 250px;
  `};
`;

export default CarouselNews;
