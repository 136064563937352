import {takeEvery, put, call} from 'redux-saga/effects';
import {
  FIND_ROOM_POI_ACTION,
  GET_HEAT_MAP_ACTION,
  GET_HEAT_MAP_REQUEST,
  GET_HEAT_MAP_SUCCESS,
  GET_ROOMS_WITH_RESERVATION,
  GET_ROOMS_WITH_RESERVATION_FAILED,
  GET_ROOMS_WITH_RESERVATION_REQUEST,
  GET_ROOMS_WITH_RESERVATION_SUCCESS,
  SET_POI_LIST,
} from '../actions/actionsList';
import POI from '../api/POI';
import config from '../config';
import {setGlobalError} from '../actions/global';

/**
 * Get Floor List.
 *
 * @returns {IterableIterator<*>}
 */
function* findRoomPOIHandler(action) {
  const {payload} = action;

  try {
    const {page, limit, perPage, sortBy, key, descending} = payload || {};
    const responseData = yield call(POI.findRoom, {
      page,
      limit,
      perPage,
      sortBy,
      key,
      descending,
    });
    const {code, data, message} = responseData || {};
    const {content} = data || {};
    if (code === 200) {
      yield put({type: SET_POI_LIST, payload: {data: content}});
    } else {
      yield put(setGlobalError(message));
    }
  } catch (e) {
    yield put(setGlobalError(config.INTERNAL_SERVER_ERROR));
  }
}

/**
 * Get Heat Map.
 *
 * @param action
 * @returns {IterableIterator<*>}
 */
function* getHeatMapActionHandler(action) {
  // const {payload} = action;

  yield put({type: GET_HEAT_MAP_REQUEST});
  const responseData = yield call(POI.getHeatMap);
  const {code, data, message} = responseData || {};
  const {content} = data || {};
  if (code === 200) {
    yield put({type: GET_HEAT_MAP_SUCCESS, payload: {data: content}});
  } else {
    yield put({type: GET_HEAT_MAP_SUCCESS, payload: {data: content}});
    yield put(setGlobalError(message));
  }
}

function* getRoomsWithReservationActionHandler(action) {
  const {payload} = action;
  const {date, floor_id, sort_col, sort_dir} = payload || {};

  yield put({type: GET_ROOMS_WITH_RESERVATION_REQUEST});

  const responseData = yield call(
    POI.getRoomsWithReservation,
    date,
    floor_id,
    sort_col,
    sort_dir
  );
  const {code, data, message} = responseData || {};

  if (code === 200) {
    yield put({
      type: GET_ROOMS_WITH_RESERVATION_SUCCESS,
      payload: {data},
    });
  } else {
    yield put({
      type: GET_ROOMS_WITH_RESERVATION_FAILED,
      payload: {data},
    });
    yield put(setGlobalError(message));
  }
}

/**
 * Reservation Saga.
 *
 * @returns {IterableIterator<*|ForkEffect>}
 */
export default function*() {
  yield takeEvery(FIND_ROOM_POI_ACTION, findRoomPOIHandler);
  yield takeEvery(GET_HEAT_MAP_ACTION, getHeatMapActionHandler);
  yield takeEvery(
    GET_ROOMS_WITH_RESERVATION,
    getRoomsWithReservationActionHandler
  );
}
