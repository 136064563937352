import React from 'react';
import styled from 'styled-components';
import {Field} from 'redux-form';
import {InputStyle, TextError} from '../../../styles';

const MessageField = ({
  input,
  placeholder,
  defaultValue,
  meta: {touched, error},
}) => {
  return (
    <FormGroupMessage>
      <FieldWrapper>
        <MessageInput
          component="textarea"
          name="message"
          id="message"
          placeholder="Message"
        />
      </FieldWrapper>
      {touched && error && <TextError>{error}</TextError>}
    </FormGroupMessage>
  );
};

const FormGroupMessage = styled.div`
  margin-bottom: 20px;
`;

const FieldWrapper = styled.div`
  clear: both;
  display: block;
  width: 100%;
`;

const MessageInput = styled(Field)`
  ${props => InputStyle};
`;

export default MessageField;
