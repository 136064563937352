import React, {Component} from 'react';
import {Redirect} from 'react-router';
import {connect} from 'react-redux';

export default function(ComposedComponent) {
  class Authentication extends Component {
    componentWillMount() {
      const {userIsLoggedIn} = this.props.auth;

      if (userIsLoggedIn === false) {
        return <Redirect push to="/login" />;
      }
    }

    componentWillUpdate(nextProps) {
      const {userIsLoggedIn} = this.props.auth;

      if (userIsLoggedIn === false) {
        return <Redirect push to="/login" />;
      }
    }

    render() {
      const {userIsLoggedIn} = this.props.auth;

      if (userIsLoggedIn === false) {
        return <Redirect push to="/login" />;
      }

      return <ComposedComponent {...this.props} />;
    }
  }

  function mapStateToProps(state) {
    return {
      auth: state.auth,
    };
  }

  return connect(mapStateToProps, null)(Authentication);
}
