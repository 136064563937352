import {
  MediaBody,
  MediaHeading,
  MediaList,
  NotifMessage,
} from '../../../styles';
import React, {useEffect, useState} from 'react';
import MediaObject from '../MediaObject';
import {
  notificationSender,
  parseBodyMessage,
  reservationURL,
} from '../../../utils/helpers';
import {Link} from 'react-router-dom';

const MediaReservationInfo = props => {
  const {notification} = props;

  const [bodyMessage, setBodyMessage] = useState({});
  const [message, setMessage] = useState();
  const [user, setUser] = useState({});

  useEffect(() => {
    const {message: msg, body} = parseBodyMessage(notification);

    setBodyMessage(body || {});
    setMessage(msg || (body || {}).message);
    setUser(notificationSender(notification) || {});
  }, [notification]);

  const id = bodyMessage.reservation_id || bodyMessage.id_reservation;
  const to = reservationURL(id);

  return (
    <MediaList>
      <MediaObject notification={notification} />
      <MediaBody tag={Link} to={to} target="_blank">
        <MediaHeading heading>{user.name} Info Reservation</MediaHeading>
        <NotifMessage>{message}</NotifMessage>
      </MediaBody>
    </MediaList>
  );
};

export default MediaReservationInfo;
