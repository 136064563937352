import React, {Component, Fragment} from 'react';
import {
  Button,
  Card,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import {Col, Row} from '../../styles/grid';
import MaterialIcon from '../UIKit/MaterialIcon';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import LoadingComponent from '../UIKit/LoadingComponent';
import {dump, reservationTime} from '../../utils/helpers';
import {getReservationByPOI} from '../../actions/reservation';
import Avatar from '../Avatar';
import POIInfoList from './POIInfoList';

class CardSelectedMarkerInfo extends Component {
  state = {
    modal: false,
  };

  toggle = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  };

  handleClose = () => {
    this.props.onClose();
  };

  handleDirections = () => {
    this.props.onDirections(this.props.poi);
    this.props.onClose();
  };

  componentDidMount() {
    const {poi} = this.props;
    if (poi && poi.id) {
      this.props.getReservationBYPOI(poi.id);
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.poi !== this.props.poi) {
      this.props.getReservationBYPOI(nextProps.poi.id);
    }
  }

  render() {
    const poi = this.props.reservation.poiMarker || this.props.poi;
    const {
      reservationByPOI: reservation,
      loadingReservationByPOI: loading,
    } = this.props.reservation;

    if (!poi) return null;

    const {zone, name, capacity, cluster} = poi;
    const clusterData = cluster ? cluster[0] || {} : {};
    const {floor} = zone;

    dump({reservation, loading, poi});

    return (
      <Fragment>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}>
          <ModalHeader toggle={this.toggle}>{name}</ModalHeader>
          <POIInfoList poi={poi} />
          <ModalFooter className="justify-content-center">
            <Button color="primary" onClick={this.handleDirections}>
              Directions
            </Button>
          </ModalFooter>
        </Modal>
        <CardMarkerInfo>
          {loading ? (
            <LoadingComponent />
          ) : (
            <ListGroup flush>
              <ListGroupItem>
                <POIName>
                  {name}
                  <CloseButton onClick={this.handleClose} color="link">
                    <MaterialIcon icon="times" />
                  </CloseButton>
                </POIName>
                <POIInfo>
                  <div>
                    <MaterialIcon icon="map-marker-alt" />
                  </div>
                  <div>
                    {name} &middot; {floor.name} &middot; {clusterData.name}
                  </div>
                </POIInfo>
                <POIInfo>
                  <div>
                    <MaterialIcon icon="users" />
                  </div>
                  <div>Up to {capacity} people</div>
                </POIInfo>
              </ListGroupItem>
              {reservation && (
                <ListGroupItem>
                  <Row>
                    <Col sm={4}>
                      <Avatar
                        className="rounded-circle"
                        image={reservation.creator.avatar}
                      />
                    </Col>
                    <Col sm={8}>
                      <h5>
                        {reservationTime(reservation.start_time)} -{' '}
                        {reservationTime(reservation.end_time)}
                      </h5>
                      <POIInfo>{reservation.creator.name}</POIInfo>
                      <POIInfo>{reservation.meeting_description}</POIInfo>
                    </Col>
                  </Row>
                </ListGroupItem>
              )}
              <ListGroupItem>
                <Row className="align-items-center">
                  <Col sm={4}>
                    <MoreInfo onClick={this.toggle}>More Info</MoreInfo>
                  </Col>
                  <Col className="text-right">
                    <Button
                      color="primary mr-1"
                      onClick={this.handleDirections}>
                      Directions
                    </Button>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          )}
        </CardMarkerInfo>
      </Fragment>
    );
  }
}

const POIName = styled.h3`
  font-weight: bold;
  font-size: 20px;
`;

const POIInfo = styled.div`
  font-size: 14px;
  color: #939393;
  display: flex;
  width: 100%;

  i {
    width: 30px;
  }
`;

const MoreInfo = styled(Button).attrs({
  color: 'link',
})`
  font-size: 14px !important;
  font-weight: bold !important;
  color: #595959 !important;
`;

const CardMarkerInfo = styled(Card)`
  position: absolute !important;
  bottom: 0px;
  width: 50%;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 99;
  border-radius: 0 !important;
`;

const CloseButton = styled(Button)`
  float: right;
`;

CardSelectedMarkerInfo.propTypes = {
  poi: PropTypes.object,
  onClose: PropTypes.func,
  onDirections: PropTypes.func,
};

CardSelectedMarkerInfo.defaultProps = {
  poi: null,
  onClose: () => {},
  onDirections: () => {},
};

function mapStateToProps(state) {
  const {reservation} = state;

  return {reservation};
}

function mapDispatchToProps(dispatch) {
  return {
    getReservationBYPOI: bindActionCreators(getReservationByPOI, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CardSelectedMarkerInfo);
