import React, {Component} from 'react';
import {connect} from 'react-redux';
import ApplicationLayout from '../../components/Layouts/ApplicationLayout';
import {
  Container,
  MediaGroup,
  MediaList,
  NotifCardMedia,
  NotifCardMediaHeader,
  NotifContent,
  NotifTitle,
  MainContent,
} from '../../styles';
import {Col, Row} from '../../styles/grid';
import {getNotificationNoNeedActionRequest} from '../../actions/notification';
import {bindActionCreators} from 'redux';
import NotificationItem from './NotificationItem';
import styled from 'styled-components';
import NotificationTabs from './NotificationTabs';
import LoadingOrEmpty from '../../components/UIKit/LoadingOrEmpty';
import {scrolledToBottom} from '../../utils/helpers';
import MaterialSpinner from '../../components/UIKit/MaterialSpinner';

class NotificationGeneral extends Component {
  componentDidMount() {
    this.fetch();
    window.addEventListener('scroll', this.handleOnScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleOnScroll);
  }

  handleOnScroll = () => {
    if (scrolledToBottom()) {
      this.loadMore();
    }
  };

  fetch = (params = {}) => {
    this.props.getNotifications(params);
  };

  loadMore = () => {
    if (!this.hasMorePage()) return;

    const {global} = this.props;
    const {pagination} = global;
    let page = pagination.current_page;
    page++;
    this.fetch({page, loadMore: true});
  };

  hasMorePage = () => {
    const {global} = this.props;
    const {pagination} = global;
    return pagination.current_page < pagination.last_page;
  };

  render() {
    const {notification} = this.props;
    const {notifications, loading, loadingMore} = notification;
    const hasMorePage = this.hasMorePage();

    return (
      <ApplicationLayout withoutFooter>
        <MainContent>
          <Container>
            <NotifContent>
              <Row flex justifyContent="center">
                <Col xs={12} sm={8}>
                  <NotifCardMedia>
                    <NotifCardMediaHeader>
                      <NotifTitle>Notifications</NotifTitle>
                      {/*<Button color="primary">Notification Setting</Button>*/}
                    </NotifCardMediaHeader>
                    <NotificationTabs />
                    <MediaGroup>
                      <LoadingOrEmpty
                        loading={loading}
                        data={notifications}
                        message="No notification yet"
                      />
                      {notifications.map((notification, index) => (
                        <NotificationItem
                          key={index}
                          notification={notification}
                        />
                      ))}
                      {hasMorePage && (
                        <LoadMoreWrapper>
                          {loadingMore && (
                            <MaterialSpinner indeterminate={loadingMore} />
                          )}
                          {/*<Button*/}
                          {/*color="primary"*/}
                          {/*onClick={this.loadMore}*/}
                          {/*disabled={loading}>*/}
                          {/*{loading ? 'Loading...' : 'Load More'}*/}
                          {/*</Button>*/}
                        </LoadMoreWrapper>
                      )}
                    </MediaGroup>
                  </NotifCardMedia>
                </Col>
              </Row>
            </NotifContent>
          </Container>
        </MainContent>
      </ApplicationLayout>
    );
  }
}

const LoadMoreWrapper = styled(MediaList)`
  display: flex;
  justify-content: center;
`;

function mapStateToProps(state) {
  const {notification, global} = state;
  return {
    notification,
    global,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getNotifications: bindActionCreators(
      getNotificationNoNeedActionRequest,
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationGeneral);
