import {takeEvery, put, call} from 'redux-saga/effects';
import {
  CANCEL_ORDER_ACTION,
  CANCELING_ORDER,
  FETCHING_ORDER_MENU,
  GET_ORDER_LIST_REQUEST,
  GET_ORDER_MENU_REQUEST,
  LOADED,
  LOADING,
  ORDER_CANCELED,
  ORDER_MENU_FETCHED,
  ORDER_UPDATED,
  SET_ORDER,
  SET_ORDER_LIST,
  SET_ORDERS,
  SET_PAGINATION,
  UPDATE_ORDER_REQUEST,
  UPDATING_ORDER,
} from '../actions/actionsList';
import Order from '../api/Order';
import {getOrderListRequest} from '../actions/order';
import swal from 'sweetalert2';
import config from '../config';
import {setGlobalError} from '../actions/global';

/**
 * Action handler for getting group menu list.
 *
 * @returns {IterableIterator<*>}
 */
function* getOrderListRequestHandler(action) {
  const {payload} = action;

  try {
    yield put({type: LOADING});
    const {limit, sortBy, page, descending, search, status} = payload || {};
    const responseData = yield call(Order.getList, {
      page,
      limit,
      sortBy,
      descending,
      search,
      status,
    });
    const {code, data, message} = responseData || {};
    const {result, paginate} = data || {};

    if (code === 200) {
      yield put({type: LOADED});
      yield put({type: SET_ORDERS, payload: {orders: result}});
      yield put({type: SET_PAGINATION, payload: {pagination: paginate}});
    } else {
      yield put({type: LOADED});
      yield put(setGlobalError(message));
    }
  } catch (e) {
    yield put({type: LOADED});
    yield put(setGlobalError(config.INTERNAL_SERVER_ERROR));
  }
}

function* getOrderMenuRequestHandler(action) {
  const {payload} = action;

  try {
    yield put({type: FETCHING_ORDER_MENU});
    const {reservationId} = payload || {};
    const responseData = yield call(
      Order.getOrdersByReservationId,
      reservationId
    );
    const {code, data} = responseData || {};
    if (code === 200) {
      yield put({type: ORDER_MENU_FETCHED, payload: {data}});
      yield put({type: SET_ORDER_LIST, payload: {data}});
    } else {
      yield put({type: ORDER_MENU_FETCHED});
    }
  } catch (e) {
    yield put({type: ORDER_MENU_FETCHED});
    yield put(setGlobalError(config.INTERNAL_SERVER_ERROR));
  }
}

function* updateOrderRequestHandler(action) {
  const {payload} = action;

  try {
    const {orderId, data} = payload || {};
    yield put({type: UPDATING_ORDER, payload: {orderId}});
    const response = yield call(Order.update, orderId, data);
    const {code, data: responseData, message} = response || {};
    if (code === 200) {
      yield put({type: ORDER_UPDATED, payload: {data: responseData}});
      yield put({type: SET_ORDER, payload: {order: responseData}});
      yield put(getOrderListRequest({status: 'incomplete'}));
    } else {
      yield put({type: ORDER_UPDATED});
      yield put(setGlobalError(message));
    }
  } catch (e) {
    yield put({type: ORDER_UPDATED});
    yield put(setGlobalError(config.INTERNAL_SERVER_ERROR));
  }
}

function* cancelOrderActionHandler(action) {
  const {payload} = action;

  try {
    const {id, onCancel} = payload || {};
    yield put({type: CANCELING_ORDER, payload: {id}});
    const response = yield call(Order.cancel, id);
    const {code, data, message} = response || {};
    if (code === 200) {
      yield put({type: ORDER_CANCELED, payload: {id, data}});
      swal('Success', message, 'success');
      if (onCancel) {
        onCancel();
      }
    } else {
      yield put({type: ORDER_CANCELED});
      yield put(setGlobalError(message));
      swal('Failed', message, 'error');
    }
  } catch (e) {
    yield put({type: ORDER_CANCELED});
    yield put(setGlobalError(config.INTERNAL_SERVER_ERROR));
  }
}

/**
 * Reservation Saga.
 *
 * @returns {IterableIterator<*|ForkEffect>}
 */
export default function*() {
  yield takeEvery(GET_ORDER_LIST_REQUEST, getOrderListRequestHandler);
  yield takeEvery(GET_ORDER_MENU_REQUEST, getOrderMenuRequestHandler);
  yield takeEvery(UPDATE_ORDER_REQUEST, updateOrderRequestHandler);
  yield takeEvery(CANCEL_ORDER_ACTION, cancelOrderActionHandler);
}
