import {takeEvery, put, call} from 'redux-saga/effects';
import {
  GET_SETTING_REQUEST,
  SET_SETTINGS,
  UPSERT_SETTING_FAILED,
  UPSERT_SETTING_LOADING,
  UPSERT_SETTING_REQUEST,
  UPSERT_SETTING_SUCCESS,
} from '../actions/actionsList';
import Setting from '../api/Setting';
import config from '../config';
import {setGlobalError} from '../actions/global';

/**
 * Action handler for getting group menu list.
 *
 * @returns {IterableIterator<*>}
 */
function* getSettingRequestHandler() {
  try {
    const responseData = yield call(Setting.getAll);
    const {status, data, message} = responseData || {};
    if (status === 200) {
      yield put({type: SET_SETTINGS, payload: {settings: data.data}});
    } else {
      yield put(setGlobalError(message));
    }
  } catch (e) {
    yield put(setGlobalError(config.INTERNAL_SERVER_ERROR));
  }
}

/**
 * Upsert setting.
 *
 * @param action
 * @returns {IterableIterator<*>}
 */
function* upsertSettingRequestHandler(action) {
  const {payload} = action;

  try {
    yield put({type: UPSERT_SETTING_LOADING});
    const {data} = payload || {};
    const responseData = yield call(Setting.upsert, data);
    const {code, data: result, message} = responseData || {};
    if (code === 200) {
      yield put({type: UPSERT_SETTING_SUCCESS, payload: {data: result}});
    } else {
      yield put({type: UPSERT_SETTING_FAILED});
      yield put(setGlobalError(message));
    }
  } catch (e) {
    yield put({type: UPSERT_SETTING_FAILED});
    yield put(setGlobalError(config.INTERNAL_SERVER_ERROR));
  }
}

/**
 * Reservation Saga.
 *
 * @returns {IterableIterator<*|ForkEffect>}
 */
export default function*() {
  yield takeEvery(GET_SETTING_REQUEST, getSettingRequestHandler);
  yield takeEvery(UPSERT_SETTING_REQUEST, upsertSettingRequestHandler);
}
