import {
  UPSERT_SETTING_LOADING,
  UPSERT_SETTING_SUCCESS,
  UPSERT_SETTING_FAILED,
  SET_SETTINGS,
  AUTH_OUT_SUCCESS,
} from '../actions/actionsList';

let initialState = {
  settings: [],
  upsertingSetting: false,
};

export default function(state = initialState, action) {
  const {type, payload} = action;

  switch (type) {
    case AUTH_OUT_SUCCESS: {
      return {...initialState};
    }

    case SET_SETTINGS: {
      const {settings} = payload;
      return {...state, settings};
    }

    case UPSERT_SETTING_LOADING: {
      return {...state, upsertingSetting: true};
    }

    case UPSERT_SETTING_SUCCESS: {
      return {...state, upsertingSetting: false};
    }

    case UPSERT_SETTING_FAILED: {
      return {...state, upsertingSetting: false};
    }

    default:
      return state;
  }
}
