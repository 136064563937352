import {Col, media, Row} from '../../styles/grid';
import React, {Component} from 'react';
import styled, {css} from 'styled-components';
import {Card, CardBody} from 'reactstrap';
import {
  ColImage,
  ContentPlaceholder,
  PostImage,
  PostImagePlaceholder,
} from '../../styles';

class PostPlaceholderCard extends Component {
  render() {
    const {isAnnouncement} = this.props;

    return (
      <PostingCard>
        <PostBody>
          <PostRow>
            <ColImage xs={12} sm={5}>
              <PostImagePlaceholder />
            </ColImage>
            <ColPost xs={12} sm={isAnnouncement ? 12 : 7}>
              <ContentPlaceholder height={28} marginBottom={'8px'} />
              <DescriptionWrapper>
                <ContentPlaceholder
                  marginBottom={'3px'}
                  height={12}
                  width={'100%'}
                />
                <ContentPlaceholder
                  marginBottom={'3px'}
                  height={12}
                  width={'100%'}
                />
                <ContentPlaceholder
                  marginBottom={'3px'}
                  height={12}
                  width={'70%'}
                />
                <ContentPlaceholder
                  marginBottom={'3px'}
                  height={12}
                  width={'60%'}
                />
              </DescriptionWrapper>
              <Row className="mt-4">
                <Col>
                  <ContentPlaceholder height={16} width={'60%'} />
                  <ContentPlaceholder height={12} width={'40%'} />
                </Col>
              </Row>
            </ColPost>
          </PostRow>
        </PostBody>
      </PostingCard>
    );
  }
}

const ColPost = styled(Col)`
  padding: 8px;
  ${media.xs`
    padding: 0!important;
  `};
`;

const PostBody = styled(CardBody)`
  padding: 0 8px !important;
  flex: 1;
  display: flex;
  animation: fading 1.5s infinite;
`;

const PostingCard = styled(Card)`
  height: auto;
  margin-bottom: 10px;

  ${media.xs`
    padding: 16px;
  `};
`;

const PostRow = styled(Row)`
  width: 100%;
`;

const DescriptionWrapper = styled.div`
  height: 72px;
`;

export default PostPlaceholderCard;
