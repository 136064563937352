import {takeEvery, put, call} from 'redux-saga/effects';
import {
  GET_EULA_NOW_ACTION,
  GET_EULA_NOW_REQUEST,
  GET_EULA_NOW_SUCCESS,
  GET_EULA_NOW_FAILED,
} from '../actions/actionsList';
import Eula from '../api/Eula';
import {setGlobalError} from '../actions/global';

function* getEulaNowActionHandler(action) {
  const {payload} = action;
  const {id} = payload || {};

  yield put({type: GET_EULA_NOW_REQUEST});

  const responseData = yield call(Eula.getEulaNow, id);
  const {code, data, message} = responseData || {};

  if (code === 200) {
    yield put({
      type: GET_EULA_NOW_SUCCESS,
      payload: {data: (data || {}).content},
    });
  } else {
    yield put({type: GET_EULA_NOW_FAILED});
    yield put(setGlobalError(message));
  }
}

export default function*() {
  yield takeEvery(GET_EULA_NOW_ACTION, getEulaNowActionHandler);
}
