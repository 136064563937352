import {
  getFormattedReservationDate,
  getFormattedTime,
  isNeedMyApproval,
  notificationSender,
  parseBodyMessage,
  reservationStatus,
} from '../../../utils/helpers';
import {
  MediaBody,
  MediaHeading,
  MediaList,
  MediaNotifText,
  MediaUserPosition,
  TextHeader,
  TextMuted,
} from '../../../styles';
import {
  Alert,
  Card,
  CardBody,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
} from 'reactstrap';
import React, {useEffect, useState} from 'react';
import swal from 'sweetalert2';
import {useDispatch, useSelector} from 'react-redux';
import {
  approveReservationRequest,
  getReservationDetailRequest,
  rejectReservationRequest,
} from '../../../actions/reservation';
import styled from 'styled-components';
import LoadingComponent from '../../../components/UIKit/LoadingComponent';
import MaterialIcon from '../../../components/UIKit/MaterialIcon';
import MediaObject from '../MediaObject';
import MediaPOIInfo from '../MediaPOIInfo';
import {Link} from 'react-router-dom';

const storeSelector = store => {
  const {reservation, global} = store;
  const {loading} = global || {};
  const {
    reservation_id: reservationId,
    reservation: reservationDetail,
    approving,
    rejecting,
  } = reservation || {};

  return {
    reservationId,
    reservationDetail,
    approving,
    rejecting,
    loading,
  };
};

const MediaNeedApproval = props => {
  // props
  const {notification, className} = props;

  // store
  const dispatch = useDispatch();
  const {reservationDetail, loading, approving, rejecting} = useSelector(
    storeSelector
  );

  // state
  const [modal, setModal] = useState(false);
  const [notifBody, setNotifBody] = useState({});
  const [approvers, setApprovers] = useState([]);
  const [user, setUser] = useState({});

  // methods
  const handleApprove = reservation => {
    const id = reservation.reservation_id;
    swal({
      title: 'Approve Reservation',
      text: 'Do you really want to approve this reservation?',
      type: 'warning',
      showCancelButton: true,
    }).then(result => {
      if (result.value) {
        dispatch(approveReservationRequest(id, () => onApprove(id)));
      }
    });
  };
  const handleReject = reservation => {
    const id = reservation.reservation_id;
    swal({
      title: 'Reject Reservation',
      text: 'Do you really want to reject this reservation?',
      type: 'warning',
      showCancelButton: true,
    }).then(result => {
      if (result.value) {
        dispatch(rejectReservationRequest(id, () => onReject(id)));
      }
    });
  };
  const onApprove = id => {
    fetchDetail(id);
  };
  const onReject = id => {
    fetchDetail(id);
  };
  const toggle = () => {
    setModal(!modal);
  };
  // const viewDetail = reservation => {
  //   toggle();
  //   fetchDetail(reservation.reservation_id);
  // };
  const fetchDetail = id => {
    dispatch(getReservationDetailRequest(id));
  };

  // effects
  useEffect(() => {
    const {body} = parseBodyMessage(notification);

    setNotifBody(body || {});
    setUser(notificationSender(notification));
  }, [notification]);

  useEffect(() => {
    const {approver} = reservationDetail || {};
    setApprovers(approver || []);
  }, [reservationDetail]);

  // render
  const {reservation_id, meeting_description} = notifBody || {};
  const isApproving = +approving === +reservation_id;
  const isRejecting = +rejecting === +reservation_id;

  const {status, poi} = reservationDetail || {};
  const {name: poiName, capacity, zone} = poi || {};
  const {floor, name: zoneName} = zone || {};
  const {name: floorName} = floor || {};

  const isPending = status ? status === 'pending' : false;

  const showApproveRejectButtons =
    !loading && reservationDetail && isPending && isNeedMyApproval(approvers);
  const reservationURL = `/reservations/${reservation_id}/approval-request`;

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>Detail Reservation</ModalHeader>
        <ModalBody>
          {!reservationDetail && !loading && (
            <Alert color="warning">Data reservation not found</Alert>
          )}

          {loading && <LoadingComponent />}

          {!loading && reservationDetail && (
            <>
              <StatusText>{reservationStatus(status)}</StatusText>
              <ListGroup>
                <ListGroupItem>
                  <ListGroupTitle>Host Name</ListGroupTitle>
                  <ListGroupText>{reservationDetail.host_name}</ListGroupText>
                </ListGroupItem>
                <ListGroupItem>
                  <ListGroupTitle>Meeting Name</ListGroupTitle>
                  <ListGroupText>
                    {reservationDetail.meeting_description}
                  </ListGroupText>
                </ListGroupItem>
                <ListGroupItem>
                  <ListGroupTitle>Meeting Date</ListGroupTitle>
                  <ListGroupText>
                    {getFormattedReservationDate(
                      reservationDetail.date_reservation
                    )}
                  </ListGroupText>
                </ListGroupItem>
                <ListGroupItem>
                  <ListGroupTitle>Meeting Time</ListGroupTitle>
                  <ListGroupText>{`${getFormattedTime(
                    reservationDetail.start_time
                  )} - ${getFormattedTime(
                    reservationDetail.end_time
                  )}`}</ListGroupText>
                </ListGroupItem>
                <ListGroupItem>
                  <ListGroupTitle>Total Participant</ListGroupTitle>
                  <ListGroupText>1</ListGroupText>
                </ListGroupItem>
              </ListGroup>
              <TextHeader color="warning">Meeting Room</TextHeader>
              <Card>
                <CardBody>
                  <p>{poiName}</p>
                  <TextMuted>
                    <MaterialIcon icon="map-marker" />
                    <TextIcon>
                      {poiName} &middot; {zoneName} &middot; {floorName}
                    </TextIcon>
                  </TextMuted>
                  <TextMuted>
                    <MaterialIcon icon="users" />
                    <TextIcon>Up to {capacity} people</TextIcon>
                  </TextMuted>
                </CardBody>
              </Card>
            </>
          )}
        </ModalBody>
        {showApproveRejectButtons && (
          <StyledModalFooter>
            <Button
              color="primary"
              onClick={() => handleApprove(notifBody)}
              disabled={isApproving}>
              {isApproving ? 'Approving...' : 'Approve'}
            </Button>
            <Button
              color="warning"
              onClick={() => handleReject(notifBody)}
              disabled={isRejecting}>
              {isRejecting ? 'Rejecting...' : 'Reject'}
            </Button>
          </StyledModalFooter>
        )}
      </Modal>
      <MediaList>
        <MediaObject notification={notification} />
        <MediaBody>
          <MediaHeading heading>
            {user.name}
            <MediaNotifText>Need Your Approval</MediaNotifText>
          </MediaHeading>
          <Link target="blank" to={reservationURL}>
            <MediaUserPosition>{meeting_description}</MediaUserPosition>
          </Link>
          <POICardWrapper>
            <MediaPOIInfo reservation={notifBody} />
          </POICardWrapper>
          <ButtonViewDetail
            color="primary"
            tag={Link}
            target="blank"
            to={reservationURL}
            // onClick={() => viewDetail(notifBody)}
            size="sm">
            View Detail Reservation
          </ButtonViewDetail>
        </MediaBody>
      </MediaList>
    </>
  );
};

const POICardWrapper = styled.div`
  margin-bottom: 10px;
`;

const ButtonViewDetail = styled(Button)`
  &:hover {
    background-color: #a32020 !important;
  }
`;
const ListGroupTitle = styled.div`
  font-size: 12px;
  color: #757575;
`;

const ListGroupText = styled.div`
  font-size: 14px;
`;

const StyledModalFooter = styled(ModalFooter)`
  justify-content: center !important;
`;

const TextIcon = styled.span`
  margin-left: 10px;
`;

const StatusText = styled.div`
  margin-bottom: 1em;
  text-transform: capitalize;
  font-size: 14px;
  text-align: right;
`;

export default MediaNeedApproval;
