import React from 'react';
import SubmitOONForm from '../Profile/FormSubmitOON';
import {Modal, ModalBody, ModalHeader} from 'reactstrap';
import {closeModal, toggleModal} from '../../actions/modal';
import {useDispatch, useSelector} from 'react-redux';
import EditProfile from '../Profile/edit';
import BookReservationRoom from '../Reservations/BookReservationRoom';
import BookingSuccess, {
  CheckListImage,
  MessageText,
  SuccessMessage,
  SuccessMessageWrapper,
  ThankYouText,
} from '../Reservations/BookingSuccess';
import SearchMeetingRoom from '../Reservations/SearchMeetingRoom';
import {bookingDone, bookingReset} from '../../actions/reservation';
import ModalReleaseOON from '../Profile/ModalReleaseOON';
import Logo from '../../components/Logo';
import ModalChangePassword from './ModalChangePassword';
import ModalForgotPassword from './ModalForgotPassword';
import {APP_VERSION} from '../../config/constants';

const storeSelector = store => {
  const {modal, reservation} = store;

  return {
    modal,
    reservation,
  };
};

const Global = () => {
  const dispatch = useDispatch();
  const {modal} = useSelector(storeSelector);

  const onCloseModalBookingSuccess = () => {
    dispatch(closeModal('bookingSuccess'));
    dispatch(bookingDone());
    setTimeout(() => {
      dispatch(bookingReset());
    }, 1000);
  };

  const onCloseModalSuccess = () => {
    dispatch(closeModal('success'));
    setTimeout(() => {
      dispatch(bookingReset());
    }, 1000);
  };

  // const handleModalBeverageOrderClose = () => {
  //   dispatch(closeModal('beverageOrderSuccess'));
  //   setTimeout(() => {
  //     dispatch(submitOrderDone());
  //   }, 1000);
  // };

  const toggle = name => () => {
    dispatch(toggleModal(name));
  };

  const {
    modalOON,
    editProfile,
    bookReservationRoom,
    searchMeetingRoom,
    bookingSuccess,
    beverageOrderSuccess,
    modalReleaseOON,
    modalAbout,
    changePassword,
    forgotPassword,
    success,
  } = modal;

  return (
    <>
      <Modal size="md" isOpen={modalOON} toggle={toggle('modalOON')}>
        <SubmitOONForm />
      </Modal>
      <Modal size="lg" isOpen={editProfile} toggle={toggle('editProfile')}>
        <EditProfile />
      </Modal>
      <Modal
        size="lg"
        isOpen={bookReservationRoom}
        toggle={toggle('bookReservationRoom')}>
        <BookReservationRoom />
      </Modal>
      <Modal
        size="lg"
        isOpen={bookingSuccess}
        toggle={toggle('bookingSuccess')}
        onClosed={onCloseModalBookingSuccess}>
        <BookingSuccess onToggle={toggle('bookingSuccess')} />
      </Modal>
      <Modal
        size="lg"
        isOpen={success.open}
        toggle={toggle('success')}
        onClosed={onCloseModalSuccess}>
        <BookingSuccess
          {...((success.config || {}).props || {})}
          onToggle={toggle('success')}
        />
      </Modal>
      <Modal
        size="lg"
        isOpen={searchMeetingRoom}
        toggle={toggle('searchMeetingRoom')}>
        <SearchMeetingRoom />
      </Modal>
      <Modal
        size="lg"
        isOpen={beverageOrderSuccess}
        toggle={toggle('beverageOrderSuccess')}>
        <ModalHeader toggle={toggle('beverageOrderSuccess')}>
          Beverage Order
        </ModalHeader>
        <ModalBody>
          <SuccessMessageWrapper>
            <SuccessMessage>
              <CheckListImage />
              <ThankYouText>Thank you</ThankYouText>
              <MessageText>We will prepared your beverages</MessageText>
            </SuccessMessage>
          </SuccessMessageWrapper>
        </ModalBody>
      </Modal>
      <Modal
        size="md"
        isOpen={modalReleaseOON}
        toggle={toggle('modalReleaseOON')}>
        <ModalReleaseOON />
      </Modal>
      <Modal isOpen={modalAbout} toggle={toggle('modalAbout')}>
        <ModalHeader toggle={toggle('modalAbout')}>About</ModalHeader>
        <ModalBody>
          <SuccessMessageWrapper>
            <SuccessMessage>
              <Logo />
              <MessageText className="mt-3">Version {APP_VERSION}</MessageText>
            </SuccessMessage>
          </SuccessMessageWrapper>
        </ModalBody>
      </Modal>
      <Modal
        size="md"
        isOpen={changePassword.open}
        {...changePassword?.config?.main}
        toggle={toggle('changePassword')}>
        <ModalChangePassword
          headerConfig={changePassword?.config?.header}
          footerConfig={changePassword?.config?.footer}
        />
      </Modal>
      <Modal
        size="md"
        isOpen={forgotPassword.open}
        {...forgotPassword?.config?.main}
        toggle={toggle('forgotPassword')}>
        <ModalForgotPassword
          headerConfig={forgotPassword?.config?.header}
          footerConfig={forgotPassword?.config?.footer}
        />
      </Modal>
    </>
  );
};

export default Global;
