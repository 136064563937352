import {Col, Row} from '../../styles/grid';
import MaterialIcon from '../UIKit/MaterialIcon';
import React, {Component} from 'react';
import styled from 'styled-components';
import {Progress, ListGroupItem} from 'reactstrap';
import {bindActionCreators} from 'redux';
import connect from 'react-redux/es/connect/connect';
import {setSelectedFloorAction} from '../../actions/map';
import {closeModal} from '../../actions/modal';

class HeatMapItem extends Component {
  selectFloor = event => {
    const floor = this.props.item.level;
    this.props.setFloor(floor);
    this.props.closeModal('modalHeatMap');
    if (this.props.onSelected) {
      this.props.onSelected(floor);
    }
    event.preventDefault();
  };

  getColor = () => {
    const {totalPeopleTracker, floor_capacity} = this.props.item;
    const percentage = this.getFloorUsagePercentage();

    let color = '';
    if (totalPeopleTracker !== 0 && floor_capacity !== 0) {
      if (percentage >= 0 && percentage <= 20) {
        color = 'pwc-light-red-2';
      } else if (percentage > 20 && percentage <= 40) {
        color = 'pwc-light-red-1';
      } else if (percentage > 40 && percentage <= 60) {
        color = 'pwc-red';
      } else if (percentage > 60 && percentage <= 80) {
        color = 'pwc-dark-red-1';
      } else {
        color = 'pwc-dark-red-2';
      }
    }
    return color;
  };

  getFloorUsagePercentage = () => {
    const {totalPeopleTracker, floor_capacity} = this.props.item;
    const percent = Math.floor((totalPeopleTracker / floor_capacity) * 100);
    return percent > 100 ? 100 : percent;
  };

  render() {
    const {item, map} = this.props;
    const {floor} = map;
    const {
      totalPoi,
      totalUsed,
      level,
      totalPeopleTracker,
      floor_capacity,
    } = item;
    const capacity = +totalPoi;
    const occupied = +totalUsed;
    const available = +capacity - +occupied;
    const floorUsagePercentage = this.getFloorUsagePercentage();
    const color = this.getColor();
    const isActive = level === floor;

    return (
      <ListGroupItem
        tag="a"
        href="#"
        active={isActive}
        onClick={this.selectFloor}
        action>
        <Row>
          <FloorCol xs={2} sm={2}>
            <div>
              <FloorLabel active={isActive}>Floor</FloorLabel>
              <FloorText>{level}</FloorText>
            </div>
          </FloorCol>
          <Col xs={9} sm={9}>
            <Row>
              <Col>
                <TitleText active={isActive}>Capacity</TitleText>
                <CapacityText active={isActive}>{capacity}</CapacityText>
              </Col>
              <Col>
                <TitleText active={isActive}>Occupied</TitleText>
                <OccupiedText active={isActive}>{occupied}</OccupiedText>
              </Col>
              <Col>
                <TitleText active={isActive}>Available Workspace</TitleText>
                <AvailableText active={isActive}>{available}</AvailableText>
              </Col>
            </Row>
            <Divider active={isActive} />
            <Row>
              <Col>
                <TitleText active={isActive}>Floor Capacity</TitleText>
                <AvailableText active={isActive}>
                  {totalPeopleTracker}/{floor_capacity}
                </AvailableText>
              </Col>
            </Row>
            <StyledProgress color={color} value={floorUsagePercentage} />
          </Col>
          <ColNav xs={1} sm={1}>
            <ChevronButton active={isActive}>
              <MaterialIcon icon="chevron-right" />
            </ChevronButton>
          </ColNav>
        </Row>
      </ListGroupItem>
    );
  }
}

const Divider = styled.hr`
  border-color: ${props => (props.active ? '#fff' : '#dedede')};
`;

const StyledProgress = styled(Progress)`
  margin-top: 1em;
`;

const ColNav = styled(Col)``;

const ChevronButton = styled.span`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &:hover {
    text-decoration: none;
  }

  i {
    font-size: 1.5em;
    color: ${props => (props.active ? '#fff' : 'var(--secondary)')};
  }
`;

const TitleText = styled.div`
  color: ${props => (props.active ? '#fff' : '#a1a1a1')};
`;
const OccupiedText = styled.div`
  color: ${props => (props.active ? '#fff' : '#515151')};
  font-weight: bold;
`;
const CapacityText = styled.div`
  color: ${props => (props.active ? '#fff' : '#515151')};
  font-weight: bold;
`;
const AvailableText = styled.div`
  color: ${props => (props.active ? '#fff' : '#515151')};
  font-weight: bold;
`;
const FloorText = styled.div`
  text-align: center;
  font-size: 18px;
  font-weight: bold;
`;
const FloorLabel = styled.div`
  text-align: center;
  font-size: 18px;
  color: ${props => (props.active ? '#fff' : '#a1a1a1')};
`;
const FloorCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

function mapStateToProps(state) {
  const {map} = state;

  return {map};
}

function mapDispatchToProps(dispatch) {
  return {
    closeModal: bindActionCreators(closeModal, dispatch),
    setFloor: bindActionCreators(setSelectedFloorAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HeatMapItem);
