import {PoiApi} from '../utils/api';

export default {
  /**
   * Get all floor.
   *
   * @param page
   * @param limit
   * @param perPage
   * @param sortBy
   * @param key
   * @param descending
   * @returns {*}
   */
  fetch({
    page = 1,
    limit = 1000,
    perPage = 1000,
    sortBy = 'name',
    descending = 'true',
    key = '',
  }) {
    return PoiApi.get(`/v1/floor`, {
      params: {page, limit, perPage, sortBy, key, descending},
    })
      .then(response => (response || {}).data)
      .catch(err => err && (err.response || {}).data);
  },

  /**
   * Get POI By floor ID.
   *
   * @param id
   * @param page
   * @param key
   * @param perPage
   * @returns {Promise<AxiosResponse<any>>}
   */
  getPoiByFloor({id, page = 1, key, perPage = 10}) {
    return PoiApi.get(`/v1/poi/by/floor/${id}`, {
      params: {page, key, perPage, limit: perPage},
    })
      .then(response => (response || {}).data)
      .catch(err => err && (err.response || {}).data);
  },

  /**
   * Get Floors for Gantt Chart.
   *
   * @returns {Promise<AxiosResponse<any> | never>}
   */
  getForGanttChart() {
    return PoiApi.get(`/v1/floor/ganttchart`)
      .then(response => (response || {}).data)
      .catch(err => err && (err.response || {}).data);
  },
};
