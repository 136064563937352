import {
  SET_PROFILE,
  UPDATING,
  UPDATED,
  SET_PROFILE_TO_EDIT,
  GET_PROFILE_FOR_EDIT_FAILED,
  GET_PROFILE_FOR_EDIT_SUCCESS,
  GET_PROFILE_FOR_EDIT_REQUEST,
  EDIT_PROFILE_ACTION,
  REMOVING_ADDITIONAL_EDITOR,
  ADDITIONAL_EDITOR_REMOVED,
  REMOVE_ADDITIONAL_EDITOR_FAILED,
  ADDING_ADDITIONAL_EDITOR,
  ADD_ADDITIONAL_EDITOR_SUCCESS,
  ADD_ADDITIONAL_EDITOR_FAILED,
  ADD_ADDITIONAL_EDITOR_DONE,
  EDIT_PROFILE_SUCCESS,
  REMOVE_CONTACT_PERSON_REQUEST,
  REMOVE_CONTACT_PERSON_SUCCEED,
  REMOVE_CONTACT_PERSON_FAILED,
  ADD_CONTACT_PERSON_REQUEST,
  ADD_CONTACT_PERSON_SUCCEED,
  ADD_CONTACT_PERSON_FAILED,
  AUTH_OUT_SUCCESS,
} from '../actions/actionsList';
import update from 'immutability-helper';

let initialState = {
  profileData: null,
  updating: false,
  isEditedByEditor: false,
  profileToEdit: null,
  profileUserId: null,
  isLoadingProfile: false,
  profileUpdated: false,
  removingAdditionalEditor: null,
  additionalEditorRemoved: false,
  addingAdditionalEditor: false,
  additionalEditorAdded: false,
  removingContactPerson: null,
  contactPersonRemoved: false,
  addingContactPerson: false,
  contactPersonAdded: false,
};

export default function(state = initialState, action) {
  const {type, payload} = action;

  switch (type) {
    case AUTH_OUT_SUCCESS: {
      return {...initialState};
    }

    case UPDATING:
      return {...state, updating: true};

    case UPDATED:
      return {...state, updating: false};

    case SET_PROFILE:
      const {profile} = payload;
      return {...state, profileData: profile};

    case SET_PROFILE_TO_EDIT:
      const {profileData, isEditedByEditor} = payload;
      const profileToEdit = {
        additionalEditorId: profileData.id,
        mobile_phone: profileData.mobile_phone,
        wa_numbers: profileData.wa_numbers,
        contact_person: profileData.contact_person,
        message: profileData.message,
        avatar: profileData.avatar,
        nick_name: profileData.nick_name,
      };
      return {...state, profileToEdit, isEditedByEditor: !!isEditedByEditor};

    case GET_PROFILE_FOR_EDIT_REQUEST: {
      return {...state, isLoadingProfile: true};
    }

    case GET_PROFILE_FOR_EDIT_SUCCESS: {
      const {data: profileToEdit} = payload;
      return {
        ...state,
        profileToEdit,
        isLoadingProfile: false,
      };
    }

    case GET_PROFILE_FOR_EDIT_FAILED: {
      return {...state, isLoadingProfile: false};
    }

    case EDIT_PROFILE_ACTION: {
      const {id: profileUserId} = payload;
      return {
        ...state,
        profileUserId,
        profileUpdated: false,
      };
    }

    case EDIT_PROFILE_SUCCESS: {
      return {...state, profileUpdated: true};
    }

    case REMOVING_ADDITIONAL_EDITOR:
      const {id} = payload;
      return {...state, removingAdditionalEditor: id};

    case ADDITIONAL_EDITOR_REMOVED: {
      const id = payload.id;
      const index = state.profileToEdit.additional_editor.findIndex(
        item => item.id === id
      );
      if (index > -1) {
        return update(state, {
          removingAdditionalEditor: {$set: null},
          additionalEditorRemoved: {$set: true},
          profileToEdit: {
            additional_editor: {$splice: [[index, 1]]},
          },
        });
      }

      return {
        ...state,
        removingAdditionalEditor: null,
        additionalEditorRemoved: true,
      };
    }

    case REMOVE_ADDITIONAL_EDITOR_FAILED:
      return {
        ...state,
        removingAdditionalEditor: null,
        additionalEditorRemoved: false,
      };

    case ADDING_ADDITIONAL_EDITOR:
      return {
        ...state,
        addingAdditionalEditor: true,
      };

    case ADD_ADDITIONAL_EDITOR_SUCCESS: {
      const {data} = payload;
      return update(state, {
        addingAdditionalEditor: {$set: false},
        additionalEditorAdded: {$set: true},
        profileToEdit: {
          additional_editor: {$push: [data]},
        },
      });
    }

    case ADD_ADDITIONAL_EDITOR_FAILED:
      return {
        ...state,
        addingAdditionalEditor: false,
        additionalEditorAdded: false,
      };

    case ADD_ADDITIONAL_EDITOR_DONE:
      return {
        ...state,
        addingAdditionalEditor: false,
        additionalEditorAdded: false,
      };

    case REMOVE_CONTACT_PERSON_REQUEST: {
      const {contactPersonId} = payload;
      return {...state, removingContactPerson: contactPersonId};
    }

    case REMOVE_CONTACT_PERSON_SUCCEED: {
      const {contactPersonId, userId} = payload;
      const index = state.profileToEdit.contact_person.findIndex(
        item =>
          item.contact_person === contactPersonId && item.users_id === userId
      );
      if (index > -1) {
        return update(state, {
          removingContactPerson: {$set: null},
          contactPersonRemoved: {$set: true},
          profileToEdit: {
            contact_person: {$splice: [[index, 1]]},
          },
        });
      }

      return {
        ...state,
        removingContactPerson: null,
        contactPersonRemoved: true,
      };
    }

    case REMOVE_CONTACT_PERSON_FAILED:
      return {
        ...state,
        removingContactPerson: null,
        contactPersonRemoved: false,
      };

    case ADD_CONTACT_PERSON_REQUEST:
      return {
        ...state,
        addingContactPerson: true,
      };

    case ADD_CONTACT_PERSON_SUCCEED: {
      const {data} = payload;
      return update(state, {
        addingContactPerson: {$set: false},
        contactPersonAdded: {$set: true},
        profileToEdit: {
          contact_person: {$push: [data]},
        },
      });
    }

    case ADD_CONTACT_PERSON_FAILED:
      return {
        ...state,
        addingContactPerson: false,
        contactPersonAdded: false,
      };

    default:
      return state;
  }
}
