import React, {Component, Fragment} from 'react';
import styled from 'styled-components';
import {AsyncTypeahead} from 'react-bootstrap-typeahead';
import {TextError} from '../../styles';

class TypeaheadField extends Component {
  state = {
    selected: null,
  };

  handleChange = selected => {
    this.setState({selected});
    const {valueKey, valueFormatter} = this.props;
    const typeahead = this.typeahead;
    const instance = typeahead ? typeahead.getInstance() : null;
    // console.log({typeahead, instance});
    if (selected.length) {
      let value = selected[0];
      if (valueKey) {
        value = value[valueKey];
      }
      if (valueFormatter) {
        value = valueFormatter(value);
      }
      this.props.input.onChange(value);
      this.handleSelected(value, selected, instance);
    } else {
      this.props.input.onChange('');
      this.handleSelected('', selected, instance);
      if (instance) {
        instance.clear();
      }
    }
  };

  handleSelected = (value, selected, instance) => {
    const {onSelected} = this.props;
    if (onSelected) {
      onSelected(value, selected, instance);
    }
  };

  // onInputChanged = value => {
  //   this.props.input.onChange(value);
  // };

  render() {
    const {selected} = this.state;
    const {
      input,
      meta: {touched, error},
      ...rest
    } = this.props;

    return (
      <Fragment>
        <StyledAsyncTypeahead
          selected={selected}
          ref={typeahead => (this.typeahead = typeahead)}
          onChange={this.handleChange}
          // onInputChange={this.onInputChanged}
          {...rest}
        />
        {touched && error && <TextError>{error}</TextError>}
      </Fragment>
    );
  }
}

const StyledAsyncTypeahead = styled(AsyncTypeahead)`
  input:focus {
    color: #495057 !important;
    background-color: #fff !important;
    border-color: #e0301e !important;
    outline: 0 !important;
    box-shadow: 0 0 0 0.2rem rgba(224, 48, 30, 0.56) !important;
  }
`;

export default TypeaheadField;
