import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import styled, {createGlobalStyle} from 'styled-components';
import Loadable from 'react-loadable';

import {
  CenteredContent,
  Container,
  ContentHeader,
  MainContent,
} from '../../styles';
import {media} from '../../styles/grid';
import {PageHeading, SubHeading} from '../../styles/typography';

import {bindActionCreators} from 'redux';
import LoadingComponent from '../../components/UIKit/MaterialSpinner';
import Spinner from '../../components/UIKit/Spinner';
import Pagination from '../../components/Pagination/Pagination';
import UserCard from '../../components/Users/UserCard';
import UserDetail from '../../components/Users/UserDetail';
import RecentSearch from '../../utils/RecentSearch';
import ApplicationLayout from '../../components/Layouts/ApplicationLayout';
import {dump, isNonStaff} from '../../utils/helpers';
import {clearPagination} from '../../actions/global';
import {Modal, Row, Col, Alert} from 'reactstrap';
import {openModal, toggleModal} from '../../actions/modal';
import {
  clearRecentSearchRequest,
  clearUserRequest,
  detailUserRequest,
  emptyUserDataRequest,
  loadRecentSearchRequest,
  searchUsersRequest,
} from '../../actions/user';
import MonitorUserInOffice from '../../components/Users/MonitorUserInOffice/MonitorUserInOffice';
import HomepageAnnouncement from '../../components/Users/HomepageAnnouncement/HomepageAnnouncement';

const SearchForm = Loadable({
  loader: () => import('../../components/SearchForm'),
  loading: LoadingComponent,
});

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      searching: false,
      recentSearch: false,
      keyword: '',
      page: 1,
    };
  }

  componentDidMount() {
    const {loadRecentSearch, clearPagination} = this.props;
    loadRecentSearch();
    clearPagination();
    if (RecentSearch.notEmpty()) {
      this.setState({recentSearch: true});
    }
  }

  searchPeople = ({keyword, page, perPage}) => {
    if (!keyword) {
      keyword = this.state.keyword;
    }
    if (!page) {
      page = this.state.page;
    }
    const {searchPeopleRequest} = this.props;
    searchPeopleRequest({
      keyword,
      page,
      perPage,
      sortBy: 'name',
      descending: false,
    });
    this.setState({
      recentSearch: false,
      searching: true,
      keyword,
      page,
    });
  };

  onModalClosed = () => {
    this.setState({showModal: false});
    const {clearPeopleRequest} = this.props;
    clearPeopleRequest();
  };

  search = values => {
    if (!values) return;
    const keyword = values.keyword.trim();
    if (keyword && keyword.length > 2) {
      const page = 1;
      this.setState({keyword, page});
      this.searchPeople({keyword, page});
    }
  };

  viewDetail = item => {
    const profile = item.recentSearch ? item.profile : item;
    const {detailPeopleRequest} = this.props;
    detailPeopleRequest(profile.id);
    // this.setState({
    //   showModal: true,
    // });
    this.props.openModal('detailUser');
  };

  handlePagination = page => {
    this.searchPeople({page});
  };

  getSearchTitle = () => {
    const {recentSearch, searching, keyword} = this.state;
    let searchTitle = null;
    if (recentSearch) {
      searchTitle = `Recently Searched`;
    } else if (searching) {
      searchTitle = `Search Result: "${keyword}"`;
    }
    return searchTitle;
  };

  clearRecentSearch = event => {
    if (event) {
      event.preventDefault();
    }
    const {clearRecentSearch} = this.props;
    clearRecentSearch();
    this.setState({
      recentSearch: false,
      searching: false,
    });
  };

  toggleModal = () => {
    this.props.toggleModal('detailUser');
  };

  onSearchEmpty = () => {
    const {emptyPeopleData} = this.props;
    emptyPeopleData();
    this.setState({recentSearch: false, searching: false});
  };

  render() {
    const {
      search,
      viewDetail,
      onModalClosed,
      handlePagination,
      getSearchTitle,
      clearRecentSearch,
      onSearchEmpty,
    } = this;
    const {user, loading, pagination, modal, loadingUserDetail} = this.props;
    const {users, user: detailPeople} = user;
    const {searching, recentSearch, keyword, page: currentPage} = this.state;
    let showPagination =
      Object.keys(pagination).length > 0 &&
      pagination &&
      pagination.last_page > 1;
    let searchTitle = getSearchTitle();
    const userIsEmpty = users.length < 1;
    const profile = detailPeople ? detailPeople.profile : null;
    const nonStaff = isNonStaff(profile);
    const modalTitle = nonStaff ? profile.name : 'Users Details';
    const {detailUser: showModal} = modal;

    return (
      <Fragment>
        <Modal
          size="lg"
          toggle={this.toggleModal}
          isOpen={showModal}
          onClosed={onModalClosed}>
          <UserDetail
            user={detailPeople}
            loading={loadingUserDetail}
            title={modalTitle}
          />
        </Modal>
        <ApplicationLayout withoutFooter>
          <ContentHeader>
            <Container>
              <Row>
                <LeftSide>
                  <PageHeading>
                    <PeopleHeading>People Directory</PeopleHeading>
                  </PageHeading>
                </LeftSide>
                <RightSide>
                  <SearchFormCustom>
                    <SearchForm
                      onEmpty={onSearchEmpty}
                      onSubmit={search}
                      placeholder="Search Staff..."
                      timeout={500}
                      loading={loading}
                      searching={searching}
                    />
                  </SearchFormCustom>
                </RightSide>
              </Row>
            </Container>
          </ContentHeader>
          <MainContent>
            <Container>
              <HomepageAnnouncement />

              <MonitorUserInOffice data-qa={'sect-office-monitoring'} />

              {userIsEmpty && !loading && !searching && (
                <EmptyTextWrapper>
                  <EmptyTextBody>
                    <SearchIconWrapper>
                      <SearchIcon />
                    </SearchIconWrapper>
                    <EmptyText>Search PwC Staff on People Directory</EmptyText>
                  </EmptyTextBody>
                </EmptyTextWrapper>
              )}
              {userIsEmpty && !loading && searching && (
                <EmptyTextWrapper>
                  <EmptyTextBody>
                    <SearchIconWrapper>
                      <SearchIcon />
                    </SearchIconWrapper>
                    <EmptyText>No result for "{keyword}"</EmptyText>
                  </EmptyTextBody>
                </EmptyTextWrapper>
              )}
              {searchTitle && !userIsEmpty && (
                <SubHeadingTitle>
                  {searchTitle}
                  {recentSearch && (
                    <ClearRecentSearchButton onClick={clearRecentSearch}>
                      Clear
                    </ClearRecentSearchButton>
                  )}
                </SubHeadingTitle>
              )}
              {loading ? (
                <CenteredContent>
                  <Spinner />
                </CenteredContent>
              ) : (
                <Row>
                  {users.map((item, index) => {
                    return (
                      <Col
                        className="mb-2"
                        sm={6}
                        md={6}
                        lg={4}
                        xl={4}
                        key={`user-${index}`}>
                        <UserCard user={item} onClickHandler={viewDetail} />
                      </Col>
                    );
                  })}
                </Row>
              )}
              {showPagination && !recentSearch && (
                <Pagination
                  currentPage={currentPage}
                  onChange={handlePagination}
                  totalPage={pagination.last_page}
                />
              )}
            </Container>
          </MainContent>
        </ApplicationLayout>
        <CreateGlobalStyle />
      </Fragment>
    );
  }
}

const CreateGlobalStyle = createGlobalStyle`
  body {
    background-color: #f8f8f8!important;
  }
`;

// const Wrapper = styled.div``;
const ClearRecentSearchButton = styled.a.attrs({
  href: '#',
})`
  margin-left: 11px;
  font-size: 14px;
  position: relative;
  top: -1px;
  color: #e40000 !important;
`;

export const LeftSide = styled(Col).attrs({
  xs: 12,
  sm: 12,
  md: 6,
})``;

export const RightSide = styled(Col).attrs({
  xs: 12,
  sm: 12,
  md: 6,
})`
  display: flex;
  justify-content: flex-end;

  ${media.xs`
    justify-content: flex-start;
  `};
`;

const PeopleHeading = styled.div`
  margin-top: 10px;
  font-size: 20px;
  font-weight: bold;
`;

const SearchFormCustom = styled.div`
  margin-top: 8px;
  font-size: 16px;
`;

const EmptyTextWrapper = styled.div`
  text-align: center;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SearchIconWrapper = styled.div`
  display: block;
  width: 100%;
  margin-bottom: 10px;
`;

const SearchIcon = styled.input.attrs({
  src: '/assets/images/search.png',
  alt: 'Search',
  type: 'image',
})``;

const EmptyTextBody = styled.div``;

const EmptyText = styled.div``;

const SubHeadingTitle = styled(SubHeading)`
  margin-bottom: 10px;
  margin-top: 10px;
`;

function mapStateToProps(state) {
  const {global, modal} = state;
  const {pagination} = global;
  const {searching: loading, loadingUserDetail} = state.user;

  return {
    user: state.user,
    loading,
    pagination,
    modal,
    loadingUserDetail,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    searchPeopleRequest: bindActionCreators(searchUsersRequest, dispatch),
    detailPeopleRequest: bindActionCreators(detailUserRequest, dispatch),
    clearPeopleRequest: bindActionCreators(clearUserRequest, dispatch),
    clearRecentSearch: bindActionCreators(clearRecentSearchRequest, dispatch),
    loadRecentSearch: bindActionCreators(loadRecentSearchRequest, dispatch),
    emptyPeopleData: bindActionCreators(emptyUserDataRequest, dispatch),
    clearPagination: bindActionCreators(clearPagination, dispatch),
    openModal: bindActionCreators(openModal, dispatch),
    toggleModal: bindActionCreators(toggleModal, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Users);
