import React, {Component} from 'react';
import {Row} from 'reactstrap';
import {FlexCol, PostWrapper} from '../../styles';
import PostCard from './PostCard';
import PropTypes from 'prop-types';

class PostGrid extends Component {
  render() {
    const {posts, external} = this.props;

    return (
      <PostWrapper>
        <Row>
          {posts.map(post => (
            <FlexCol xs={12} sm={6} key={post.id}>
              <PostCard post={post} isExternal={external} />
            </FlexCol>
          ))}
        </Row>
      </PostWrapper>
    );
  }
}

PostGrid.propTypes = {
  posts: PropTypes.array,
  external: PropTypes.bool,
};

PostGrid.defaultProps = {
  posts: [],
  external: false,
};

export default PostGrid;
