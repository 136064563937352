import React, {Component} from 'react';
import {TextError} from '../../styles';
import {FormLabel} from '../Profile/edit';
import MeetingRoomCard from '../Reservations/MeetingRoomCard';
import {FormGroup} from '../Profile/FormSubmitOON';
import {Button, Col, Row} from 'reactstrap';
import {connect} from 'react-redux';
import CurrentBooking from '../../utils/CurrentBooking';
import {bindActionCreators} from 'redux';
import {
  selectMeetingRoom,
  setMeetingRoomParams,
} from '../../actions/reservation';
import {openModal} from '../../actions/modal';
import {toast} from '../../utils/helpers';
import config from '../../config';

class MeetingRoomField extends Component {
  openSearchMeetingRoomModal = () => {
    const {
      openModal,
      formData,
      setMeetingRoomParams,
      reservationType: type,
      isClusterAdmin,
      isWorkspace,
    } = this.props;

    let error;
    let hasMeetingDate = formData.meeting_date;
    let hasCheckIn = formData.check_in || hasMeetingDate;
    let hasCheckInRelease = formData.check_in_release || hasMeetingDate;
    let isSingleBooking = type === 'single';
    let isProjectBooking = type === 'project';

    if (!hasCheckIn) {
      error = 'Please choose start date first';
    }
    if (!hasCheckInRelease) {
      error = 'Please choose end date first';
    }
    if (!hasMeetingDate) {
      error = 'Please choose meeting date first';
    }

    if (isProjectBooking) {
      if (!formData.check_in_release) {
        error = 'Please choose end date first';
      }
    }

    if (error) {
      toast({title: error, type: 'error'});
      return;
    }

    if (!hasCheckIn || !hasCheckInRelease) {
      this.setState({error});
    } else {
      let {
        start_time,
        end_time,
        check_in,
        check_in_release,
        meetingRoomSearch: key,
        number_of_participant,
        conference_type: video_conference,
        need_conference,
        meeting_type,
      } = formData;

      if (isSingleBooking) {
        check_in = hasMeetingDate;
        check_in_release = hasMeetingDate;
      }

      const params = {
        start_time,
        end_time,
        check_in,
        check_in_release,
        key,
        total_attendance: number_of_participant,
        book_platform: 'web',
        isClusterAdmin,
        is_workspace: isWorkspace || (type === 'single' ? 0 : 1),
        reservation_type: type,
        need_conference,
        meeting_type,
      };

      if (need_conference) {
        params.video_conference = video_conference;
      }

      if (config.DEBUG) {
        console.log('MEETING ROOM PARAMS', params);
      }

      setMeetingRoomParams(params);
      this.setState({error: null});
      openModal('searchMeetingRoom');
    }
  };

  componentWillReceiveProps(props) {
    const {selectedMeetingRoom} = props;
    const id = selectedMeetingRoom
      ? selectedMeetingRoom.id
      : selectedMeetingRoom;
    this.props.input.onChange(id);
  }

  render() {
    const {
      meta: {touched, error},
      selectedMeetingRoom,
      withoutClear,
      noMargin,
    } = this.props;

    return (
      <FormGroup>
        <FormLabel>Meeting Room</FormLabel>
        {selectedMeetingRoom !== null && (
          <Row>
            <Col xs={12}>
              <MeetingRoomCard
                item={selectedMeetingRoom}
                change={this.openSearchMeetingRoomModal}
                withoutClear={withoutClear}
                noMargin={noMargin}
              />
            </Col>
          </Row>
        )}
        {!selectedMeetingRoom && (
          <Row>
            {/*<Col xs={12} sm={6}>*/}
            {/*<FormGroup>*/}
            {/*<TextField*/}
            {/*component="input"*/}
            {/*name="meetingRoomSearch"*/}
            {/*placeholder="Search..."*/}
            {/*/>*/}
            {/*</FormGroup>*/}
            {/*</Col>*/}
            <Col xs={12} sm={6}>
              <Button
                onClick={this.openSearchMeetingRoomModal}
                type="button"
                color="primary">
                Search a Meeting Room
              </Button>
            </Col>
          </Row>
        )}
        <Row>
          <Col>{touched && error && <TextError>{error}</TextError>}</Col>
        </Row>
      </FormGroup>
    );
  }
}

function mapStateToProps(state) {
  let {selectedMeetingRoom} = state.reservation;
  const currentBooking = CurrentBooking.fetch();

  if (currentBooking && currentBooking.meetingRoom) {
    selectedMeetingRoom = currentBooking.meetingRoom;
  }

  return {
    selectedMeetingRoom,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    openModal: bindActionCreators(openModal, dispatch),
    setMeetingRoomParams: bindActionCreators(setMeetingRoomParams, dispatch),
    selectMeetingRoom: bindActionCreators(selectMeetingRoom, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MeetingRoomField);
