import React, {Component} from 'react';
import {
  Col,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalHeader,
  Progress,
  Row,
} from 'reactstrap';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {toggleModal} from '../../actions/modal';
import styled from 'styled-components';

class ModalMapInfo extends Component {
  toggle = () => {
    this.props.toggleModal('modalMapInfo');
  };

  getItems = () => {
    return [
      {
        color: 'pwc-light-red-2',
        min: 0,
        max: 20,
      },
      {
        color: 'pwc-light-red-1',
        min: 20,
        max: 40,
      },
      {
        color: 'pwc-red',
        min: 40,
        max: 60,
      },
      {
        color: 'pwc-dark-red-1',
        min: 60,
        max: 80,
      },
      {
        color: 'pwc-dark-red-2',
        min: 80,
        max: 100,
      },
    ];
  };

  render() {
    const {modalMapInfo} = this.props.modal;
    const items = this.getItems();

    return (
      <Modal centered size="lg" isOpen={modalMapInfo} toggle={this.toggle}>
        <ModalHeader toggle={this.toggle}>Floor Capacity Legend</ModalHeader>
        <StyledModalBody>
          <ListGroup>
            {items.map((item, index) => (
              <ListGroupItem key={index}>
                <Row className="align-items-center">
                  <Col xs={2}>
                    <Progress value="100" max="100" color={item.color} />
                  </Col>
                  <Col>
                    {item.min} - {item.max} % of Floor Capacity
                  </Col>
                </Row>
              </ListGroupItem>
            ))}
          </ListGroup>
        </StyledModalBody>
      </Modal>
    );
  }
}

const StyledModalBody = styled(ModalBody)`
  padding: 10px 1em;
`;

function mapStateToProps(state) {
  const {modal} = state;

  return {modal};
}

function mapDispatchToProps(dispatch) {
  return {
    toggleModal: bindActionCreators(toggleModal, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalMapInfo);
