import {call, put, takeEvery} from 'redux-saga/effects';
import {
  APPROVE_RESERVATION_BY_CLUSTER_ADMIN_REQUEST,
  APPROVE_RESERVATION_REQUEST,
  APPROVED,
  APPROVING,
  BOOK_GROUP_RESERVATION_CLUSTER_ADMIN_REQUEST,
  BOOK_RESERVATION_CLUSTER_ADMIN_REQUEST,
  BOOK_RESERVATION_REQUEST,
  BOOKING_SUCCESS,
  CANCEL_RESERVATION_REQUEST,
  CANCELED,
  CANCELING,
  FIND_MEETING_ROOM_CLUSTER_ADMIN_REQUEST,
  FIND_MEETING_ROOM_REQUEST,
  FIND_ROOM_WITH_QRCODE_REQUEST,
  GET_MY_CLUSTER_ADMIN_ACTION,
  GET_MY_CLUSTER_ADMIN_FAILED,
  GET_MY_CLUSTER_ADMIN_REQUEST,
  GET_MY_CLUSTER_ADMIN_SUCCESS,
  GET_MY_CLUSTER_SIC_ACTION,
  GET_MY_CLUSTER_SIC_FAILED,
  GET_MY_CLUSTER_SIC_REQUEST,
  GET_MY_CLUSTER_SIC_SUCCESS,
  GET_RESERVATION_CLUSTER_ADMIN_REQUEST,
  GET_RESERVATION_CLUSTER_SIC_REQUEST,
  GET_RESERVATION_DETAIL_ACTION,
  GET_RESERVATION_LIST_APPROVAL_REQUEST,
  GET_RESERVATION_LIST_BY_DATE_REQUEST,
  GET_RESERVATION_LIST_FOR_LIASON_OFFICER_REQUEST,
  GET_RESERVATION_LIST_FOR_RECEPTIONIST_REQUEST,
  GET_RESERVATION_LIST_REQUEST,
  LOADED,
  LOADING,
  REJECT_RESERVATION_BY_CLUSTER_ADMIN_REQUEST,
  REJECT_RESERVATION_REQUEST,
  REJECTED,
  REJECTING,
  RELEASE_RESERVATION_REQUEST,
  RELEASED,
  RELEASING,
  RESCHEDULE_RESERVATION_ACTION,
  RESCHEDULE_RESERVATION_DONE,
  RESCHEDULE_RESERVATION_FAILED,
  RESCHEDULE_RESERVATION_REQUEST,
  RESCHEDULE_RESERVATION_SUCCESS,
  SET_MEETING_ROOMS,
  SET_PAGINATION,
  SET_RESERVATION,
  SUBMITTED,
  SUBMITTING,
  UPDATE_RESERVATION_DONE,
  UPDATE_RESERVATION_FAILED,
  UPDATE_RESERVATION_REQUEST,
  UPDATE_RESERVATION_SUCCESS,
  UPDATING_RESERVATION,
  FIND_ROOM_REQUEST,
  FIND_ROOM_SUCCEED,
  FIND_ROOM_FAILED,
  FIND_ROOM_CLUSTER_ADMIN_REQUEST,
  FIND_ROOM_CLUSTER_ADMIN_SUCCEED,
  FIND_ROOM_CLUSTER_ADMIN_FAILED,
  GET_RESERVATION_DETAIL_REQUEST,
  GET_RESERVATION_DETAIL_SUCCEED,
  GET_RESERVATION_DETAIL_FAILED,
  GET_RESERVATION_APPROVAL_REQUESTS_SUCCEED,
  GET_RESERVATION_APPROVAL_REQUESTS_REQUEST,
  GET_RESERVATION_APPROVAL_REQUESTS_FAILED,
  GET_MY_RESERVATIONS_REQUEST,
  GET_MY_RESERVATIONS_SUCCEED,
  GET_MY_RESERVATIONS_FAILED,
  GET_RESERVATION_CLUSTER_ADMIN_ACTION,
  GET_RESERVATION_CLUSTER_ADMIN_SUCCEED,
  GET_RESERVATION_CLUSTER_ADMIN_FAILED,
  GET_RESERVATION_CLUSTER_SIC_ACTION,
  GET_RESERVATION_CLUSTER_SIC_SUCCEED,
  GET_RESERVATION_CLUSTER_SIC_FAILED,
  GET_RESERVATION_RECEPTIONIST_REQUEST,
  GET_RESERVATION_RECEPTIONIST_SUCCEED,
  GET_RESERVATION_RECEPTIONIST_FAILED,
  GET_RESERVATION_LIASON_OFFICER_REQUEST,
  GET_RESERVATION_LIASON_OFFICER_SUCCEED,
  GET_RESERVATION_LIASON_OFFICER_FAILED,
  ADD_GUEST_ACTION,
  ADD_GUEST_SUCCEED,
  ADD_GUEST_FAILED,
  ADD_GUEST_REQUEST,
  GET_RESERVATION_BY_POI_ACTION,
  GET_RESERVATION_BY_POI_REQUEST,
  GET_RESERVATION_BY_POI_SUCCEED,
  GET_RESERVATION_BY_POI_FAILED,
  BOOK_RESERVATION_WFO_REQUEST,
  GET_WFO_RESERVATION_LIST,
  SET_WFO_RESERVATION_LIST,
  CANCEL_WFO_RESERVATION_REQUEST,
  WFO_CANCELED,
  WFO_CREATED,
  REJECT_RESERVATION_BY_CLUSTER_SIC_REQUEST,
  APPROVE_RESERVATION_BY_CLUSTER_SIC_REQUEST,
} from '../actions/actionsList';
import {closeModal, openModal} from '../actions/modal';
import swal from 'sweetalert2';
import Reservation from '../api/Reservation';
import config from '../config';
import {dump, getCurrentUser, toast} from '../utils/helpers';
import {setGlobalError} from '../actions/global';

/**
 * Action handler for getting WFO reservation list.
 *
 * @returns {IterableIterator<*>}
 */
export function* getWFOList(action) {
  const {payload} = action;

  const user = getCurrentUser() || {};

  const params = {
    ...(payload || {}),
    id_user: (user || {}).id,
  };

  const responseData = yield call(Reservation.getWFOList, params);

  const {code, data, message, error} = responseData || {};
  const {content, pagginate: paginate} = data || {};

  if (code === 200 && !error) {
    yield put({
      type: SET_WFO_RESERVATION_LIST,
      payload: {
        data: content,
        total: paginate.total,
      },
    });
  } else {
    yield put({
      type: SET_WFO_RESERVATION_LIST,
      payload: {
        error: message || error,
      },
    });
  }
}

/**
 * Action handler for getting reservation list.
 *
 * @returns {IterableIterator<*>}
 */
function* getReservationListActionHandler(action) {
  const {payload} = action;

  yield put({type: GET_MY_RESERVATIONS_REQUEST});

  const {page, perPage, sortBy, keyword, descending, withPaginate} =
    payload || {};
  const responseData = yield call(Reservation.getList, {
    page,
    perPage,
    sortBy,
    keyword,
    descending,
    withPaginate,
  });
  const {code, data, message} = responseData || {};
  const {content, pagginate} = data || {};

  if (code === 200) {
    yield put({
      type: GET_MY_RESERVATIONS_SUCCEED,
      payload: {reservations: content},
    });
    yield put({type: SET_PAGINATION, payload: {pagination: pagginate}});
  } else {
    yield put({type: GET_MY_RESERVATIONS_FAILED});
    yield put(setGlobalError(message));
  }
}

/**
 * Get list of reservation for admin (receptionist).
 *
 * @param action
 * @returns {IterableIterator<*>}
 */
function* getReservationListForReceptionistActionHandler(action) {
  const {payload} = action;

  yield put({type: GET_RESERVATION_RECEPTIONIST_REQUEST});

  const {page, perPage, sortBy, key, descending} = payload || {};
  const responseData = yield call(Reservation.getListForReceptionist, {
    page,
    perPage,
    sortBy,
    key,
    descending,
  });
  const {code, data, message} = responseData || {};
  const {content, pagginate} = data || {};
  if (code === 200) {
    yield put({
      type: GET_RESERVATION_RECEPTIONIST_SUCCEED,
      payload: {reservations: content},
    });
    yield put({type: SET_PAGINATION, payload: {pagination: pagginate}});
  } else {
    yield put({type: GET_RESERVATION_RECEPTIONIST_FAILED});
    yield put(setGlobalError(message));
  }
}

/**
 * Action handler for getting reservation cluster admin.
 *
 * @returns {IterableIterator<*>}
 */
function* getReservationClusterAdminActionHandler(action) {
  const {payload} = action;

  yield put({type: GET_RESERVATION_CLUSTER_ADMIN_REQUEST});

  const {page, perPage, sortBy, keyword, descending, clusterId, status} =
    payload || {};
  const responseData = yield call(Reservation.getListForClusterAdmin, {
    page,
    perPage,
    sortBy,
    keyword,
    descending,
    clusterId,
    status,
  });
  const {code, data, message} = responseData || {};
  if (code === 200) {
    const {content, pagginate} = data || {};
    yield put({
      type: GET_RESERVATION_CLUSTER_ADMIN_SUCCEED,
      payload: {reservations: content},
    });
    yield put({type: SET_PAGINATION, payload: {pagination: pagginate}});
  } else {
    yield put({type: GET_RESERVATION_CLUSTER_ADMIN_FAILED});
    yield put(setGlobalError(message));
  }
}

/**
 * Action handler for getting reservation cluster admin.
 *
 * @returns {IterableIterator<*>}
 */
function* getReservationClusterSICActionHandler(action) {
  const {payload} = action;

  yield put({type: GET_RESERVATION_CLUSTER_SIC_REQUEST});

  const {page, perPage, sortBy, keyword, descending, clusterId, status} =
    payload || {};
  const responseData = yield call(Reservation.getListForClusterSIC, {
    page,
    perPage,
    sortBy,
    keyword,
    descending,
    clusterId,
    status,
  });
  const {code, data, message} = responseData || {};
  if (code === 200) {
    const {content, pagginate} = data || {};
    yield put({
      type: GET_RESERVATION_CLUSTER_SIC_SUCCEED,
      payload: {reservations: content},
    });
    yield put({type: SET_PAGINATION, payload: {pagination: pagginate}});
  } else {
    yield put({type: GET_RESERVATION_CLUSTER_SIC_FAILED});
    yield put(setGlobalError(message));
  }
}

function* getReservationDetailActionHandler(action) {
  const {payload} = action;

  yield put({type: GET_RESERVATION_DETAIL_REQUEST});
  const {id} = payload || {};
  const responseData = yield call(Reservation.getDetail, id);
  const {code, data, message} = responseData || {};
  if (code === 200) {
    const hasData = Object.keys(data).length > 0;
    yield put({type: GET_RESERVATION_DETAIL_SUCCEED});
    if (hasData) {
      yield put({type: SET_RESERVATION, payload: {reservation: data}});
    }
  } else {
    yield put({type: GET_RESERVATION_DETAIL_FAILED});
    yield put(setGlobalError(message));
  }
}

function* findRoomActionHandler(action) {
  const {payload} = action;

  yield put({type: FIND_ROOM_REQUEST});
  const {
    start_time,
    end_time,
    check_in,
    check_in_release,
    booking_type,
    total_attendance,
    book_platform,
    key,
    reservation_type,
    video_conference,
    meeting_type,
  } = payload || {};
  const responseData = yield call(Reservation.findRoom, {
    start_time,
    end_time,
    check_in,
    check_in_release,
    booking_type,
    total_attendance,
    book_platform,
    key,
    reservation_type,
    video_conference,
    meeting_type,
  });
  const {code, data, message} = responseData || {};
  if (code === 200) {
    yield put({type: FIND_ROOM_SUCCEED});
    yield put({type: SET_MEETING_ROOMS, payload: {rooms: data}});
  } else {
    yield put({type: FIND_ROOM_FAILED});
    // yield put(setGlobalError(message));
    toast({title: message, type: 'error'});
  }
}

function* findRoomWithQRCodeActionHandler(action) {
  const {payload} = action;

  try {
    yield put({type: LOADING});
    const {
      start_time,
      end_time,
      check_in,
      check_in_release,
      qrcode,
      total_attendance,
      book_platform,
      key,
    } = payload || {};
    const responseData = yield call(Reservation.findRoomWithQRCode, {
      start_time,
      end_time,
      check_in,
      check_in_release,
      qrcode,
      total_attendance,
      book_platform,
      key,
    });
    const {code, data, message} = responseData || {};
    if (code === 200) {
      yield put({type: LOADED});
      yield put({type: SET_MEETING_ROOMS, payload: {rooms: data}});
    } else {
      yield put({type: LOADED});
      yield put(setGlobalError(message));
    }
  } catch (e) {
    yield put(setGlobalError(config.INTERNAL_SERVER_ERROR));
    yield put({type: LOADED});
  }
}

function* bookReservationActionHandler(action) {
  const {payload} = action;

  try {
    yield put({type: SUBMITTING});
    yield put(setGlobalError(null));
    const responseData = yield call(Reservation.create, payload.data);
    const {code, message} = responseData || {};
    if (code === 200) {
      yield put({type: SUBMITTED});
      yield put({type: BOOKING_SUCCESS});
      yield put(openModal('bookingSuccess'));
    } else {
      yield put({type: SUBMITTED});
      yield put(setGlobalError(message));
    }
  } catch (e) {
    yield put(setGlobalError(config.INTERNAL_SERVER_ERROR));
    yield put({type: SUBMITTED});
  }
}

export function* reserveWFO(action) {
  const {payload} = action;

  try {
    yield put(setGlobalError(null));

    const responseData = yield call(Reservation.reserveWFO, payload.data);
    const {code, message, error, data} = responseData || {};

    if (code === 200) {
      yield put({type: WFO_CREATED});
      yield put({type: BOOKING_SUCCESS});
      yield put(
        openModal('success', {
          props: {
            title: 'WFO Reservation',
          },
        })
      );
    } else {
      yield put({
        type: WFO_CREATED,
        payload: {
          error: message || error,
          data,
        },
      });
    }
  } catch (e) {
    const {message, data} = (e && e.response && e.response.data) || e || {};

    yield put({
      type: WFO_CREATED,
      payload: {
        error: message,
        data,
      },
    });
    yield put(setGlobalError(null));
  }
}

function* cancelWFOReserve(action) {
  const {payload} = action;
  const {id} = payload || {};

  try {
    const responseData = yield call(Reservation.cancelWFOReserve, {id});
    const {code, message, data} = responseData || {};

    if (code === 200) {
      yield put({type: WFO_CANCELED, payload: {id, data}});
      swal('Success', 'Reservation successfully cancelled', 'success');
    } else {
      yield put({type: WFO_CANCELED, payload: {id, error: message}});
      swal('Failed', message, 'error');
    }
  } catch (e) {
    const {message} = (e && e.response && e.response.data) || e || {};

    yield put({
      type: WFO_CANCELED,
      payload: {id, error: message || config.INTERNAL_SERVER_ERROR},
    });
    swal('Failed', message, 'error');
  }
}

function* bookReservationForClusterAdminActionHandler(action) {
  const {payload} = action;

  try {
    yield put({type: SUBMITTING});
    const responseData = yield call(
      Reservation.createForClusterAdmin,
      payload.data
    );
    const {code, message} = responseData || {};
    if (code === 200) {
      yield put({type: SUBMITTED});
      yield put({type: BOOKING_SUCCESS});
      yield put(openModal('bookingSuccess'));
    } else {
      yield put({type: SUBMITTED});
      yield put(setGlobalError(message));
    }
  } catch (e) {
    yield put(setGlobalError(config.INTERNAL_SERVER_ERROR));
    yield put({type: SUBMITTED});
  }
}

function* bookGroupReservationClusterAdminActionHandler(action) {
  const {payload} = action;

  yield put({type: SUBMITTING});
  const responseData = yield call(
    Reservation.createGroupReservationClusterAdmin,
    payload.data
  );
  const {code, message} = responseData || {};
  if (code === 200) {
    yield put({type: SUBMITTED});
    yield put({type: BOOKING_SUCCESS});
    yield put(openModal('bookingSuccess'));
  } else {
    yield put({type: SUBMITTED});
    yield put(setGlobalError(message));
  }
}

function* cancelReservationActionHandler(action) {
  const {payload} = action;

  try {
    const {id, remarks, onCancel, key} = payload || {};
    yield put({type: CANCELING, payload: {id}});
    const responseData = yield call(Reservation.cancel, {id, remarks});
    const {code, message, data} = responseData || {};
    if (code === 200) {
      yield put({type: CANCELED, payload: {id, data, key}});
      swal('Success', message, 'success');
      if (onCancel) {
        onCancel();
      }
    } else {
      yield put({type: CANCELED, payload: {}});
      yield put(setGlobalError(message));
      swal('Failed', message, 'error');
    }
  } catch (e) {
    yield put(setGlobalError(config.INTERNAL_SERVER_ERROR));
    yield put({type: CANCELED, payload: {}});
  }
}

function* approveReservationActionHandler(action) {
  const {payload} = action;

  try {
    const {id, onApprove, key} = payload || {};
    yield put({type: APPROVING, payload: {id}});
    const responseData = yield call(Reservation.approve, id);
    const {code, message, data} = responseData || {};
    if (code === 200) {
      yield put({type: APPROVED, payload: {id, data, key}});
      swal('Success', message, 'success');
      if (onApprove) {
        onApprove();
      }
    } else {
      yield put({type: APPROVED, payload: {}});
      yield put(setGlobalError(message));
      swal('Failed', message, 'error');
    }
  } catch (e) {
    yield put(setGlobalError(config.INTERNAL_SERVER_ERROR));
    yield put({type: APPROVED});
  }
}

function* approveByClusterAdminReservationActionHandler(action) {
  const {payload} = action;

  try {
    const {id, onApprove} = payload || {};
    yield put({type: APPROVING, payload: {id}});
    const responseData = yield call(Reservation.approveByClusterAdmin, id);
    const {code, message, data} = responseData || {};
    if (code === 200) {
      yield put({type: APPROVED, payload: {id, data, key: 'clusterAdmins'}});
      swal('Success', message, 'success');
      if (onApprove) {
        onApprove();
      }
    } else {
      yield put({type: APPROVED, payload: {}});
      yield put(setGlobalError(message));
      swal('Failed', message, 'error');
    }
  } catch (e) {
    yield put(setGlobalError(config.INTERNAL_SERVER_ERROR));
    yield put({type: APPROVED, payload: {}});
  }
}

function* approveByClusterSICReservationActionHandler(action) {
  const {payload} = action;

  try {
    const {id, onApprove} = payload || {};
    yield put({type: APPROVING, payload: {id}});
    const responseData = yield call(Reservation.approveByClusterSIC, id);
    const {code, message, data} = responseData || {};
    if (code === 200) {
      yield put({type: APPROVED, payload: {id, data, key: 'clusterSICs'}});
      swal('Success', message, 'success');
      if (onApprove) {
        onApprove();
      }
    } else {
      yield put({type: APPROVED, payload: {}});
      yield put(setGlobalError(message));
      swal('Failed', message, 'error');
    }
  } catch (e) {
    dump({
      funcName: 'approveByClusterSICReservationActionHandler',
      file: 'sagas/reservation.js',
      message: {e},
    });
    yield put(setGlobalError(config.INTERNAL_SERVER_ERROR));
    yield put({type: APPROVED, payload: {}});
  }
}

function* rejectReservationActionHandler(action) {
  const {payload} = action;

  try {
    const {id, remarks, onReject, key} = payload || {};
    yield put({type: REJECTING, payload: {id}});
    const responseData = yield call(Reservation.reject, id, remarks);
    const {code, message, data} = responseData || {};
    if (code === 200) {
      yield put({type: REJECTED, payload: {id, data, key}});
      swal('Success', message, 'success');
      if (onReject) {
        onReject();
      }
    } else {
      yield put({type: REJECTED, payload: {}});
      yield put(setGlobalError(message));
      swal('Failed', message, 'error');
    }
  } catch (e) {
    yield put(setGlobalError(config.INTERNAL_SERVER_ERROR));
    yield put({type: REJECTED, payload: {}});
  }
}

function* rejectByClusterAdminReservationActionHandler(action) {
  const {payload} = action;

  try {
    const {id, remarks, onReject} = payload || {};
    yield put({type: REJECTING, payload: {id}});
    const responseData = yield call(
      Reservation.rejectByClusterAdmin,
      id,
      remarks
    );
    const {code, message, data} = responseData || {};
    if (code === 200) {
      yield put({type: REJECTED, payload: {id, data, key: 'clusterAdmins'}});
      swal('Success', message, 'success');
      if (onReject) {
        onReject();
      }
    } else {
      yield put({type: REJECTED});
      yield put(setGlobalError(message));
      swal('Failed', message, 'error');
    }
  } catch (e) {
    dump({
      funcName: 'rejectByClusterAdminReservationActionHandler',
      file: 'sagas/reservation.js',
      message: {e},
    });
    yield put(setGlobalError(config.INTERNAL_SERVER_ERROR));
    yield put({type: REJECTED});
  }
}

function* rejectByClusterSICReservationActionHandler(action) {
  const {payload} = action;

  try {
    const {id, remarks, onReject} = payload || {};
    yield put({type: REJECTING, payload: {id}});
    const responseData = yield call(
      Reservation.rejectByClusterSIC,
      id,
      remarks
    );
    const {code, message, data} = responseData || {};
    if (code === 200) {
      yield put({type: REJECTED, payload: {id, data, key: 'clusterSICs'}});
      swal('Success', message, 'success');
      if (onReject) {
        onReject();
      }
    } else {
      yield put({type: REJECTED});
      yield put(setGlobalError(message));
      swal('Failed', message, 'error');
    }
  } catch (e) {
    yield put(setGlobalError(config.INTERNAL_SERVER_ERROR));
    yield put({type: REJECTED});
  }
}

function* releaseReservationActionHandler(action) {
  const {payload} = action;

  try {
    const {id, onRelease, key} = payload || {};
    yield put({type: RELEASING, payload: {id}});
    const responseData = yield call(Reservation.release, id);
    const {code, message} = responseData || {};
    if (code === 200) {
      yield put({type: RELEASED, payload: {id, key}});
      swal('Success', message, 'success');
      if (onRelease) {
        onRelease();
      }
    } else {
      yield put({type: RELEASED});
      yield put(setGlobalError(message));
      swal('Failed', message, 'error');
    }
  } catch (e) {
    yield put(setGlobalError(config.INTERNAL_SERVER_ERROR));
    yield put({type: RELEASED});
  }
}

function* findRoomForClusterAdminActionHandler(action) {
  const {payload} = action;

  yield put({type: FIND_ROOM_CLUSTER_ADMIN_REQUEST});
  const {
    start_time,
    end_time,
    check_in,
    check_in_release,
    booking_type,
    total_attendance,
    book_platform,
    key,
    is_workspace,
    reservation_type,
    video_conference,
    meeting_type,
  } = payload || {};
  const responseData = yield call(Reservation.findRoomForClusterAdmin, {
    start_time,
    end_time,
    check_in,
    check_in_release,
    booking_type,
    total_attendance,
    book_platform,
    key,
    is_workspace,
    reservation_type,
    video_conference,
    meeting_type,
  });
  const {code, data, message} = responseData || {};
  if (code === 200) {
    yield put({type: FIND_ROOM_CLUSTER_ADMIN_SUCCEED});
    yield put({type: SET_MEETING_ROOMS, payload: {rooms: data}});
  } else {
    yield put({type: FIND_ROOM_CLUSTER_ADMIN_FAILED});
    // yield put(setGlobalError(message));
    toast({title: message, type: 'error'});
  }
}

function* getReservationListForLiasonOfficerActionHandler(action) {
  const {payload} = action;

  yield put({type: GET_RESERVATION_LIASON_OFFICER_REQUEST});

  const {page, perPage, sortBy, key, descending} = payload || {};
  const responseData = yield call(Reservation.getListForLiasonOfficer, {
    page,
    perPage,
    sortBy,
    key,
    descending,
  });
  const {code, data, message} = responseData || {};
  const {content, pagginate} = data || {};
  if (code === 200) {
    yield put({
      type: GET_RESERVATION_LIASON_OFFICER_SUCCEED,
      payload: {reservations: content},
    });
    yield put({type: SET_PAGINATION, payload: {pagination: pagginate}});
  } else {
    yield put({type: GET_RESERVATION_LIASON_OFFICER_FAILED});
    yield put(setGlobalError(message));
  }
}

function* getReservationListByDateActionHandler(action) {
  const {payload} = action;

  yield put({type: GET_RESERVATION_RECEPTIONIST_REQUEST});

  const {
    page,
    perPage,
    sortBy,
    key,
    fromDate,
    toDate,
    descending,
    status,
    show_all,
    meeting_type,
  } = payload || {};
  const responseData = yield call(Reservation.getListByDate, {
    page,
    perPage,
    sortBy,
    key,
    fromDate,
    toDate,
    descending,
    status,
    show_all,
    meeting_type,
  });
  const {status: statusCode, data, message} = responseData || {};
  const {content, pagginate} = data || {};
  if (statusCode === 200) {
    yield put({
      type: GET_RESERVATION_RECEPTIONIST_SUCCEED,
      payload: {reservations: content},
    });
    yield put({type: SET_PAGINATION, payload: {pagination: pagginate}});
  } else {
    yield put({type: GET_RESERVATION_RECEPTIONIST_FAILED});
    yield put(setGlobalError(message));
  }
}

function* getReservationListApprovalRequestHandler(action) {
  const {payload} = action;

  yield put({type: GET_RESERVATION_APPROVAL_REQUESTS_REQUEST});
  const {page, perPage, sortBy, key, descending, is_pending} = payload || {};
  const responseData = yield call(Reservation.getListApprovalRequest, {
    page,
    perPage,
    sortBy,
    key,
    descending,
    is_pending,
  });
  const {code, data, message} = responseData || {};
  const {content, pagginate} = data || {};
  if (code === 200) {
    yield put({type: LOADED});
    yield put({
      type: GET_RESERVATION_APPROVAL_REQUESTS_SUCCEED,
      payload: {data: content},
    });
    // yield put({type: SET_RESERVATIONS, payload: {reservations: content}});
    yield put({type: SET_PAGINATION, payload: {pagination: pagginate}});
  } else {
    yield put({type: LOADED});
    yield put({
      type: GET_RESERVATION_APPROVAL_REQUESTS_FAILED,
      payload: {data: content},
    });
    yield put(setGlobalError(message));
  }
}

function* updateReservationRequestHandler(action) {
  const {payload} = action;

  yield put({type: UPDATING_RESERVATION});
  const {id, data} = payload || {};
  const res = yield call(Reservation.update, id, data);
  const {data: reservation, code, message} = res || {};

  if (code === 200) {
    yield put({
      type: UPDATE_RESERVATION_SUCCESS,
      payload: {data: reservation},
    });
    yield put({type: UPDATE_RESERVATION_DONE});
  } else {
    yield put({type: UPDATE_RESERVATION_FAILED});
    swal('Error', message, 'error');
  }
}

/**
 * Get my cluster.
 *
 * @param action
 * @returns {IterableIterator<*>}
 */
function* getMyClusterAdminRequestHandler(action) {
  try {
    yield put({type: GET_MY_CLUSTER_ADMIN_REQUEST});

    const res = yield call(Reservation.getMyClusterAdmin);
    const {data, code, message} = res || {};

    if (code === 200) {
      yield put({
        type: GET_MY_CLUSTER_ADMIN_SUCCESS,
        payload: {data},
      });
    } else {
      yield put({type: GET_MY_CLUSTER_ADMIN_FAILED});
      yield put(setGlobalError(message));
    }
  } catch (e) {
    yield put({type: GET_MY_CLUSTER_ADMIN_FAILED});
    yield put(setGlobalError(config.INTERNAL_SERVER_ERROR));
  }
}

/**
 * Get curren user SIC cluster.
 *
 * @param action
 * @returns {IterableIterator<*>}
 */
function* getMyClusterSICRequestHandler(action) {
  try {
    yield put({type: GET_MY_CLUSTER_SIC_REQUEST});

    const res = yield call(Reservation.getMyClusterSIC);
    const {data, code, message} = res || {};

    if (code === 200) {
      yield put({
        type: GET_MY_CLUSTER_SIC_SUCCESS,
        payload: {data},
      });
    } else {
      yield put({type: GET_MY_CLUSTER_SIC_FAILED});
      yield put(setGlobalError(message));
    }
  } catch (e) {
    yield put({type: GET_MY_CLUSTER_SIC_FAILED});
    yield put(setGlobalError(config.INTERNAL_SERVER_ERROR));
  }
}

function* rescheduleReservationRequestHandler(action) {
  const {payload} = action;
  const {data: formData} = payload || {};

  yield put({type: RESCHEDULE_RESERVATION_REQUEST});
  const res = yield call(Reservation.reschedule, formData);
  const {data, code, message} = res || {};

  if (code === 200) {
    yield put({
      type: RESCHEDULE_RESERVATION_SUCCESS,
      payload: {data},
    });
    yield put({type: RESCHEDULE_RESERVATION_DONE});
  } else {
    yield put({type: RESCHEDULE_RESERVATION_FAILED});
    swal('Error', message, 'error');
  }
}

function* addGuestActionHandler(action) {
  const {payload} = action;
  const {id: id_reservation, guest, onSuccess} = payload || {};

  yield put({type: ADD_GUEST_REQUEST});
  const res = yield call(Reservation.addGuest, {
    id_reservation,
    guest,
  });
  const {data, code, message} = res || {};

  if (code === 200) {
    yield put({
      type: ADD_GUEST_SUCCEED,
      payload: {data},
    });
    yield put(closeModal('modalAddGuest'));
    setTimeout(() => {
      toast({
        title: 'Guest added successfully!',
        type: 'info',
      });
      if (onSuccess) {
        onSuccess(data);
      }
    }, 1000);
  } else {
    yield put({type: ADD_GUEST_FAILED});
    swal('Error', message, 'error');
  }
}

function* getReservationByPOIActionHandler(action) {
  const {payload} = action;
  const {id} = payload || {};

  yield put({type: GET_RESERVATION_BY_POI_REQUEST});
  const res = yield call(Reservation.getReservationByPOI, id);
  const {data, code, message} = res || {};

  if (code === 200) {
    const {reservation, poi} = data || {};
    yield put({
      type: GET_RESERVATION_BY_POI_SUCCEED,
      payload: {reservation, poi},
    });
  } else {
    yield put({type: GET_RESERVATION_BY_POI_FAILED});
    swal('Error', message, 'error');
  }
}

/**
 * Reservation Saga.
 *
 * @returns {IterableIterator<*|ForkEffect>}
 */
export default function*() {
  yield takeEvery(GET_WFO_RESERVATION_LIST, getWFOList);
  yield takeEvery(
    GET_RESERVATION_LIST_REQUEST,
    getReservationListActionHandler
  );
  yield takeEvery(
    GET_RESERVATION_DETAIL_ACTION,
    getReservationDetailActionHandler
  );
  yield takeEvery(FIND_MEETING_ROOM_REQUEST, findRoomActionHandler);
  yield takeEvery(BOOK_RESERVATION_REQUEST, bookReservationActionHandler);
  yield takeEvery(CANCEL_RESERVATION_REQUEST, cancelReservationActionHandler);
  yield takeEvery(APPROVE_RESERVATION_REQUEST, approveReservationActionHandler);
  yield takeEvery(
    APPROVE_RESERVATION_BY_CLUSTER_ADMIN_REQUEST,
    approveByClusterAdminReservationActionHandler
  );
  yield takeEvery(
    APPROVE_RESERVATION_BY_CLUSTER_SIC_REQUEST,
    approveByClusterSICReservationActionHandler
  );
  yield takeEvery(REJECT_RESERVATION_REQUEST, rejectReservationActionHandler);
  yield takeEvery(
    REJECT_RESERVATION_BY_CLUSTER_ADMIN_REQUEST,
    rejectByClusterAdminReservationActionHandler
  );
  yield takeEvery(
    REJECT_RESERVATION_BY_CLUSTER_SIC_REQUEST,
    rejectByClusterSICReservationActionHandler
  );
  yield takeEvery(RELEASE_RESERVATION_REQUEST, releaseReservationActionHandler);
  yield takeEvery(
    GET_RESERVATION_CLUSTER_ADMIN_ACTION,
    getReservationClusterAdminActionHandler
  );
  yield takeEvery(
    GET_RESERVATION_CLUSTER_SIC_ACTION,
    getReservationClusterSICActionHandler
  );
  yield takeEvery(
    BOOK_RESERVATION_CLUSTER_ADMIN_REQUEST,
    bookReservationForClusterAdminActionHandler
  );
  yield takeEvery(
    FIND_MEETING_ROOM_CLUSTER_ADMIN_REQUEST,
    findRoomForClusterAdminActionHandler
  );
  yield takeEvery(
    BOOK_GROUP_RESERVATION_CLUSTER_ADMIN_REQUEST,
    bookGroupReservationClusterAdminActionHandler
  );
  yield takeEvery(
    FIND_ROOM_WITH_QRCODE_REQUEST,
    findRoomWithQRCodeActionHandler
  );
  yield takeEvery(
    GET_RESERVATION_LIST_FOR_RECEPTIONIST_REQUEST,
    getReservationListForReceptionistActionHandler
  );
  yield takeEvery(
    GET_RESERVATION_LIST_FOR_LIASON_OFFICER_REQUEST,
    getReservationListForLiasonOfficerActionHandler
  );
  yield takeEvery(
    GET_RESERVATION_LIST_BY_DATE_REQUEST,
    getReservationListByDateActionHandler
  );
  yield takeEvery(
    GET_RESERVATION_LIST_APPROVAL_REQUEST,
    getReservationListApprovalRequestHandler
  );
  yield takeEvery(UPDATE_RESERVATION_REQUEST, updateReservationRequestHandler);
  yield takeEvery(GET_MY_CLUSTER_ADMIN_ACTION, getMyClusterAdminRequestHandler);
  yield takeEvery(GET_MY_CLUSTER_SIC_ACTION, getMyClusterSICRequestHandler);
  yield takeEvery(
    RESCHEDULE_RESERVATION_ACTION,
    rescheduleReservationRequestHandler
  );
  yield takeEvery(ADD_GUEST_ACTION, addGuestActionHandler);
  yield takeEvery(
    GET_RESERVATION_BY_POI_ACTION,
    getReservationByPOIActionHandler
  );
  yield takeEvery(BOOK_RESERVATION_WFO_REQUEST, reserveWFO);
  yield takeEvery(CANCEL_WFO_RESERVATION_REQUEST, cancelWFOReserve);
}
