import React, {Component} from 'react';
import styled, {css} from 'styled-components';
import PropTypes from 'prop-types';

class Pagination extends Component {
  constructor(props) {
    super(props);
    const {maxSize, totalPage} = props;
    this.state = {
      currentPage: props.currentPage || 1,
      sliceStart: 0,
      disableHook: false,
      maxSize,
      totalPage,
    };
  }

  isActive = page => {
    const {currentPage} = this.state;
    return currentPage === page;
  };

  next = () => {
    const {currentPage, totalPage} = this.state;
    const page = currentPage + 1;
    if (page <= totalPage) {
      this.onPageChanged(page);
    }
  };

  prev = () => {
    const {currentPage} = this.state;
    const page = currentPage - 1;
    if (page > 0) {
      this.onPageChanged(page);
    }
  };

  onPageChanged = page => {
    this.setState({currentPage: page});
    const {onChange} = this.props;
    if (onChange) {
      onChange(page);
      this.setState({disableHook: false});
    }
  };

  onFirstPage = () => {
    const {currentPage} = this.state;
    return currentPage === 1;
  };

  onLastPage = () => {
    const {currentPage, totalPage} = this.state;
    return currentPage === totalPage;
  };

  goToFirstPage = () => {
    this.onPageChanged(1);
  };

  goToLastPage = () => {
    const {totalPage} = this.state;
    this.onPageChanged(totalPage);
  };

  nextChunk = () => {
    const {maxSize} = this.state;
    const {sliceStart: currentSliceStart} = this.state;
    this.setState({sliceStart: currentSliceStart + maxSize, disableHook: true});
  };

  prevChunk = () => {
    const {maxSize} = this.state;
    const {sliceStart: currentSliceStart} = this.state;
    this.setState({sliceStart: currentSliceStart - maxSize, disableHook: true});
  };

  componentWillUpdate(prevProps, prevState) {
    if (prevState.disableHook) {
      return;
    } else {
      const {
        sliceStart: currentChunkStart,
        currentPage,
        maxSize: chunkSize,
        totalPage,
      } = prevState;
      const currentChunkEnd = currentChunkStart + chunkSize;
      if (currentPage > currentChunkEnd) {
        const lastChunkStart = totalPage - chunkSize;
        if (currentPage > lastChunkStart) {
          this.setState({sliceStart: lastChunkStart});
        } else {
          this.setState({sliceStart: currentPage - 1});
        }
      } else if (currentPage < currentChunkStart + 1) {
        if (currentPage > chunkSize) {
          this.setState({sliceStart: currentPage - chunkSize});
        } else {
          this.setState({sliceStart: 0});
        }
      }
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (
      nextProps.totalPage !== this.state.totalPage ||
      nextProps.currentPage !== this.state.currentPage
    ) {
      this.setState({
        totalPage: nextProps.totalPage,
        currentPage: nextProps.currentPage,
      });
    }
  }

  render() {
    const {
      onPageChanged,
      isActive,
      next,
      prev,
      onFirstPage,
      onLastPage,
      goToFirstPage,
      goToLastPage,
    } = this;
    const {sliceStart, maxSize, totalPage} = this.state;
    const sliceEnd = sliceStart + maxSize;
    const data = [...Array(totalPage).keys()].map(item => item + 1);
    const items = data.slice(sliceStart, sliceEnd);
    return (
      <PaginationWrapper>
        <PaginationList>
          {!onFirstPage() && (
            <PaginationItem onClick={goToFirstPage}>First</PaginationItem>
          )}
          <PaginationItem disable={onFirstPage()} onClick={prev}>
            Previous
          </PaginationItem>
          {sliceStart > 0 && (
            <PaginationItem onClick={this.prevChunk}>&hellip;</PaginationItem>
          )}
          {items.map(page => {
            return (
              <PaginationItem
                active={isActive(page)}
                onClick={() => onPageChanged(page)}
                key={page}>
                {page}
              </PaginationItem>
            );
          })}
          {sliceEnd < totalPage && (
            <PaginationItem onClick={this.nextChunk}>&hellip;</PaginationItem>
          )}
          <PaginationItem disable={onLastPage()} onClick={next}>
            Next
          </PaginationItem>
          {!this.onLastPage() && (
            <PaginationItem onClick={goToLastPage}>Last</PaginationItem>
          )}
        </PaginationList>
      </PaginationWrapper>
    );
  }
}

const PaginationWrapper = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;

const PaginationList = styled.ol`
  display: block;
  position: relative;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
`;
const PaginationItem = styled.li`
  position: relative;
  display: inline-block;
  padding: .5rem .75rem;
  margin-left: -1px;
  line-height: 1.25;
  cursor: pointer;
  &:first-child {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  &:last-child {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  ${props => {
    if (props.disable) {
      return css`
        cursor: not-allowed;
        color: #6c757d;
        background-color: #dee2e6;
        border-color: #dee2e6;
      `;
    } else if (props.active) {
      return css`
        background-color: #e40000;
        border-color: #e40000;
        color: #fff;
      `;
    } else {
      return css`
        color: #e40000;
        background-color: #fff;
        border: 1px solid #dee2e6;
      `;
    }
  }}
  }}
`;

Pagination.propTypes = {
  maxSize: PropTypes.number,
  totalPage: PropTypes.number,
  onChange: PropTypes.func,
};

Pagination.defaultProps = {
  maxSize: 5,
  totalPage: 1,
  onChange: () => {},
};

export default Pagination;
